import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'NoAppointmentDisplay.title',
    defaultMessage: 'Aucun ambassadeur contacté',
  },
  description: {
    id: 'NoAppointmentDisplay.description',
    defaultMessage: 'Découvrez ici les profils des ambassadeurs contactés par le participant',
  },
});
