import { gql } from '@apollo/client';

export const GET_SCHOOL_DATA = gql`
  query GET_SCHOOL_DATA {
    getSchoolData 
  }
`;

export const GET_SCHOOLS = gql`
  query GET_SCHOOLS($department: String!, $schoolType: String!, $name: String!) {
    schools(department: $department, schoolType: $schoolType, name: $name) {
      nodes {
        id
        name
        communeName
      }
    } 
  }
`;