import { defineMessages } from 'react-intl';

const commonErrorsTranslations = defineMessages({
  default: {
    id: 'errors.default',
    defaultMessage: 'Une erreur est survenue, merci de réessayer',
  },
  network: {
    id: 'errors.network',
    defaultMessage: "Oups, il semblerait qu'on ait perdu le contact !",
  },
  technical: {
    id: 'errors.technical',
    defaultMessage: 'Oups ! Un problème technique est survenu ! Essayez de rafraîchir la page.',
  },
});

export default commonErrorsTranslations;
