import { gql } from '@apollo/client';

export const GET_PROJECT_TO_UPDATE = gql`
  query GET_PROJECT_TO_UPDATE($id: ID!) {
    project(id: $id) {
      id
      name
      schools {
        nodes {
          id
          name
        }
      }
    }
  }
`;

export const SEARCH_ORGANISATION_BY_NAME = gql`
  query SEARCH_ORGANISATION_BY_NAME($term: String!) {
    organizations(filter: { search: $term }) {
      nodes {
        id
        name
      }
    }
  }
`;

export const CREATE_NEW_PROJECT = gql`
  mutation CREATE_NEW_PROJECT($name: String!, $schools: [SchoolInput!]) {
    project: createProject(input: { name: $name, schools: $schools}) {
      id
    }
  }
`;

export const UPDATE_PROJECT = gql`
  mutation UPDATE_PROJECT($id: ID!, $name: String!, $schools: [SchoolInput!]) {
    project: updateProject(id: $id, input: { name: $name, schools: $schools }) {
      id
      name
    }
  }
`;

export const GET_SCHOOL_DATA = gql`
  query GET_SCHOOL_DATA {
    getSchoolData 
  }
`;

export const GET_SCHOOLS = gql`
  query GET_SCHOOLS($department: String!, $schoolType: String!, $name: String!) {
    schools(department: $department, schoolType: $schoolType, name: $name) {
      nodes {
        id
        name
        communeName
      }
    } 
  }
`;