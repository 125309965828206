import { gql } from '@apollo/client';
import { PROJECT_MEMBERS_INVITATION, PROJECT_MEMBERS_MEMBERSHIP } from '../../../../ProjectShow.gql';

export const DELEGATE_OWNERSHIP = gql`
  ${PROJECT_MEMBERS_MEMBERSHIP}

  mutation DELEGATE_OWNERSHIP($id: ID!) {
    delegateMembership(id: $id) {
      ...PROJECT_MEMBERS_MEMBERSHIP
    }
  }
`;

export const EDIT_MEMBERSHIP_ROLE = gql`
  ${PROJECT_MEMBERS_MEMBERSHIP}

  mutation EDIT_MEMBERSHIP_ROLE($id: ID!, $role: Role!) {
    updateMembership(id: $id, input: { role: $role }) {
      ...PROJECT_MEMBERS_MEMBERSHIP
    }
  }
`;

export const EDIT_INVITATION_ROLE = gql`
  ${PROJECT_MEMBERS_INVITATION}

  mutation EDIT_INVITATION_ROLE($id: ID!, $role: InvitationRole!) {
    updateInvitation(id: $id, input: { role: $role }) {
      ...PROJECT_MEMBERS_INVITATION
    }
  }
`;
