import React, { useContext } from 'react';
import classnames from 'classnames';
import ModalContext from '../../context/ModalContext';

import styles from './ModalFooter.module.scss';

interface ModalFooterProps {
  cancelText: string;
  submitText: string;
  disableSubmit?: boolean;
  cancelFunction?: () => void;
  submitFunction?: () => void;
  className?: string;
  hideCancelButton?: boolean;
}
function ModalFooter({
  cancelText,
  submitText,
  disableSubmit,
  cancelFunction,
  submitFunction,
  className,
  hideCancelButton,
}: ModalFooterProps): JSX.Element {
  const { closeModal } = useContext(ModalContext);

  return (
    <footer className={classnames(styles.modalFooter, className)}>
      {!hideCancelButton && (
        <button
          type="button"
          onClick={typeof cancelFunction === 'function' ? cancelFunction : closeModal}
          className={styles.cancelButton}
          data-testid="cancel-button"
        >
          {cancelText}
        </button>
      )}
      <button
        type={typeof submitFunction === 'function' ? 'button' : 'submit'}
        disabled={disableSubmit}
        className={styles.submitButton}
        onClick={typeof submitFunction === 'function' ? submitFunction : undefined}
        data-testid="submit-button"
      >
        {submitText}
      </button>
    </footer>
  );
}

export default ModalFooter;
