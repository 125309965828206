import React from 'react';
import Icon from '../../../../components/icon/Icon';

import styles from './CustomCheckbox.module.scss';

interface CustomCheckboxProps {
  id?: string;
  name?: string;
  indeterminate?: boolean;
  checked?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
}
export default function CustomCheckbox({ id, name, indeterminate, checked, onChange, disabled }: CustomCheckboxProps) {
  const ref = React.useRef<HTMLInputElement>(null);
  function onClick() {
    if (!disabled) {
      const checkbox = ref.current!;
      Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, 'checked')?.set?.call(
        checkbox,
        !checkbox.checked,
      );
      checkbox.dispatchEvent(new Event('click', { bubbles: true }));
    }
  }

  return (
    <>
      <input
        type="checkbox"
        name={name}
        onChange={onChange}
        checked={checked}
        className={styles.input}
        ref={ref}
        disabled={disabled}
      />
      <span
        role="checkbox"
        aria-checked={indeterminate && !checked ? 'mixed' : checked}
        tabIndex={0}
        aria-labelledby={id}
        className={styles.customCheckbox}
        onClick={onClick}
        onKeyPress={onClick}
        aria-disabled={disabled}
      >
        {indeterminate && !checked && (
          <Icon
            name="remove"
            className={styles.icon}
          />
        )}
        {checked && (
          <Icon
            name="check"
            className={styles.icon}
          />
        )}
      </span>
    </>
  );
}
