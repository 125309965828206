import { useQuery } from '@apollo/client';
import React from 'react';
import { useIntl } from 'react-intl';
import { SchoolRoutes } from '../../../../Routes';
import { Get_Sidebar_Project_TreeQuery, Get_Sidebar_Project_TreeQueryVariables } from '../../@types/graphql';
import TreeNavItemLink from './components/tree-nav-item/components/tree-nav-item-link/TreeNavItemLink';
import TreeNavProject from './components/tree-nav-item/components/tree-nav-project/TreeNavProject';
import TreeNavTitle from './components/tree-nav-title/TreeNavTitle';
import { GET_SIDEBAR_PROJECT_TREE } from './TreeNav.gql';
import translations from './TreeNav.translations';

import styles from './TreeNav.module.scss';

function TreeNav() {
  const intl = useIntl();

  const { data } = useQuery<Get_Sidebar_Project_TreeQuery, Get_Sidebar_Project_TreeQueryVariables>(
    GET_SIDEBAR_PROJECT_TREE,
  );

  return (
    <>
      <nav className={styles.treeNav}>
        <ul role="menu">
          <TreeNavItemLink
            id="sidebar-home-item"
            label={intl.formatMessage(translations.home)}
            icon="home"
            link={SchoolRoutes.home}
          />
        </ul>
      </nav>
      <nav className={styles.treeNav}>
        <TreeNavTitle
          id={intl.formatMessage(translations.projects)}
          label={intl.formatMessage(translations.projects)}
        />
        <ul role="tree">
          {data?.projects?.nodes.map((project) => (
            <TreeNavProject
              key={project?.id}
              project={project}
            />
          ))}
        </ul>
      </nav>
    </>
  );
}

export default TreeNav;
