import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ImportParticipantsModal.title',
    defaultMessage: 'Importer des participants',
  },
  submitList: {
    id: 'ImportParticipantsModal.submitList',
    defaultMessage: 'Importer les participants',
  },
  cancel: {
    id: 'ImportParticipantsModal.cancel',
    defaultMessage: 'Annuler',
  },
  sectionTitle: {
    id: 'ImportParticipantsModal.sectionTitle',
    defaultMessage: 'Importez une liste de plusieurs participants en 3 étapes',
  },
  importStepsIntro: {
    id: 'ImportParticipantsModal.importStepsIntro',
    defaultMessage: "Voici comment se déroule l'import de participations",
  },
  importStep1DownloadXlsx: {
    id: 'ImportParticipantsModal.importStep1DownloadXlsx',
    defaultMessage: 'Download the template Excel XLSX file',
  },
  importStep2FillXlsx: {
    id: 'ImportParticipantsModal.importStep2FillXlsx',
    defaultMessage: 'Open the file, and fill in the fields for each participant',
  },
  importStep3UploadXlsx: {
    id: 'ImportParticipantsModal.importStep3UploadXlsx',
    defaultMessage: 'Upload your file on this page',
  },
  importStep4ReviewErrors: {
    id: 'ImportParticipantsModal.importStep4ReviewErrors',
    defaultMessage: 'After you click import, wait for the file to be processed, and review any errors',
  },
  instructions: {
    id: 'ImportParticipantsModal.instructions',
    defaultMessage: 'Instructions',
  },
  firstItem: {
    id: 'ImportParticipantsModal.firstItem',
    defaultMessage: 'Seuls les fichiers de type XLSX sont acceptés.',
  },
  secondItem: {
    id: 'ImportParticipantsModal.secondItem',
    defaultMessage: 'La feuille de calcul xlsx doit faire moins de 10Mo et ne contenir qu’une seule feuille.',
  },
  thirdItem: {
    id: 'ImportParticipantsModal.thirdItem',
    defaultMessage:
      "Le fichier doit comporter uniquement les en-têtes suivants: 'Prenom', 'Nom', 'Email' (non sensible à la casse).",
  },
  fourthItem: {
    id: 'ImportParticipantsModal.fourthItem',
    defaultMessage: 'Ces 3 valeurs sont requises par personne afin de procéder à la création de participants.',
  },
  fifthItem: {
    id: 'ImportParticipantsModal.fifthItem',
    defaultMessage: "La clé unique pour identifier un participant existant est la colonne 'email'.",
  },
  sixthItem: {
    id: 'ImportParticipantsModal.sixthItem',
    defaultMessage: 'Si un participant existe déjà, une affiliation au programme lui sera ajoutée.',
  },
  seventhItem: {
    id: 'ImportParticipantsModal.seventhItem',
    defaultMessage: "Une invitation est envoyée automatiquement aux participants lors de l'import. ",
  },
  eighthItem: {
    id: 'ImportParticipantsModal.eighthItem',
    defaultMessage:
      "La possibilité de choisir la date d'envoi des invitations, et de relancer les invitations restées sans réponse arrivera prochainement.",
  },
  ninthItem: {
    id: 'ImportParticipantsModal.ninthItem',
    defaultMessage:
      'Si votre programme est déjà en cours, pensez à envoyer une notification à ces nouveaux participants.',
  },
  selectFile: {
    id: 'ImportParticipantsModal.selectFile',
    defaultMessage: 'Sélectionner le fichier .xlsx rempli',
  },
  downloadTemplateCardTitle: {
    id: 'ImportParticipantsModal.downloadTemplateCardTitle',
    defaultMessage: 'Télécharger le fichier modèle',
  },
  downloadTemplateCardDescription: {
    id: 'ImportParticipantsModal.downloadTemplateCardDescription',
    defaultMessage: 'Ce modèle vous permettra de renseigner votre liste de participants au format attendu',
  },
  downloadTemplateCardButton: {
    id: 'ImportParticipantsModal.downloadTemplateCardButton',
    defaultMessage: 'Télécharger le modèle',
  },
  uploadFileCardTitle: {
    id: 'ImportParticipantsModal.uploadFileCardTitle',
    defaultMessage: 'Importez votre fichier complété',
  },
  uploadFileCardDescription: {
    id: 'ImportParticipantsModal.uploadFileCardDescription',
    defaultMessage: 'Importez le fichier complété contenant votre liste de participants',
  },
  uploadFileCardButton: {
    id: 'ImportParticipantsModal.uploadFileCardButton',
    defaultMessage: 'Importer mon fichier',
  },
  submitFormCardTitle: {
    id: 'ImportParticipantsModal.submitFormCardTitle',
    defaultMessage: 'Validez votre import',
  },
  submitFormCardDescription: {
    id: 'ImportParticipantsModal.submitFormCardDescription',
    defaultMessage: 'Dernière étape, cliquez sur valider pour envoyer les invitations',
  },
  submitFormCardButton: {
    id: 'ImportParticipantsModal.submitFormCardButton',
    defaultMessage: 'Valider',
  },
  success: {
    id: 'ImportParticipantsModal.success',
    defaultMessage:
      'Votre fichier a bien été envoyé. Merci de rafraîchir la page dans une dizaine de secondes avec F5 ou via le bouton du navigateur.',
  },
  error: {
    id: 'ImportParticipantsModal.error',
    defaultMessage:
      "Une erreur est survenue lors de l'envoi de votre fichier. Veuillez vérifier votre import (titre des colonnes, etc) et réessayer. Si le problème persiste, contactez My Job Glasses",
  },
});

export const toastrMessages = defineMessages({
  success: {
    id: 'ImportParticipantsModal.success',
    defaultMessage:
      'Votre fichier a bien été envoyé. Merci de rafraîchir la page dans une dizaine de secondes avec F5 ou via le bouton du navigateur.',
  },
  error: {
    id: 'ImportParticipantsModal.error',
    defaultMessage: "Une erreur est survenue lors de l'envoi de votre fichier. Veuillez réessayer.",
  },
  PROGRAM_IS_ALREADY_PROCESSING_AN_IMPORT: {
    id: 'ImportParticipantsModal.PROGRAM_IS_ALREADY_PROCESSING_AN_IMPORT',
    defaultMessage: 'Un import est déjà en cours',
  },
  CANNOT_PARSE_FILE: {
    id: 'ImportParticipantsModal.CANNOT_PARSE_FILE',
    defaultMessage: 'Le format du fichier est invalide',
  },
  FILE_IS_MISSING_HEADERS: {
    id: 'ImportParticipantsModal.FILE_IS_MISSING_HEADERS',
    defaultMessage: 'Il manque un ou plusieurs des headers suivants: Prénom, Nom, Email',
  },
});
