import { gql } from '@apollo/client';

export const GET_PROGRAM_PARTICIPATION_PROGRESS = gql`
  query GET_PROGRAM_PARTICIPATION_PROGRESS($id: ID!) {
    participation(id: $id) {
      id
      confirmedMeetingCount
      unfollowedConversationCount
      waitingForConfirmationMeetingCount
      program {
        id
        meetingsQuantity
      }
      hermesContacts {
        appointmentContactMethod
        appointmentDate
        avatarUrl
        companyName
        companySector
        jobTitle
        professionalFirstName
        professionalUrl
        state
      }
    }
  }
`;
