import React from 'react';
import XIcon from '../../assets/component-icons/XIcon';
import { MessageDescriptor, useIntl } from 'react-intl';

import './RemovableTag.scss';

type RemovableTagProps<T> = {
  value: T;
  name?: string;
  translation?: MessageDescriptor | string;
  onRemove: (value: T) => void;
};

export default function RemovableTag<T>({ value, name, translation, onRemove }: RemovableTagProps<T>) {
  const intl = useIntl();

  const displayedText =
    typeof translation === 'string' ? translation : translation ? intl.formatMessage(translation) : name;

  return (
    <button
      type="button"
      className="removable-tag"
      onClick={() => onRemove(value)}
    >
      <span>{displayedText}</span>
      <XIcon />
    </button>
  );
}
