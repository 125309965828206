import React, { useState } from 'react';
import classnames from 'classnames';
import { FieldProps } from 'formik';
import Icon from '../../icon/Icon';

import styles from './TextField.module.scss';

interface WithLabel {
  labelId: undefined;
  label: string;
}
interface WithoutLabel {
  labelId: string;
  label: undefined;
}
type TextFieldProps = FieldProps & {
  type: 'text' | 'password' | 'number' | 'email';
  className?: string;
  helper?: string;
  disabled: boolean;
  leadingIcon?: string;
  trailingIcon?: string;
  maxCharCount?: number;
  withoutBorder?: boolean;
  placeholder?: string;
  submitOnBlur?: boolean;
} & (WithLabel | WithoutLabel);

function TextField({
  field,
  form,
  type = 'text',
  className,
  label,
  labelId,
  helper,
  disabled,
  leadingIcon,
  trailingIcon,
  maxCharCount,
  withoutBorder,
  placeholder,
  submitOnBlur,
}: TextFieldProps): JSX.Element {
  const error = form.errors?.[field.name];
  const touched = form.touched?.[field.name];

  const [isFocused, setIsFocused] = useState(false);
  const focus = () => setIsFocused(true);
  function blur(e: React.FocusEvent<HTMLInputElement>) {
    field.onBlur(e);
    setIsFocused(false);
    if (submitOnBlur) {
      form.submitForm();
    }
  }
  function change(e: React.ChangeEvent<HTMLInputElement>) {
    if (maxCharCount) {
      if (e.target.value.length > maxCharCount) {
        field.onChange({
          ...e,
          target: {
            ...e.target,
            value: e.target.value.slice(0, maxCharCount),
          },
        });
        return;
      }
    }
    field.onChange(e);
  }

  const [inputType, setInputType] = useState<TextFieldProps['type']>(type);
  function togglePasswordVisibility() {
    setInputType(inputType === 'password' ? 'text' : 'password');
  }

  return (
    <div
      data-testid="text-field"
      data-test-name={field.name}
      className={classnames(styles.fieldWrapper, {
        [styles.withoutBorder]: withoutBorder,
      })}
    >
      <div
        className={classnames(styles.field, className, {
          [styles.focused]: isFocused,
          [styles.withValue]: field.value,
          [styles.disabled]: disabled,
        })}
        aria-invalid={(!!error && !!touched) || undefined}
      >
        {leadingIcon && (
          <Icon
            name={leadingIcon}
            className={styles.leadingIcon}
          />
        )}
        <input
          id={field.name}
          type={inputType}
          name={field.name}
          value={field.value}
          onChange={change}
          onFocus={focus}
          onBlur={blur}
          disabled={disabled}
          aria-labelledby={label ? `${field.name}_label ${helper && `${field.name}_helper`}` : labelId}
          aria-errormessage={`${field.name}_error_message`}
          placeholder={placeholder}
        />
        {trailingIcon && type !== 'password' && (
          <Icon
            name={trailingIcon}
            className={styles.trailingIcon}
          />
        )}
        {type === 'password' && (
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className={styles.togglePasswordVisibilityButton}
          >
            <Icon
              name={inputType === 'password' ? 'visibility_off' : 'visibility'}
              className={styles.trailingIcon}
            />
          </button>
        )}
        {label && (
          <label
            id={`${field.name}_label`}
            htmlFor={field.name}
            className={styles.label}
          >
            {label}
          </label>
        )}
      </div>
      <div className={styles.footer}>
        {helper && (!error || !touched) && (
          <p
            id={`${field.name}_helper`}
            className={styles.helper}
          >
            {helper}
          </p>
        )}
        {error && touched && (
          <p
            id={`${field.name}_error_message`}
            className={styles.error}
          >
            {error}
          </p>
        )}
        {maxCharCount && (
          <p
            className={classnames(styles.charCounter, {
              [styles.maxCharReached]: field.value?.length >= maxCharCount,
            })}
          >
            {field.value?.length || 0}/{maxCharCount}
          </p>
        )}
      </div>
    </div>
  );
}

export default TextField;
