import Icon from '../../../../../../../../components/icon/Icon';
import ProgressDonut from '../../../../../../../../components/progress-donut/ProgressDonut';
import { useIntl } from 'react-intl';
import RTFBold from '../../../../../../rich-text-formatting/bold/RTFBold';
import translations from './ProgramShowTargetProgressionWithParticipants.translations';

import styles from './ProgramShowTargetProgressionWithParticipants.module.scss';

interface ProgramShowTargetProgressionWithParticipantsProps {
  completedParticipationsCount: number;
  meetingsQuantity: number;
  expectedParticipations: number;
}
export default function ProgramShowTargetProgressionWithParticipants({
  completedParticipationsCount,
  meetingsQuantity,
  expectedParticipations,
}: ProgramShowTargetProgressionWithParticipantsProps) {
  const intl = useIntl();

  return (
    <div className={styles.programShowTargetProgressionWithParticipants}>
      <div>
        <ProgressDonut
          count={completedParticipationsCount}
          total={expectedParticipations}
        />
      </div>
      <div>
        <div className={styles.targetWrapper}>
          <Icon
            name="track_changes"
            className={styles.illustration}
          />
          <div className={styles.target}>
            <p className={styles.targetTitle}>{intl.formatMessage(translations.targetTitle)}</p>
            <p className={styles.targetDisplay}>
              {intl.formatMessage(translations.targetDisplay, {
                x: meetingsQuantity,
              })}
            </p>
          </div>
        </div>
        <p className={styles.description}>
          {intl.formatMessage(translations.description, {
            boldThis: RTFBold,
            value: (text: string[]) => <span className={styles.value}>{text.join('')}</span>,
            x: Math.round((completedParticipationsCount / expectedParticipations) * 100),
          })}
        </p>
      </div>
    </div>
  );
}
