import React, { useCallback } from 'react';
import Modal from '../../../../../../../../../../components/modal/Modal';
import ModalHeader from '../../../../../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../../../../../components/modal/components/modal-footer/ModalFooter';
import { useIntl } from 'react-intl';
import commonButtonsTranslations from '../../../../../../../../commons/translations/buttons.translations';
import translations from './ArchiveParticipationModal.translations';

import styles from './ArchiveParticipationModal.module.scss';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../../../Routes';
import client from '../../../../../../../../config/graphql';
import {
  Remove_Participant_Or_Invitation_From_ProgramMutation,
  Remove_Participant_Or_Invitation_From_ProgramMutationVariables,
} from '../../../../../../../../@types/graphql';
import { useMutation } from '@apollo/client';
import { REMOVE_PARTICIPANT_OR_INVITATION_FROM_PROGRAM } from '../../ParticipantsTable.gql';

export default function ArchiveParticipationModal() {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const values = location.state?.values || [];
  const { programid = '' } = useParams();

  const [removeParticipations] = useMutation<
    Remove_Participant_Or_Invitation_From_ProgramMutation,
    Remove_Participant_Or_Invitation_From_ProgramMutationVariables
  >(REMOVE_PARTICIPANT_OR_INVITATION_FROM_PROGRAM, {
    onError: (err) => {
      console.log(err.graphQLErrors?.[0]?.extensions?.code);
    },
  });


  const remove = useCallback(
    (ids: string[]) => {
      return Promise.all(
        ids.map((id) =>
          removeParticipations({
            refetchQueries: ['GET_PARTICIPANTS_AND_INVITATIONS'],
            variables: {
              id,
            },
            awaitRefetchQueries: true,
          }).then(() => {
            client.cache.evict({
              id: client.cache.identify({
                id,
                __typename: 'Participation',
              }),
            });
            navigate(generatePath(SchoolRoutes.programShow, { programid }))
          })
        )
      );
    },
    [removeParticipations]
  );

  return (
    <Modal
      title={intl.formatMessage(translations.confirmTitle)}
      openPathname={generatePath(SchoolRoutes.programShowRemoveParticipation, { programid })}
      closeUrl={generatePath(SchoolRoutes.programShow, { programid })}
    >
      <ModalHeader title={intl.formatMessage(translations.confirmTitle)} />

      <ModalBody>
        <p className={styles.description}>{intl.formatMessage(translations.confirmDescription)}</p>
      </ModalBody>

      <ModalFooter
        className={styles.modalFooter}
        cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
        submitFunction={() => remove(values)}
        submitText={intl.formatMessage(commonButtonsTranslations.confirm)}
      />
    </Modal>
  );
}
