import React from 'react';
import { useMemo, useCallback, useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../../../components/snackbar/hooks/useSnackbar';
import Modal from '../../../../../../../../components/modal/Modal';
import ModalHeader from '../../../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../../../components/modal/components/modal-footer/ModalFooter';
import StaticAutocompleteMultiSelectInput from '../../../../../../../../components/static-autocomplete-multi-select-input/StaticAutocompleteMultiSelectInput';
import { useFormik } from 'formik';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../Routes';
import {
  Get_Member_ProgramsQuery,
  Get_Member_ProgramsQueryVariables,
  CreateDesignationsMutation,
  CreateDesignationsMutationVariables,
} from '../../../../../../@types/graphql';
import useMembershipId from '../../hooks/useMembershipId';
import commonButtonsTranslations from '../../../../../../commons/translations/buttons.translations';
import { ADD_TO_PROGRAMS, GET_MEMBER_PROGRAMS } from './MemberProgramModal.gql';
import translations from './MemberProgramModal.translations';
import styles from './MemberProgramModal.module.scss';

type FormikValues = {
  programs: string[];
};

interface MemberProgramModalProps {
  projectId: string;
}

export default function MemberProgramModal({ projectId }: MemberProgramModalProps) {
  const intl = useIntl();
  const navigate = useNavigate();

  const membershipId = useMembershipId() || '';

  const { success, error } = useSnackbar();

  const [placeholder, setPlaceholder] = useState<string>(intl.formatMessage(translations.placeholder));
  const [nonManagedPrograms, setnonManagedPrograms] = useState<number>(0);

  const { data } = useQuery<Get_Member_ProgramsQuery, Get_Member_ProgramsQueryVariables>(GET_MEMBER_PROGRAMS, {
    variables: {
      id: membershipId,
    },
  });

  const [addMemberToPrograms] = useMutation<CreateDesignationsMutation, CreateDesignationsMutationVariables>(
    ADD_TO_PROGRAMS,
  );

  const formik = useFormik<FormikValues>({
    initialValues: {
      programs: [],
    },
    onSubmit: async ({ programs }) => {
      await addMemberToPrograms({
        variables: {
          program_ids: programs,
          membership_id: membershipId,
        },
      })
        .then(() => {
          success(intl.formatMessage(translations.success));
          navigate(generatePath(SchoolRoutes.projectMembers, { projectid: projectId }));
        })
        .catch((e) => {
          error(e.message);
        });
    },
  });

  const handlePrograms = useCallback(
    (program: any) => {
      if (!formik.values.programs.includes(program.value)) formik.setFieldValue('programs', [...formik.values.programs, program.value]);
      else {
        formik.setFieldValue(
          'programs',
          formik.values.programs.filter((v) => v !== program.value),
        );
      }
    },
    [formik.values.programs],
  );

  const selectAll = () => {
    if (formik.values.programs.length === data?.membership?.nonManagedPrograms?.nodes?.length) {
      formik.setFieldValue('programs', []);
    } else {
      formik.setFieldValue('programs', data?.membership?.nonManagedPrograms?.nodes?.map((node) => node?.id) || []);
    }
  };

  const options = useMemo(
    () =>
      data?.membership?.nonManagedPrograms?.nodes?.map((node) => ({
        parent: node?.group?.name || '',
        key: node?.id,
        name: node?.name,
      })) || [],
    [data],
  );

  useEffect(() => {
    if (formik.values.programs.length > 0) {
      setPlaceholder(intl.formatMessage(translations.selectedPrograms, { count: formik.values.programs.length }));
    } else {
      setPlaceholder(intl.formatMessage(translations.placeholder));
    }
  }, [formik.values.programs]);

  useEffect(() => {
    setnonManagedPrograms(data?.membership?.nonManagedPrograms?.nodes?.length || 0);
  });

  return (
    <Modal
      title={intl.formatMessage(translations.title)}
      openPathname={SchoolRoutes.projectMemberAddToProgram}
      closeUrl={generatePath(SchoolRoutes.projectMembers, { projectid: projectId })}
    >
      <form onSubmit={formik.handleSubmit}>
        <ModalHeader
          className={styles.title}
          title={intl.formatMessage(translations.title)}
        />
        <ModalBody className={styles.modalBody}>
          {nonManagedPrograms > 0 ? (
            <>
              <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
              <StaticAutocompleteMultiSelectInput
                name="programs"
                values={options.map((value) => ({
                  parent: value.parent,
                  value: value.key,
                  translation: value.name,
                }))}
                placeholder={placeholder}
                selectedValues={formik.values.programs || []}
                onChange={handlePrograms}
                selectAll={selectAll}
                allowSelectAll={true}
              />
            </>
          ) : (
            <p className={styles.description}>{intl.formatMessage(translations.zeroNonManagedPrograms)}</p>
          )}
        </ModalBody>
        <ModalFooter
          cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
          submitText={intl.formatMessage(commonButtonsTranslations.validate)}
          className={styles.modalFooter}
          disableSubmit={!formik.dirty}
        />
      </form>
    </Modal>
  );
}
