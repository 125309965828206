import { defineMessages } from 'react-intl';

export default defineMessages({
  resend: {
    id: 'GroupActions.resend',
    defaultMessage: 'Renvoyer',
  },
  import: {
    id: 'GroupActions.import',
    defaultMessage: 'Importer',
  },
});
