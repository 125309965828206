import styles from './ContentLoading.module.scss';

export default function ContentLoading() {
  return (
    <div className={styles.loader}>
      <div />
      <div />
    </div>
  );
}
