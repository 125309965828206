import { gql } from '@apollo/client';

export const GET_PROGRAM_BEFORE_PUBLICATION = gql`
  query GET_PROGRAM_BEFORE_PUBLICATION($id: ID!) {
    program(id: $id) {
      id
      name
      school
      grade
      diploma
      startAt
      endAt
      expectedParticipations
      targetMeetingsCategories
      targetStudentsCategories
      meetingsQuantity
      collaborations {
        nodes {
          user {
            id
            firstName
            lastName
          }
        }
      }
      programTemplate {
        name
        templateOptions {
          minimalDaysDuration
          meetingsQuantity
        }
      }
      description
      individualSupport
      programNotificationFrequency
    }
  }
`;
