import { gql } from '@apollo/client';

export const GET_MEMBER_PROGRAMS = gql`
  query GET_MEMBER_PROGRAMS($id: ID!) {
    membership(id: $id) {
      nonManagedPrograms {
        nodes {
          id
          name
          group {
            name
          }
        }
      }
    }
  }
`;

export const ADD_TO_PROGRAMS = gql`
  mutation createDesignations($program_ids: [ID!]!, $membership_id: ID!) {
    createDesignations(programIds: $program_ids, membershipId: $membership_id) {
      id
    }
  }
`;
