import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'PreventLeaveProgramCreation.title',
    defaultMessage: 'Abandonner la configuration du programme ?',
  },
  description: {
    id: 'PreventLeaveProgramCreation.description',
    defaultMessage:
      'Si vous quittez la page de configuration d’un programme, toutes les données configurées seront perdues.',
  },
});
