import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ParticipantsTableEmpty.title',
    defaultMessage: 'Aucun participant n’a rejoint votre programme',
  },
  description: {
    id: 'ParticipantsTableEmpty.description',
    defaultMessage:
      'Copiez le code de participation et partagez le à votre groupe de membres pour qu’ils rejoignent votre programme.',
  },
  copyCode: {
    id: 'ParticipantsTableEmpty.copyCode',
    defaultMessage: 'Copier le code',
  },
  codeCopied: {
    id: 'ParticipantsTableEmpty.codeCopied',
    defaultMessage: 'Code copié',
  },
});
