import React from 'react';
import { useMemo } from 'react';
import Card from '../../../../../../../../components/card/Card';
import { FieldProps } from 'formik';
import { useIntl } from 'react-intl';
import { Unpacked } from '../../../../../../../../@types/helper';
import { ProgramTemplate } from '../../../../../../@types/graphql';
import RTFBold from '../../../../../../rich-text-formatting/bold/RTFBold';
import translations from './ProgramTemplateField.translations';

import styles from './ProgramTemplateField.module.scss';

interface ProgramTemplateFieldProps extends FieldProps {
  templates: Array<
    Pick<ProgramTemplate, 'id' | 'displayedName' | 'description'> & {
      templateOptions?: Pick<
        Unpacked<NonNullable<ProgramTemplate['templateOptions']>>,
        'minimalDaysDuration' | 'meetingsQuantity'
      > | null;
    }
  >;
}
export default function ProgramTemplateField({ field, templates }: ProgramTemplateFieldProps) {
  const intl = useIntl();

  const mappedTemplate = useMemo(
    () =>
      templates.map((template) => ({
        id: template.id,
        name: template.displayedName,
        description: template.description,
        options: [
          {
            name: intl.formatMessage(translations.xMeetings, {
              x: template.templateOptions?.meetingsQuantity,
            }),
            description: intl.formatMessage(translations.xMeetingsDescription),
          },
          {
            name: intl.formatMessage(translations.xMonth, {
              x: (template.templateOptions?.minimalDaysDuration || 30) / 30,
            }),
            description: intl.formatMessage(translations.xMonthDescription),
          },
        ],
      })),
    [templates],
  );

  return (
    <div className={styles.programTemplateField}>
      {mappedTemplate.map((template) => (
        <label
          key={template.id}
          htmlFor={`${field.name}_${template.id}`}
          className={styles.option}
        >
          <input
            id={`${field.name}_${template.id}`}
            type="radio"
            name={field.name}
            value={template.id}
            onChange={field.onChange}
            checked={field.value === template.id}
          />
          <Card
            name={intl.formatMessage(translations.program, {
              name: template.name,
              boldThis: RTFBold,
            })}
            description={template.description}
            active={field.value === template.id}
            options={template.options}
            className={styles.card}
          />
        </label>
      ))}
    </div>
  );
}
