import { defineMessages } from 'react-intl';
import { Role } from '../../@types/graphql';

const commonRoleTranslations = defineMessages<Role>({
  [Role.Owner]: {
    id: 'enums.Role.owner',
    defaultMessage: 'Propriétaire',
  },
  [Role.Admin]: {
    id: 'enums.Role.admin',
    defaultMessage: 'Administrateur',
  },
  [Role.Companion]: {
    id: 'enums.Role.companion',
    defaultMessage: 'Accompagnateur',
  },
  [Role.Guest]: {
    id: 'enums.Role.guest',
    defaultMessage: 'Invité',
  },
});

export default commonRoleTranslations;
