import React from 'react';
import { useQuery } from '@apollo/client';
import { ProgressionStepsChildrenProps } from '../../../../../../components/progression-steps/ProgressionSteps';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import {
  Get_Program_Before_PublicationQuery,
  Get_Program_Before_PublicationQueryVariables,
} from '../../../../@types/graphql';
import PageError from '../../../../commons/page-error/PageError';
import PageLoading from '../../../../commons/page-loading/PageLoading';
import commonButtonsTranslations from '../../../../commons/translations/buttons.translations';
import RTFBold from '../../../../rich-text-formatting/bold/RTFBold';
import { StepsContext } from '../../@types/steps-context';
import ProgramCustomizeDetails from './components/program-customize-details/ProgramCustomizeDetails';
import ProgramNotificationsConfiguration from './components/program-notifications-configuration/ProgramNotificationsConfiguration';
import ProgramPublicationGroup from './components/program-publication-group/ProgramPublicationGroup';
import useProgramPublicationAction from './hooks/useProgramPublicationAction';
import { GET_PROGRAM_BEFORE_PUBLICATION } from './ProgramPublicationView.gql';
import translations from './ProgramPublicationView.translations';

import styles from './ProgramPublicationView.module.scss';
import GlobalErrorDisplay from '../../../../commons/global-error-display/GlobalErrorDisplay';

export default function ProgramPublicationView({
  onPreviousStep,
  context,
}: ProgressionStepsChildrenProps<StepsContext>) {
  const intl = useIntl();

  const { data, loading, error, refetch } = useQuery<
    Get_Program_Before_PublicationQuery,
    Get_Program_Before_PublicationQueryVariables
  >(GET_PROGRAM_BEFORE_PUBLICATION, {
    skip: !context?.programId,
    variables: {
      id: context?.programId || '',
    },
  });

  const onSubmit = useProgramPublicationAction(context?.programId);

  if (loading) {
    return <PageLoading />;
  }

  if (!data?.program || error) {
    return <PageError refetch={refetch} />;
  }

  return (
    <section>
      <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
      <p className={styles.description}>{intl.formatMessage(translations.description)}</p>

      <ProgramPublicationGroup
        title={intl.formatMessage(
          data.program.individualSupport ? translations.individualCoachingTitle : translations.groupCoachingTitle,
          {
            boldThis: RTFBold,
          },
        )}
      />

      <ProgramPublicationGroup
        title={intl.formatMessage(translations.programType, {
          programTemplateName: data.program.programTemplate.name,
          meetingRequired: data.program.meetingsQuantity,
        })}
      />

      <ProgramPublicationGroup
        title={intl.formatMessage(translations.programCustomizationTitle)}
        component={ProgramCustomizeDetails}
        program={data.program}
      />

      <ProgramPublicationGroup
        title={intl.formatMessage(translations.notificationConfiguration)}
        component={ProgramNotificationsConfiguration}
        program={data.program}
      />

      <Formik
        initialValues={{}}
        onSubmit={onSubmit}
      >
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <GlobalErrorDisplay className={styles.error} />
            <div className={styles.actions}>
              <button
                type="button"
                onClick={() => onPreviousStep()}
                className={styles.previousButton}
              >
                {intl.formatMessage(commonButtonsTranslations.previous)}
              </button>
              <button
                type="submit"
                className={styles.nextButton}
              >
                {intl.formatMessage(commonButtonsTranslations.create)}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </section>
  );
}
