import { defineMessages } from 'react-intl';

export default defineMessages({
  individualCoaching: {
    id: 'CoachingTypeField.individualCoaching',
    defaultMessage: 'Accompagner un <boldThis>seul membre</boldThis>',
  },
  groupCoaching: {
    id: 'CoachingTypeField.groupCoaching',
    defaultMessage: 'Accompagner un <boldThis>groupe de membres</boldThis>',
  },
  optionIndividualSupport: {
    id: 'CoachingTypeField.optionIndividualSupport',
    defaultMessage: 'Suivi individuel',
  },
  optionCollectiveSupport: {
    id: 'CoachingTypeField.optionCollectiveSupport',
    defaultMessage: 'Suivi collectif',
  },
  optionConsolidatedData: {
    id: 'CoachingTypeField.optionConsolidatedData',
    defaultMessage: 'Statistiques consolidées',
  },
});
