import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { generatePath, matchPath, useLocation, useNavigate } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../Routes';
import { GET_SCHOOL_DATA, GET_SCHOOLS } from './SchoolForm.gql';
import translations from './SchoolForm.translations';

import styles from './SchoolForm.module.scss';
import Icon from '../../../../../../components/icon/Icon';
import SelectInput from '../../../../../../components/select-input/SelectInput';
import DynamicAutocompleteCategorizedSelectInput from '../../../../../../components/dynamic-autocomplete-categorized-select-input/DynamicAutocompleteCategorizedSelectInput';
import RemovableTag from '../../../../../../components/removable-tag/RemovableTag';
import useDebounce from '../../../../../../hooks/use-debounce';
import SearchIcon from '../../../../../../assets/component-icons/SearchIcon';
import CreateGouvSchoolModal from '../create-gouv-school-modal/CreateGouvSchoolModal';

interface SchoolFormProps {
  formik: any;
  state: {
    schoolId?: string;
    schoolName?: string;
  };
  existingSchools?: any[];
}

export default function SchoolForm({ formik, state, existingSchools }: SchoolFormProps) {
  const intl = useIntl();
  const navigate = useNavigate();

  const [department, setDepartment] = useState('');
  const [schoolType, setSchoolType] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [debouncedSchoolName] = useDebounce(schoolName);
  const [schoolOptions, setSchoolOptions] = useState<{ categoryKey: string; value: string; translation: string }[]>([]);

  const { data, loading } = useQuery(GET_SCHOOL_DATA);

  const { data: schools, loading: schoolsLoading } = useQuery(GET_SCHOOLS, {
    variables: {
      department: department,
      schoolType: schoolType,
      name: debouncedSchoolName,
    },
    skip: !department || !debouncedSchoolName || !schoolType,
  });

  const departmentOptions = data?.getSchoolData?.departments
    ? Object.entries(data.getSchoolData.departments)
        .sort(([aKey], [bKey]) => aKey.localeCompare(bKey, undefined, { numeric: true }))
        .map(([key, value]) => ({
          value: key,
          translation: value,
        }))
    : [];

  const schoolTypeOptions = data?.getSchoolData?.school_types
    ? Object.entries(data.getSchoolData.school_types).map(([key, value]) => ({
        value: key,
        translation: value,
      }))
    : [];

  useEffect(() => {
    if (Array.isArray(schools?.schools?.nodes)) {
      const options = schools.schools.nodes.map((school: { id: string; communeName: string; name: string }) => ({
        value: school.id,
        categoryKey: school.communeName,
        translation: school.name,
      }));
      setSchoolOptions(options);
    } else {
      setSchoolOptions([]);
    }
  }, [schools?.schools?.nodes]);

  useEffect(() => {
    if (data?.getSchoolData) {
      setSchoolType(data.getSchoolData.initial_school_type_key);

      if (data?.getSchoolData?.initial_school.length && !existingSchools) {
        const initialSchool = [
          {
            id: data.getSchoolData.initial_school[0],
            name: data.getSchoolData.initial_school[1],
          },
        ];

        formik.setFieldValue('schools', initialSchool);
      }

      if (existingSchools) {
        const schools = existingSchools?.map((school) => ({
          id: school.id,
          name: school.name,
        }));

        formik.setFieldValue('schools', schools);
      }
    }
  }, [data?.getSchoolData, existingSchools]);

  useEffect(() => {
    const newSchools = [
      ...(Array.isArray(formik.values.schools) ? formik.values.schools : []),
      ...(state?.schoolId && state?.schoolName ? [{ id: state.schoolId, name: state.schoolName }] : []),
    ];

    formik.setFieldValue('schools', newSchools);
    navigate(window.location.pathname, { replace: true, state: null });
  }, [state]);

  const isForeignDepartment = !/\d/.test(department);
  const shouldShowSchoolEmptyStateMessageForeignDepartment = isForeignDepartment && debouncedSchoolName.length >= 1;
  const shouldShowSchoolEmptyStateMessage =
    shouldShowSchoolEmptyStateMessageForeignDepartment || debouncedSchoolName.length >= 5;

  function openGouvSchoolModal() {
    navigate(SchoolRoutes.createGouvSchool);
  }

  if (loading) {
    return null;
  }

  return (
    <>
      <p className={styles.label}>{intl.formatMessage(translations.schoolTitle)}</p>
      <div className={styles.schoolSubtitle}>
        <Icon name="info" />
        <p>{intl.formatMessage(translations.schoolSubtitle)}</p>
      </div>
      <p className={styles.schoolLabel}>{intl.formatMessage(translations.schoolLabelSubtitle)}</p>
      <fieldset className={styles.fieldset}>
        <SelectInput
          name="schoolType"
          placeholder={intl.formatMessage(translations.selectSchoolType)}
          values={schoolTypeOptions}
          value={schoolType}
          onChange={(schoolType) => {
            setSchoolType(schoolType);
          }}
        />
      </fieldset>
      <fieldset className={styles.multiFieldset}>
        <SelectInput
          name="department"
          placeholder={intl.formatMessage(translations.selectDepartment)}
          values={departmentOptions}
          className={styles.multiFieldSelect}
          value={department}
          onChange={(department) => {
            setDepartment(department);
          }}
        />
        <DynamicAutocompleteCategorizedSelectInput
          name="schoolIds"
          placeholder={intl.formatMessage(translations.selectSchool)}
          values={schoolOptions}
          icon={SearchIcon}
          loading={schoolsLoading}
          className={styles.multiFieldSelect}
          onSelection={(school) => {
            if ((formik.values.schools || []).length < 10) {
              formik.setFieldValue('schools', [
                ...(formik.values.schools || []),
                { id: school.value, name: school.translation },
              ]);
            }
            setDepartment('');
            setSchoolType('');
          }}
          onTyping={(value) => {
            setSchoolName(value);
          }}
          emptyStateMessage={
            shouldShowSchoolEmptyStateMessage ? intl.formatMessage(translations.emptySchoolNameMessage) : ''
          }
          onEmptyStateClick={openGouvSchoolModal}
        />
      </fieldset>
      {formik.values.schools.length >= 1 && (
        <>
          <p className={styles.selectedSchoolsLabel}>{intl.formatMessage(translations.selectedSchoolsLabel)}</p>
          <div className={styles.selectedSchools}>
            {formik.values.schools.map((school, index) => (
              <RemovableTag
                key={index}
                value={school.id}
                name={school.name}
                onRemove={() => {
                  formik.setFieldValue(
                    'schools',
                    formik.values.schools.filter((_, i) => i !== index),
                  );
                }}
              />
            ))}
          </div>
        </>
      )}
      <CreateGouvSchoolModal
        department={department}
        schoolType={schoolType}
        name={debouncedSchoolName}
      />
    </>
  );
}
