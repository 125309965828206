import { defineMessages } from 'react-intl';

const commonButtonsTranslations = defineMessages({
  cancel: {
    id: 'buttons.cancel',
    defaultMessage: 'Annuler',
  },
  confirm: {
    id: 'buttons.confirm',
    defaultMessage: 'Confirmer',
  },
  validate: {
    id: 'buttons.validate',
    defaultMessage: 'Valider',
  },
  next: {
    id: 'buttons.next',
    defaultMessage: 'Suivant',
  },
  previous: {
    id: 'buttons.previous',
    defaultMessage: 'Précédent',
  },
  apply: {
    id: 'buttons.apply',
    defaultMessage: 'Appliquer',
  },
  close: {
    id: 'buttons.close',
    defaultMessage: 'Fermer',
  },
  create: {
    id: 'buttons.create',
    defaultMessage: 'Créer',
  },
  gotIt: {
    id: 'buttons.gotIt',
    defaultMessage: "J'ai compris",
  },
});

export default commonButtonsTranslations;
