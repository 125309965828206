import { useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { ProgramShowParams } from '../../@types/route-params';
import { SchoolRoutes } from '../../../../Routes';

export default function useProgramRouteMatch(programId: string) {
  const [isCurrentLocation, setIsCurrentLocation] = useState(false);
  const currentLocation = useLocation();

  useEffect(() => {
    if (
      matchPath(SchoolRoutes.baseProgram, location.pathname)?.params?.programid === programId
    ) {
      setIsCurrentLocation(true);
    } else {
      setIsCurrentLocation(false);
    }
  }, [currentLocation]);

  return [isCurrentLocation];
}
