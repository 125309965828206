import { ProgressionStepsChildrenProps } from '../../../../../../components/progression-steps/ProgressionSteps';
import { Field, Formik } from 'formik';
import { useIntl } from 'react-intl';
import commonButtonsTranslations from '../../../../commons/translations/buttons.translations';
import { CoachingType } from '../../@types/coaching-type';
import { StepsContext } from '../../@types/steps-context';
import CoachingTypeField from './components/coaching-type-field/CoachingTypeField';
import translations from './CoachingTypeView.translations';
import validationSchema from './CoachingTypeView.validations';

import styles from './CoachingTypeView.module.scss';

interface FormikValues {
  coachingType: CoachingType | '';
}
export default function CoachingTypeView({ onNextStep, context }: ProgressionStepsChildrenProps<StepsContext>) {
  const intl = useIntl();

  function onSubmit(values: FormikValues) {
    onNextStep({
      ...context,
      ...values,
    });
  }

  return (
    <Formik
      initialValues={{
        coachingType: context?.coachingType || '',
      }}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnMount
    >
      {({ handleSubmit, isValid }) => (
        <form onSubmit={handleSubmit}>
          <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
          <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
          <Field
            name="coachingType"
            component={CoachingTypeField}
          />
          <div className={styles.actions}>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={!isValid}
            >
              {intl.formatMessage(commonButtonsTranslations.next)}
            </button>
          </div>
        </form>
      )}
    </Formik>
  );
}
