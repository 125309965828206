import classnames from 'classnames';
import Icon from '../../../icon/Icon';
import { AvatarSize } from '../../@types/avatar';

import styles from './AvatarFallback.module.scss';

interface AvatarFallbackProps {
  size: AvatarSize;
  className?: string;
}
function AvatarFallback({ size, className }: AvatarFallbackProps) {
  return (
    <span className={classnames(styles.avatarContainer, [styles[size]], className)}>
      <Icon
        name="person_outline"
        className={styles.icon}
      />
    </span>
  );
}

export default AvatarFallback;
