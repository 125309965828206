import React from 'react';
import { useMemo, useState } from 'react';
import Icon from '../../../../../../../../../../components/icon/Icon';
import Tooltip from '../../../../../../../../../../components/tooltip/Tooltip';
import classnames from 'classnames';
import { useDrag } from 'react-dnd';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useMatch, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../../../Routes';
import { Group, Maybe, Program } from '../../../../../../../../@types/graphql';
import { ProjectCustomizeParams } from '../../../../../../../../@types/route-params';
import { DNDItem } from '../../../../enums/DND';
import useAddGroupMutation from '../../hooks/add-group-mutation/useAddGroupMutation';
import useMoveGroupProgram from '../../hooks/use-move-group-program/useMoveGroupProgram';
import ProjectStructureListItem from '../project-structure-list-item/ProjectStructureListItem';
import GroupNameEditionForm from './components/group-name-edition-form/GroupNameEditionForm';
import translations from './ProjectStructureListGroupItem.translations';

import styles from './ProjectStructureListGroupItem.module.scss';

type ChildProgram = Pick<Program, 'id' | 'name' | '__typename'> & {
  canUpdate: Pick<Program['canUpdate'], 'value'>;
};
type ChildGroup = Pick<Group, 'id' | 'name' | '__typename'> & {
  canCreateGroup: Pick<Group['canCreateGroup'], 'value'>;
  canDelete: Pick<Group['canDelete'], 'value'>;
  canUpdate: Pick<Group['canUpdate'], 'value'>;
  groups?: Maybe<{ nodes: ChildGroup[] }>;
  programs?: Maybe<{ nodes: ChildProgram[] }>;
};
interface ProjectStructureListGroupItemProps {
  group: ChildGroup;
  level: number;
}
export default function ProjectStructureListGroupItem({ group, level = 0 }: ProjectStructureListGroupItemProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const hasChildren = !!group.groups?.nodes.length || !!group.programs?.nodes.length;
  const match = useMatch<ProjectCustomizeParams>(SchoolRoutes.projectCustomize);
  const { projectid } = useParams();

  const [isShowingSubItems, setSubItemsVisibility] = useState(true);
  function toggle() {
    setSubItemsVisibility(!isShowingSubItems);
  }

  const addNewSubGroup = useAddGroupMutation(projectid, group.id);
  function handleAddGroup() {
    addNewSubGroup();
    setSubItemsVisibility(true);
  }

  function handleDeleteGroup() {
    if (projectid && group.id) {
      navigate(
        generatePath(SchoolRoutes.projectGroupDelete, {
          projectid,
          groupid: group.id,
        }),
      );
    }
  }

  const disabledDeleteGroupButton = useMemo(
    () => !group.canDelete.value || typeof projectid !== 'string' || typeof group.id !== 'string',
    [group, level, projectid],
  );

  const [, dragRef, previewDragRef] = useDrag(() => ({
    type: DNDItem.group,
    item: group,
    canDrag: group.canUpdate.value,
  }));

  const [{ isOver }, dropRef] = useMoveGroupProgram(group.id, projectid!, () => {
    setSubItemsVisibility(true);
  });

  return (
    <li
      className={styles.wrapper}
      data-testid={`group-${group.id}`}
      ref={previewDragRef}
    >
      <div
        className={classnames(styles.projectStudentListGroupItem, {
          [styles.dropOver]: isOver,
        })}
        data-testid={`group-${group.id}-drop`}
        ref={dropRef}
      >
        <button
          type="button"
          className={styles.dnd}
          ref={dragRef}
          data-testid={`group-${group.id}-drag`}
          disabled={!group.canUpdate.value}
        >
          <Icon
            name="more_vert"
            className={styles.icon}
          />
        </button>

        <div
          className={classnames(styles.content, [styles[`level${level}`]])}
          style={{
            marginLeft: level * 8,
          }}
        >
          <button
            type="button"
            onClick={toggle}
            className={styles.dropdownToggler}
            disabled={!hasChildren}
          >
            <Icon
              name={isShowingSubItems ? 'arrow_drop_down' : 'arrow_right'}
              className={styles.icon}
            />
          </button>

          <Icon
            name="folder"
            className={styles.folderIcon}
          />

          <GroupNameEditionForm
            group={group}
            className={styles.label}
          />

          <div className={styles.actions}>
            <Tooltip
              text={intl.formatMessage(translations.addAGroup)}
              position="bottom-start"
              tooltipClassName={styles.tooltip}
            >
              {({ tooltipId, onMouseEnter, onMouseLeave }) => (
                <button
                  type="button"
                  aria-label={tooltipId}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onClick={handleAddGroup}
                  className={styles.action}
                  disabled={!group.canCreateGroup.value || level >= 4}
                  data-testid="add-button"
                >
                  <Icon
                    name="create_new_folder"
                    className={styles.icon}
                  />
                </button>
              )}
            </Tooltip>
            <Tooltip
              text={intl.formatMessage(translations.deleteGroupTooltip)}
              position="bottom-start"
              tooltipClassName={styles.tooltip}
            >
              {({ tooltipId, onMouseEnter, onMouseLeave }) => (
                <button
                  type="button"
                  aria-label={tooltipId}
                  onMouseEnter={onMouseEnter}
                  onMouseLeave={onMouseLeave}
                  onClick={handleDeleteGroup}
                  className={styles.action}
                  disabled={disabledDeleteGroupButton}
                  data-testid="delete-button"
                >
                  <Icon
                    name="delete"
                    className={styles.icon}
                  />
                </button>
              )}
            </Tooltip>
          </div>
        </div>
      </div>
      <ul
        hidden={!isShowingSubItems}
        className={styles.subItems}
      >
        {group.groups?.nodes.map((subGroup) => (
          <ProjectStructureListItem
            key={subGroup.id}
            item={subGroup}
            level={level + 1}
          />
        ))}
        {group.programs?.nodes.map((subGroup) => (
          <ProjectStructureListItem
            key={subGroup.id}
            item={subGroup}
            level={level + 1}
          />
        ))}
      </ul>
    </li>
  );
}
