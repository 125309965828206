import { ApolloCache, useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../../../../../components/snackbar/hooks/useSnackbar';
import { useDrop } from 'react-dnd';
import { useIntl } from 'react-intl';
import {
  Get_Project_List_StructureQuery,
  Get_Project_List_StructureQueryVariables,
  Group,
  Program,
  Update_Group_Parent_GroupMutation,
  Update_Group_Parent_GroupMutationVariables,
  Update_Program_Parent_GroupMutation,
  Update_Program_Parent_GroupMutationVariables,
} from '../../../../../../../../@types/graphql';
import { MJGApolloError, MJGGraphQLErrorCode, UPDATE_GROUP_ERROR } from '../../../../../../../../@types/graphql-errors';
import commonErrorsTranslations from '../../../../../../../../commons/translations/errors.translations';
import { DNDItem } from '../../../../enums/DND';
import { GET_PROJECT_LIST_STRUCTURE } from '../../ProjectListStructure.gql';
import { UPDATE_GROUP_PARENT_GROUP, UPDATE_PROGRAM_PARENT_GROUP } from './useMoveGroupProgram.gql';
import { addItemToGroup, removeTreeElement } from './useMoveGroupProgram.utils';
import translations from './useMoveGroupProgram.translations';

function updateProjectTree(
  cache: ApolloCache<Update_Program_Parent_GroupMutation | Update_Group_Parent_GroupMutation>,
  projectId: string,
  item: Program | Group,
  parentId: string | null,
) {
  const previousData = cache.readQuery<Get_Project_List_StructureQuery, Get_Project_List_StructureQueryVariables>({
    query: GET_PROJECT_LIST_STRUCTURE,
    variables: {
      id: projectId,
    },
  });
  if (previousData?.project) {
    let optimisticProject = removeTreeElement(previousData.project, item.id) as typeof previousData.project;
    optimisticProject = addItemToGroup(optimisticProject, item, parentId);
    cache.writeQuery<Get_Project_List_StructureQuery, Get_Project_List_StructureQueryVariables>({
      query: GET_PROJECT_LIST_STRUCTURE,
      variables: {
        id: projectId,
      },
      data: {
        project: optimisticProject,
      },
    });
  }
}

export default function useMoveGroupProgram(groupId: string | null, projectId: string, afterDrop?: () => void) {
  const intl = useIntl();
  const { error: displayError } = useSnackbar();

  const [updateProgramParent] = useMutation<
    Update_Program_Parent_GroupMutation,
    Update_Program_Parent_GroupMutationVariables
  >(UPDATE_PROGRAM_PARENT_GROUP, {
    refetchQueries: ['GET_SIDEBAR_PROJECT_TREE', 'GET_PROJECT_LIST_STRUCTURE'],
  });

  const [updateGroupParent] = useMutation<
    Update_Group_Parent_GroupMutation,
    Update_Group_Parent_GroupMutationVariables
  >(UPDATE_GROUP_PARENT_GROUP, {
    refetchQueries: ['GET_SIDEBAR_PROJECT_TREE', 'GET_PROJECT_LIST_STRUCTURE'],
  });

  return useDrop(() => ({
    accept: [DNDItem.program, DNDItem.group],
    canDrop: (item) => item.id !== groupId,
    drop: (item: Program | Group) => {
      if (item.__typename === 'Program') {
        updateProgramParent({
          optimisticResponse: {
            updateProgram: item,
          },
          variables: {
            parentId: groupId,
            programId: item.id,
          },
          update: (cache) => {
            updateProjectTree(cache, projectId, item, groupId);
          },
        });
      } else if (item.__typename === 'Group') {
        updateGroupParent({
          optimisticResponse: {
            updateGroup: item,
          },
          variables: {
            groupId: item.id,
            parentId: groupId,
          },
          update: (cache) => {
            updateProjectTree(cache, projectId, item, groupId);
          },
        }).catch((error: MJGApolloError<{ parent: string; name: string }, UPDATE_GROUP_ERROR>) => {
          const firstError = error.graphQLErrors?.[0];
          if (
            firstError?.extensions?.code === MJGGraphQLErrorCode.BAD_USER_INPUT &&
            (firstError?.extensions?.validationErrors?.parent?.includes(
              UPDATE_GROUP_ERROR.GROUP_PARENT_CANNOT_PARENT_TO_CHILD,
            ) ||
              firstError?.extensions?.validationErrors?.parent?.includes(
                UPDATE_GROUP_ERROR.GROUP_PARENT_CANNOT_PARENT_TO_SELF,
              ) ||
              firstError?.extensions?.validationErrors?.name?.includes(UPDATE_GROUP_ERROR.GROUP_NAME_TAKEN))
          ) {
            displayError(intl.formatMessage(translations.GROUP_PARENT_CANNOT_PARENT_TO_CHILD));
          } else {
            displayError(intl.formatMessage(commonErrorsTranslations.default));
          }
        });
      }
      afterDrop?.();
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver() && !!monitor.canDrop(),
    }),
  }));
}
