import React from 'react';
import Icon from '../../../../../../../../components/icon/Icon';
import classnames from 'classnames';

import styles from './ProjectStatisticWithIcon.module.scss';

interface ProjectStatisticWithIconProps {
  title: string | React.ReactNode;
  iconName: string;
  highlight?: boolean;
  value?: number;
  description?: string | React.ReactNode;
  className?: string;
  withoutParticipant?: boolean;
}
export default function ProjectStatisticWithIcon({
  title,
  iconName,
  highlight,
  value,
  description,
  className,
  withoutParticipant,
}: ProjectStatisticWithIconProps) {
  return (
    <div className={classnames(styles.bloc, className)}>
      <Icon
        name={iconName}
        className={classnames(styles.icon, {
          [styles.highlight]: highlight,
        })}
      />
      <h4 className={styles.title}>{title}</h4>
      <p className={styles.value}>{withoutParticipant ? '-' : value || 0}</p>
      {(value || 0) > 0 && <p className={styles.description}>{description}</p>}
    </div>
  );
}
