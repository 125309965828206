import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ProgramNotificationConfigurationView.title',
    defaultMessage: 'Configuration des notifications',
  },
  description: {
    id: 'ProgramNotificationConfigurationView.description',
    defaultMessage: 'Définir la fréquence des emails de relance',
  },
  information: {
    id: 'ProgramNotificationConfigurationView.information',
    defaultMessage: 'Ces informations peuvent être modifiées ultérieurement.',
  },
  information2: {
    id: 'ProgramNotificationConfigurationView.information2',
    defaultMessage:
      'Les emails de relance rappellent aux participants le temps restant pour atteindre l’objectif fixé et ainsi recevoir un certificat My Job Glasses.<br></br><br></br>Ces emails sont envoyés tous les lundis matin pendant la durée du programme.',
  },
  emailFrequencyLabel: {
    id: 'ProgramNotificationConfigurationView.emailFrequencyLabel',
    defaultMessage: 'Fréquence d’envoi',
  },
});
