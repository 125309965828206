import { defineMessages } from 'react-intl';

export default defineMessages({
  singleImport: {
    id: 'TabSwitch.singleImport',
    defaultMessage: 'Participant unique',
  },
  imports: {
    id: 'TabSwitch.imports',
    defaultMessage: 'Liste de participants',
  },
  invitations: {
    id: 'TabSwitch.invitations',
    defaultMessage: 'Invitations',
  },
});
