import React from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Navigate } from 'react-router-dom';
import { SchoolRoutes } from '../../../../Routes';
import { Get_Archived_Projects_ListQuery, Get_Archived_Projects_ListQueryVariables } from '../../@types/graphql';
import PageError from '../../commons/page-error/PageError';
import PageLoading from '../../commons/page-loading/PageLoading';
import PageClassic from '../../layout/pages/page-classic/PageClassic';
import ArchivedProjectItem from './components/archived-project-item/ArchivedProjectItem';
import RecoverProjectModal from './components/recover-project-modal/RecoverProjectModal';
import { GET_ARCHIVED_PROJECTS_LIST } from './ProjectArchivedList.gql';
import translations from './ProjectArchivedList.translations';

import styles from './ProjectArchivedList.module.scss';

export default function ProjectArchivedList() {
  const intl = useIntl();
  const { data, loading, error, refetch } = useQuery<
    Get_Archived_Projects_ListQuery,
    Get_Archived_Projects_ListQueryVariables
  >(GET_ARCHIVED_PROJECTS_LIST);

  if (error) {
    return <PageError refetch={refetch} />;
  }

  if (loading) {
    return <PageLoading />;
  }

  if (!data?.archivedProjects?.nodes?.length) {
    return <Navigate to={SchoolRoutes.home} />;
  }

  return (
    <PageClassic
      title={intl.formatMessage(translations.title)}
      titleIcon="inventory_2"
      backLink={SchoolRoutes.home}
      loading={loading}
    >
      <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
      <section>
        {data?.archivedProjects?.nodes.map((project) => (
          <ArchivedProjectItem
            key={project?.id}
            item={project}
          />
        ))}
      </section>
      <RecoverProjectModal />
    </PageClassic>
  );
}
