import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MemberProgramModal.title',
    defaultMessage: 'Ajouter un membre à un ou plusieurs programmes',
  },
  description: {
    id: 'MemberProgramModal.description',
    defaultMessage:
      'Sélectionnez le ou les programmes concernés puis cliquez sur valider pour ajouter ce membre comme responsable du ou des programmes.',
  },
  placeholder: {
    id: 'MemberProgramModal.placeholder',
    defaultMessage: 'Sélectionnez un ou plusieurs programmes',
  },
  selectedPrograms: {
    id: 'MemberProgramModal.selectedPrograms',
    defaultMessage: '{count} programmes(s) sélectionné(s)',
  },
  success: {
    id: 'MemberProgramModal.success',
    defaultMessage: 'Le membre a bien été ajouté au(x) programme(s)',
  },
  editProgramSuccess: {
    id: 'MemberProgramModal.editProgramSuccess',
    defaultMessage: "L'invitation a bien été mise à jour",
  },
  zeroNonManagedPrograms: {
    id: 'MemberProgramModal.zeroNonManagedPrograms',
    defaultMessage:
      "Ce membre est responsable dans tous les programmes du projet et ne peut plus être ajouté à d'autres programmes.",
  },
});
