import Icon from '../../../../../../../../components/icon/Icon';
import Tooltip from '../../../../../../../../components/tooltip/Tooltip';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../Routes';
import translations from './ArchivedProjectsLink.translations';

import styles from './ArchivedProjectsLink.module.scss';

interface ArchivedProjectsLinkProps {
  total: number;
}
export default function ArchivedProjectsLink({ total }: ArchivedProjectsLinkProps) {
  const intl = useIntl();

  if (!total) {
    return (
      <Tooltip text={intl.formatMessage(translations.tootlip)}>
        {({ onMouseEnter, onMouseLeave, tooltipId }) => (
          <button
            type="button"
            onMouseEnter={onMouseEnter}
            onFocus={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onBlur={onMouseLeave}
            aria-describedby={tooltipId}
            className={styles.button}
          >
            <Icon
              name="inventory_2"
              className={styles.icon}
            />
            <span className={styles.label}>{intl.formatMessage(translations.label)}</span>
          </button>
        )}
      </Tooltip>
    );
  }

  return (
    <Link
      to={SchoolRoutes.projectArchivedList}
      className={styles.link}
    >
      <Icon
        name="inventory_2"
        className={styles.icon}
      />
      <span className={styles.label}>{intl.formatMessage(translations.label)}</span>
    </Link>
  );
}
