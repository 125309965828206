import React from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import ProgramShowCurrentParticipation from './components/program-show-current-participation/ProgramShowCurrentParticipation';
import ProgramShowNoParticipants from './components/program-show-no-participants/ProgramShowNoParticipants';
import translations from './ProgramShowParticipants.translations';

import styles from './ProgramShowParticipants.module.scss';

interface ProgramShowParticipantsProps {
  totalParticipations: number;
  expectedParticipations: number;
  className?: string;
}
export default function ProgramShowParticipants({
  totalParticipations,
  expectedParticipations,
  className,
}: ProgramShowParticipantsProps) {
  const intl = useIntl();

  return (
    <div className={classnames(styles.bloc, className)}>
      <h3 className={styles.title}>{intl.formatMessage(translations.title)}</h3>

      {totalParticipations > 0 ? (
        <ProgramShowCurrentParticipation
          totalParticipations={totalParticipations}
          expectedParticipations={expectedParticipations}
        />
      ) : (
        <ProgramShowNoParticipants />
      )}
    </div>
  );
}
