import React from 'react';
import Avatar from '../../../../../../../../components/avatar/Avatar';
import { AvatarSize } from '../../../../../../../../components/avatar/Avatar';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { HermesContact, HermesContactState } from '../../../../../../@types/graphql';
import AppointmentStatus from './components/appointment-status/AppointmentStatus';
import translations from './AppointmentItem.translations';

import styles from './AppointmentItem.module.scss';

interface AppointmentItemProps {
  item: Pick<
    HermesContact,
    | 'avatarUrl'
    | 'professionalFirstName'
    | 'professionalUrl'
    | 'jobTitle'
    | 'companyName'
    | 'companySector'
    | 'state'
    | 'appointmentDate'
    | 'appointmentContactMethod'
  >;
}
export default function AppointmentItem({ item }: AppointmentItemProps) {
  const intl = useIntl();

  return (
    <div
      className={classnames(styles.item, {
        [styles.notScheduled]: item.state === HermesContactState.NoScheduledAppointment,
        [styles.upcoming]: item.state === HermesContactState.Upcoming,
        [styles.waitingConfirmation]: item.state === HermesContactState.PastAwaitingConfirmation,
        [styles.confirmed]: item.state === HermesContactState.PastAndConfirmed,
        [styles.invalidated]: item.state === HermesContactState.InvalidatedAppointment,
        [styles.reported]: item.state === HermesContactState.ProfileReportedByTheProfessional,

        [styles.unfollowed]: item.state === HermesContactState.UnfollowedConversationWithThisProfessional,
      })}
    >
      <div className={styles.header}>
        <Avatar
          avatarLink={item.avatarUrl || undefined}
          avatarInitials={item.professionalFirstName.slice(0, 2)}
          size={AvatarSize.xsmall}
          className={styles.avatar}
        />
        <h5 className={styles.firstName}>{item.professionalFirstName}</h5>
        <a
          href={item.professionalUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={styles.link}
        >
          {intl.formatMessage(translations.seeProfile)}
        </a>
      </div>
      <p className={styles.jobTitle}>{item.jobTitle}</p>
      <p className={styles.companyName}>{`${item.companyName} (${item.companySector})`}</p>
      <h6 className={styles.appointment}>{intl.formatMessage(translations.appointment)}</h6>
      <AppointmentStatus
        status={item.state}
        type={item.appointmentContactMethod}
        date={item.appointmentDate}
      />
    </div>
  );
}
