import { gql } from '@apollo/client';
import { ProgramPublicationStatus } from '../../../../@types/graphql';

export const COUNT_MY_PROGRAMS = gql`
  query COUNT_MY_PROGRAMS($id: ID!) {
    project(id: $id) {
      id
      myPrograms: programs(filter: {
        query: [
          {
            filterBy: programPublicationStatus,
            operator: eq,
            value: "${ProgramPublicationStatus.Published}"
          },
          {
            filterBy: authorId,
            operator: eq,
            value: "me"
          }
        ]
      }) {
        total
      }
    }
  }
`;
