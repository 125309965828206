import { gql } from '@apollo/client';

export const GET_PROJECT_MEMBER_UPDATE_PERMISSIONS = gql`
  query GET_PROJECT_MEMBER_UPDATE_PERMISSIONS($id: ID!) {
    project(id: $id) {
      id
      myRole
    }
  }
`;
