import styles from './ProjectStructureSkeletonListItem.module.scss';

interface ProjectStructureSkeletonListItemProps {
  level: number;
}
export default function ProjectStructureSkeletonListItem({ level = 0 }: ProjectStructureSkeletonListItemProps) {
  return (
    <li data-testid="project-structure-skeleton-item">
      <div
        className={styles.skeletonItem}
        style={{
          paddingLeft: level * 24 + 8,
        }}
      >
        <div className={styles.placeholder} />
      </div>
    </li>
  );
}
