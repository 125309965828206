import React from 'react';
import classnames from 'classnames';
import { useNavigate, useMatch } from 'react-router-dom';
import ReactModal from 'react-modal';
import ModalContext from './context/ModalContext';

import styles from './Modal.module.scss';

interface ModalProps {
  children: React.ReactNode;
  title: string;
  openPathname: string | string[];
  closeUrl: string;
  modalClassName?: string;
  format?: 'small' | 'normal' | 'large' | 'xlarge';
}
function Modal({
  children,
  title,
  openPathname,
  closeUrl,
  format = 'normal',
  modalClassName,
}: ModalProps): JSX.Element {
  const match = Array.isArray(openPathname) ? openPathname.find((path) => useMatch(path)) : useMatch(openPathname);

  const navigate = useNavigate();
  function closeModal() {
    navigate(closeUrl);
  }

  return (
    <ModalContext.Provider value={{ closeModal }}>
      <ReactModal
        isOpen={!!match}
        contentLabel={title}
        className={classnames(styles.modal, styles[format], modalClassName)}
        overlayClassName={styles.modalOverlay}
        onRequestClose={closeModal}
      >
        {children}
      </ReactModal>
    </ModalContext.Provider>
  );
}

export default Modal;
