import classnames from 'classnames';
import { useIntl } from 'react-intl';
import ProgramShowTargetProgressionNoParticipants from './components/program-show-target-progression-no-participants/ProgramShowTargetProgressionNoParticipants';
import ProgramShowTargetProgressionWithParticipants from './components/program-show-target-progression-with-participants/ProgramShowTargetProgressionWithParticipants';
import translations from './ProgramShowTargetProgression.translations';

import styles from './ProgramShowTargetProgression.module.scss';

interface ProgramShowTargetProgressionProps {
  totalParticipations: number;
  completedParticipationsCount: number;
  meetingsQuantity: number;
  expectedParticipations: number;
  className?: string;
}
export default function ProgramShowTargetProgression({
  totalParticipations,
  completedParticipationsCount,
  meetingsQuantity,
  expectedParticipations,
  className,
}: ProgramShowTargetProgressionProps) {
  const intl = useIntl();

  return (
    <div className={classnames(styles.bloc, className)}>
      <h3 className={styles.title}>{intl.formatMessage(translations.title)}</h3>

      {totalParticipations > 0 ? (
        <ProgramShowTargetProgressionWithParticipants
          completedParticipationsCount={completedParticipationsCount}
          expectedParticipations={expectedParticipations}
          meetingsQuantity={meetingsQuantity}
        />
      ) : (
        <ProgramShowTargetProgressionNoParticipants target={meetingsQuantity} />
      )}
    </div>
  );
}
