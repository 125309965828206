import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ProgramPublicationView.title',
    defaultMessage: 'Récapitulatif de votre programme',
  },
  description: {
    id: 'ProgramPublicationView.description',
    defaultMessage:
      'Vous y êtes presque ! Cliquez sur “Créer” pour terminer la création de votre programme et obtenir son code de participation.',
  },
  individualCoachingTitle: {
    id: 'ProgramPublicationView.individualCoachingTitle',
    defaultMessage: 'Accompagnement <boldThis>d’un ou une seul(e) membre</boldThis>',
  },
  programType: {
    id: 'ProgramPublicationView.programType',
    defaultMessage: 'Programme {programTemplateName} - {meetingRequired} rendez-vous',
  },
  groupCoachingTitle: {
    id: 'ProgramPublicationView.groupCoachingTitle',
    defaultMessage: 'Accompagnement <boldThis>d’un groupe de membres</boldThis>',
  },
  programCustomizationTitle: {
    id: 'ProgramPublicationView.programCustomizationTitle',
    defaultMessage: 'Personnalisation du programme',
  },
  notificationConfiguration: {
    id: 'ProgramPublicationView.notificationConfiguration',
    defaultMessage: 'Configuration des notifications',
  },
});
