import { gql } from '@apollo/client';
import { ProgramPublicationStatus } from '../../../../@types/graphql';

export const GET_PROJECT_STATISTICS = gql`
  query GET_PROJECT_STATISTICS(
    $id: ID!,
    $programIds: [String!],
    $includeArchived: Boolean,
  ) {
    project(id: $id) {
      id
      myRole
      collectiveMeetingsCount
      collectiveMeetingsParticipants
      programs(filter: {
        query: [
          {
            filterBy: programPublicationStatus,
            operator: eq,
            value: "${ProgramPublicationStatus.Published}"
          }
        ]
      }) {
        total
      }
      programsStatistics(programIds: $programIds, includeArchived: $includeArchived) {
        confirmedAppointmentsCount
        conversationsCount
        metProfessionalsCount
        participationsCount
        programsCompletionsPourcentage
        participantsWithConversationsPourcentage
        participantsWithAppointmentsPourcentage
        delivredCertificates
        deliveredCertificatesPourcentage
        expectedCertificates
        programsCount
        professionalEvaluations {
          key
          count
        }
        studentEvaluations {
          key
          count
        }
        jobTopFive {
          count
          identifier
          rank
        }
        companyTopFive {
          count
          identifier
          rank
        }
        sectorTopFive {
          count
          identifier
          rank
        }
      }
    }
  }
`;
