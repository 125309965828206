import React from 'react';
import Modal from '../../../../../../components/modal/Modal';
import ModalHeader from '../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../components/modal/components/modal-footer/ModalFooter';
import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../Routes';
import commonButtonsTranslations from '../../../../commons/translations/buttons.translations';
import translations from './CreateGouvSchoolModal.translations';

import styles from './CreateGouvSchoolModal.module.scss';
import TextInput from '../../../../../../components/text-input/TextInput';
import GooglePlaceInput from '../../../../../../components/google-place-input/GooglePlaceInput';
import MarkerPinIcon from '../../../../../../assets/component-icons/MarkerPinIcon';
import { CREATE_GOUV_SCHOOL } from './CreateGouvSchoolModal.gql';
import { Create_Gouv_SchoolMutation, Create_Gouv_SchoolMutationVariables } from '../../../../@types/graphql';
import validationSchema from './CreateGouvSchoolModal.validations';
import { useSnackbar } from '../../../../../../components/snackbar/hooks/useSnackbar';

interface FormikValues {
  name: string;
  communeName: string;
}

interface CreateGouvSchoolModalProps {
  department: string;
  schoolType: string;
  name: string;
}

export default function CreateGouvSchoolModal({ department, name, schoolType }: CreateGouvSchoolModalProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { success } = useSnackbar();

  const [createGouvSchool] = useMutation<Create_Gouv_SchoolMutation, Create_Gouv_SchoolMutationVariables>(
    CREATE_GOUV_SCHOOL,
  );

  const onSubmit = (values: FormikValues) => {
    createGouvSchool({
      variables: {
        name: values.name,
        communeName: values.communeName,
        establishmentType: schoolType,
        departmentLabel: department,
      },
    }).then((response) => {
      if (response.data?.createGouvSchool?.id) {
        success(intl.formatMessage(translations.success));
        navigate(SchoolRoutes.projectCreate, {
          state: {
            schoolId: response.data.createGouvSchool.id,
            schoolName: response.data.createGouvSchool.name,
          },
        });
      }
    });
  };

  return (
    <Modal
      title={intl.formatMessage(translations.title)}
      openPathname={[SchoolRoutes.createGouvSchool]}
      closeUrl={generatePath(SchoolRoutes.projectCreate)}
    >
      <Formik
        initialValues={{
          name: name || '',
          communeName: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema(intl)}
      >
        {({ handleSubmit, dirty, values, isValid, isSubmitting, setFieldValue }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader title={intl.formatMessage(translations.title)} />
            <ModalBody className={styles.modalBody}>
              <div className={styles.inputWrapper}>
                <TextInput
                  label={intl.formatMessage(translations.schoolLabel)}
                  onChange={(e) => {
                    setFieldValue('name', e.target.value);
                  }}
                  value={values.name || ''}
                />
              </div>
              <GooglePlaceInput
                name="communeName"
                icon={MarkerPinIcon}
                label={intl.formatMessage(translations.locationLabel)}
                onChange={(e) => {
                  setFieldValue('communeName', e.description);
                }}
              />
            </ModalBody>
            <ModalFooter
              cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
              submitText={intl.formatMessage(commonButtonsTranslations.confirm)}
              disableSubmit={!isValid || !dirty || isSubmitting}
              className={styles.modalFooter}
            />
          </form>
        )}
      </Formik>
    </Modal>
  );
}
