import React from 'react';
import classnames from 'classnames';

import styles from './RingLoader.module.scss';

interface RingLoaderProps {
  className?: string;
  loading?: boolean;
}
function RingLoader({ className, loading = true }: RingLoaderProps) {
  if (!loading) {
    return <div className={styles.spacer} />;
  }
  return (
    <div className={classnames(className, styles.loader)}>
      <div />
      <div />
      <div />
      <div />
    </div>
  );
}

export default RingLoader;
