enum Routes {
  schoolAdmin = '/',
}

export enum SchoolRoutes {
  home = '/',

  crash = '/crash',
  projectCreate = '/projects/create',
  createGouvSchool = '/projects/create/create-gouv-school',
  projectArchivedList = '/projects/archived',
  projectArchivedListRecover = '/projects/archived/:projectid/recover',
  projectShow = '/projects/:projectid',
  projectArchive = '/projects/:projectid/archive',
  projectJoin = '/projects/:projectid/invitations/:invitationid/join',
  projectUpdate = '/projects/:projectid/update',
  projectStats = '/projects/:projectid/stats',
  projectCustomize = '/projects/:projectid/customize',
  projectGroupDelete = '/projects/:projectid/customize/group/:groupid/delete',
  projectMembers = '/projects/:projectid/members',
  projectMembersCreateInvitation = '/projects/:projectid/members/invitations/create',
  projectMembersEditInvitation = '/projects/:projectid/members/invitations/:invitationid/edit',
  projectMemberInvitationAddToProgram = '/projects/:projectid/members/invitations/:invitationid/add',
  projectMemberEdit = '/projects/:projectid/members/memberships/:membershipid/edit',
  projectMemberDelete = '/projects/:projectid/members/memberships/:membershipid/delete',
  projectMemberAddToProgram = '/projects/:projectid/members/memberships/:membershipid/add',

  // Programs
  projectProgramCreate = '/projects/:projectid/programs/create',
  groupProgramCreate = '/projects/:projectid/groups/:groupid/programs/create',
  baseProgram = '/programs/:programid',
  programShow = '/programs/:programid/show',
  programArchive = '/programs/:programid/show/archive',
  programDuplicate = '/programs/:programid/show/duplicate',
  programStudentAffiliationHelp = '/programs/:programid/show/student-affiliation-help',
  programUpdate = '/programs/:programid/update',
  programShowRemoveParticipation = '/programs/:programid/show/participations/batch/delete',
  programShowRecoverParticipation = '/programs/:programid/show/participations/batch/recover',
  importParticipants = '/programs/:programid/import-participants',
  importsHistory = '/programs/:programid/imports-history',
  importParticipantsModal = '/programs/:programid/import-participants/modal',

  // Participations
  participationShow = '/programs/:programid/participations/:participationid/show',
}

export default Routes;
