import { gql } from '@apollo/client';
import { InvitationStatus, ProgramPublicationStatus } from '../../@types/graphql';

export const PROJECT_MEMBERS_MEMBERSHIP = gql`
  fragment PROJECT_MEMBERS_MEMBERSHIP on Membership {
    id
    createdAt
    updatedAt
    userEmail
    userFirstName
    userLastName
    role
    isMe
    canUpdate {
      value
    }
    canDelete {
      value
    }
  }
`;

export const PROJECT_MEMBERS_INVITATION = gql`
  fragment PROJECT_MEMBERS_INVITATION on Invitation {
    id
    email
    role
    createdAt
    updatedAt
    invitationStatus
    canUpdate {
      value
    }
    canDelete {
      value
    }
  }
`;

export const PROJECT_MEMBERS_PROGRAM_FRAGMENT = gql`
  fragment PROJECT_MEMBERS_PROGRAM_FRAGMENT on Program {
    id
    name
    canShow {
      value
    }
    canUpdate {
      value
    }
  }
`;

export const PROJECT_MEMBERS_GROUP_FRAGMENT = gql`
  ${PROJECT_MEMBERS_PROGRAM_FRAGMENT}

  fragment PROJECT_MEMBERS_GROUP_FRAGMENT on Group {
    id
    name
    canUpdate {
      value
    }
    canCreateGroup {
      value
    }
    canDelete {
      value
    }
    programs(filter: {
      query: {
        filterBy: programPublicationStatus,
        operator: eq,
        value: "${ProgramPublicationStatus.Published}"
      }
    }) {
      nodes {
        ...PROJECT_MEMBERS_PROGRAM_FRAGMENT
      }
    }
  }
`;

export const GET_PROJECT_INFO = gql`
    ${PROJECT_MEMBERS_INVITATION}
    ${PROJECT_MEMBERS_MEMBERSHIP}
    ${PROJECT_MEMBERS_GROUP_FRAGMENT}

  query GET_PROJECT_INFO($id: ID!, $programIds: [String!], $includeArchived: Boolean) {
    project(id: $id) {
      id
      name
      myRole
      collectiveMeetingsCount
      collectiveMeetingsParticipants
      canCreateInvitation {
        value
      }
      schools {
        nodes {
          id
          name
        }
      }
      invitations(filter: {
        query: [
          {filterBy: invitationStatus, operator: eq, value: "${InvitationStatus.Sent}"}
        ]
      }) {
        nodes {
          ...PROJECT_MEMBERS_INVITATION
        }
      }
      memberships(sort: [
        { order: ASC, orderBy: userFirstName },
        { order: ASC, orderBy: userLastName },
      ]) {
        nodes {
          ...PROJECT_MEMBERS_MEMBERSHIP
        }
      }
      canCreateGroup {
        value
      }
      directPrograms(filter: {
        query: {
          filterBy: programPublicationStatus,
          operator: eq,
          value: "${ProgramPublicationStatus.Published}"
        }
      }) {
        nodes {
          ...PROJECT_MEMBERS_PROGRAM_FRAGMENT
        }
      }
      directGroups {
        nodes {
          ...PROJECT_MEMBERS_GROUP_FRAGMENT
          groups {
            nodes {
              ...PROJECT_MEMBERS_GROUP_FRAGMENT
              groups {
                nodes {
                  ...PROJECT_MEMBERS_GROUP_FRAGMENT
                  groups {
                    nodes {
                      ...PROJECT_MEMBERS_GROUP_FRAGMENT
                      groups {
                        nodes {
                          ...PROJECT_MEMBERS_GROUP_FRAGMENT
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      programsStatistics(programIds: $programIds, includeArchived: $includeArchived) {
        confirmedAppointmentsCount
        conversationsCount
        metProfessionalsCount
        participationsCount
        programsCompletionsPourcentage
        participantsWithConversationsPourcentage
        participantsWithAppointmentsPourcentage
        delivredCertificates
        deliveredCertificatesPourcentage
        expectedCertificates
        programsCount
        professionalEvaluations {
          key
          count
        }
        studentEvaluations {
          key
          count
        }
        jobTopFive {
          count
          identifier
          rank
        }
        companyTopFive {
          count
          identifier
          rank
        }
        sectorTopFive {
          count
          identifier
          rank
        }
      }
      organization {
        id
        name
      }
      canUpdate {
        value
      }
      canArchive {
        value
      }
      programs(filter: {
        query: [
          {
            filterBy: programPublicationStatus,
            operator: eq,
            value: "${ProgramPublicationStatus.Published}"
          }
        ]
      }) {
        total
        nodes {
          id
          name  
        }
      }
      myPrograms: programs(filter: {
        query: [
          {
            filterBy: programPublicationStatus,
            operator: eq,
            value: "${ProgramPublicationStatus.Published}"
          },
          {
            filterBy: authorId,
            operator: eq,
            value: "me"
          }
        ]
      }) {
        total
      }
    }
  }
`;
