import Icon from '../../../../../../../../../../components/icon/Icon';
import { useIntl } from 'react-intl';
import {
  HermesContactAppointmentContactMethod,
  HermesContactState,
  Maybe,
} from '../../../../../../../../@types/graphql';
import translations from './AppointmentStatus.translations';
import { getAppointmentTypeIcon } from './AppointmentStatus.utils';

import styles from './AppointmentStatus.module.scss';

interface AppointmentStatusProps {
  date?: string;
  status: HermesContactState;
  type?: Maybe<HermesContactAppointmentContactMethod>;
}
export default function AppointmentStatus({ date, status, type }: AppointmentStatusProps) {
  const intl = useIntl();
  const renderNoAppointmentSchduled = () => (
    <span className={styles.noAppointment}>
      {intl.formatMessage(translations[HermesContactState.NoScheduledAppointment])}
    </span>
  );
  const renderAppointmentDate = () => (
    <span className={styles.appointmentDate}>
      <Icon
        name={getAppointmentTypeIcon(type)}
        className={styles.icon}
      />
      {intl.formatDate(date, {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
      })}
    </span>
  );

  switch (status) {
    case HermesContactState.NoScheduledAppointment:
      return <p className={styles.appointmentStatus}>{renderNoAppointmentSchduled()}</p>;
    case HermesContactState.Upcoming:
      return (
        <p className={styles.appointmentStatus}>
          {renderAppointmentDate()}
          <span className={styles.upcoming}>
            <Icon
              name="watch_later"
              className={styles.icon}
            />
            {intl.formatMessage(translations[HermesContactState.Upcoming])}
          </span>
        </p>
      );
    case HermesContactState.PastAwaitingConfirmation:
      return (
        <p className={styles.appointmentStatus}>
          {renderAppointmentDate()}
          <span className={styles.waitingConfirmation}>
            <Icon
              name="hourglass_empty"
              className={styles.icon}
            />
            {intl.formatMessage(translations[HermesContactState.PastAwaitingConfirmation])}
          </span>
        </p>
      );
    case HermesContactState.PastAndConfirmed:
      return (
        <p className={styles.appointmentStatus}>
          {renderAppointmentDate()}
          <span className={styles.confirmed}>
            <Icon
              name="check_circle"
              className={styles.icon}
            />
            {intl.formatMessage(translations[HermesContactState.PastAndConfirmed])}
          </span>
        </p>
      );
    case HermesContactState.InvalidatedAppointment:
      return (
        <p className={styles.appointmentStatus}>
          <span className={styles.invalidated}>
            {intl.formatMessage(translations[HermesContactState.InvalidatedAppointment])}
          </span>
        </p>
      );
    case HermesContactState.ProfileReportedByTheProfessional:
      return (
        <p className={styles.appointmentStatus}>
          {renderAppointmentDate()}
          <span className={styles.reported}>
            <Icon
              name="error_outline"
              className={styles.icon}
            />
            {intl.formatMessage(translations[HermesContactState.ProfileReportedByTheProfessional])}
          </span>
        </p>
      );
    case HermesContactState.UnfollowedConversationWithThisProfessional:
      return (
        <p className={styles.appointmentStatus}>
          {renderNoAppointmentSchduled()}
          <span className={styles.unfollowed}>
            <Icon
              name="error_outline"
              className={styles.icon}
            />
            {intl.formatMessage(translations[HermesContactState.UnfollowedConversationWithThisProfessional])}
          </span>
        </p>
      );
    /* istanbul ignore next */
    default:
      return null;
  }
}
