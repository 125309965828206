import { defineMessages } from 'react-intl';
import { TargetStudentsCategories } from '../../@types/graphql';

const commonTargetStudentsCategories = defineMessages({
  [TargetStudentsCategories.University]: {
    id: 'TargetStudentsCategories.College',
    defaultMessage: 'Des étudiants du supérieur',
  },
  [TargetStudentsCategories.HighSchool]: {
    id: 'TargetStudentsCategories.HighSchool',
    defaultMessage: 'Des lycéens',
  },
  [TargetStudentsCategories.MiddleSchool]: {
    id: 'TargetStudentsCategories.MiddleSchool',
    defaultMessage: 'Des collégiens',
  },
  [TargetStudentsCategories.AllStudents]: {
    id: 'TargetStudentsCategories.All',
    defaultMessage: 'Tous',
  },
});

export default commonTargetStudentsCategories;
