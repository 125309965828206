import { defineMessages } from 'react-intl';

export default defineMessages({
  complete: {
    id: 'ProfileCompletionStatus.complete',
    defaultMessage: 'Profil complet',
  },
  incomplete: {
    id: 'ProfileCompletionStatus.incomplete',
    defaultMessage: 'Profil incomplet',
  },
});
