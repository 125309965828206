import { gql } from '@apollo/client';

export const GET_PROGRAM_TEMPLATES = gql`
  query GET_PROGRAM_TEMPLATES {
    programTemplates {
      nodes {
        id
        displayedName
        description
        templateOptions {
          minimalDaysDuration
          meetingsQuantity
        }
      }
    }
  }
`;

export const CREATE_NEW_PROGRAM = gql`
  mutation CREATE_NEW_PROGRAM($projectId: ID!, $groupId: ID, $programTemplateId: ID!, $individualSupport: Boolean!) {
    createProgram(
      input: {
        projectId: $projectId
        groupId: $groupId
        programTemplateId: $programTemplateId
        individualSupport: $individualSupport
      }
    ) {
      id
    }
  }
`;
