import { gql } from '@apollo/client';

export const ARCHIVE_PROGRAM = gql`
  mutation ARCHIVE_PROGRAM($id: ID!) {
    archiveProgram(id: $id) {
      id
      project {
        id
      }
    }
  }
`;
