import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { errorTranslations } from './ProjectCreateUpdate.translations';

export default (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string()
      .ensure()
      .min(1, intl.formatMessage(errorTranslations.requiredProjectName))
      .required(intl.formatMessage(errorTranslations.requiredProjectName)),
    schools: Yup.array()
      .of(
        Yup.object().shape({
          id: Yup.string().required(),
          name: Yup.string().required(),
        })
      )
      .min(1, intl.formatMessage(errorTranslations.requiredSchool))
  });
