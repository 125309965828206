import React from 'react';
import classnames from 'classnames';
import Icon from '../../../../../../../../components/icon/Icon';
import { Formik } from 'formik';
import { useIntl } from 'react-intl';
import { useLocation, useNavigate } from 'react-router-dom';
import useDropdown from '../../../../../../hooks/use-drop-down/useDropDown';
import useProjectTree from '../../../../../../hooks/use-project-tree/useProjectTree';
import { useOutsideClickEffect } from '../../../../../../../../utils/useOutsideClickEffect';
import commonButtonsTranslations from '../../../../../../commons/translations/buttons.translations';
import RTFBold from '../../../../../../rich-text-formatting/bold/RTFBold';
import useFiltersParameter from '../../../../hooks/useFiltersParameter';
import FilterGroupItem from './components/filter-group-item/FilterGroupItem';
import FilterProgramItem from './components/filter-program-item/FilterProgramItem';
import translations from './ProjectStatisticFilter.translations';

import styles from './ProjectStatisticFilter.module.scss';
import CustomCheckbox from '../../../../../../commons/custom-checkbox/CustomCheckbox';

export interface ProjectStatisticFiltersValues {
  programIds: string[];
  includeArchived: boolean;
}
interface ProjectStatisticFilterProps {
  projectId: string;
  programsCount: number;
  totalProgramsCount: number;
}
export default function ProjectStatisticFilter({
  projectId,
  programsCount,
  totalProgramsCount,
}: ProjectStatisticFilterProps) {
  const intl = useIntl();
  const [isOpen, , open, close] = useDropdown();
  const ref = useOutsideClickEffect<HTMLDivElement>(close);
  const projectTree = useProjectTree(projectId);
  const navigate = useNavigate();
  const location = useLocation();
  const { programIds, includeArchived } = useFiltersParameter();

  function onSubmit(values: ProjectStatisticFiltersValues) {
    navigate({
      pathname: location.pathname,
      search: `?programIds=${values.programIds.join(',')}&includeArchived=${values.includeArchived}`,
    });
  }

  return (
    <div className={styles.wrapper}>
      <button
        type="button"
        onClick={open}
        className={styles.filterButton}
        disabled={totalProgramsCount < 1}
        aria-expanded={isOpen}
      >
        <Icon
          name="filter_alt"
          className={styles.filterIcon}
        />
        {intl.formatMessage(translations.filterButton)}
        {((programIds?.length || 0) > 0 || !includeArchived) && <span className={styles.beacon} />}
      </button>

      <div
        className={classnames(styles.filterBar, {
          [styles.hidden]: !isOpen,
        })}
        aria-hidden={!isOpen}
        ref={ref}
      >
        <Formik
          initialValues={{
            programIds: programIds || [],
            includeArchived: includeArchived,
          }}
          onSubmit={onSubmit}
        >
          {({ handleSubmit, handleReset, values, setFieldValue, resetForm, submitForm }) => (
            <form
              onSubmit={handleSubmit}
              onReset={handleReset}
              className={styles.filterBarForm}
            >
              <div className={styles.filterBarHeader}>
                <h1 className={styles.filterBarTitle}>{intl.formatMessage(translations.filterBarTitle)}</h1>
                <button
                  type="button"
                  onClick={close}
                  aria-label={intl.formatMessage(commonButtonsTranslations.close)}
                  className={styles.closeFilterBarButton}
                >
                  <Icon name="double_arrow" />
                </button>
                <div className={styles.filterArchivedCheckbox}>
                  <CustomCheckbox
                    onChange={() => setFieldValue('includeArchived', !values.includeArchived)}
                    checked={!values.includeArchived}
                  />
                  <label>
                    {intl.formatMessage(translations.hideArchived)}
                  </label>
                </div>
              </div>

              <h2 className={styles.filterBarSubtitle}>{intl.formatMessage(translations.filterBarSubtitle)}</h2>
              <p className={styles.filterBarDescription}>{intl.formatMessage(translations.filterBarDescription)}</p>
              <ul
                className={styles.tree}
                role="tree"
              >
                {projectTree?.groups.map((group) => (
                  <FilterGroupItem
                    key={`statistic_filter_group_${group.id}`}
                    group={group}
                  />
                ))}
                {projectTree?.programs.map(
                  (program) =>
                    program.canShow && (
                      <FilterProgramItem
                        key={`statistic_filter_program_${program.id}`}
                        program={program}
                      />
                    ),
                )}
              </ul>

              <div className={styles.filterBarFooter}>
                <button
                  type="submit"
                  className={styles.filterBarSubmitButton}
                >
                  {intl.formatMessage(commonButtonsTranslations.apply)}
                </button>

                <button
                  type="button"
                  className={styles.filterBarResetButton}
                  onClick={() => {
                    resetForm({ values: { programIds: [], includeArchived: true } });
                    submitForm();
                  }}
                >
                  {intl.formatMessage(translations.reset)}
                </button>
              </div>
            </form>
          )}
        </Formik>
      </div>

      <p className={styles.programsCount}>
        {intl.formatMessage(translations.nPrograms, {
          boldThis: RTFBold,
          x: programsCount,
          total: totalProgramsCount,
        })}
      </p>
    </div>
  );
}
