import React from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import uniqid from 'uniqid';
import { Get_My_Project_ListQuery, Get_My_Project_ListQueryVariables } from '../../../../@types/graphql';
import ProjectListCreateItem from './components/project-list-create-item/ProjectListCreateItem';
import ProjectListItem from './components/project-list-item/ProjectListItem';
import ProjectListItemSkeleton from './components/project-list-item-skeleton/ProjectListItemSkeleton';
import ArchivedProjectsLink from './components/archived-projects-link/ArchivedProjectsLink';
import { GET_MY_PROJECT_LIST } from './ProjectList.gql';
import translations from './ProjectList.translations';

import styles from './ProjectList.module.scss';

function ProjectList() {
  const intl = useIntl();

  const { data, loading } = useQuery<Get_My_Project_ListQuery, Get_My_Project_ListQueryVariables>(GET_MY_PROJECT_LIST);

  return (
    <section
      data-testid="homepage-project-list"
      className={styles.projectList}
    >
      <header className={styles.header}>
        <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
        <ArchivedProjectsLink total={data?.me?.archivedProjects?.total || 0} />
      </header>
      <ul className={styles.list}>
        {loading &&
          Array.from({ length: 3 }, () => (
            <li key={uniqid()}>
              <ProjectListItemSkeleton />
            </li>
          ))}
        {data?.me?.invitations?.nodes?.map((invitation) => (
          <li key={invitation?.id}>
            <ProjectListItem
              project={invitation?.project}
              acceptInvitationId={invitation?.id}
            />
          </li>
        ))}
        {data?.projects?.nodes?.map((project) => (
          <li key={project?.id}>
            <ProjectListItem project={project} />
          </li>
        ))}
        <li>
          <ProjectListCreateItem />
        </li>
      </ul>
    </section>
  );
}

export default ProjectList;
