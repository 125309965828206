import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../../../../../components/snackbar/hooks/useSnackbar';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { Delete_GroupMutation, Delete_GroupMutationVariables } from '../../../../../../../../@types/graphql';
import { DELETE_GROUP_ERROR, MJGApolloError } from '../../../../../../../../@types/graphql-errors';
import useFiltersParameter from '../../../../../../hooks/useFiltersParameter';
import { SchoolRoutes } from '../../../../../../../../../../Routes';
import { doesErrorExist } from '../../../../../../../../../../utils/graphql';
import commonErrorsTranslations from '../../../../../../../../commons/translations/errors.translations';
import { GET_PROJECT_INFO } from '../../../../../../ProjectShow.gql';
import { DELETE_GROUP } from './useDeleteGroupMutation.gql';
import { errorTranslations } from './useDeleteGroupMutation.translations';

export function useDeleteGroupMutation({
  groupId,
  projectId,
}: {
  groupId: string | undefined;
  projectId: string | undefined;
}) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { error } = useSnackbar();
  const { programIds } = useFiltersParameter();

  const [deleteGroup, { loading }] = useMutation<Delete_GroupMutation, Delete_GroupMutationVariables>(DELETE_GROUP);

  return {
    loading,
    deleteGroup: () => {
      /* istanbul ignore next */
      if (!groupId || !projectId) {
        return undefined;
      }
      return deleteGroup({
        variables: {
          groupId,
        },
        refetchQueries: [
          {
            query: GET_PROJECT_INFO,
            variables: {
              id: projectId,
              programIds,
            },
          },
        ],
      })
        .then(() => {
          navigate(
            generatePath(SchoolRoutes.projectCustomize, {
              projectid: projectId,
            }),
          );
        })
        .catch((errors: MJGApolloError<{ name: string }, DELETE_GROUP_ERROR>) => {
          if (doesErrorExist(errors, DELETE_GROUP_ERROR.UNAUTHORIZED)) {
            error(intl.formatMessage(errorTranslations[DELETE_GROUP_ERROR.UNAUTHORIZED]));
          } else if (doesErrorExist(errors, DELETE_GROUP_ERROR.NOT_FOUND)) {
            error(intl.formatMessage(errorTranslations[DELETE_GROUP_ERROR.NOT_FOUND]));
          } else {
            error(intl.formatMessage(commonErrorsTranslations.default));
          }
        });
    },
  };
}
