import { defineMessages } from 'react-intl';

export default defineMessages({
  faq: {
    id: 'UserNav.faq',
    defaultMessage: 'Besoin d’aide ?',
  },
  switch: {
    id: 'UserNav.switch',
    defaultMessage: 'Espace Membre My Job Glasses',
  },
  search: {
    id: 'UserNav.search',
    defaultMessage: 'Rechercher des Ambassadeurs',
  },
  signout: {
    id: 'UserNav.signout',
    defaultMessage: 'Se déconnecter',
  },
});
