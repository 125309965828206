import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useQuery } from '@apollo/client';
import { useOutsideClickEffect } from '../../../../../../../../utils/useOutsideClickEffect';
import { Get_MeQuery, Get_MeQueryVariables } from '../../../../../../@types/graphql';
import { GET_ME } from '../../../../../../pages/homepage/Homepage.gql';
import TreeNavItemExternalLink from '../../../../../tree-nav/components/tree-nav-item/components/tree-nav-item-external-link/TreeNavItemExternalLink';
import Avatar from '../../../../../../../../components/avatar/Avatar';
import { AvatarSize } from '../../../../../../../../components/avatar/Avatar';
import './ProfileActionItem.scss';
import translations from './ProfileActionItem.translations';

function ProfileActionItem() {
  const intl = useIntl();
  const { data } = useQuery<Get_MeQuery, Get_MeQueryVariables>(GET_ME);
  const signoutLink = `${import.meta.env.REACT_APP_ACCOUNT_FRONTEND_URL}/signout`;
  const signinLink = `${import.meta.env.REACT_APP_APP_FRONTEND_URL}`;
  const searchLink = `${import.meta.env.REACT_APP_APP_FRONTEND_URL}/search`;

  const [isShowingPopup, setIsShowingPopup] = useState(false);
  function toggleMenu() {
    setIsShowingPopup(!isShowingPopup);
  }
  function close() {
    setIsShowingPopup(false);
  }
  const ref = useOutsideClickEffect<HTMLDivElement>(close);

  const menu = [
    {
      id: 'profile-menu-switch-item',
      label: intl.formatMessage(translations.switch),
      icon: 'switch_access_shortcut',
      link: signinLink,
    },
    {
      id: 'profile-menu-search',
      label: intl.formatMessage(translations.search),
      icon: 'search',
      link: searchLink,
    },
    {
      id: 'profile-menu-faq-item',
      label: intl.formatMessage(translations.faq),
      icon: 'info',
      link: 'https://help.myjobglasses.com/fr/collections/194721-creer-un-programme-d-accompagnement',
    },
    {
      id: 'profile-menu-signout-item',
      label: intl.formatMessage(translations.signout),
      icon: 'logout',
      link: signoutLink,
    },
  ];
  return (
    <div
      ref={ref}
      className="profile-menu-wrapper"
    >
      <button
        type="button"
        onClick={toggleMenu}
        className="profile-menu-wrapper__avatar-button"
      >
        <Avatar
          avatarLink={data?.me?.avatarUrl || undefined}
          size={AvatarSize.small}
        />
      </button>

      <ul
        role="menu"
        className="profile-menu-wrapper__list"
        hidden={!isShowingPopup}
      >
        {menu?.map((action) => {
          return (
            <TreeNavItemExternalLink
              key={action.id}
              id={action.id}
              label={action.label}
              icon={action.icon}
              link={action.link}
            />
          );
        })}
      </ul>
    </div>
  );
}

export default ProfileActionItem;
