import React from 'react';
import Icon from '../../../../../components/icon/Icon';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { BreadcrumbsItem, PageHeader } from '../../context/HeaderContext';
import uniqid from 'uniqid';

import styles from './PageClassic.module.scss';

interface PageClassicProps {
  title?: string;
  titleIcon?: string;
  backLink?: string;
  subTitle?: string | React.ReactNode;
  iconName?: BreadcrumbsItem['iconName'];
  iconComponent?: BreadcrumbsItem['iconComponent'];
  aside?: React.FunctionComponent;
  leftAside?: React.FunctionComponent;
  children?: React.ReactNode;
  breadcrumbs?: BreadcrumbsItem[];
  actions?: Array<React.ReactNode>;
  loading?: boolean;
  className?: string;
  badge?: React.ReactNode;
  subTitleBadge?: React.ReactNode;
}
function PageClassic({
  title,
  titleIcon,
  backLink,
  subTitle,
  iconName,
  iconComponent,
  aside: Aside,
  leftAside: LeftAside,
  children,
  breadcrumbs,
  actions,
  loading,
  className,
  badge,
  subTitleBadge,
}: PageClassicProps) {
  const location = useLocation();
  return (
    <>
      <PageHeader
        title={title}
        breadcrumb={
          loading
            ? []
            : [
                ...(breadcrumbs || []),
                {
                  iconName,
                  iconComponent,
                  link: location.pathname,
                  name: title || '',
                },
              ]
        }
        hasBreadcrumb
        isHiddenTitle
      />
      <main className={classnames(styles.pageClassic, className)}>
        <header className={styles.header}>
          {LeftAside && (
            <aside className={styles.leftAside}>
              <LeftAside />
            </aside>
          )}

          <div className={styles.titles}>
            <div className={styles.titleWrapper}>
              <h1
                className={classnames(styles.title, {
                  [styles.loading]: loading,
                })}
              >
                {backLink && (
                  <Link
                    to={backLink}
                    className={styles.backLink}
                  >
                    <Icon
                      name="arrow_back_ios"
                      className={styles.titleIcon}
                    />
                  </Link>
                )}
                {titleIcon && (
                  <Icon
                    name={titleIcon}
                    className={styles.titleIcon}
                  />
                )}
                {title}
              </h1>
              {badge}
            </div>
            <div className={styles.subTitleWrapper}>
              {subTitle && <h2 className={styles.subTitle}>{subTitle}</h2>}
              {subTitleBadge}
            </div>
          </div>

          <aside className={styles.aside}>
            {(actions?.length ?? 0) > 0 && (
              <div className={styles.actions}>
                {actions?.map((action) => {
                  if (React.isValidElement(action)) {
                    return <div key={uniqid()}>{action}</div>;
                  }
                })}
              </div>
            )}
            {Aside && <Aside />}
          </aside>
        </header>
        {children}
      </main>
    </>
  );
}

export default PageClassic;
