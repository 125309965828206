import { defineMessages } from 'react-intl';

export default defineMessages({
  reportedTooltip: {
    id: 'CertificationCell.reportedTooltip',
    defaultMessage: 'Profil signalé',
  },
  unfollowedConversationTooltip: {
    id: 'CertificationCell.unfollowedConversationTooltip',
    defaultMessage: 'Conversation(s) non suivie(s)',
  },
});
