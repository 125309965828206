import React from 'react';
import PageCenter from '../../layout/pages/page-center/PageCenter';
import ContentLoading from '../content-loading/ContentLoading';

function PageLoading() {
  return (
    <PageCenter>
      <ContentLoading />
    </PageCenter>
  );
}

export default PageLoading;
