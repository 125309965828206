import { gql } from '@apollo/client';

export const UPDATE_PROGRAM_PARENT_GROUP = gql`
  mutation UPDATE_PROGRAM_PARENT_GROUP($programId: ID!, $parentId: ID) {
    updateProgram(id: $programId, input: { groupId: $parentId }) {
      id
    }
  }
`;

export const UPDATE_GROUP_PARENT_GROUP = gql`
  mutation UPDATE_GROUP_PARENT_GROUP($groupId: ID!, $parentId: ID) {
    updateGroup(id: $groupId, input: { parentId: $parentId }) {
      id
    }
  }
`;
