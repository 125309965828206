import React, { useMemo } from 'react';
import { ProjectStructureDiagramDimensions } from '../../../../enums/Dimensions';
import { DiagramElement } from '../../ProjectDiagramStructure.utils';
import DiagramGroupItem from '../diagram-group-item/DiagramGroupItem';
import DiagramProgramItem from '../diagram-program-item/DiagramProgramItem';
import DiagramProjectItem from '../diagram-project-item/DiagramProjectItem';

interface DiagramBlocProps {
  element: DiagramElement;
  moveLeft?: number;
  testid?: string;
}
const BLOC_HEIGHT = 35;
const BLOC_WIDTH = ProjectStructureDiagramDimensions.ITEM_WIDTH;
const GUTTER_WIDTH = 20;
const GUTTER_HEIGHT = 40;
export default function DiagramBloc({ element, moveLeft, testid }: DiagramBlocProps) {
  const width = useMemo(() => element.leaf * (BLOC_WIDTH + GUTTER_WIDTH), [element]);

  return (
    <svg
      width={width}
      height={1000}
      y={BLOC_HEIGHT + GUTTER_HEIGHT}
      x={moveLeft}
      style={{ overflow: 'visible' }}
      data-testid={testid}
    >
      {element.type !== 'Project' && (
        <line
          x1={width / 2}
          y1={-(GUTTER_HEIGHT / 2)}
          x2={width / 2}
          y2={-5}
          stroke="grey"
          strokeWidth={1}
          strokeDasharray={2}
          markerEnd="url(#line-arrow-marker)"
          data-testid="previous-link-line"
        />
      )}

      {element.type === 'Project' && (
        <DiagramProjectItem
          x={width / 2}
          y={BLOC_HEIGHT / 2}
          label={element.name.substring(0, 24)}
        />
      )}
      {element.type === 'Group' && (
        <DiagramGroupItem
          x={width / 2}
          y={BLOC_HEIGHT / 2}
          label={element.name.substring(0, 14)}
        />
      )}
      {element.type === 'Program' && (
        <DiagramProgramItem
          x={width / 2}
          y={BLOC_HEIGHT / 2}
          label={element.name.substring(0, 14)}
        />
      )}

      {element.children?.length && (
        <>
          <line
            x1={width / 2}
            y1={35}
            x2={width / 2}
            y2={35 + GUTTER_HEIGHT / 2}
            stroke="grey"
            strokeWidth={1}
            strokeDasharray={2}
            data-testid="next-link-line"
          />
          {element.children.length > 1 && (
            <line
              x1={(element.children[0].leaf * (BLOC_WIDTH + GUTTER_WIDTH)) / 2}
              y1={35 + GUTTER_HEIGHT / 2}
              x2={width - (element.children[element.children.length - 1].leaf * (BLOC_WIDTH + GUTTER_WIDTH)) / 2}
              y2={35 + GUTTER_HEIGHT / 2}
              stroke="grey"
              strokeWidth={1}
              strokeDasharray={2}
              data-testid="fork-link-line"
            />
          )}
        </>
      )}

      {element.children?.map((child, index, array) => {
        const previousBranchLeaf = array.slice(0, index).reduce((prev, { leaf }) => prev + leaf, 0);
        return (
          <DiagramBloc
            key={child.name}
            element={child}
            moveLeft={previousBranchLeaf * (BLOC_WIDTH + GUTTER_WIDTH)}
          />
        );
      })}
    </svg>
  );
}
