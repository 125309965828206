import { defineMessages } from 'react-intl';
import { ARCHIVE_PROJECT_ERRORS } from '../../../../../../@types/graphql-errors';

export const errorTranslations = defineMessages({
  [ARCHIVE_PROJECT_ERRORS.NOT_FOUND]: {
    id: 'CanArchiveModalContent.errors.NOT_FOUND',
    defaultMessage: "Ce projet n'existe pas ou est déjà archivé.",
  },
  [ARCHIVE_PROJECT_ERRORS.UNAUTHORIZED]: {
    id: 'CanArchiveModalContent.errors.UNAUTHORIZED',
    defaultMessage: "Seul le propriétaire du projet est authorisé à l'archiver",
  },
});

export default defineMessages({
  title: {
    id: 'CanArchiveModalContent.title',
    defaultMessage: 'Archiver le projet',
  },
  description: {
    id: 'CanArchiveModalContent.description',
    defaultMessage:
      'Si vous archivez ce projet, l’ensemble de ses membres (vous y compris) n’aura plus accès à son contenu. Vous pourrez désarchiver ce projet ultérieurement depuis votre page “Projets Archivés” accessible par votre page d’accueil.',
  },
});
