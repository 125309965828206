import React, { forwardRef, useCallback } from 'react';
import classnames from 'classnames';
import { SnackbarContent, useSnackbar } from 'notistack';
import Icon from '../icon/Icon';

import styles from './SnackMessage.module.scss';

const SnackMessage = forwardRef<
  HTMLDivElement,
  {
    id: string | number;
    message: string | React.ReactNode;
    variant: 'success' | 'error' | 'info' | 'warning';
    icon?: string;
    closeButtonContent?: string | React.ReactNode;
  }
>(({ id, message, variant, icon, closeButtonContent }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <div
        className={classnames(styles.content, {
          [styles[variant]]: variant,
        })}
      >
        {icon && (
          <Icon
            name={icon}
            className={styles.leadingIcon}
          />
        )}
        <span className={styles.message}>{message}</span>
        {closeButtonContent && (
          <div className={styles.actions}>
            {closeButtonContent && (
              <button
                type="button"
                onClick={handleDismiss}
                className={styles.closeButton}
              >
                {closeButtonContent}
              </button>
            )}
          </div>
        )}
      </div>
    </SnackbarContent>
  );
});

export default SnackMessage;
