import Storage from '../enums/storage';

export function getStorageItem(key: Storage, defaultValue: string) {
  try {
    return window.localStorage?.getItem?.(key) || defaultValue;
  } catch (e) {
    console.error(e);
    return defaultValue;
  }
}

export function setStorageItem(key: Storage, value: string) {
  try {
    window.localStorage?.setItem(key, value);
  } catch (e) {
    console.error(e);
  }
}
