import { defineMessages } from 'react-intl';

export default defineMessages({
  archivedAt: {
    id: 'ArchivedProjectItem.archivedAt',
    defaultMessage: 'Archivé le {date}',
  },
  authoredBy: {
    id: 'ArchivedProjectItem.authoredBy',
    defaultMessage: 'Créé par {name}',
  },
  members: {
    id: 'ArchivedProjectItem.members',
    defaultMessage:
      '{x, plural, =0 {# membres rattachés} =1 {# membre rattaché} other {# membres rattachés}} au projet',
  },
  programs: {
    id: 'ArchivedProjectItem.programs',
    defaultMessage: '{x, plural, =0 {# programmes terminés} =1 {# programme terminé} other {# programmes terminés}}',
  },
  recoverTooltip: {
    id: 'ArchivedProjectItem.recoverTooltip',
    defaultMessage: 'Désarchiver',
  },
  recoverNotAllowedTooltip: {
    id: 'ArchivedProjectItem.recoverNotAllowedTooltip',
    defaultMessage: 'Veuillez contacter le propriétaire du projet pour pouvoir le désarchiver ',
  },
});
