import { defineMessages } from 'react-intl';

export default defineMessages({
  addToProgram: {
    id: 'ActionsCell.addToProgram',
    defaultMessage: 'Ajouter à un programme',
  },
  revokeInvitation: {
    id: 'ActionsCell.revokeInvitation',
    defaultMessage: 'Retirer l’invitation',
  },
  removeMembership: {
    id: 'ActionsCell.removeMembership',
    defaultMessage: 'Retirer du projet',
  },
  leaveProject: {
    id: 'ActionsCell.leaveProject',
    defaultMessage: 'Quitter le projet',
  },
  editRole: {
    id: 'ActionsCell.editRole',
    defaultMessage: 'Modifier le rôle',
  },
  notAllowedToSelfDeleteOwner: {
    id: 'ActionsCell.notAllowedToSelfDeleteOwner',
    defaultMessage: 'Veuillez désigner un autre membre comme propriétaire pour pouvoir quitter le projet.',
  },
});
