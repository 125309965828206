import { defineMessages } from 'react-intl';
import { CREATE_PROJECT_ERRORS, UPDATE_PROJECT_ERRORS } from '../../@types/graphql-errors';

export const errorTranslations = defineMessages({
  requiredProjectName: {
    id: 'ProjectCreateUpdate.requiredProjectName',
    defaultMessage: 'Veuillez nommer ce projet.',
  },
  requiredOrganisation: {
    id: 'ProjectCreateUpdate.requiredOrganisation',
    defaultMessage: 'Veuillez sélectionner une organisation existante ou ajouter la vôtre à la liste.',
  },
  requiredSchool: {
    id: 'ProjectCreateUpdate.requiredOrganisation',
    defaultMessage: 'Veuillez sélectionner établissement existant ou ajouter le votre à la liste.',
  },
  [CREATE_PROJECT_ERRORS.PROJECT_NAME_TAKEN]: {
    id: 'ProjectCreateUpdate.PROJECT_NAME_TAKEN',
    defaultMessage: 'Ce nom de projet existe déjà. Veuillez en choisir un autre.',
  },
  [UPDATE_PROJECT_ERRORS.UNAUTHORIZED]: {
    id: 'ProjectCreateUpdate.UNAUTHORIZED',
    defaultMessage: 'Seul le propriétaire du projet est authorisé à le modifier',
  },
  default: {
    id: 'ProjectCreateUpdate.default',
    defaultMessage: 'Le projet n’a pas pu être créé. Veuillez réessayer.',
  },
});

export default defineMessages({
  createProjectTitle: {
    id: 'ProjectCreateUpdate.title',
    defaultMessage: 'Créer un projet',
  },
  editProjectTitle: {
    id: 'ProjectCreateUpdate.editProjectTitle',
    defaultMessage: 'Modifier le projet',
  },
  projectNameLabel: {
    id: 'ProjectCreateUpdate.projectNameLabel',
    defaultMessage: 'Nom du projet',
  },
  projectNameSubtitle: {
    id: 'ProjectCreateUpdate.projectNameSubtitle',
    defaultMessage: 'Le Nom du projet correspond à votre nom d’établissement + l’année scolaire en cours.',
  },
  projectNameSubtitleForFT: {
    id: 'ProjectCreateUpdate.projectNameSubtitleForFT',
    defaultMessage: 'Le Nom du projet correspond, par exemple, au nom de votre structure associé à sa ville ou sa région.',
  },
  projectNamePlaceholder: {
    id: 'ProjectCreateUpdate.projectNamePlaceholder',
    defaultMessage: 'Nom de votre école - YY/YY',
  },
  projectNamePlaceholderForFT: {
    id: 'ProjectCreateUpdate.projectNamePlaceholderForFT',
    defaultMessage: 'Exemple : France Travail La Réunion',
  },
  organisationLabel: {
    id: 'ProjectCreateUpdate.organisationLabel',
    defaultMessage: 'Nom de l’organisation',
  },
  organisationPlaceholder: {
    id: 'ProjectCreateUpdate.organisationPlaceholder',
    defaultMessage: 'Organisation',
  },
  createButton: {
    id: 'ProjectCreateUpdate.createButton',
    defaultMessage: 'Créer le projet',
  },
  editButton: {
    id: 'ProjectCreateUpdate.editButton',
    defaultMessage: 'Modifier',
  },
});
