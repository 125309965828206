import { defineMessages } from 'react-intl';
import { UPDATE_GROUP_ERROR } from '../../../../../../../../../../@types/graphql-errors';

export const errorTranslations = defineMessages({
  [UPDATE_GROUP_ERROR.UNAUTHORIZED]: {
    id: 'GroupNameEditionForm.UNAUTHORIZED',
    defaultMessage: 'Cette action est réservée aux membres administrateurs du projet.',
  },
  [UPDATE_GROUP_ERROR.GROUP_NAME_TAKEN]: {
    id: 'GroupNameEditionForm.GROUP_NAME_TAKEN',
    defaultMessage: 'Un nom de groupe similaire existe déjà, merci de rafraichir la page et réessayer',
  },
  [UPDATE_GROUP_ERROR.NOT_FOUND]: {
    id: 'GroupNameEditionForm.NOT_FOUND',
    defaultMessage: "Ce groupe n'existe pas, merci de rafraichir la page et réessayer ",
  },
});
