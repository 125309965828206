import React, { useContext, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';

interface HeaderActionSubItem {
  iconName: string;
  name: string;
  onClick: () => void;
  tooltip?: string;
  disabled?: boolean;
}
interface HeaderActionWithAction {
  onClick: () => void;
  menu?: undefined;
}
interface HeaderActionWithSubAction {
  menu: HeaderActionSubItem[];
  onClick?: undefined;
}

export type HeaderAction = {
  'data-testid'?: string;
  iconName: string;
  name: string;
  disabled?: boolean;
  onClick?: () => void;
} & (HeaderActionWithAction | HeaderActionWithSubAction);

export interface BreadcrumbsItem {
  iconName?: string;
  iconComponent?: React.FunctionComponent<any>;
  name: string;
  link: string;
}

export interface HeaderContextProps {
  title: string;
  setIsHiddenTitle(value: boolean): void;
  isHiddenTitle: boolean;
  setTitle(title: string): void;
  actions: Array<HeaderAction | React.ReactNode>;
  setActions(value: Array<HeaderAction | React.ReactNode>): void;
  breadcrumb: BreadcrumbsItem[];
  setBreadcrumb(value: BreadcrumbsItem[]): void;
  hasBreadcrumb: boolean;
  setHasBreadcrumb(value: boolean): void;
}
const HeaderContext = React.createContext<HeaderContextProps>({
  title: '',

  /* istanbul ignore next */
  setIsHiddenTitle() {},
  isHiddenTitle: false,

  /* istanbul ignore next */
  setTitle() {},
  actions: [],
  /* istanbul ignore next */
  setActions() {},
  breadcrumb: [],
  /* istanbul ignore next */
  setBreadcrumb() {},
  hasBreadcrumb: false,
  /* istanbul ignore next */
  setHasBreadcrumb() {},
});

interface WithDynamicHeaderProps {
  children: React.ReactNode;
}
export function WithDynamicHeader({ children }: WithDynamicHeaderProps) {
  const [title, setTitle] = useState<string>('');
  const [actions, setActions] = useState<Array<HeaderAction | React.ReactNode>>([]);
  const [breadcrumb, setBreadcrumb] = useState<BreadcrumbsItem[]>([]);
  const [hasBreadcrumb, setHasBreadcrumb] = useState(false);
  const [isHiddenTitle, setIsHiddenTitle] = useState(false);

  return (
    <HeaderContext.Provider
      value={{
        title,
        setIsHiddenTitle,
        isHiddenTitle,
        setTitle,
        actions,
        setActions,
        breadcrumb,
        setBreadcrumb,
        hasBreadcrumb,
        setHasBreadcrumb,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
}

interface PageHeaderProps {
  title?: string;
  actions?: Array<HeaderAction | React.ReactNode>;
  breadcrumb?: BreadcrumbsItem[];
  hasBreadcrumb?: boolean;
  isHiddenTitle?: boolean;
}
export function PageHeader({ title, actions, breadcrumb, hasBreadcrumb, isHiddenTitle }: PageHeaderProps) {
  const { setTitle, setActions, setBreadcrumb, setHasBreadcrumb, setIsHiddenTitle } = useContext(HeaderContext);

  useEffect(() => {
    setTitle(title || '');
    setIsHiddenTitle(isHiddenTitle || false);
    setActions(actions || []);
    setBreadcrumb(breadcrumb || []);
    setHasBreadcrumb(typeof hasBreadcrumb === 'boolean' ? hasBreadcrumb : (breadcrumb?.length || 0) > 0);
    return () => {
      setTitle('');
      setIsHiddenTitle(false);
      setActions([]);
      setBreadcrumb([]);
      setHasBreadcrumb(false);
    };
  }, [title, actions, breadcrumb]);

  return <Helmet title={title} />;
}

export default HeaderContext;
