import React from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../components/snackbar/hooks/useSnackbar';
import TextField from '../../../../components/fields/text-field/TextField';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { Formik, Field } from 'formik';
import { SchoolRoutes } from '../../../../Routes';
import translations, { toastrMessages } from './ImportSingleParticipantModal.translations';
import './ImportSingleParticipantModal.scss';
import { IMPORT_SINGLE_INVITATION } from './gql/ImportSingleParticipant.gql';
import validationSchema from './ImportSingleParticipant.validations';

type ImportSingleParticipantModalProps = {
  programId: string;
};

function ImportSingleParticipantTab({ programId }: ImportSingleParticipantModalProps) {
  const intl = useIntl();
  const { success, error } = useSnackbar();
  const navigate = useNavigate();

  const [importSingleParticipant] = useMutation(IMPORT_SINGLE_INVITATION, {
    refetchQueries: ['GET_PARTICIPANTS_AND_INVITATIONS'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      success(intl.formatMessage(translations.success));
      navigate(
        generatePath(SchoolRoutes.programShow, {
          programid: programId,
        }),
      );
    },
    onError: (err) => {
      const errorCode = err.graphQLErrors?.[0]?.extensions?.code;
      if (errorCode && (errorCode as keyof typeof toastrMessages)) {
        const toastrMessage = intl.formatMessage(toastrMessages[errorCode as keyof typeof toastrMessages]);
        if (toastrMessage) {
          error(toastrMessage);
        } else {
          error(intl.formatMessage(toastrMessages.error));
        }
      } else {
        error(err.message);
        error(intl.formatMessage(toastrMessages.error));
      }
    },
  });

  return (
    <Formik
      initialValues={{
        firstName: '',
        lastName: '',
        email: '',
      }}
      validationSchema={validationSchema(intl)}
      onSubmit={(values) => {
        importSingleParticipant({
          variables: {
            programId,
            ...values,
          },
        });
      }}
    >
      {({ handleSubmit }) => (
        <>
          <div className="import-single-participant-form">
            <div className="import-single-participant-form__input">
              <Field
                name="firstName"
                component={TextField}
                label={intl.formatMessage(translations.firstNameLabel)}
              />
            </div>
            <div className="import-single-participant-form__input">
              <Field
                name="lastName"
                component={TextField}
                label={intl.formatMessage(translations.lastNameLabel)}
              />
            </div>
            <div className="import-single-participant-form__input">
              <Field
                name="email"
                component={TextField}
                label={intl.formatMessage(translations.emailLabel)}
              />
            </div>
            <button
              type="button"
              className="import-single-participant-form__button"
              onClick={() => handleSubmit()}
            >
              {intl.formatMessage(translations.submitButton)}
            </button>
          </div>
        </>
      )}
    </Formik>
  );
}

export default ImportSingleParticipantTab;
