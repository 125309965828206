import Icon from '../../../../../../../../components/icon/Icon';
import { useIntl } from 'react-intl';
import translations from './ProgramShowNoParticipants.translations';

import styles from './ProgramShowNoParticipants.module.scss';

export default function ProgramShowNoParticipants() {
  const intl = useIntl();

  return (
    <div className={styles.noParticipants}>
      <Icon
        name="people"
        className={styles.illustration}
      />
      <h4 className={styles.title}>{intl.formatMessage(translations.title)}</h4>
      <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
    </div>
  );
}
