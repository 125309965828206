import React from 'react';
import { useMemo } from 'react';
import Icon from '../../../../../../../../components/icon/Icon';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { download } from '../../../../../../../../utils/file';
import translations from './ExportParticipantsButton.translations';

import styles from './ExportParticipantsButton.module.scss';

interface ExportParticipantsButtonProps {
  programId?: string;
  programName?: string;
  disabled?: boolean;
}
export default function ExportParticipantsButton({ programId, programName, disabled }: ExportParticipantsButtonProps) {
  const intl = useIntl();

  const fileName = useMemo(
    () =>
      `myjobglasses_report_${programName?.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_${moment().format('YYYY-MM-DD')}.xlsx`,
    [programName],
  );

  function onClick() {
    download(`${import.meta.env.REACT_APP_BACKEND_API_URL}/programs/${programId}/export.xlsx`, fileName);
  }

  return (
    <button
      type="button"
      className={styles.exportButton}
      onClick={onClick}
      disabled={disabled || !programId}
    >
      <Icon
        name="file_download"
        className={styles.icon}
      />
      {intl.formatMessage(translations.button)}
    </button>
  );
}
