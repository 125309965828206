import { gql } from '@apollo/client';
import { InvitationStatus } from '../../../../@types/graphql';
import { PROJECT_MEMBERS_INVITATION, PROJECT_MEMBERS_MEMBERSHIP } from '../../ProjectShow.gql';

export const GET_PROJECT_MEMBERSHIPS = gql`
  ${PROJECT_MEMBERS_MEMBERSHIP}
  ${PROJECT_MEMBERS_INVITATION}

  query GET_PROJECT_MEMBERSHIPS($id: ID!) {
    project(id: $id) {
      id
      name
      canCreateInvitation {
        value
      }
      invitations(filter: {
        query: [
          {filterBy: invitationStatus, operator: eq, value: "${InvitationStatus.Sent}"}
        ]
      }) {
        nodes {
          ...PROJECT_MEMBERS_INVITATION
        }
      }
      memberships(sort: [
        { order: ASC, orderBy: userFirstName },
        { order: ASC, orderBy: userLastName },
      ]) {
        nodes {
          ...PROJECT_MEMBERS_MEMBERSHIP
        }
      }
    }
  }
`;
