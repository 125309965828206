import { gql } from '@apollo/client';

export const CUSTOMIZE_PROGRAM = gql`
  mutation CUSTOMIZE_PROGRAM(
    $id: ID!
    $name: String
    $school: ID
    $grade: GradeKeyEnum
    $diploma: String
    $startAt: ISO8601DateTime
    $endAt: ISO8601DateTime
    $expectedParticipations: Int
    $collaboratorsIds: [ID!]
    $description: String
    $targetMeetingsCategories: TargetMeetingsCategories
    $targetStudentsCategories: TargetStudentsCategories
  ) {
    updateProgram(
      id: $id
      input: {
        name: $name
        school: $school
        grade: $grade
        diploma: $diploma
        startAt: $startAt
        endAt: $endAt
        expectedParticipations: $expectedParticipations
        collaboratorsIds: $collaboratorsIds
        description: $description
        targetMeetingsCategories: $targetMeetingsCategories
        targetStudentsCategories: $targetStudentsCategories
      }
    ) {
      id
      name
      startAt
      endAt
      expectedParticipations
      targetMeetingsCategories
      targetStudentsCategories
      description
      collaborations {
        nodes {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;
