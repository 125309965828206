import TextField from '../../../../../../../../../../../../components/fields/text-field/TextField';
import { Field, Formik } from 'formik';
import { Group } from '../../../../../../../../../../@types/graphql';
import { useSubmitFunction } from './GroupNameEditionForm.hooks';

interface GroupNameEditionFormProps {
  group: Pick<Group, 'id' | 'name'> & {
    canUpdate: Pick<Group['canUpdate'], 'value'>;
  };
  className?: string;
}
export default function GroupNameEditionForm({ group, className }: GroupNameEditionFormProps) {
  const onSubmit = useSubmitFunction();

  return (
    <Formik
      initialValues={{
        id: group.id,
        name: group.name,
      }}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            name="name"
            component={TextField}
            className={className}
            disabled={!group.canUpdate.value}
            withoutBorder
            submitOnBlur
          />
        </form>
      )}
    </Formik>
  );
}
