import { gql } from '@apollo/client';

export const GET_ARCHIVED_PROJECTS_LIST = gql`
  query GET_ARCHIVED_PROJECTS_LIST {
    archivedProjects(sort: [{ orderBy: archivedAt, order: DESC }]) {
      nodes {
        id
        name
        organization {
          id
          name
        }
        author {
          id
          firstName
          lastName
        }
        archivedMemberships {
          total
        }
        archivedPrograms {
          total
        }
        canRecover {
          value
        }
      }
    }
  }
`;
