import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../../../../../../../components/snackbar/hooks/useSnackbar';
import { useIntl } from 'react-intl';
import {
  Update_Group_NameMutation,
  Update_Group_NameMutationVariables,
} from '../../../../../../../../../../@types/graphql';
import {
  MJGApolloError,
  MJGGraphQLErrorCode,
  UPDATE_GROUP_ERROR,
} from '../../../../../../../../../../@types/graphql-errors';
import commonErrorsTranslations from '../../../../../../../../../../commons/translations/errors.translations';
import { UPDATE_GROUP_NAME } from './GroupNameEditionForm.gql';
import { errorTranslations } from './GroupNameEditionForm.translations';

interface FormikFields {
  id: string;
  name: string;
}
export function useSubmitFunction() {
  const intl = useIntl();
  const { error } = useSnackbar();

  const [updateGroup] = useMutation<Update_Group_NameMutation, Update_Group_NameMutationVariables>(UPDATE_GROUP_NAME);

  function onSubmit(values: FormikFields) {
    return updateGroup({
      variables: {
        id: values.id,
        name: values.name,
      },
    }).catch((err: MJGApolloError<FormikFields, UPDATE_GROUP_ERROR>) => {
      if (err.graphQLErrors?.[0]?.extensions?.code === UPDATE_GROUP_ERROR.UNAUTHORIZED) {
        error(intl.formatMessage(errorTranslations[UPDATE_GROUP_ERROR.UNAUTHORIZED]));
      } else if (err.graphQLErrors?.[0]?.extensions?.code === UPDATE_GROUP_ERROR.NOT_FOUND) {
        error(intl.formatMessage(errorTranslations[UPDATE_GROUP_ERROR.NOT_FOUND]));
      } else if (
        err.graphQLErrors?.[0]?.extensions?.code === MJGGraphQLErrorCode.BAD_USER_INPUT &&
        err.graphQLErrors?.[0]?.extensions?.validationErrors?.name.includes(UPDATE_GROUP_ERROR.GROUP_NAME_TAKEN)
      ) {
        error(intl.formatMessage(errorTranslations[UPDATE_GROUP_ERROR.GROUP_NAME_TAKEN]));
      } else if (err.networkError) {
        error(intl.formatMessage(commonErrorsTranslations.network));
      } else {
        error(intl.formatMessage(commonErrorsTranslations.default));
      }
    });
  }
  return onSubmit;
}
