import { defineMessages } from 'react-intl';
import { DELETE_MEMBERSHIP_ERROR } from '../../../../../../@types/graphql-errors';

export const errorTranslations = defineMessages({
  [DELETE_MEMBERSHIP_ERROR.MEMBERSHIP_ROLE_PROJECT_OWNER_CANNOT_LEAVE]: {
    id: 'MemberDeleteModal.MEMBERSHIP_ROLE_PROJECT_OWNER_CANNOT_LEAVE',
    defaultMessage: 'Veuillez désigner un autre membre comme propriétaire pour pouvoir quitter le projet.',
  },
});

export default defineMessages({
  title: {
    id: 'MemberDeleteModal.title',
    defaultMessage: 'Retirer du projet',
  },
  selfTitle: {
    id: 'MemberDeleteModal.selfTitle',
    defaultMessage: 'Quitter le projet',
  },
  description: {
    id: 'MemberDeleteModal.description',
    defaultMessage:
      'En cliquant sur “Confirmer”, {firstName} {lastName} n’aura plus accès à ce projet et perdra donc toutes les informations qui le concernent.',
  },
  selfDescription: {
    id: 'MemberDeleteModal.selfDescription',
    defaultMessage:
      'En cliquant sur “Quitter”, vous ne pourrez plus accéder à ce projet et aux données qu’il contient. Pour récupérer l’accès à ce projet, vous devrez être invité(e) par l’un de ces membres.',
  },
  instructions: {
    id: 'MemberDeleteModal.instruction',
    defaultMessage: 'Écrire "{instructionWord}" pour confirmer ce choix',
  },
  instructionWord: {
    id: 'MemberDeleteModal.instructionWord',
    defaultMessage: 'RETIRER',
  },
  selfInstructionWord: {
    id: 'MemberDeleteModal.selfInstructionWord',
    defaultMessage: 'QUITTER',
  },
  selfSubmitButton: {
    id: 'MemberDeleteModal.selfSubmitButton',
    defaultMessage: 'QUITTER',
  },
  success: {
    id: 'MemberDeleteModal.success',
    defaultMessage: '{firstName} {lastName} a été retiré(e) du projet !',
  },
});
