import { gql } from '@apollo/client';

export const GET_INVITATION_PROGRAMS = gql`
  query GET_INVITATION_PROGRAMS($id: ID!) {
    invitation(id: $id) {
      programIds
      programs {
        nodes {
          id
          name
          group {
            name
          }
        }
      }
    }
  }
`;

export const EDIT_INVITATION_PROGRAMS = gql`
  mutation EDIT_INVITATION_PROGRAMS($id: ID!, $programIds: [ID!]!) {
    updateInvitation(id: $id, input: { programIds: $programIds }) {
      id
    }
  }
`;
