import { defineMessages } from 'react-intl';

export default defineMessages({
  program: {
    id: 'ProgramTemplateField.program',
    defaultMessage: 'Programme <boldThis>{name}</boldThis>',
  },
  xMeetings: {
    id: 'ProgramTemplateField.xMeetings',
    defaultMessage: '{x} rendez-vous ',
  },
  xMeetingsDescription: {
    id: 'ProgramTemplateField.xMeetingsDescription',
    defaultMessage: 'Avec des ambassadeurs de My Job Glasses',
  },
  xMonth: {
    id: 'ProgramTemplateField.xMonth',
    defaultMessage: '{x} mois',
  },
  xMonthDescription: {
    id: 'ProgramTemplateField.xMonthDescription',
    defaultMessage: 'Durée minimum',
  },
});
