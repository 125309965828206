import { useState } from 'react';

export default function useDropdown(): [boolean, () => void, () => void, () => void] {
  const [isOpen, setIsOpen] = useState(false);

  function toogle() {
    setIsOpen(!isOpen);
  }

  function open() {
    setIsOpen(true);
  }

  function close() {
    setIsOpen(false);
  }

  return [isOpen, toogle, open, close];
}
