import React from 'react';
import { WithDynamicHeader } from '../context/HeaderContext';
import { LayoutWithDynamicSidebar } from '../context/LayoutContext';
import Header from '../header/Header';
import Sidebar from '../sidebar/Sidebar';

import styles from './Layout.module.scss';

interface LayoutProps {
  children: React.ReactNode;
}

function Layout({ children }: LayoutProps) {
  return (
    <WithDynamicHeader>
      <LayoutWithDynamicSidebar>
        <div className={styles.layout}>
          <Sidebar />
          <main className={styles.main}>
            <Header />
            {children}
          </main>
        </div>
      </LayoutWithDynamicSidebar>
    </WithDynamicHeader>
  );
}

export default Layout;
