import React from 'react';
import Modal from '../../../../../../../../components/modal/Modal';
import ModalHeader from '../../../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../../../components/modal/components/modal-footer/ModalFooter';
import TextField from '../../../../../../../../components/fields/text-field/TextField';
import { Field, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { generatePath, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../Routes';
import GlobalErrorDisplay from '../../../../../../commons/global-error-display/GlobalErrorDisplay';
import commonButtonsTranslations from '../../../../../../commons/translations/buttons.translations';
import useMembershipId from '../../hooks/useMembershipId';
import useMembership from '../../hooks/useMembership';
import { useMembershipDeleteMutation } from './MemberDeleteModal.hooks';
import translations from './MemberDeleteModal.translations';
import validationSchema from './MemberDeleteModal.validations';

import styles from './MemberDeleteModal.module.scss';

export default function MemberDeleteModal() {
  const intl = useIntl();
  const { projectid } = useParams();

  const membershipId = useMembershipId();
  const membership = useMembership(membershipId);
  const onSubmit = useMembershipDeleteMutation(membershipId);

  const instructionWord = membership?.isMe
    ? intl.formatMessage(translations.selfInstructionWord)
    : intl.formatMessage(translations.instructionWord);

  const title = membership?.isMe ? intl.formatMessage(translations.selfTitle) : intl.formatMessage(translations.title);

  return (
    <Modal
      title={title}
      openPathname={SchoolRoutes.projectMemberDelete}
      closeUrl={generatePath(SchoolRoutes.projectMembers, { projectid: projectid || '' })}
    >
      <Formik
        initialValues={{
          leave: '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema(instructionWord)}
        validateOnMount
      >
        {({ handleSubmit, isValid }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader title={title} />
            <ModalBody>
              <p className={styles.description}>
                {membership?.isMe
                  ? intl.formatMessage(translations.selfDescription)
                  : intl.formatMessage(translations.description, {
                      firstName: membership?.userFirstName,
                      lastName: membership?.userLastName,
                    })}
              </p>
              <p className={styles.instructions}>
                {intl.formatMessage(translations.instructions, {
                  instructionWord,
                })}
              </p>
              <Field
                name="leave"
                component={TextField}
                placeholder={instructionWord}
              />
              <GlobalErrorDisplay />
            </ModalBody>
            <ModalFooter
              cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
              submitText={
                membership?.isMe
                  ? intl.formatMessage(translations.selfSubmitButton)
                  : intl.formatMessage(commonButtonsTranslations.confirm)
              }
              disableSubmit={!isValid}
              className={styles.modalFooter}
            />
          </form>
        )}
      </Formik>
    </Modal>
  );
}
