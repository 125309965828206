import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ProgramTemplateView.title',
    defaultMessage: 'Type de programme',
  },
  description: {
    id: 'ProgramTemplateView.description',
    defaultMessage: 'Définissez l’objectif à atteindre',
  },
});
