export default function DP01() {
  return (
    <filter
      id="DP01"
      height="130%"
    >
      <feGaussianBlur
        in="SourceAlpha"
        stdDeviation="1"
        result="blur1"
      />
      <feGaussianBlur
        in="SourceAlpha"
        stdDeviation="1"
        result="blur2"
      />
      <feGaussianBlur
        in="SourceAlpha"
        stdDeviation="3"
        result="blur3"
      />

      <feDropShadow
        dx="0"
        dy="0"
        floodOpacity="0"
        floodColor="rgba(14,14,17,0.14)"
        result="offsetblur"
        in="blur1"
      />
      <feDropShadow
        dx="0"
        dy="-2"
        floodOpacity="-1"
        floodColor="rgba(14,14,17,0.12)"
        result="offsetblur2"
        in="blur2"
      />
      <feDropShadow
        dx="0"
        dy="-1"
        floodOpacity="0"
        floodColor="rgba(14,14,17,0.2)"
        result="offsetblur3"
        in="blur3"
      />

      <feComponentTransfer
        result="shadow1"
        in="offsetblur"
      >
        <feFuncA
          type="linear"
          slope="0.5"
        />
      </feComponentTransfer>

      <feComponentTransfer
        result="shadow2"
        in="offsetblur2"
      >
        <feFuncA
          type="linear"
          slope="0.2"
        />
      </feComponentTransfer>

      <feComponentTransfer
        result="shadow3"
        in="offsetblur3"
      >
        <feFuncA
          type="linear"
          slope="0.2"
        />
      </feComponentTransfer>

      <feMerge>
        <feMergeNode in="shadow1" />
        <feMergeNode in="shadow2" />
        <feMergeNode in="shadow3" />
        <feMergeNode in="SourceGraphic" />
      </feMerge>
    </filter>
  );
}
