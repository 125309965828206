import { gql } from '@apollo/client';

export const GET_ME = gql`
  query GET_ME {
    me {
      id
      firstName
      avatarUrl
      primarySituation
    }
  }
`;
