import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useFormik, FormikProvider } from 'formik';
import { useIntl } from 'react-intl';
import { useParams, useLocation, matchPath } from 'react-router-dom';
import { SchoolRoutes } from '../../../../Routes';
import GlobalErrorDisplay from '../../commons/global-error-display/GlobalErrorDisplay';
import PageLoading from '../../commons/page-loading/PageLoading';
import { GET_PROJECT_TO_UPDATE } from './ProjectCreateUpdate.gql';
import { useCreateNewProject } from './ProjectCreateUpdate.hooks';
import translations from './ProjectCreateUpdate.translations';
import validationSchema from './ProjectCreateUpdate.validations';

import styles from './ProjectCreateUpdate.module.scss';
import TextInput from '../../../../components/text-input/TextInput';
import SchoolForm from './components/school-form/SchoolForm';
import { GET_ME } from '../homepage/Homepage.gql';

type FormikValues = {
  name: string;
  schools?: any[];
};

function ProjectCreateUpdate() {
  const intl = useIntl();
  const location = useLocation();

  const match = matchPath(SchoolRoutes.projectUpdate, location.pathname);

  const { projectid } = useParams();
  const { data: user, loading: userLoading } = useQuery(GET_ME);
  const [schools, setSchools] = useState<any[]>([]);

  const showSchoolForm = user?.me?.primarySituation != 'JOB_SEEKER_SUPPORT'

  const { data, loading } = useQuery(GET_PROJECT_TO_UPDATE, {
    variables: {
      id: projectid || '',
    },
    skip: !match || !projectid,
    fetchPolicy: 'network-only',
  });

  const onSubmit = useCreateNewProject(projectid);

  useEffect(() => {
    if (data?.project.schools) {
      setSchools(data.project.schools.nodes);
    }
  }, [data?.project.schools]);

  const formik = useFormik<FormikValues>({
    initialValues: {
      name: data?.project?.name || '',
      schools: showSchoolForm ? [] : undefined,
    },
    validationSchema: validationSchema(intl),
    onSubmit: onSubmit,
    enableReinitialize: true,
  });

  if (loading || userLoading) {
    return <PageLoading />;
  }

  return (
    <main className={styles.pageCreate}>
      <div className={styles.projectCreateContainer}>
        <div className={styles.projectCreateTitle}>
          {match
            ? intl.formatMessage(translations.editProjectTitle)
            : intl.formatMessage(translations.createProjectTitle)}
        </div>
        <FormikProvider value={formik}>
          <form
            className={styles.form}
            onSubmit={formik.handleSubmit}
          >
            <fieldset className={styles.fieldset}>
              <label
                id="project-name-label"
                className={styles.label}
                htmlFor="name"
              >
                {intl.formatMessage(translations.projectNameLabel)}
              </label>
              <p className={styles.subtitle}>{showSchoolForm ? intl.formatMessage(translations.projectNameSubtitle) : intl.formatMessage(translations.projectNameSubtitleForFT)}</p>
              <TextInput
                name="name"
                placeholder={showSchoolForm ? intl.formatMessage(translations.projectNamePlaceholder) : intl.formatMessage(translations.projectNamePlaceholderForFT)}
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </fieldset>
            {showSchoolForm && (
              <SchoolForm
                formik={formik}
                state={location.state}
                existingSchools={schools}
              />
            )}
            <div className={styles.actions}>
              <GlobalErrorDisplay />
              <button
                type="submit"
                className={styles.submitButton}
                disabled={!formik.dirty || !formik.isValid || formik.isSubmitting}
              >
                {match ? intl.formatMessage(translations.editButton) : intl.formatMessage(translations.createButton)}
              </button>
            </div>
          </form>
        </FormikProvider>
      </div>
    </main>
  );
}

export default ProjectCreateUpdate;
