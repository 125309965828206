import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'NoResultsTable.title',
    defaultMessage: '🔎 Aucun participant trouvé',
  },
  description: {
    id: 'NoResultsTable.description',
    defaultMessage: '💡 Que faire ?',
  },
  bulletOne: {
    id: 'NoResultsTable.bulletOne',
    defaultMessage: 'Vérifiez l\'orthographe.',
  },
  bulletTwo: {
    id: 'NoResultsTable.bulletTwo',
    defaultMessage: 'Assurez-vous que le participant a bien été invité au programme.',
  },
  bulletThree: {
    id: 'NoResultsTable.bulletThree',
    defaultMessage: 'Si nécessaire, partagez le code de participation avec le membre concerné pour qu’il puisse rejoindre le programme.',
  },
});
