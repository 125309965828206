import React from 'react';
import Icon from '../../../../../../../../components/icon/Icon';
import { Chart } from 'react-google-charts';
import classnames from 'classnames';

import styles from './ProjectStatisticWithColumnChart.module.scss';

interface ProjectStatisticWithColumnChartProps {
  title: string;
  description: string | React.ReactNode;
  data: any[];
  reviewCount: number;
  noData?: boolean;
  noDataTitle: string;
  noDataDescription: string;
  className?: string;
  color: string;
}
export default function ProjectStatisticWithColumnChart({
  title,
  description,
  data,
  noData,
  reviewCount,
  noDataTitle,
  noDataDescription,
  className,
  color,
}: ProjectStatisticWithColumnChartProps) {
  const options = {
    legend: { position: 'none' },
    colors: [color],
    chartArea: { width: '85%', height: '70%' },
    hAxis: {
      title: data[0][0],
    },
    vAxis: {
      title: data[0][1],
      format: '',
      minorGridlines: {
        count: 0,
      },
    },
    fontSize: 16,
    fontName: 'Roboto',
  };

  return (
    <div className={classnames(styles.bloc, className)}>
      <h4 className={styles.title}>{title}</h4>
      {reviewCount > 0 && !noData ? (
        <div className={styles.chart}>
          <p className={styles.chartDescription}>{description}</p>
          <Chart
            chartType="ColumnChart"
            data={data}
            options={options}
            width={'100%'}
            height={'300px'}
          />
        </div>
      ) : (
        <div className={styles.noData}>
          <Icon
            name="bar_chart"
            className={styles.noDataIcon}
          />
          <h5 className={styles.noDataTitle}>{noDataTitle}</h5>
          <p className={styles.noDataDescription}>{noDataDescription}</p>
        </div>
      )}
    </div>
  );
}
