import React from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../../../components/snackbar/hooks/useSnackbar';
import { useIntl } from 'react-intl';
import { generatePath, useParams } from 'react-router-dom';
import { Revoke_InvitationMutation, Revoke_InvitationMutationVariables, Role } from '../../../../../../@types/graphql';
import { SchoolRoutes } from '../../../../../../../../Routes';
import ActionButton from '../../../../../../commons/action-button/ActionButton';
import cellWrapper from '../../../../../../commons/cell-wrapper/cellWrapper';
import commonErrorsTranslations from '../../../../../../commons/translations/errors.translations';
import { RowRenderer } from '../../@types/project-members-table';
import { REVOKE_INVITATION } from './ActionsCell.gql';
import translations from './ActionsCell.translations';

import styles from './ActionsCell.module.scss';

function ActionsCell({ rowData }: RowRenderer) {
  const intl = useIntl();
  const { projectid = '' } = useParams();
  const { error } = useSnackbar();

  const [revokeInvitation] = useMutation<Revoke_InvitationMutation, Revoke_InvitationMutationVariables>(
    REVOKE_INVITATION,
    {
      variables: {
        id: rowData?.id,
      },
      refetchQueries: ['GET_PROJECT_INFO'],
      onError: () => {
        error(intl.formatMessage(commonErrorsTranslations.default));
      },
    },
  );

  if (rowData?.__typename === 'Membership') {
    if (rowData.isMe) {
      return (
        <ActionButton
          iconName="more_horiz"
          actions={[
            {
              name: intl.formatMessage(translations.addToProgram),
              link: generatePath(SchoolRoutes.projectMemberAddToProgram, {
                projectid,
                membershipid: rowData.id,
              }),
              disabled: !rowData.canUpdate.value,
            },
            {
              name: intl.formatMessage(translations.editRole),
              link: generatePath(SchoolRoutes.projectMemberEdit, {
                projectid,
                membershipid: rowData.id,
              }),
              disabled: !rowData.canUpdate.value,
            },
            {
              name: intl.formatMessage(translations.leaveProject),
              link: generatePath(SchoolRoutes.projectMemberDelete, {
                projectid,
                membershipid: rowData.id,
              }),
              disabledExplanation:
                rowData.role === Role.Owner ? intl.formatMessage(translations.notAllowedToSelfDeleteOwner) : undefined,
              disabled: rowData.role === Role.Owner || !rowData.canDelete.value,
            },
          ]}
          className={styles.button}
          popupClassName={styles.popup}
        />
      );
    }

    return (
      <ActionButton
        iconName="more_horiz"
        actions={[
          {
            name: intl.formatMessage(translations.addToProgram),
            link: generatePath(SchoolRoutes.projectMemberAddToProgram, {
              projectid,
              membershipid: rowData.id,
            }),
            disabled: !rowData.canUpdate.value,
          },
          {
            name: intl.formatMessage(translations.editRole),
            link: generatePath(SchoolRoutes.projectMemberEdit, {
              projectid,
              membershipid: rowData.id,
            }),
            disabled: !rowData.canUpdate.value,
          },
          {
            name: intl.formatMessage(translations.removeMembership),
            link: generatePath(SchoolRoutes.projectMemberDelete, {
              projectid,
              membershipid: rowData.id,
            }),
            disabled: !rowData.canDelete.value,
          },
        ]}
        className={styles.button}
        popupClassName={styles.popup}
      />
    );
  }

  if (rowData?.__typename === 'Invitation') {
    return (
      <ActionButton
        iconName="more_horiz"
        actions={[
          {
            name: intl.formatMessage(translations.addToProgram),
            link: generatePath(SchoolRoutes.projectMemberInvitationAddToProgram, {
              projectid,
              invitationid: rowData.id,
            }),
            disabled: !rowData.canUpdate.value,
          },
          {
            name: intl.formatMessage(translations.editRole),
            link: generatePath(SchoolRoutes.projectMembersEditInvitation, {
              projectid,
              invitationid: rowData.id,
            }),
            disabled: !rowData.canUpdate.value,
          },
          {
            name: intl.formatMessage(translations.revokeInvitation),
            onClick: revokeInvitation,
            disabled: !rowData.canDelete.value,
          },
        ]}
        className={styles.button}
        popupClassName={styles.popup}
      />
    );
  }
  return null;
}

export default cellWrapper(ActionsCell);
