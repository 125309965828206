import React from 'react';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../Routes';
import { ViewSwitch } from '../../../../pages/program-show/components/participants-activity-board/components/participants-table/ParticipantsTable';
interface BatchActionsBarActionProps {
  values: string[];
  label: string;
  className?: string;
  state: ViewSwitch;
}
export default function BatchActionsBarAction({ values, label, className, state }: BatchActionsBarActionProps) {
  const navigate = useNavigate();
  const { programid = '' } = useParams();

  function handleClick() {
    state === 'active' ?
      navigate(generatePath(SchoolRoutes.programShowRemoveParticipation, { programid }), { state: { values } }) :
      navigate(generatePath(SchoolRoutes.programShowRecoverParticipation, { programid }), { state: { values } });
  }

  return (
    <button
      type="button"
      className={className}
      onClick={handleClick}
    >
      {label}
    </button>
  );
}
