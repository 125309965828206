import { defineMessages } from 'react-intl';

export default defineMessages({
  schoolTitle: {
    id: 'ProjectCreateUpdate.schoolTitle',
    defaultMessage: 'Votre établissement',
  },
  schoolLabelSubtitle: {
    id: 'ProjectCreateUpdate.schoolLabelSubtitle',
    defaultMessage: 'Sélectionnez  le type d’établissement concerné, puis son département puis son nom',
  },
  selectSchoolType: {
    id: 'ProjectCreateUpdate.selectSchoolType',
    defaultMessage: 'Sélectionnez un type d\'établissement',
  },
  selectDepartment: {
    id: 'ProjectCreateUpdate.selectDepartment',
    defaultMessage: 'Sélectionnez un département',
  },
  selectSchool: {
    id: 'ProjectCreateUpdate.selectSchool',
    defaultMessage: 'Recherchez votre établissement',
  },
  schoolSubtitle: {
    id: 'ProjectCreateUpdate.schoolSubtitle',
    defaultMessage: 'Si votre établissement est présent dans plusieurs départements, indiquez-les tous (maximum 10). Effectuez une nouvelle recherche pour ajouter un autre établissement.',
  },
  addSchool: {
    id: 'ProjectCreateUpdate.addSchool',
    defaultMessage: 'Ajouter un établissement',
  },
  emptySchoolNameMessage: {
    id: 'ProjectCreateUpdate.emptySchoolNameMessage',
    defaultMessage: 'Pas de résultat. Cliquez ici si vous n\'avez pas trouvé votre établissement dans la liste',
  },
  selectedSchoolsLabel: {
    id: 'ProjectCreateUpdate.selectedSchoolsLabel',
    defaultMessage: 'Écoles ajoutées au projet',
  },
});
