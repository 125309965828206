import React from 'react';
import { useIntl } from 'react-intl';
import translations from './NoResultsTable.translations';

import styles from './NoResultsTable.module.scss';

export default function NoResultsTable() {
  const intl = useIntl();

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{intl.formatMessage(translations.title)}</p>
      <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
      <ul>
        <li className={styles.bulletPoint}>{intl.formatMessage(translations.bulletOne)}</li>
        <li className={styles.bulletPoint}>{intl.formatMessage(translations.bulletTwo)}</li>
        <li className={styles.bulletPoint}>{intl.formatMessage(translations.bulletThree)}</li>
      </ul>
    </div>
  );
}
