import React from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../components/snackbar/hooks/useSnackbar';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../Routes';
import { doesErrorExist } from '../../../../../../utils/graphql';
import { Recover_ProjectMutation, Recover_ProjectMutationVariables } from '../../../../@types/graphql';
import { MJGApolloError, RECOVER_PROJECT_ERRORS } from '../../../../@types/graphql-errors';
import ConfirmationModal from '../../../program-show/components/participants-activity-board/components/participants-table/components/archive-participation-modal/ArchiveParticipationModal';
import commonErrorsTranslations from '../../../../commons/translations/errors.translations';
import { RECOVER_PROJECT } from './RecoverProjectModal.gql';
import styles from './RecoverProjectModal.module.scss';
import translations, { errorTranslations } from './RecoverProjectModal.translations';
import Modal from '../../../../../../components/modal/Modal';
import ModalHeader from '../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../components/modal/components/modal-footer/ModalFooter';
import commonButtonsTranslations from '../../../../commons/translations/buttons.translations';
import { MJGFormattedMessage } from '../../../../../../components/mjg-formatted-message/MJGFormattedMessage';

export default function RecoverProjectModal() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { projectid = '' } = useParams();
  const { error: errorSnackbar } = useSnackbar();

  const [recoverProject, { loading }] = useMutation<Recover_ProjectMutation, Recover_ProjectMutationVariables>(
    RECOVER_PROJECT,
    {
      variables: {
        projectId: projectid || '',
      },
      onCompleted: (data) => {
        if (data.recoverProject) {
          navigate(SchoolRoutes.projectArchivedList);
        }
      },
      onError: (error) => {
        const errors = error as MJGApolloError<{}, RECOVER_PROJECT_ERRORS>;

        if (doesErrorExist(errors, RECOVER_PROJECT_ERRORS.NOT_FOUND)) {
          errorSnackbar(intl.formatMessage(errorTranslations.NOT_FOUND));
        } else if (doesErrorExist(errors, RECOVER_PROJECT_ERRORS.UNAUTHORIZED)) {
          errorSnackbar(intl.formatMessage(errorTranslations.UNAUTHORIZED));
        } else if (errors.networkError) {
          errorSnackbar(intl.formatMessage(commonErrorsTranslations.network));
        } else {
          errorSnackbar(intl.formatMessage(commonErrorsTranslations.technical));
        }
      },
      refetchQueries: ['GET_SIDEBAR_PROJECT_TREE', 'GET_MY_PROJECT_LIST', 'GET_ARCHIVED_PROJECTS_LIST'],
      awaitRefetchQueries: true,
    },
  );

  return (
    <Modal
      title={intl.formatMessage(translations.title)}
      openPathname={generatePath(SchoolRoutes.projectArchivedListRecover, { projectid: projectid })}
      closeUrl={generatePath(SchoolRoutes.projectArchivedList)}
    >
      <ModalHeader title={intl.formatMessage(translations.title)} />

      <ModalBody>
        <p className={styles.description}>
          <MJGFormattedMessage translation={translations.description} />
        </p>
      </ModalBody>

      <ModalFooter
        className={styles.modalFooter}
        cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
        submitFunction={recoverProject}
        submitText={intl.formatMessage(commonButtonsTranslations.confirm)}
      />
    </Modal>
  );
}
