import React, { useCallback } from 'react';
import { useSnackbar as useNotistack } from 'notistack';
import SnackMessage from '../SnackMessage';

interface SnackMessageOption {
  icon?: string;
  time?: number;
  closeButtonContent?: string | React.ReactNode;
}
export function useSnackbar() {
  const { enqueueSnackbar } = useNotistack();

  const success = useCallback(
    (message: string, options?: SnackMessageOption) =>
      enqueueSnackbar(message, {
        variant: 'success',
        autoHideDuration: options?.time || 5000,
        content: (key) => (
          <SnackMessage
            id={key}
            message={message}
            variant="success"
            icon={options?.icon}
            closeButtonContent={options?.closeButtonContent}
          />
        ),
      }),
    [],
  );

  const error = useCallback(
    (message: string, options?: SnackMessageOption) =>
      enqueueSnackbar(message, {
        variant: 'error',
        autoHideDuration: options?.time || 5000,
        content: (key) => (
          <SnackMessage
            id={key}
            message={message}
            variant="error"
            icon={options?.icon}
            closeButtonContent={options?.closeButtonContent}
          />
        ),
      }),
    [],
  );

  const warning = useCallback(
    (message: string, options?: SnackMessageOption) =>
      enqueueSnackbar(message, {
        variant: 'warning',
        autoHideDuration: options?.time || 5000,
        content: (key) => (
          <SnackMessage
            id={key}
            message={message}
            variant="warning"
            icon={options?.icon}
            closeButtonContent={options?.closeButtonContent}
          />
        ),
      }),
    [],
  );

  const info = useCallback(
    (message: string, options?: SnackMessageOption) =>
      enqueueSnackbar(message, {
        variant: 'info',
        autoHideDuration: options?.time || 5000,
        content: (key) => (
          <SnackMessage
            id={key}
            message={message}
            variant="info"
            icon={options?.icon}
            closeButtonContent={options?.closeButtonContent}
          />
        ),
      }),
    [],
  );

  return {
    success,
    error,
    warning,
    info,
  };
}
