import { gql } from '@apollo/client';

export const GET_PARTICIPANT_STATISTICS = gql`
  query GET_PARTICIPANT_STATISTICS($id: ID!) {
    participation(id: $id) {
      id
      visitsCount
      bookmarkedProfessionalsCount
      conversationsCount
      unfollowedConversationCount
      remainingContactCount
      incomingMeetingCount
      waitingForConfirmationMeetingCount
      confirmedMeetingCount
    }
  }
`;
