import React, { useCallback, useState } from 'react';
import Modal from '../../../../../../components/modal/Modal';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../Routes';
import translations, { toastrMessages } from './ProgramDuplicateModal.translations';
import { useIntl } from 'react-intl';
import commonButtonsTranslations from '../../../../commons/translations/buttons.translations';

import ModalHeader from '../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalFooter from '../../../../../../components/modal/components/modal-footer/ModalFooter';
import { useSnackbar } from '../../../../../../components/snackbar/hooks/useSnackbar';
import { useMutation } from '@apollo/client';
import { DUPLICATE_PROGRAM } from './ProgramDuplicateModal.gql';
import styles from './ProgramDuplicateModal.module.scss';
import { MutationDuplicateProgramArgs } from '../../../../@types/graphql';

export default function ProgramDuplicateModal() {
  const { programid = '' } = useParams();
  const intl = useIntl();
  const navigate = useNavigate();
  const { success, error } = useSnackbar();
  const [programDuplicationCount, setProgramDuplicationCount] = useState(1);

  const [duplicateProgram] = useMutation<MutationDuplicateProgramArgs>(DUPLICATE_PROGRAM, {
    variables: {
      id: programid,
      programDuplicationCount: programDuplicationCount,
    },
    refetchQueries: ['GET_SIDEBAR_PROJECT_TREE'],
    awaitRefetchQueries: true,
    onCompleted: () => {
      navigate(generatePath(SchoolRoutes.programShow, { programid }));
      success(intl.formatMessage(toastrMessages.success));
    },
    onError: (err) => {
      const errorCode = err.graphQLErrors?.[0]?.extensions?.code;
      const validationErrors = err.graphQLErrors?.[0]?.extensions?.validationErrors;

      if (validationErrors) {
        Object.entries(validationErrors).forEach(([field, errors]) => {
          errors.forEach((errorMessage: string) => {
            error(intl.formatMessage(toastrMessages[errorMessage as keyof typeof toastrMessages]));
          });
        });

        return
      }

      if (errorCode && (errorCode as keyof typeof toastrMessages)) {
        const toastrMessage = intl.formatMessage(toastrMessages[errorCode as keyof typeof toastrMessages]);
        if (toastrMessage) {
          error(toastrMessage);
        } else {
          error(intl.formatMessage(toastrMessages.error));
        }
      } else {
        error(intl.formatMessage(toastrMessages.error));
      }
    },
  });

  const onConfirm = useCallback(() => duplicateProgram(), []);

  return (
    <Modal
      title={intl.formatMessage(translations.title)}
      openPathname={generatePath(SchoolRoutes.programDuplicate, { programid })}
      closeUrl={generatePath(SchoolRoutes.programShow, { programid })}
    >
      <ModalHeader title={intl.formatMessage(translations.title)} />

      <div className={styles.body}>
        <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
        <input
          type="number"
          min={1}
          value={programDuplicationCount}
          className={styles.input}
          onChange={(e) => setProgramDuplicationCount(parseInt(e.target.value))}
        ></input>
      </div>

      <ModalFooter
        className={styles.footer}
        cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
        submitFunction={onConfirm}
        submitText={intl.formatMessage(commonButtonsTranslations.confirm)}
      />
    </Modal>
  );
}
