import { defineMessages } from 'react-intl';
import { ProgramProgressionStatus } from '../../@types/graphql';

export default defineMessages({
  [ProgramProgressionStatus.Upcoming]: {
    id: 'ProgramStatusBadge.Upcoming',
    defaultMessage: 'À venir',
  },
  [ProgramProgressionStatus.Ongoing]: {
    id: 'ProgramStatusBadge.Ongoing',
    defaultMessage: 'En cours',
  },
  [ProgramProgressionStatus.Finished]: {
    id: 'ProgramStatusBadge.Finished',
    defaultMessage: 'Terminé',
  },
});
