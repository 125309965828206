import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ProjectStatisticsDisplay.title',
    defaultMessage: 'Statistiques',
  },
  subTitle: {
    id: 'ProjectStatisticsDisplay.subTitle',
    defaultMessage: 'Retrouvez ici prochainement les statistiques de votre projet !',
  },
  description: {
    id: 'ProjectStatisticsDisplay.description',
    defaultMessage:
      'Découvrez les centres d’intérêt de vos participants, leur progression dans chaque programme et ce qu’ils ont pensé de leurs rendez-vous.',
  },
  addProgramButton: {
    id: 'ProjectStatisticsDisplay.addProgramButton',
    defaultMessage: 'Programme',
  },
  noData: {
    id: 'ProjectStatisticsDisplay.noData',
    defaultMessage: 'Aucune donnée',
  },
  nReview: {
    id: 'ProjectStatisticsDisplay.nReview',
    defaultMessage: '{x} avis',
  },
  participantsAffiliated: {
    id: 'ProjectStatisticsDisplay.participantsAffiliated',
    defaultMessage: '<boldThis>Participants</boldThis> affiliés',
  },
  participantsAffiliatedDescription: {
    id: 'ProjectStatisticsDisplay.participantsAffiliatedDescription',
    defaultMessage: '<boldThis>{x}%</boldThis> du nombre prévu',
  },
  professionalsContactedByParticipants: {
    id: 'ProjectStatisticsDisplay.professionalsContactedByParticipants',
    defaultMessage: '<boldThis>Conversations initiées</boldThis> par les participants',
  },
  professionalsContactedByParticipantsDescription: {
    id: 'ProjectStatisticsDisplay.professionalsContactedByParticipantsDescription',
    defaultMessage: '<boldThis>{x}%</boldThis> des participants',
  },
  accomplishedAppointments: {
    id: 'ProjectStatisticsDisplay.accomplishedAppointments',
    defaultMessage: '<boldThis>Rendez-vous</boldThis> réalisés',
  },
  accomplishedCollectiveAppointments: {
    id: 'ProjectStatisticsDisplay.accomplishedCollectiveAppointments',
    defaultMessage: '<boldThis>Rendez-vous collectifs</boldThis> réalisés',
  },
  accomplishedAppointmentsDescription: {
    id: 'ProjectStatisticsDisplay.accomplishedAppointmentsDescription',
    defaultMessage: '<boldThis>{x}%</boldThis> des participants',
  },
  accomplishedCollectiveAppointmentsDescription: {
    id: 'ProjectStatisticsDisplay.accomplishedCollectiveAppointmentsDescription',
    defaultMessage: '<boldThis>{x}</boldThis> personnes impactées',
  },
  differentProfessionalsMet: {
    id: 'ProjectStatisticsDisplay.differentProfessionalsMet',
    defaultMessage: '<boldThis>Ambassadeurs</boldThis> différents rencontrés',
  },
  differentProfessionalsMetDescription: {
    id: 'ProjectStatisticsDisplay.differentProfessionalsMetDescription',
    defaultMessage: 'Sur {x, plural, zero {# rencontres} one {# rencontre} other {# rencontres}}',
  },
  progression: {
    id: 'ProjectStatisticsDisplay.progression',
    defaultMessage: 'Progression',
  },
  progressionDescription: {
    id: 'ProjectStatisticsDisplay.progressionDescription',
    defaultMessage: '<boldThis>Certificats</boldThis> délivrés',
  },
  progressionNoData: {
    id: 'ProjectStatisticsDisplay.progressionNoData',
    defaultMessage: 'Le nombre de participants ayant atteint l’objectif sera visible ici',
  },
  progressionPercentage: {
    id: 'ProjectStatisticsDisplay.progressionPercentage',
    defaultMessage: '<boldThis>{x}%</boldThis> des participants',
  },
  utilityOfAppointments: {
    id: 'ProjectStatisticsDisplay.utilityOfAppointments',
    defaultMessage: 'Utilité des rendez-vous',
  },
  utilityOfAppointmentsNoData: {
    id: 'ProjectStatisticsDisplay.utilityOfAppointmentsNoData',
    defaultMessage:
      'Les réponses des participants à la question “Est-ce que ce rendez-vous t’a été utile ?” seront visibles ici',
  },
  utilityOfAppointmentsDescription: {
    id: 'ProjectStatisticsDisplay.utilityOfAppointmentsDescription',
    defaultMessage: '<boldThis>Évaluation des participants</boldThis> suite à leur rendez-vous avec un ambassadeur',
  },
  preparationOfAppointments: {
    id: 'ProjectStatisticsDisplay.preparationOfAppointments',
    defaultMessage: 'Préparation des rendez-vous',
  },
  preparationOfAppointmentsNoData: {
    id: 'ProjectStatisticsDisplay.preparationOfAppointmentsNoData',
    defaultMessage:
      'Les réponses des ambassadeurs à la question “Est-ce que ce rendez-vous a été bien préparé ?” seront visibles ici',
  },
  preparationOfAppointmentsDescription: {
    id: 'ProjectStatisticsDisplay.preparationOfAppointmentsDescription',
    defaultMessage: '<boldThis>Évaluation des ambassadeurs</boldThis> suite à leur rendez-vous avec un participant ',
  },
  top5Companies: {
    id: 'ProjectStatisticsDisplay.top5Companies',
    defaultMessage: 'Top 5 entreprises',
  },
  top5CompaniesNoData: {
    id: 'ProjectStatisticsDisplay.top5CompaniesNoData',
    defaultMessage: 'Les noms des 5 entreprises les plus rencontrées par vos participants seront visibles ici',
  },
  top5SecteursOfActivity: {
    id: 'ProjectStatisticsDisplay.top5SecteursOfActivity',
    defaultMessage: 'Top 5 secteurs d’activité',
  },
  top5SecteursOfActivityNoData: {
    id: 'ProjectStatisticsDisplay.top5SecteursOfActivityNoData',
    defaultMessage: 'Les noms des 5 secteurs d’activité les plus populaires parmi vos participants seront visibles ici',
  },
  top5Professions: {
    id: 'ProjectStatisticsDisplay.top5Professions',
    defaultMessage: 'Top 5 métiers',
  },
  top5ProfessionsNoData: {
    id: 'ProjectStatisticsDisplay.top5ProfessionsNoData',
    defaultMessage: 'Les noms des 5 métiers les plus rencontrés par vos participants seront visibles ici',
  },
});
