import React from 'react';
import Icon from '../../../../../../components/icon/Icon';
import Tooltip from '../../../../../../components/tooltip/Tooltip';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { Maybe, Project } from '../../../../@types/graphql';
import { SchoolRoutes } from '../../../../../../Routes';
import translations from './ArchivedProjectItem.translations';

import styles from './ArchivedProjectItem.module.scss';

interface ArchivedProjectItemProps {
  item: Pick<Project, 'id' | 'name' | 'archivedAt'> & {
    organization: Pick<Project['organization'], 'id' | 'name'>;
    author: Pick<Project['author'], 'id' | 'firstName' | 'lastName'>;
    archivedMemberships?: Maybe<Pick<NonNullable<Project['memberships']>, 'total'>>;
    archivedPrograms?: Maybe<Pick<NonNullable<Project['programs']>, 'total'>>;
    canRecover: Pick<Project['canRecover'], 'value'>;
  };
}
export default function ArchivedProjectItem({ item }: ArchivedProjectItemProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  const canRecover = item.canRecover.value || false;

  function onRecover() {
    if (canRecover) {
      navigate(
        generatePath(SchoolRoutes.projectArchivedListRecover, {
          projectid: item.id,
        }),
      );
    }
  }

  return (
    <article className={styles.item}>
      <header className={styles.header}>
        <h3 className={styles.name}>{item.name}</h3>
        <p className={styles.archivedAt}>
          {intl.formatMessage(translations.archivedAt, {
            date: intl.formatDate(item.archivedAt, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
          })}
        </p>
      </header>
      <h4 className={styles.organizationName}>{item?.organization?.name}</h4>
      <p className={styles.information}>
        <Icon
          name="person_outline"
          className={styles.icon}
        />
        {intl.formatMessage(translations.authoredBy, {
          name: `${item.author.firstName} ${item.author.lastName}`,
        })}
      </p>
      <p className={styles.information}>
        <Icon
          name="group"
          className={styles.icon}
        />
        {intl.formatMessage(translations.members, {
          x: item.archivedMemberships?.total || 0,
        })}
      </p>
      <p className={styles.information}>
        <Icon
          name="check_circle"
          className={styles.icon}
        />
        {intl.formatMessage(translations.programs, {
          x: item.archivedPrograms?.total || 0,
        })}
      </p>
      <Tooltip
        text={
          canRecover
            ? intl.formatMessage(translations.recoverTooltip)
            : intl.formatMessage(translations.recoverNotAllowedTooltip)
        }
        className={styles.recoverWrapper}
        tooltipClassName={styles.tooltip}
        position="bottom-start"
      >
        {({ onMouseEnter, onMouseLeave, tooltipId }) => (
          <button
            type="button"
            onMouseEnter={onMouseEnter}
            onFocus={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onBlur={onMouseLeave}
            onClick={onRecover}
            aria-describedby={tooltipId}
            className={classnames(styles.recoverButton, {
              [styles.disabled]: !canRecover,
            })}
          >
            <Icon
              name="unarchive"
              className={styles.recoverIcon}
            />
          </button>
        )}
      </Tooltip>
    </article>
  );
}
