import React from 'react';
import { SnackbarProvider as NotistackProvider } from 'notistack';

interface SnackbarProviderProps {
  children: React.ReactNode;
}
export default function SnackbarProvider({ children }: SnackbarProviderProps) {
  return (
    <NotistackProvider
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      {children}
    </NotistackProvider>
  );
}
