import { defineMessages } from 'react-intl';

export default defineMessages({
  close: {
    id: 'ProgramCreate.close',
    defaultMessage: 'Fermer',
  },
  title: {
    id: 'ProgramCreate.title',
    defaultMessage: 'Nouveau programme',
  },
  description: {
    id: 'ProgramCreate.description',
    defaultMessage:
      "Les programmes sont conçus et certifiés par My Job Glasses pour vous permettre d'accompagner les membres dans leur orientation. Une fois le programme créé, partagez son code de participation aux membres afin de suivre leur activité.",
  },
});
