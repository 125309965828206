import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'GroupeDeleteModal.title',
    defaultMessage: 'Supprimer ce groupe',
  },
  body: {
    id: 'GroupeDeleteModal.body',
    defaultMessage:
      'En cliquant sur “Confirmer”, tous les groupes sous-jacents seront supprimés. Les programmes sous-jacents sont eux conservés.',
  },
});
