import React from 'react';
import BatchActionsBarAction from '../batch-actions-bar-action/BatchActionsBarAction';
import translations from './BatchActionBar.translations';

import styles from './BatchActionBar.module.scss';
import { useIntl } from 'react-intl';
import { ViewSwitch } from '../../../../pages/program-show/components/participants-activity-board/components/participants-table/ParticipantsTable';

interface BatchActionBarProps {
  values: string[];
  selectedItemsMessage?: (amount: number) => string;
  state: ViewSwitch;
}
export default function BatchActionBar({ values, selectedItemsMessage, state }: BatchActionBarProps) {
  if (!values.length) {
    return null;
  }

  const intl = useIntl();

  const label = intl.formatMessage(translations[state === 'active' ? 'removeButton' : 'recoverButton']);

  return (
    <>
      <div className={styles.batchActionBar}>
        {typeof selectedItemsMessage === 'function' && (
          <p className={styles.message}>{selectedItemsMessage(values.length)}</p>
        )}
        <div className={styles.actions}>
            <BatchActionsBarAction
              values={values}
              label={label}
              className={styles.actionButton}
              state={state}
            />
        </div>
      </div>
    </>
  );
}
