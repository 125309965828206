import { useIntl } from 'react-intl';
import translations from './NoAppointmentDisplay.translations';

import styles from './NoAppointmentDisplay.module.scss';

export default function NoAppointmentDisplay() {
  const intl = useIntl();

  return (
    <div className={styles.noAppointmentDisplay}>
      <h4 className={styles.title}>{intl.formatMessage(translations.title)}</h4>
      <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
    </div>
  );
}
