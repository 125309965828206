import React from 'react';
import Icon from '../../../../../../components/icon/Icon';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { BreadcrumbsItem } from '../../../context/HeaderContext';

import styles from './BreadcrumbItem.module.scss';

interface BreadcrumbItemProps {
  item: BreadcrumbsItem;
  className?: string;
  isLastItem?: boolean;
}
function BreadcrumbItem({ item, className, isLastItem }: BreadcrumbItemProps) {
  return (
    <>
      <Link
        to={item.link}
        className={classnames(className, styles.breadcrumbItem, styles.link)}
        aria-current={isLastItem ? 'page' : undefined}
      >
        {item.iconName && (
          <Icon
            name={item.iconName}
            className={styles.icon}
          />
        )}
        {item.iconComponent && React.createElement(item.iconComponent)}
        <span className={styles.name}>{item.name}</span>
      </Link>
      {!isLastItem && (
        <span role="separator">
          <Icon
            name="navigate_next"
            className={styles.divider}
          />
        </span>
      )}
    </>
  );
}

export default BreadcrumbItem;
