import { defineMessages } from 'react-intl';
import { HermesContactState } from '../../../../../../../../@types/graphql';

export default defineMessages({
  [HermesContactState.NoScheduledAppointment]: {
    id: 'AppointmentStatus.NoScheduledAppointment',
    defaultMessage: 'Aucun rendez-vous programmé',
  },
  [HermesContactState.Upcoming]: {
    id: 'AppointmentStatus.Upcoming',
    defaultMessage: 'À venir',
  },
  [HermesContactState.PastAwaitingConfirmation]: {
    id: 'AppointmentStatus.PastAwaitingConfirmation',
    defaultMessage: 'Passé, en attente de confirmation',
  },
  [HermesContactState.UnfollowedConversationWithThisProfessional]: {
    id: 'AppointmentStatus.UnfollowedConversationWithThisProfessional',
    defaultMessage: 'Conversation non suivie avec cet ambassadeur',
  },
  [HermesContactState.PastAndConfirmed]: {
    id: 'AppointmentStatus.PastAndConfirmed',
    defaultMessage: 'Passé et confirmé',
  },
  [HermesContactState.InvalidatedAppointment]: {
    id: 'AppointmentStatus.InvalidatedAppointment',
    defaultMessage: 'Rendez-vous annulé',
  },
  [HermesContactState.ProfileReportedByTheProfessional]: {
    id: 'AppointmentStatus.ProfileReportedByTheProfessional',
    defaultMessage: "Profil signalé par l'ambassadeur",
  },
});
