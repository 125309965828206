import React from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { generatePath, Link, useMatch } from 'react-router-dom';
import { GET_PROGRAM_IMPORTS } from '../../../import-participants/gql/getimports.gql';
import { SchoolRoutes } from '../../../../../../Routes';
import {
  ProgramInvitationFields,
  QueryFilterOperator,
  Get_Program_Participants_BoardQuery,
  Get_Program_Participants_BoardQueryVariables,
} from '../../../../@types/graphql';
import ExportParticipantsButton from './components/export-participants-button/ExportParticipantsButton';
import ImportParticipantsButton from './components/import-participants-button/ImportParticipantsButton';
import ImportsHistoryButton from './components/imports-history-button/ImportsHistoryButton';
import ParticipantsTable from './components/participants-table/ParticipantsTable';
import { GET_PROGRAM_PARTICIPANTS_BOARD } from './ParticipantsActivityBoard.gql';
import translations from './ParticipantsActivityBoard.translations';
import styles from './ParticipantsActivityBoard.module.scss';

export default function ParticipantsActivityBoard() {
  const intl = useIntl();

  const match = useMatch({
    path: SchoolRoutes.programShow,
  });

  const { data: imports } = useQuery(GET_PROGRAM_IMPORTS, {
    variables: {
      filter: {
        query: [
          {
            filterBy: ProgramInvitationFields.ProgramId,
            operator: QueryFilterOperator.Eq,
            value: match?.params.programid,
          },
        ],
      },
    },
  });

  const { data } = useQuery<Get_Program_Participants_BoardQuery, Get_Program_Participants_BoardQueryVariables>(
    GET_PROGRAM_PARTICIPANTS_BOARD,
    {
      skip: !match?.params.programid,
      variables: {
        id: match?.params.programid || '',
      },
      fetchPolicy: 'cache-only',
    },
  );

  return (
    <section>
      <div className={styles.headerWrapper}>
        <header className={styles.header}>
          <h3 className={styles.title}>{intl.formatMessage(translations.title)}</h3>
          <p className={styles.description}>
            {intl.formatMessage(translations.description, {
              link: (text) =>
                data?.program?.project.id ? (
                  <Link
                    to={generatePath(SchoolRoutes.projectStats, {
                      projectid: data?.program?.project.id,
                    })}
                    className={styles.link}
                  >
                    {text}
                  </Link>
                ) : (
                  text
                ),
            })}
          </p>
        </header>
        <div className={styles.headerActions}>
          <ImportParticipantsButton programId={data?.program?.id || ''} />
          {imports?.programInvitationImports?.nodes?.length > 0 && (
            <ImportsHistoryButton programId={data?.program?.id || ''} />
          )}
          <ExportParticipantsButton
            programId={data?.program?.id}
            programName={data?.program?.name}
            disabled={!data?.program?.participations?.total}
          />
        </div>
      </div>
      <ParticipantsTable />
    </section>
  );
}
