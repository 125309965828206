import React from 'react';
import styles from './ProjectListItemSkeleton.module.scss';

function ProjectListItemSkeleton() {
  return (
    <div className={styles.ProjectListItemSkeleton}>
      <span className={styles.projectNamePlaceholder}>&nbsp;</span>
      <span className={styles.organizationNamePlaceholder}>&nbsp;</span>
    </div>
  );
}

export default ProjectListItemSkeleton;
