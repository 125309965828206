import React from 'react';
import Icon from '../../../../../../../../../../components/icon/Icon';
import classnames from 'classnames';
import { useFormikContext } from 'formik';
import CustomCheckbox from '../../../../../../../../commons/custom-checkbox/CustomCheckbox';
import ProgramDot from '../../../../../../../../commons/program-dot/ProgramDot';
import { ProjectTreeProgram } from '../../../../../../../../hooks/use-project-tree/useProjectTree';
import { ProjectStatisticFiltersValues } from '../../ProjectStatisticFilter';

import styles from './FilterProgramItem.module.scss';

interface FilterProgramItemProps {
  program: ProjectTreeProgram;
}
export default function FilterProgramItem({ program }: FilterProgramItemProps) {
  const { setFieldValue, values } = useFormikContext<ProjectStatisticFiltersValues>();

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setFieldValue('programIds', [...values.programIds, program.id]);
    } else {
      setFieldValue(
        'programIds',
        values.programIds.filter((value) => value !== program.id),
      );
    }
  }

  return (
    <li
      className={classnames(styles.programItem, {
        [styles.locked]: !program.canShow,
      })}
      role="treeitem"
    >
      <CustomCheckbox
        id={program.id}
        onChange={onChange}
        checked={values.programIds.includes(program.id)}
        disabled={!program.canShow}
      />
      {program.canShow ? (
        <ProgramDot
          status={program.status}
          className={styles.programItemStatus}
        />
      ) : (
        <Icon
          name="lock"
          className={styles.lockIcon}
        />
      )}
      <label
        htmlFor={program.id}
        className={styles.programItemLabel}
        title={program.name}
      >
        {program.name}
      </label>
    </li>
  );
}
