import { defineMessages } from 'react-intl';

export default defineMessages({
  label: {
    id: 'ArchivedProjectsLink.label',
    defaultMessage: 'Projets archivés',
  },
  tootlip: {
    id: 'ArchivedProjectsLink.tootlip',
    defaultMessage: 'Aucun projet archivé',
  },
});
