import React from 'react';
import { AvatarSize } from '../../../../../../../../components/avatar/Avatar';
import GroupedAvatar from '../../../../../../../../components/grouped-avatar/GroupedAvatar';
import { useIntl } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../Routes';
import { getUserInitials } from '../../../../../../../../utils/user';
import { Maybe, Membership, Project } from '../../../../../../@types/graphql';
import RoleBadge from '../../../../../../commons/role-badge/RoleBadge';
import translations from './ProjectListItem.translations';

import styles from './ProjectListItem.module.scss';

interface ProjectListItemProps {
  project: Pick<Project, 'id' | 'name' | 'myRole' > & {
    organization: Pick<Project['organization'], 'name'>;
    schools: Pick<Project['schools'], 'name'>;
    memberships?: Maybe<{
      nodes: Pick<Membership, 'id' | 'userFirstName' | 'userLastName'>[];
    }>;
  };
  acceptInvitationId?: string;
}
function ProjectListItem({ project, acceptInvitationId }: ProjectListItemProps) {
  const intl = useIntl();

  return (
    <Link
      to={
        acceptInvitationId
          ? generatePath(SchoolRoutes.projectJoin, {
              projectid: project.id,
              invitationid: acceptInvitationId,
            })
          : generatePath(SchoolRoutes.projectStats, { projectid: project.id })
      }
      className={styles.projectListItem}
      data-testid="project-list-item"
    >
      <span className={styles.projectInformations}>
        <span className={styles.projectName}>{project.name}</span>
        <span className={styles.organizationName}>{project?.schools?.nodes?.length? project.schools.nodes[0].name : project?.organization?.name}</span>
      </span>
      <span className={styles.aside}>
        {acceptInvitationId ? (
          <Link
            to={generatePath(SchoolRoutes.projectJoin, {
              projectid: project.id,
              invitationid: acceptInvitationId,
            })}
            className={styles.joinButton}
          >
            {intl.formatMessage(translations.join)}
          </Link>
        ) : (
          <>
            {project.myRole && (
              <RoleBadge
                role={project.myRole}
                className={styles.role}
              />
            )}
            {project.memberships?.nodes.length && (
              <GroupedAvatar
                avatars={project.memberships.nodes.map((member) => ({
                  avatarInitials: getUserInitials({
                    firstName: member.userFirstName,
                    lastName: member.userLastName,
                  }),
                  altText: `${member.userFirstName} ${member.userLastName}`,
                }))}
                size={AvatarSize.xsmall}
              />
            )}
          </>
        )}
      </span>
    </Link>
  );
}

export default ProjectListItem;
