import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { errorTranslations } from './ProgramConfigurationView.translations';
import { GradeEnum, TargetMeetingsCategories } from '../../../../@types/graphql';

export default (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string()
      .ensure()
      .max(40, intl.formatMessage(errorTranslations.programNameLimit))
      .required(intl.formatMessage(errorTranslations.programNameRequired))
      .typeError(intl.formatMessage(errorTranslations.programNameRequired)),
    school: Yup.string().when('showSchoolForm', {
      is: true,
      then: (schema) => schema.required(intl.formatMessage(errorTranslations.schoolRequired)),
      otherwise: (schema) => schema.notRequired(),
    }),
    grade: Yup.mixed<GradeEnum>().when('showSchoolForm', {
      is: true,
      then: (schema) => schema.required(intl.formatMessage(errorTranslations.gradeRequired)),
      otherwise: (schema) => schema.notRequired(),
    }),
    diploma: Yup.string().when('showSchoolForm', {
      is: true,
      then: (schema) => schema.required(intl.formatMessage(errorTranslations.diplomaNameRequired)),
      otherwise: (schema) => schema.notRequired(),
    }),
    startAt: Yup.mixed().required(intl.formatMessage(errorTranslations.startDateRequired)),
    endAt: Yup.mixed().required(intl.formatMessage(errorTranslations.endDateRequired)),
    collaboratorsIds: Yup.array()
      .min(1, intl.formatMessage(errorTranslations.programManagerMin))
      .max(100, intl.formatMessage(errorTranslations.programManagerMax))
      .required(intl.formatMessage(errorTranslations.programManagerMin)),
    expectedParticipations: Yup.number()
      .integer(intl.formatMessage(errorTranslations.participantsNumberFormat))
      .positive(intl.formatMessage(errorTranslations.participantsNumberFormat))
      .required(intl.formatMessage(errorTranslations.expectedParticipationsRequired))
      .typeError(intl.formatMessage(errorTranslations.participantsNumberFormat)),
    targetMeetingsCategories: Yup.mixed().oneOf(Object.values(TargetMeetingsCategories)).required(),
    description: Yup.string()
      .ensure()
      .trim()
      .min(1, intl.formatMessage(errorTranslations.descriptionRequired))
      .max(2000, intl.formatMessage(errorTranslations.programDescriptionLimit))
      .required(intl.formatMessage(errorTranslations.descriptionRequired))
      .typeError(intl.formatMessage(errorTranslations.descriptionRequired)),
  });
