import { useQuery } from '@apollo/client';
import {
  Get_Project_Member_Update_PermissionsQuery,
  Get_Project_Member_Update_PermissionsQueryVariables,
  Role,
} from '../../../../../@types/graphql';
import { GET_PROJECT_MEMBER_UPDATE_PERMISSIONS } from './useMemberPermissions.gql';
import { useParams } from 'react-router-dom';

export function useMemberPermissions() {
  const { projectid } = useParams();

  const { data } = useQuery<
    Get_Project_Member_Update_PermissionsQuery,
    Get_Project_Member_Update_PermissionsQueryVariables
  >(GET_PROJECT_MEMBER_UPDATE_PERMISSIONS, {
    variables: {
      id: projectid || '',
    },
    fetchPolicy: 'cache-only',
  });

  return {
    canSelfDelete: !(data?.project?.myRole === Role.Owner),
    canChooseNewOwner: data?.project?.myRole === Role.Owner,
  };
}
