import { gql } from '@apollo/client';
import { ProgramPublicationStatus } from '../../../../../../@types/graphql';

export const GET_PROJECT_DIAGRAM_STRUCTURE = gql`
  fragment ProjectDiagramProgramFragment on Program {
    id
    name
  }

  fragment ProjectDiagramGroupFragment on Group {
    id
    name
    canUpdate {
      value
    }
    canCreateGroup {
      value
    }
    canDelete {
      value
    }
    programs(filter: {
      query: {
        filterBy: programPublicationStatus,
        operator: eq,
        value: "${ProgramPublicationStatus.Published}"
      }
    }) {
      nodes {
        ...ProjectDiagramProgramFragment
      }
    }
  }

  query GET_PROJECT_DIAGRAM_STRUCTURE($id: ID!) {
    project(id: $id) {
      id
      name
      directPrograms(filter: {
        query: {
          filterBy: programPublicationStatus,
          operator: eq,
          value: "${ProgramPublicationStatus.Published}"
        }
      }) {
        nodes {
          ...ProjectDiagramProgramFragment
        }
      }
      directGroups {
        nodes {
          ...ProjectDiagramGroupFragment
          groups {
            nodes {
              ...ProjectDiagramGroupFragment
              groups {
                nodes {
                  ...ProjectDiagramGroupFragment
                  groups {
                    nodes {
                      ...ProjectDiagramGroupFragment
                      groups {
                        nodes {
                          ...ProjectDiagramGroupFragment
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
