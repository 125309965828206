import { gql } from '@apollo/client';

export const GET_INTERCOM_CURRENT_USER = gql`
  query GET_INTERCOM_CURRENT_USER {
    me {
      hermesUserId
      email
      avatarUrl
      intercomUserHash
      createdAt
    }
  }
`;
