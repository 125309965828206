import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CoachingTypeView.title',
    defaultMessage: 'Type d’accompagnement',
  },
  description: {
    id: 'CoachingTypeView.description',
    defaultMessage: 'Sélectionnez l’option adaptée à votre situation',
  },
});
