import { defineMessages } from 'react-intl';

export default defineMessages({
  description: {
    id: 'Page404.description',
    defaultMessage: 'Oups ! La page que vous cherchez est introuvable...',
  },
  link: {
    id: 'Page404.link',
    defaultMessage: 'Retour à l’accueil',
  },
});
