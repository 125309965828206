import React from 'react';
import Icon from '../../../../../../../../components/icon/Icon';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../Routes';
import translations from './ProjectListCreateItem.translations';

import styles from './ProjectListCreateItem.module.scss';

function ProjectListCreateItem() {
  const intl = useIntl();
  return (
    <Link
      to={SchoolRoutes.projectCreate}
      className={styles.projectListCreateItem}
      data-testid="project-list-create-item"
    >
      <Icon
        name="add"
        className={styles.icon}
      />
      {intl.formatMessage(translations.createProject)}
    </Link>
  );
}

export default ProjectListCreateItem;
