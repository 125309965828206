import React from 'react';
import { useQuery } from '@apollo/client';
import ProgressionSteps from '../../../../components/progression-steps/ProgressionSteps';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../Routes';
import { Get_Program_To_UpdateQuery, Get_Program_To_UpdateQueryVariables } from '../../@types/graphql';
import { ProgramUpdateParams } from '../../@types/route-params';
import PageError from '../../commons/page-error/PageError';
import PageLoading from '../../commons/page-loading/PageLoading';
import { PageHeader } from '../../layout/context/HeaderContext';
import { StepsContext } from '../program-create/@types/steps-context';
import ProgramConfigurationView from '../program-create/views/program-configuration-view/ProgramConfigurationView';
import ProgramNotificationConfigurationView from '../program-create/views/program-notification-configuration-view/ProgramNotificationConfigurationView';
import { GET_PROGRAM_TO_UPDATE } from './ProgramUpdate.gql';
import translations from './ProgramUpdate.translations';

import styles from './ProgramUpdate.module.scss';

export default function ProgramUpdate() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { programid = "" } = useParams();

  const { data, loading, error, refetch } = useQuery<Get_Program_To_UpdateQuery, Get_Program_To_UpdateQueryVariables>(
    GET_PROGRAM_TO_UPDATE,
    {
      variables: {
        id: programid,
      },
    },
  );

  function onFinish() {
    navigate(generatePath(SchoolRoutes.programShow, { programid }));
  }

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <PageError refetch={refetch} />;
  }

  return (
    <main className={styles.pageCreate}>
      <PageHeader
        actions={[
          {
            iconName: 'close',
            name: intl.formatMessage(translations.close),
            onClick: () =>
              navigate(
                generatePath(SchoolRoutes.programShow, {
                  programid,
                }),
              ),
          },
        ]}
      />
      <p className={styles.title}>{data?.program?.name}</p>
      <ProgressionSteps<StepsContext>
        steps={[{ component: ProgramConfigurationView }, { component: ProgramNotificationConfigurationView }]}
        initialContext={{
          programId: data?.program?.id,
          name: data?.program?.name,
          programNotificationFrequency: data?.program?.programNotificationFrequency || undefined,
        }}
        onFinish={onFinish}
        className={styles.progressionSteps}
      />
    </main>
  );
}
