import React from 'react';
import { useIntl } from 'react-intl';
import AppointmentItem from '../appointment-item/AppointmentItem';
import NoAppointmentDisplay from '../no-appointment-display/NoAppointmentDisplay';
import translations from './AppointmentList.translations';

import styles from './AppointmentList.module.scss';

interface AppointmentListProps {
  items: Array<React.ComponentPropsWithoutRef<typeof AppointmentItem>['item']>;
}
export default function AppointmentList({ items }: AppointmentListProps) {
  const intl = useIntl();

  if (!items.length) {
    return <NoAppointmentDisplay />;
  }

  return (
    <div className={styles.appointmentList}>
      <h4 className={styles.title}>{intl.formatMessage(translations.title)}</h4>
      {items.map((item) => (
        <AppointmentItem
          key={item.avatarUrl}
          item={item}
        />
      ))}
    </div>
  );
}
