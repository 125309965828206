interface DiagramProjectItemProps {
  x: number;
  y: number;
  label: string;
}
const PROJECT_ITEM_HEIGHT = 32;
const PROJECT_ITEM_WIDTH = 148;
export default function DiagramProjectItem({ x, y, label }: DiagramProjectItemProps) {
  return (
    <g
      width={PROJECT_ITEM_WIDTH}
      height={PROJECT_ITEM_HEIGHT}
      x={x}
      y={y}
      transform={`translate(${x}, ${y})`}
    >
      <rect
        x={-(PROJECT_ITEM_WIDTH / 2)}
        y={-(PROJECT_ITEM_HEIGHT / 2)}
        width={PROJECT_ITEM_WIDTH}
        height={PROJECT_ITEM_HEIGHT}
        rx={PROJECT_ITEM_HEIGHT / 2}
        fill="#555557"
        style={{ filter: 'url(#DP01)' }}
      />
      <text
        x={0}
        y={4}
        textAnchor="middle"
        fontSize={12}
        fill="#FFFFFF"
      >
        {label}
      </text>
    </g>
  );
}
