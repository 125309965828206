import Storage from '../../../../../../enums/storage';
import { getStorageItem, setStorageItem } from '../../../../../../utils/storage';

export function isSkippedBanner(projectId: string): boolean {
  const ids = JSON.parse(getStorageItem(Storage.PROJECT_CREATE_PROGRAM_BANNER, JSON.stringify([])));
  return ids.includes(projectId);
}

export function addProjectToSkippedBanner(projectId: string): void {
  const ids = JSON.parse(getStorageItem(Storage.PROJECT_CREATE_PROGRAM_BANNER, JSON.stringify([])));
  setStorageItem(Storage.PROJECT_CREATE_PROGRAM_BANNER, JSON.stringify([...ids, projectId]));
}
