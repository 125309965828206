import React from 'react';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import Icon from '../../../../../../components/icon/Icon';
import { useIntl } from 'react-intl';
import { Count_My_ProgramsQuery, Count_My_ProgramsQueryVariables } from '../../../../@types/graphql';
import { COUNT_MY_PROGRAMS } from './PromptProgramCreateBanner.gql';
import translations from './PromptProgramCreateBanner.translations';
import { addProjectToSkippedBanner, isSkippedBanner } from './PromptProgramCreateBanner.utils';

import styles from './PromptProgramCreateBanner.module.scss';
import { useParams } from 'react-router-dom';

export default function PromptProgramCreateBanner() {
  const intl = useIntl();
  const { projectid } = useParams();

  const { data } = useQuery<Count_My_ProgramsQuery, Count_My_ProgramsQueryVariables>(COUNT_MY_PROGRAMS, {
    variables: {
      id: projectid || '',
    },
  });

  const [isClosed, setIsClosed] = useState<boolean>(isSkippedBanner(projectid!));
  function closeBanner() {
    setIsClosed(true);
    addProjectToSkippedBanner(projectid!);
  }

  if ((data?.project?.myPrograms?.total || 0) > 0 || isClosed) {
    return null;
  }

  return (
    <div className={styles.promptProgramCreateBanner}>
      <Icon
        name="info"
        className={styles.icon}
      />
      <p className={styles.content}>{intl.formatMessage(translations.content)}</p>
      <button
        type="button"
        onClick={closeBanner}
        className={styles.closeButton}
      >
        <Icon
          name="close"
          className={styles.closeIcon}
        />
      </button>
    </div>
  );
}
