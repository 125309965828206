import { defineMessages } from 'react-intl';
import { GradeEnum } from '../../../../@types/graphql';

export const errorTranslations = defineMessages({
  programNameLimit: {
    id: 'ProgramConfigurationView.programNameLimit',
    defaultMessage: 'Le nom du programme ne peut dépasser 40 caractères.',
  },
  programNameRequired: {
    id: 'ProgramConfigurationView.programNameRequired',
    defaultMessage: 'Veuillez saisir un nom de programme.',
  },
  programNameAlreadyExist: {
    id: 'ProgramConfigurationView.programNameAlreadyExist',
    defaultMessage: 'Ce nom de programme existe déjà. Veuillez en choisir un autre.',
  },
  schoolRequired: {
    id: 'ProgramConfigurationView.schoolRequired',
    defaultMessage: 'Vous devez sélectionner un établissement.',
  },
  gradeRequired: {
    id: 'ProgramConfigurationView.gradeRequired',
    defaultMessage: 'Vous devez sélectionner un niveau.',
  },
  diplomaNameRequired: {
    id: 'ProgramConfigurationView.diplomaNameRequired',
    defaultMessage: 'Veuillez saisir le nom du diplôme préparé.',
  },
  startDateRequired: {
    id: 'ProgramConfigurationView.startDateRequired',
    defaultMessage: 'Veuillez sélectionner une date de début.',
  },
  endDateRequired: {
    id: 'ProgramConfigurationView.endDateRequired',
    defaultMessage: 'Veuillez sélectionner une date de fin.',
  },
  programManagerMin: {
    id: 'ProgramConfigurationView.programManagerMin',
    defaultMessage: 'Veuillez sélectionner au moins 1 responsable de programme.',
  },
  programManagerMax: {
    id: 'ProgramConfigurationView.programManagerMax',
    defaultMessage: 'Un programme ne peut avoir que 100 responsables au maximum.',
  },
  participantsNumberFormat: {
    id: 'ProgramConfigurationView.participantsNumberFormat',
    defaultMessage: 'Le nombre choisi doit être un entier.',
  },
  expectedParticipationsRequired: {
    id: 'ProgramConfigurationView.expectedParticipationsRequired',
    defaultMessage: 'Veuillez saisir un nombre de participants.',
  },
  programDescriptionLimit: {
    id: 'ProgramConfigurationView.programDescriptionLimit',
    defaultMessage: 'La description ne peut dépasser 2000 caractères.',
  },
  descriptionRequired: {
    id: 'ProgramConfigurationView.descriptionRequired',
    defaultMessage: 'Veuillez saisir une description.',
  },
});

export const GradeTranslations = defineMessages({
  [GradeEnum.Autre]: { id: 'GradeEnum.Autre', defaultMessage: 'Autre cursus' },
  [GradeEnum.Bts1]: { id: 'GradeEnum.Bts1', defaultMessage: 'BTS - 1ère année' },
  [GradeEnum.Bts2]: { id: 'GradeEnum.Bts2', defaultMessage: 'BTS - 2ème année' },
  [GradeEnum.But1]: { id: 'GradeEnum.But1', defaultMessage: 'BUT - 1ère année' },
  [GradeEnum.But2]: { id: 'GradeEnum.But2', defaultMessage: 'BUT - 2ème année' },
  [GradeEnum.But3]: { id: 'GradeEnum.But3', defaultMessage: 'BUT - 3ème année' },
  [GradeEnum.Cap1]: { id: 'GradeEnum.Cap1', defaultMessage: 'CAP - 1ère année' },
  [GradeEnum.Cap2]: { id: 'GradeEnum.Cap2', defaultMessage: 'CAP - 2ème année' },
  [GradeEnum.Cfa1]: { id: 'GradeEnum.Cfa1', defaultMessage: 'Première' },
  [GradeEnum.Cfa2]: { id: 'GradeEnum.Cfa2', defaultMessage: 'Seconde' },
  [GradeEnum.Cfat]: { id: 'GradeEnum.Cfat', defaultMessage: 'Terminale' },
  [GradeEnum.College3]: { id: 'GradeEnum.College3', defaultMessage: '3e' },
  [GradeEnum.College4]: { id: 'GradeEnum.College4', defaultMessage: '4e' },
  [GradeEnum.College5]: { id: 'GradeEnum.College5', defaultMessage: '5e' },
  [GradeEnum.College6]: { id: 'GradeEnum.College6', defaultMessage: '6e' },
  [GradeEnum.Cpge1]: { id: 'GradeEnum.Cpge1', defaultMessage: 'CPGE - 1ère année' },
  [GradeEnum.Cpge2]: { id: 'GradeEnum.Cpge2', defaultMessage: 'CPGE - 2ème année' },
  [GradeEnum.Dcg1]: { id: 'GradeEnum.DCG1', defaultMessage: 'DCG - 1ère année' },
  [GradeEnum.Dcg2]: { id: 'GradeEnum.DCG2', defaultMessage: 'DCG - 2ème année' },
  [GradeEnum.Dcg3]: { id: 'GradeEnum.DCG3', defaultMessage: 'DCG - 3ème année' },
  [GradeEnum.DiplomeUniversitaire]: { id: 'GradeEnum.DiplomeUniversitaire', defaultMessage: 'Doctorat' },
  [GradeEnum.Doctorat]: { id: 'GradeEnum.Doctorat', defaultMessage: 'Diplome Universitaire' },
  [GradeEnum.Ec1]: { id: 'GradeEnum.Ec1', defaultMessage: 'Bac +1' },
  [GradeEnum.Ec2]: { id: 'GradeEnum.Ec2', defaultMessage: 'Bac +2' },
  [GradeEnum.Ec3]: { id: 'GradeEnum.Ec3', defaultMessage: 'Bac +3' },
  [GradeEnum.Ec4]: { id: 'GradeEnum.Ec4', defaultMessage: 'Bac +4' },
  [GradeEnum.Ec5]: { id: 'GradeEnum.Ec5', defaultMessage: 'Bac +5' },
  [GradeEnum.Ec6]: { id: 'GradeEnum.Ec6', defaultMessage: 'Bac >+5' },
  [GradeEnum.Ei1]: { id: 'GradeEnum.Ei1', defaultMessage: 'Bac +1' },
  [GradeEnum.Ei2]: { id: 'GradeEnum.Ei2', defaultMessage: 'Bac +2' },
  [GradeEnum.Ei3]: { id: 'GradeEnum.Ei3', defaultMessage: 'Bac +3' },
  [GradeEnum.Ei4]: { id: 'GradeEnum.Ei4', defaultMessage: 'Bac +4' },
  [GradeEnum.Ei5]: { id: 'GradeEnum.Ei5', defaultMessage: 'Bac +5' },
  [GradeEnum.Ei6]: { id: 'GradeEnum.Ei6', defaultMessage: 'Bac >+5' },
  [GradeEnum.Licence1]: { id: 'GradeEnum.Licence1', defaultMessage: 'Licence 1' },
  [GradeEnum.Licence2]: { id: 'GradeEnum.Licence2', defaultMessage: 'Licence 2' },
  [GradeEnum.Licence3]: { id: 'GradeEnum.Licence3', defaultMessage: 'Licence 3' },
  [GradeEnum.Lycee1]: { id: 'GradeEnum.Lycee1', defaultMessage: 'Première' },
  [GradeEnum.Lycee2]: { id: 'GradeEnum.Lycee2', defaultMessage: 'Seconde' },
  [GradeEnum.LyceeT]: { id: 'GradeEnum.LyceeT', defaultMessage: 'Terminale' },
  [GradeEnum.Master1]: { id: 'GradeEnum.Master1', defaultMessage: 'Master 1' },
  [GradeEnum.Master2]: { id: 'GradeEnum.Master2', defaultMessage: 'Master 2' },
  [GradeEnum.Medecine1]: { id: 'GradeEnum.Medecine1', defaultMessage: 'Médecine - 1er cycle' },
  [GradeEnum.Medecine2]: { id: 'GradeEnum.Medecine2', defaultMessage: 'Médecine - 2e cycle' },
  [GradeEnum.Medecine3]: { id: 'GradeEnum.Medecine3', defaultMessage: 'Médecine - 3e cycle' },
});


export default defineMessages({
  title: {
    id: 'ProgramConfigurationView.title',
    defaultMessage: 'Personnalisation du programme',
  },
  description: {
    id: 'ProgramConfigurationView.description',
    defaultMessage: 'Adaptez le programme à votre organisation',
  },
  information: {
    id: 'ProgramConfigurationView.information',
    defaultMessage: 'Ces informations peuvent être modifiées ultérieurement.',
  },
  nameLabel: {
    id: 'ProgramConfigurationView.nameLabel',
    defaultMessage: 'Nom du programme',
  },
  nameHelper: {
    id: 'ProgramConfigurationView.nameHelper',
    defaultMessage: 'Utilisez, par exemple, le nom de la classe ou de la formation..',
  },
  schoolLabel: {
    id: 'ProgramConfigurationView.schoolLabel',
    defaultMessage: 'Établissement',
  },
  schoolHelper: {
    id: 'ProgramConfigurationView.schoolHelper',
    defaultMessage: 'Sélectionnez l\'établissement concerné par ce programme.',
  },
  gradeLabel: {
    id: 'ProgramConfigurationView.gradeLabel',
    defaultMessage: 'Niveau de vos élèves',
  },
  gradeHelper: {
    id: 'ProgramConfigurationView.gradeHelper',
    defaultMessage: 'Sélectionnez le niveau des élèves concernés par ce programme.',
  },
  diplomaLabel: {
    id: 'ProgramConfigurationView.diplomaLabel',
    defaultMessage: 'Nom du diplôme préparé',
  },
  diplomaHelper: {
    id: 'ProgramConfigurationView.diplomaHelper',
    defaultMessage: 'Entrez le nom du diplôme ou de la formation de vos élèves.',
  },
  dateLabel: {
    id: 'ProgramConfigurationView.dateLabel',
    defaultMessage: 'Dates du programme',
  },
  startDatePlaceholder: {
    id: 'ProgramConfigurationView.startDatePlaceholder',
    defaultMessage: 'Date de début',
  },
  endDatePlaceholder: {
    id: 'ProgramConfigurationView.endDatePlaceholder',
    defaultMessage: 'Date de fin',
  },
  programTemplateRestrictions: {
    id: 'ProgramConfigurationView.programTemplateRestrictions',
    defaultMessage:
      'L’activité des participants est comptabilisée uniquement pendant cette période.<br></br>Durée minimum pour le programme {programTemplateName} : {minimalMonthDuration} mois',
  },
  programManagerLabel: {
    id: 'ProgramConfigurationView.programManagerLabel',
    defaultMessage: 'Responsable(s) du programme',
  },
  programManagerHelper: {
    id: 'ProgramConfigurationView.programManagerHelper',
    defaultMessage: 'Sélectionnez un membre du projet pour l’ajouter comme responsable.',
  },
  expectedParticipationsLabel: {
    id: 'ProgramConfigurationView.expectedParticipationsLabel',
    defaultMessage: 'Nombre prévu de participants',
  },
  targetMeetingsLabel: {
    id: 'ProgramConfigurationView.targetMeetingsLabel',
    defaultMessage: 'Les participants devront rencontrer',
  },
  targetStudentsLabel: {
    id: 'ProgramConfigurationView.targetStudentsLabel',
    defaultMessage: 'Catégories d’étudiants à rencontrer',
  },
  descriptionLabel: {
    id: 'ProgramConfigurationView.descriptionLabel',
    defaultMessage: 'Description',
  },
  descriptionHelper: {
    id: 'ProgramConfigurationView.descriptionHelperText',
    defaultMessage:
      "Les membres de votre programme verront apparaître cette description dans leur espace. Veillez à la rendre claire et précise (contexte de création de ce programme, objectifs, modalités d'évaluation du programme, etc.).",
  },
  descriptionExample: {
    id: 'ProgramConfigurationView.descriptionExample',
    defaultMessage:
      "<b>Exemple de description :</b> <br></br><br></br>Nom du module ou de votre atelier : ____<br></br>L'objectif du module ou de l'atelier : ____<br></br>Modalités d'évaluation (s'il y en a) : ____<br></br><br></br>Les rendez-vous seront également évalués par les professionnels rencontrés. Soyez respectueux, et posez toutes les questions qui vous semblent pertinentes et vous aideront à faire les bons choix.<br></br><br></br>Maintenant à vous de bâtir votre projet professionnel, bon courage !",
  },
});
