import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import useDebounce from '../../../../../../../../hooks/use-debounce';
import { useQuery } from '@apollo/client';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { useIntl } from 'react-intl';
import {
  Get_Participants_And_InvitationsQuery,
  Get_Participants_And_InvitationsQueryVariables,
  Get_Program_Participations_Meeting_QuantityQuery,
  Get_Program_Participations_Meeting_QuantityQueryVariables,
  ParticipationAndInvitationFields,
  SortDirection,
  ProgramInvitationStatus,
  QueryFilterOperator,
} from '../../../../../../@types/graphql';
import BatchActionBar from '../../../../../../commons/batch-actions/components/batch-actions-bar/BatchActionBar';
import { GET_PROGRAM_PARTICIPATIONS_MEETING_QUANTITY, GET_PARTICIPANTS_AND_INVITATIONS } from './ParticipantsTable.gql';
import { SchoolRoutes } from '../../../../../../../../Routes';
import {
  DataGrid,
  GridColDef,
  GridEventListener,
  GridSortModel,
  GridRowSelectionModel,
  GridCellParams,
} from '@mui/x-data-grid';
import { frFR } from '@mui/x-data-grid/locales';
import Searchbar from './components/search-bar/SearchBar';
import Pagination from './components/pagination/Pagination';
import ParticipantsTableEmpty from './components/participants-table-empty/ParticipantsTableEmpty';
import ProgressCell from './cells/progress/ProgressCell';
import CertificationCell from './cells/certication/CertificationCell';
import './ParticipantsTable.scss';
import translations from './ParticipantsTable.translations';
import NoResultsTable from './components/no-results-table/NoResultsTable';

export type ViewSwitch = 'active' | 'archived';

export default function ParticipantsTable() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { programid = '' } = useParams();

  const [viewSwitch, setViewSwitch] = useState<ViewSwitch>('active');
  const [term, setTerm] = useState('');
  const [debouncedSearchTerm] = useDebounce(term);
  const [sortBy, setSortBy] = useState<ParticipationAndInvitationFields>(ParticipationAndInvitationFields.UserLastName);
  const [sortDirection, setSortDirection] = useState<SortDirection>(SortDirection.Asc);
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [statusFilter, setStatusFilter] = useState<ProgramInvitationStatus | undefined>(undefined);

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 75,
    page: 0,
  });

  // Queries
  const { data: dataProgram } = useQuery<
    Get_Program_Participations_Meeting_QuantityQuery,
    Get_Program_Participations_Meeting_QuantityQueryVariables
  >(GET_PROGRAM_PARTICIPATIONS_MEETING_QUANTITY, {
    variables: {
      programId: programid,
    },
    fetchPolicy: 'cache-only',
  });

  const {
    data: participantsOrInvitations,
    loading,
    fetchMore,
  } = useQuery<Get_Participants_And_InvitationsQuery, Get_Participants_And_InvitationsQueryVariables>(
    GET_PARTICIPANTS_AND_INVITATIONS,
    {
      variables: {
        programId: programid,
        orderBy: sortBy,
        order: sortDirection,
        term: debouncedSearchTerm,
        cursor: '',
      },
      fetchPolicy: 'no-cache',
    },
  );

  // Queries results
  const meetingsQuantity = dataProgram?.program?.meetingsQuantity || 3;

  const activeParticipants = participantsOrInvitations?.activeParticipants?.edges?.map((edge) => edge?.node) ?? [];
  const archivedParticipants = participantsOrInvitations?.archivedParticipants?.edges?.map((edge) => edge?.node) ?? [];

  const activePageInfo = participantsOrInvitations?.activeParticipants?.pageInfo;

  const activeTotalRowsRef = useRef(participantsOrInvitations?.activeParticipants?.total || 0);

  const activeTotalRows = useMemo(() => {
    if (participantsOrInvitations?.activeParticipants?.total !== undefined) {
      activeTotalRowsRef.current = participantsOrInvitations?.activeParticipants?.total;
    }
    return activeTotalRowsRef.current;
  }, [participantsOrInvitations?.activeParticipants?.total]);

  const archivedTotalRows = participantsOrInvitations?.archivedParticipants?.total;

  const archivedPageInfo = participantsOrInvitations?.archivedParticipants?.pageInfo;

  const filteredActiveParticipants = useMemo(() => {
    if (!statusFilter) return activeParticipants;
    return activeParticipants.filter(p => p?.invitationStatus === statusFilter);
  }, [activeParticipants, statusFilter]);
  
  const filteredArchivedParticipants = useMemo(() => {
    if (!statusFilter) return archivedParticipants;
    return archivedParticipants.filter(p => p?.invitationStatus === statusFilter);
  }, [archivedParticipants, statusFilter]);
  
  // Table columns
  const columns: GridColDef[] = [
    { field: 'id', headerName: 'ID', width: 90 },
    {
      field: 'userLastName',
      flex: 1,
      maxWidth: 250,
      headerName: intl.formatMessage(translations.lastNameHeaderLabel),
    },
    {
      field: 'userFirstName',
      headerName: intl.formatMessage(translations.firstNameHeaderLabel),
      flex: 1,
      maxWidth: 250,
    },
    {
      field: 'invitationStatus',
      headerName: intl.formatMessage(translations.invitationStatus),
      flex: 1,
      maxWidth: 250,
      valueGetter: (cellData) => {
        return intl.formatMessage(translations[cellData as keyof typeof translations]);
      },
      cellClassName: (cell: GridCellParams<any, any, string>) => {
        let className = '';
        if (cell.value === 'Envoyée') {
          className = 'invitation-sent';
        } else if (cell.value === 'En cours') {
          className = 'invitation-processing';
        } else if (cell.value === 'Acceptée') {
          className = 'invitation-accepted';
        } else if (cell.value === 'Refusée') {
          className = 'invitation-rejected';
        }

        return className;
      },
    },
    {
      field: 'createdAt',
      headerName: intl.formatMessage(translations.createdAt),
      flex: 0.8,
      maxWidth: 200,
      valueGetter: (cellData, row) => {
        const date = new Date(cellData);

        if (row?.invitationStatus === ProgramInvitationStatus.Accepted) {
          return date.toLocaleDateString('fr-FR');
        } else {
          return 'N/A';
        }
      },
    },
    {
      field: 'visitsCount',
      headerName: intl.formatMessage(translations.profilesViewed),
      flex: 0.8,
      maxWidth: 200,
      type: 'number',
    },
    {
      field: 'conversationsCount',
      headerName: intl.formatMessage(translations.prosContacted),
      flex: 0.8,
      maxWidth: 200,
      type: 'number',
    },
    {
      field: 'incomingMeetingCount',
      headerName: intl.formatMessage(translations.upcomingAppt),
      flex: 0.8,
      maxWidth: 200,
      type: 'number',
    },
    {
      field: 'confirmedMeetingCount',
      headerName: intl.formatMessage(translations.confirmedPastAppt),
      flex: 0.8,
      maxWidth: 200,
      type: 'number',
    },
    {
      field: 'target',
      headerName: intl.formatMessage(translations.target),
      flex: 1,
      maxWidth: 350,
      renderCell: (cellData) => (
        <ProgressCell
          cellData={cellData}
          meetingsQuantity={meetingsQuantity}
        />
      ),
    },
    {
      field: 'certificationStatus',
      headerName: '',
      renderCell: (cellData) => <CertificationCell cellData={cellData} />,
      width: 30,
      cellClassName: 'certificationCell',
    },
  ];

  const loadMoreRows = (state: ViewSwitch) => {
    fetchMore({
      variables: {
        cursor: state === 'active' ? activePageInfo?.endCursor : archivedPageInfo?.endCursor,
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        if (!fetchMoreResult) return previousResult;

        let previousData;
        let newData;

        if (state === 'active') {
          previousData = previousResult.activeParticipants;
          newData = fetchMoreResult.activeParticipants;
        } else {
          previousData = previousResult.archivedParticipants;
          newData = fetchMoreResult.archivedParticipants;
        }

        return {
          ...previousResult,
          [state === 'active' ? 'activeParticipants' : 'archivedParticipants']: {
            edges: [...(previousData?.edges || []), ...(newData?.edges || [])],
            pageInfo: newData?.pageInfo,
            total: newData?.total,
          },
        };
      },
    });
  };

  const handleSortModelChange = useCallback(
    (sortModel: GridSortModel) => {
      let newSortBy = sortModel[0]?.field;

      if (!newSortBy) {
        newSortBy = ParticipationAndInvitationFields.CreatedAt;
      } else if (newSortBy === 'target') {
        newSortBy = ParticipationAndInvitationFields.ConfirmedMeetingCount;
      }
      setSortBy(newSortBy as ParticipationAndInvitationFields);

      let newSortDirection = sortModel[0]?.sort?.toUpperCase() as SortDirection | undefined;
      if (!newSortDirection) {
        newSortDirection = SortDirection.Desc;
      }
      setSortDirection(newSortDirection);

      fetchMore({
        variables: {
          programId: programid,
          orderBy: newSortBy,
          order: newSortDirection,
          term: term,
          cursor: '',
        },
      });
    },
    [fetchMore, programid, term],
  );

  const handleRowClick: GridEventListener<'rowClick'> = (data) => {
    if (data.row.invitationStatus === ProgramInvitationStatus.Accepted) {
      navigate(
        generatePath(SchoolRoutes.participationShow, {
          programid: programid,
          participationid: data.id.toString(),
        }),
      );
    }
  };

  const handleRowSelectionModelChange = (newRowSelectionModel: GridRowSelectionModel) => {
    setSelectedRows(newRowSelectionModel as string[]);
  };

  const selectedItemsMessageFn = useCallback(
    (count: number) =>
      intl.formatMessage(translations.participantsSelected, {
        x: count,
      }),
    [],
  );

  return (
    <>
      <Searchbar
        term={term}
        setTerm={setTerm}
        statusFilter={statusFilter}
        setStatusFilter={setStatusFilter}
      />
      <div className="participants-table__switch">
        <button
          onClick={() => setViewSwitch('active')}
          className={viewSwitch === 'active' ? 'active' : ''}
        >
          {intl.formatMessage(translations.activeParticipations, { count: activeTotalRows })}
        </button>
        <button
          onClick={() => setViewSwitch('archived')}
          className={viewSwitch === 'archived' ? 'active' : ''}
        >
          {intl.formatMessage(translations.archivedParticipations, { count: archivedTotalRows })}
        </button>
      </div>
      <DataGrid
        localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
        sx={{
          maxWidth: '100%',
          fontSize: '1.4rem',
          '--DataGrid-overlayHeight': '300px',
          '& .MuiDataGrid-footerContainer': {
            justifyContent: 'center',
            fontSize: '1.4rem',
          },
          '& .MuiTablePagination-root': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            fontSize: '1.4rem',
          },
          '& .MuiTablePagination-root .MuiTablePagination-selectLabel': {
            fontSize: '1.4rem',
          },
          '& .MuiTablePagination-root .MuiToolbar-root': {
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
          },
          '& .MuiTablePagination-root .MuiTablePagination-spacer': {
            display: 'none',
          },
          '& .MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '& .MuiTablePagination-displayedRows': {
            fontSize: '1.4rem',
          },
          '& .MuiTypography-root': {
            fontSize: '1.4rem !important',
          },
        }}
        slots={{
          noRowsOverlay: term.length ? NoResultsTable : ParticipantsTableEmpty,
          pagination: () => (
            <Pagination
              onClick={() => loadMoreRows(viewSwitch)}
              hasNextPage={viewSwitch == 'active' ? activePageInfo?.hasNextPage : archivedPageInfo?.hasNextPage}
            />
          ),
        }}
        rows={viewSwitch == 'active' ? filteredActiveParticipants : filteredArchivedParticipants}
        columns={columns}
        loading={loading}
        disableColumnMenu={true}
        initialState={{
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        rowCount={viewSwitch == 'active' ? activeTotalRows : archivedTotalRows}
        paginationMode="server"
        paginationModel={paginationModel}
        sortingMode="server"
        onSortModelChange={handleSortModelChange}
        onRowClick={handleRowClick}
        disableRowSelectionOnClick
        checkboxSelection
        onRowSelectionModelChange={handleRowSelectionModelChange}
        hideFooterSelectedRowCount
      />
      <BatchActionBar
        values={selectedRows}
        selectedItemsMessage={selectedItemsMessageFn}
        state={viewSwitch}
      />
    </>
  );
}
