import React from 'react';
import RingLoader from '../../../../../../../../../../../../components/ring-loader/RingLoader';
import Modal from '../../../../../../../../../../../../components/modal/Modal';
import ModalHeader from '../../../../../../../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../../../../../../../components/modal/components/modal-footer/ModalFooter';
import { useIntl } from 'react-intl';
import { generatePath, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../../../../../Routes';
import commonButtonsTranslations from '../../../../../../../../../../commons/translations/buttons.translations';
import { useDeleteGroupMutation } from '../../../../hooks/delete-group-mutation/useDeleteGroupMutation';
import translations from './GroupDeleteModal.translations';

import styles from './GroupDeleteModal.module.scss';

function GroupDeleteModal() {
  const intl = useIntl();
  const { projectid, groupid } = useParams();

  const { deleteGroup, loading } = useDeleteGroupMutation({
    projectId: projectid,
    groupId: groupid,
  });

  if (typeof projectid !== 'string') {
    return null;
  }

  return (
    <Modal
      title={intl.formatMessage(translations.title)}
      openPathname={SchoolRoutes.projectGroupDelete}
      closeUrl={generatePath(SchoolRoutes.projectCustomize, { projectid })}
    >
      <ModalHeader title={intl.formatMessage(translations.title)} />
      <ModalBody className={styles.modalBody}>
        {loading ? <RingLoader /> : <p className={styles.text}>{intl.formatMessage(translations.body)}</p>}
      </ModalBody>
      <ModalFooter
        cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
        submitText={intl.formatMessage(commonButtonsTranslations.confirm)}
        submitFunction={deleteGroup}
        disableSubmit={loading}
      />
    </Modal>
  );
}

export default GroupDeleteModal;
