import { defineMessages } from 'react-intl';

export default defineMessages({
  editAction: {
    id: 'ProjectShow.editAction',
    defaultMessage: 'Modifier',
  },
  archive: {
    id: 'ProjectShow.archive',
    defaultMessage: 'Archiver',
  },
  archiveTooltip: {
    id: 'ProjectShow.archiveTooltip',
    defaultMessage: 'Vous n’êtes pas propriétaire du projet',
  },
  seeMore: {
    id: 'ProjectShow.seeMore',
    defaultMessage: 'En savoir plus',
  },
  description: {
    id: 'ProjectCustomize.description',
    defaultMessage: 'Gérer l’organisation de vos programmes d’orientation.',
  },
  addProgramButton: {
    id: 'ProjectShow.addProgramButton',
    defaultMessage: 'Programme',
  },
  tabStats: {
    id: 'ProjectShow.tabStats',
    defaultMessage: 'Statistiques',
  },
  tabStructure: {
    id: 'ProjectShow.tabStructure',
    defaultMessage: 'Structure',
  },
  tabMembers: {
    id: 'ProjectShow.tabMembers',
    defaultMessage: 'Membres',
  },
});
