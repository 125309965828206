import React from 'react';
import { useQuery } from '@apollo/client';
import Modal from '../../../../../../components/modal/Modal';
import { useIntl } from 'react-intl';
import { generatePath, useMatch, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../Routes';
import {
  Count_Not_Finished_Project_ProgramsQuery,
  Count_Not_Finished_Project_ProgramsQueryVariables,
} from '../../../../@types/graphql';
import ModalLoading from '../../../../commons/modal-loading/ModalLoading';
import CanArchiveModalContent from './components/can-archive-modal-content/CanArchiveModalContent';
import canArchiveTranslations from './components/can-archive-modal-content/CanArchiveModalContent.translations';
import CannotArchiveModalContent from './components/cannot-archive-modal-content/CannotArchiveModalContent';
import cannotArchiveTranslations from './components/cannot-archive-modal-content/CannotArchiveModalContent.translations';
import { COUNT_NOT_FINISHED_PROJECT_PROGRAMS } from './ArchiveModal.gql';

export default function ArchiveModal() {
  const intl = useIntl();
  const { projectid } = useParams();
  const isOpen = useMatch({
    path: SchoolRoutes.projectArchive,
  });

  const { data, loading } = useQuery<
    Count_Not_Finished_Project_ProgramsQuery,
    Count_Not_Finished_Project_ProgramsQueryVariables
  >(COUNT_NOT_FINISHED_PROJECT_PROGRAMS, {
    variables: {
      projectId: projectid || '',
    },
    skip: !isOpen || !projectid,
  });
  const total = data?.project?.programs?.total || 0;

  return (
    <Modal
      title={
        total > 0
          ? intl.formatMessage(cannotArchiveTranslations.title)
          : intl.formatMessage(canArchiveTranslations.title)
      }
      openPathname={generatePath(SchoolRoutes.projectArchive, {
        projectid: projectid || '',
      })}
      closeUrl={generatePath(SchoolRoutes.projectStats, {
        projectid: projectid || '',
      })}
    >
      {loading ? <ModalLoading /> : <>{total > 0 ? <CannotArchiveModalContent /> : <CanArchiveModalContent />}</>}
    </Modal>
  );
}
