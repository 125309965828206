import React from 'react';
import FullWidthContainer from '../../../../layout/full-width-container/FullWidthContainer';
import ProjectDiagramStructure from './components/project-diagram-structure/ProjectDiagramStructure';
import GroupDeleteModal from './components/project-list-structure/components/project-structure-list-group-item/components/group-delete-modal/GroupDeleteModal';
import ProjectListStructure from './components/project-list-structure/ProjectListStructure';

import styles from './ProjectShowStructure.module.scss';

function ProjectShowStructure() {
  return (
    <FullWidthContainer className={styles.ProjectShowStructure}>
      <GroupDeleteModal />
      <ProjectListStructure />
      <ProjectDiagramStructure />
    </FullWidthContainer>
  );
}

export default ProjectShowStructure;
