import React from 'react';
import Modal from '../../../../../../../../components/modal/Modal';
import ModalHeader from '../../../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../../../components/modal/components/modal-footer/ModalFooter';
import SelectField from '../../../../../../../../components/fields/select-field/SelectField';
import { Field, Formik } from 'formik';
import { useIntl } from 'react-intl';
import { generatePath, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../Routes';
import { Role } from '../../../../../../@types/graphql';
import GlobalErrorDisplay from '../../../../../../commons/global-error-display/GlobalErrorDisplay';
import commonButtonsTranslations from '../../../../../../commons/translations/buttons.translations';
import commonRoleTranslations from '../../../../../../commons/translations/roles.translations';
import useInvitationId from '../../hooks/useInvitationId';
import useInvitation from '../../hooks/useInvitation';
import useMembership from '../../hooks/useMembership';
import useMembershipId from '../../hooks/useMembershipId';
import { useMemberPermissions } from '../../hooks/useMemberPermissions';
import { useEditRoleMutation } from './MemberEditModal.hooks';
import translations from './MemberEditModal.translations';

import styles from './MemberEditModal.module.scss';

export default function MemberEditModal() {
  const intl = useIntl();
  const { projectid } = useParams();

  const membershipId = useMembershipId();
  const membership = useMembership(membershipId);

  const invitationId = useInvitationId();
  const invitation = useInvitation(invitationId);

  const resource = membershipId ? membership : invitation;
  const resourceType = resource?.__typename;

  const currentUserAuthorizations = useMemberPermissions();

  const onSubmit = useEditRoleMutation(resourceType, membershipId || invitationId);

  return (
    <Modal
      title={intl.formatMessage(translations.title)}
      openPathname={[SchoolRoutes.projectMembersEditInvitation, SchoolRoutes.projectMemberEdit]}
      closeUrl={generatePath(SchoolRoutes.projectMembers, { projectid: projectid || '' })}
    >
      <Formik
        initialValues={{
          role:
            resource?.__typename === 'Membership'
              ? resource?.role
              : resource?.__typename === 'Invitation'
                ? resource?.role
                : Role.Guest,
        }}
        onSubmit={onSubmit}
        // allow role to set after resource loading
        enableReinitialize
      >
        {({ handleSubmit, dirty, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <ModalHeader title={intl.formatMessage(translations.title)} />
            <ModalBody className={styles.modalBody}>
              <p className={styles.description}>
                {resourceType === 'Membership' &&
                  intl.formatMessage(translations.membershipDescription, {
                    firstName: membership?.userFirstName,
                    lastName: membership?.userLastName,
                  })}
                {resourceType === 'Invitation' &&
                  intl.formatMessage(translations.invitationDescription, {
                    email: invitation?.email,
                  })}{' '}
                <a
                  href="https://help.myjobglasses.com/fr/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {intl.formatMessage(translations.seeMore)}
                </a>
              </p>
              <div className={styles.fieldWrapper}>
                <Field
                  name="role"
                  component={SelectField}
                  options={Object.values(Role).map((role) => ({
                    icon: role === resource?.role ? 'done' : '',
                    value: role,
                    label: intl.formatMessage(commonRoleTranslations[role]),
                    disabled:
                      role === Role.Owner &&
                      (!currentUserAuthorizations.canChooseNewOwner || resourceType === 'Invitation'),
                  }))}
                />
              </div>
              <GlobalErrorDisplay />
            </ModalBody>
            <ModalFooter
              cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
              submitText={intl.formatMessage(commonButtonsTranslations.confirm)}
              disableSubmit={!isValid || !dirty || isSubmitting}
              className={styles.modalFooter}
            />
          </form>
        )}
      </Formik>
    </Modal>
  );
}
