import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmTitle: {
    id: 'RecoverModal.confirmTitle',
    defaultMessage: 'Réintégrer au programme',
  },
  confirmDescription: {
    id: 'RecoverModal.confirmDescription',
    defaultMessage:
      'En cliquant sur “Confirmer”, tous les participants sélectionnés seront réintégrés au programme et leurs statistiques mises à jour.',
  },
});
