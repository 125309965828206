import React from 'react';
import ProgressionSteps from '../../../../components/progression-steps/ProgressionSteps';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, matchPath } from 'react-router-dom';
import { SchoolRoutes } from '../../../../Routes';
import { PageHeader } from '../../layout/context/HeaderContext';
import RTFBold from '../../rich-text-formatting/bold/RTFBold';
import PreventLeaveProgramCreation from './components/prevent-leave-program-creation/PreventLeaveProgramCreation';
import CoachingTypeView from './views/coaching-type-view/CoachingTypeView';
import ProgramConfigurationView from './views/program-configuration-view/ProgramConfigurationView';
import ProgramNotificationConfigurationView from './views/program-notification-configuration-view/ProgramNotificationConfigurationView';
import ProgramPublicationView from './views/program-publication-view/ProgramPublicationView';
import ProgramTemplateView from './views/program-template-view/ProgramTemplateView';
import translations from './ProgramCreate.translations';

import styles from './ProgramCreate.module.scss';

export default function ProgramCreate() {
  const intl = useIntl();
  const navigate = useNavigate();

  const match =
    matchPath(SchoolRoutes.projectProgramCreate, location.pathname) ||
    matchPath(SchoolRoutes.groupProgramCreate, location.pathname);

  function leaveProjectCreation() {
    if (match) {
      navigate(`${generatePath(match.pathname, match.params)}/leave`);
    }
  }

  return (
    <main className={styles.pageCreate}>
      <PageHeader
        actions={[
          {
            iconName: 'close',
            onClick: leaveProjectCreation,
            name: intl.formatMessage(translations.close),
          },
        ]}
      />
      <PreventLeaveProgramCreation />
      <p className={styles.title}>{intl.formatMessage(translations.title)}</p>
      <p className={styles.description}>
        {intl.formatMessage(translations.description, {
          boldThis: RTFBold,
        })}
      </p>
      <ProgressionSteps
        steps={[
          { component: CoachingTypeView },
          { component: ProgramTemplateView },
          { component: ProgramConfigurationView },
          { component: ProgramNotificationConfigurationView },
          { component: ProgramPublicationView },
        ]}
        className={styles.progressionSteps}
      />
    </main>
  );
}
