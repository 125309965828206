import React from 'react';
import { useQuery } from '@apollo/client';
import { AvatarSize } from '../../../../components/avatar/Avatar';
import GroupedAvatar from '../../../../components/grouped-avatar/GroupedAvatar';
import { useIntl } from 'react-intl';
import { generatePath, Outlet, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../Routes';
import { getUserInitials } from '../../../../utils/user';
import { Get_Program_To_ShowQuery, Get_Program_To_ShowQueryVariables } from '../../@types/graphql';
import PageError from '../../commons/page-error/PageError';
import ProgramDot from '../../commons/program-dot/ProgramDot';
import { BreadcrumbsItem } from '../../layout/context/HeaderContext';
import PageClassic from '../../layout/pages/page-classic/PageClassic';
import RTFBold from '../../rich-text-formatting/bold/RTFBold';
import ProgramArchiveModal from './components/archive-modal/ProgramArchiveModal';
import ParticipantsActivityBoard from './components/participants-activity-board/ParticipantsActivityBoard';
import ProgramParticipationCode from './components/program-participation-code/ProgramParticipationCode';
import ProgramShowParticipants from './components/program-show-participants/ProgramShowParticipants';
import ProgramShowTargetProgression from './components/program-show-target-progression/ProgramShowTargetProgression';
import ProgramStudentAffiliationModal from './components/program-student-affiliation-modal/ProgramStudentAffiliationModal';
import ActionButton from '../project-show/components/action-button/ActionButton';
import { GET_PROGRAM_TO_SHOW } from './ProgramShow.gql';
import translations from './ProgramShow.translations';

import styles from './ProgramShow.module.scss';
import ProgramStatusBadge from '../../commons/program-status-badge/ProgramStatusBadge';
import ProgramDuplicateModal from './components/duplicate-modal/ProgramDuplicateModal';

export default function ProgramShow() {
  const intl = useIntl();
  const navigate = useNavigate();

  const { programid } = useParams();

  const { data, loading, error, refetch } = useQuery<Get_Program_To_ShowQuery, Get_Program_To_ShowQueryVariables>(
    GET_PROGRAM_TO_SHOW,
    {
      skip: !programid,
      variables: {
        id: programid || '',
      },
    },
  );

  if (error) {
    return <PageError refetch={refetch} />;
  }

  const memberships = data?.program?.project?.memberships?.nodes?.filter((e) => e?.role !== 'guest') || [];
  const collaborations = data?.program?.collaborations?.nodes || [];

  const collaborations_and_memberships = [
    ...memberships,
    ...collaborations.filter((c) => !memberships.find((m) => m?.user.id === c?.user.id)),
  ];

  return (
    <PageClassic
      title={data?.program?.name}
      subTitle={
        !loading &&
        intl.formatMessage(translations.programDateRange, {
          startAt: intl.formatDate(data?.program?.startAt, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          endAt: intl.formatDate(data?.program?.endAt, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
          }),
          boldThis: RTFBold,
        })
      }
      subTitleBadge={
        <ProgramStatusBadge
          status={data?.program?.programProgressionStatus}
          className={styles.progressionBadge}
        />
      }
      iconComponent={ProgramDot}
      breadcrumbs={
        [
          data?.program?.project && {
            iconName: 'assessment',
            name: data?.program?.project.name,
            link: generatePath(SchoolRoutes.projectStats, {
              projectid: data?.program?.project.id,
            }),
          },
        ].filter(Boolean) as BreadcrumbsItem[]
      }
      actions={[
        <GroupedAvatar
          avatars={
            collaborations_and_memberships?.map((node) => ({
              avatarInitials: getUserInitials(node.user),
              altText: `${node?.user.firstName} ${node?.user.lastName}`,
            })) || []
          }
          size={AvatarSize.xsmall}
        />,
        <ActionButton
          onClick={() =>
            navigate(
              generatePath(SchoolRoutes.programDuplicate, {
                programid: programid || '',
              }),
            )
          }
          iconName="library_add"
          name={intl.formatMessage(translations.duplicate)}
          show={!data?.program?.canDuplicate.value}
        />,
        <ActionButton
          onClick={() =>
            navigate(
              generatePath(SchoolRoutes.programUpdate, {
                programid: programid || '',
              }),
            )
          }
          iconName="edit"
          name={intl.formatMessage(translations.edit)}
          show={!data?.program?.canUpdate.value}
        />,
        <ActionButton
          onClick={() =>
            navigate(
              generatePath(SchoolRoutes.programArchive, {
                programid: programid || '',
              }),
            )
          }
          iconName="delete"
          name={intl.formatMessage(translations.archive)}
          show={!data?.program?.canArchive.value}
        />,
      ]}
      aside={ProgramParticipationCode}
      loading={loading}
    >
      <div className={styles.mainStatistics}>
        <ProgramShowParticipants
          totalParticipations={data?.program?.participations?.total || 0}
          expectedParticipations={data?.program?.expectedParticipations || 0}
          className={styles.participants}
        />
        <ProgramShowTargetProgression
          totalParticipations={data?.program?.participations?.total || 0}
          completedParticipationsCount={data?.program?.completedParticipationsCount || 0}
          expectedParticipations={data?.program?.expectedParticipations || 0}
          meetingsQuantity={data?.program?.meetingsQuantity || 0}
          className={styles.targetProgression}
        />
      </div>
      <ParticipantsActivityBoard />
      <ProgramStudentAffiliationModal />
      <ProgramArchiveModal />
      <ProgramDuplicateModal />
      <Outlet />
    </PageClassic>
  );
}
