import { Group, Maybe, Program } from '../../../../../../../../@types/graphql';
import ProjectStructureListGroupItem from '../project-structure-list-group-item/ProjectStructureListGroupItem';
import ProjectStructureListProgramItem from '../project-structure-list-program-item/ProjectStructureListProgramItem';
import ProjectStructureSkeletonListItem from '../project-structure-skeleton-list-item/ProjectStructureSkeletonListItem';

type ChildProgram = Pick<Program, 'id' | 'name' | '__typename'> & {
  canUpdate: Pick<Program['canUpdate'], 'value'>;
};
type ChildGroup = Pick<Group, 'id' | 'name' | '__typename'> & {
  canCreateGroup: Pick<Group['canCreateGroup'], 'value'>;
  canDelete: Pick<Group['canDelete'], 'value'>;
  canUpdate: Pick<Group['canUpdate'], 'value'>;
  groups?: Maybe<{ nodes: ChildGroup[] }>;
  programs?: Maybe<{ nodes: ChildProgram[] }>;
};

interface ProjectStructureListItemProps {
  item?: ChildProgram | ChildGroup;
  level: number;
  skeleton?: boolean;
}
export default function ProjectStructureListItem({ item, level, skeleton }: ProjectStructureListItemProps) {
  if (skeleton) {
    return <ProjectStructureSkeletonListItem level={level} />;
  }

  switch (item?.__typename) {
    case 'Group':
      return (
        <ProjectStructureListGroupItem
          group={item}
          level={level}
        />
      );
    case 'Program':
      return (
        <ProjectStructureListProgramItem
          program={item}
          level={level}
        />
      );
    default:
      return null;
  }
}
