import { gql } from '@apollo/client';

export const GET_PARTICIPATION_CODE_FOR_EMPTY_PARTICIPATION = gql`
  query GET_PARTICIPATION_CODE_FOR_EMPTY_PARTICIPATION($id: ID!) {
    program(id: $id) {
      id
      participationCode
    }
  }
`;
