import { useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  Invitation,
  Project_Members_InvitationFragment,
  Query_Invitation_For_DeletionQuery,
  Query_Invitation_For_DeletionQueryVariables,
} from '../../../../../@types/graphql';
import { PROJECT_MEMBERS_INVITATION } from '../../../ProjectShow.gql';
import { QUERY_INVITATION_FOR_DELETION } from './useInvitation.gql';

export default function useInvitation(
  invitationId?: string,
): Pick<Invitation, '__typename' | 'email' | 'role'> | undefined {
  const { cache } = useApolloClient();

  // try to get invitation from cache
  const invitation = useMemo(
    () =>
      cache.readFragment<Project_Members_InvitationFragment>({
        id: cache.identify({
          id: invitationId,
          __typename: 'Invitation',
        }),
        fragment: PROJECT_MEMBERS_INVITATION,
      }),
    [invitationId],
  );

  // query invitation on the server
  const { data } = useQuery<Query_Invitation_For_DeletionQuery, Query_Invitation_For_DeletionQueryVariables>(
    QUERY_INVITATION_FOR_DELETION,
    {
      skip: !!invitation || !invitationId,
      variables: {
        id: invitationId || '',
      },
    },
  );

  return invitation || data?.invitation || undefined;
}
