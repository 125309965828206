import Icon from '../../../../../../../../components/icon/Icon';
import classnames from 'classnames';
import { TreeNavComponent } from '../../../../@types/TreeNavComponent';

import styles from './TreeNavItemExternalLink.module.scss';

type TreeNavItemExternalLinkProps = Pick<
  TreeNavComponent,
  'id' | 'label' | 'link' | 'icon' | 'locked' | 'level' | 'actions' | 'openBlank'
>;
function TreeNavItemExternalLink({ id, label, link, icon, locked, openBlank = false }: TreeNavItemExternalLinkProps) {
  return (
    <li
      data-testid={id}
      role="treeitem"
      className={styles.item}
    >
      <a
        href={link}
        target={openBlank ? '_blank' : '_self'}
        rel="noopener noreferrer"
        className={classnames(styles.treeNavItemExternalLink, styles.withLink, {
          [styles.locked]: locked,
        })}
      >
        {locked && (
          <Icon
            name="locked"
            className={styles.icon}
          />
        )}
        {icon && !locked && (
          <Icon
            name={icon}
            className={styles.icon}
          />
        )}
        <span className={styles.label}>{label}</span>
      </a>
    </li>
  );
}

export default TreeNavItemExternalLink;
