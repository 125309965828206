import { useQuery } from '@apollo/client';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useIntl } from 'react-intl';
import { useMatch } from 'react-router-dom';
import {
  Get_Project_List_StructureQuery,
  Get_Project_List_StructureQueryVariables,
} from '../../../../../../@types/graphql';
import { ProjectCustomizeParams } from '../../../../../../@types/route-params';
import { SchoolRoutes } from '../../../../../../../../Routes';
import AddGroupButton from './components/add-group-button/AddGroupButton';
import DropZoneProgramGroup from './components/drop-zone-program-group/DropZoneProgramGroup';
import ProjectStructureListItem from './components/project-structure-list-item/ProjectStructureListItem';
import { GET_PROJECT_LIST_STRUCTURE } from './ProjectListStructure.gql';
import translations from './ProjectListStructure.translations';

import styles from './ProjectListStructure.module.scss';

function ProjectListStructure() {
  const intl = useIntl();

  const match = useMatch<ProjectCustomizeParams>(SchoolRoutes.projectCustomize);
  const projectId = match?.params.projectid;

  const { data, loading } = useQuery<Get_Project_List_StructureQuery, Get_Project_List_StructureQueryVariables>(
    GET_PROJECT_LIST_STRUCTURE,
    {
      skip: !projectId,
      variables: {
        id: projectId || '',
      },
      fetchPolicy: 'cache-only',
    },
  );

  return (
    <section
      data-testid="project-list-structure"
      className={styles.projectListStructure}
    >
      <header className={styles.header}>
        <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
      </header>
      <div className={styles.container}>
        <div className={styles.addButton}>
          <AddGroupButton
            projectId={projectId}
            disabled={!data?.project?.canCreateGroup.value}
          />
        </div>
        <h3 className={styles.projectName}>{data?.project?.name}</h3>

        <DndProvider backend={HTML5Backend}>
          <ul className={styles.subItems}>
            {loading && (
              <>
                <ProjectStructureListItem
                  level={0}
                  skeleton
                />
                <ProjectStructureListItem
                  level={1}
                  skeleton
                />
                <ProjectStructureListItem
                  level={1}
                  skeleton
                />
                <ProjectStructureListItem
                  level={0}
                  skeleton
                />
              </>
            )}
            {data?.project?.directGroups?.nodes.map((group) => (
              <ProjectStructureListItem
                key={group.id}
                item={group}
                level={0}
              />
            ))}
            {data?.project?.directPrograms?.nodes.map((program) => (
              <ProjectStructureListItem
                key={program.id}
                item={program}
                level={0}
              />
            ))}
          </ul>
          <DropZoneProgramGroup projectId={projectId!} />
        </DndProvider>
      </div>
    </section>
  );
}

export default ProjectListStructure;
