import { defineMessages } from 'react-intl';

const commonProgramNotificationFrequency = defineMessages({
  1000: {
    id: 'programNotificationFrequencies.Never',
    defaultMessage: 'Aucun envoi',
  },
  1: {
    id: 'programNotificationFrequencies.FirstMonday',
    defaultMessage: 'Toutes les semaines',
  },
  2: {
    id: 'programNotificationFrequencies.SecondMonday',
    defaultMessage: 'Toutes les 2 semaines',
  },
  3: {
    id: 'programNotificationFrequencies.ThirdMonday',
    defaultMessage: 'Toutes les 3 semaines',
  },
  4: {
    id: 'programNotificationFrequencies.FourthMonday',
    defaultMessage: 'Toutes les 4 semaines',
  },
});

export default commonProgramNotificationFrequency;
