import { defineMessages } from 'react-intl';
import { RECOVER_PROJECT_ERRORS } from '../../../../@types/graphql-errors';

export const errorTranslations = defineMessages({
  [RECOVER_PROJECT_ERRORS.NOT_FOUND]: {
    id: 'RecoverProjectModal.NOT_FOUND',
    defaultMessage: "Ce project n'existe pas ou n'est pas archivé",
  },
  [RECOVER_PROJECT_ERRORS.UNAUTHORIZED]: {
    id: 'RecoverProjectModal.UNAUTHORIZED',
    defaultMessage: 'Veuillez contacter le propriétaire du projet pour pouvoir le désarchiver ',
  },
});

export default defineMessages({
  title: {
    id: 'RecoverProjectModal.title',
    defaultMessage: 'Désarchiver le projet',
  },
  description: {
    id: 'RecoverProjectModal.description',
    defaultMessage:
      'Si vous désarchivez ce projet, l’ensemble de ses membres (vous y compris) aura à nouveau accès à son contenu.{br}{br}Tous les membres du projet seront notifiés par e-mail.',
  },
});
