import React from 'react';
import { useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../components/snackbar/hooks/useSnackbar';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../Routes';
import { Accept_InvitationMutation, Accept_InvitationMutationVariables } from '../../@types/graphql';
import PageLoading from '../../commons/page-loading/PageLoading';
import commonErrorsTranslations from '../../commons/translations/errors.translations';
import { ACCEPT_INVITATION } from './InvitationJoin.gql';

export default function InvitationJoin() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { invitationid = '', projectid = '' } = useParams();
  const { error } = useSnackbar();

  const [acceptInvitationToProject] = useMutation<Accept_InvitationMutation, Accept_InvitationMutationVariables>(
    ACCEPT_INVITATION,
    {
      refetchQueries: ['GET_SIDEBAR_PROJECT_TREE', 'GET_MY_PROJECT_LIST'],
    },
  );

  useEffect(() => {
    acceptInvitationToProject({
      variables: {
        id: invitationid,
      },
    })
      .then(() => {
        navigate(
          generatePath(SchoolRoutes.projectStats, {
            projectid: projectid,
          }),
        );
      })
      .catch(() => {
        navigate(SchoolRoutes.home);
        error(intl.formatMessage(commonErrorsTranslations.technical));
      });
  }, []);

  return <PageLoading />;
}
