import classnames from 'classnames';
import { useFormikContext } from 'formik';
import styles from './GlobalErrorDisplay.module.scss';

interface GlobalErrorDisplayProps {
  className?: string;
}
function GlobalErrorDisplay({ className }: GlobalErrorDisplayProps) {
  const formikContext = useFormikContext();
  if (!formikContext.status?.__globalError) {
    return null;
  }
  return <p className={classnames(styles.globalError, className)}>{formikContext.status.__globalError}</p>;
}

export default GlobalErrorDisplay;
