import React from 'react';
import { useCallback } from 'react';
import Icon from '../../../../../../components/icon/Icon';
import Tooltip from '../../../../../../components/tooltip/Tooltip';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';

import styles from './ActionButtonItem.module.scss';

interface Action {
  name: string;
  iconName?: string;
  disabled?: boolean;
  link?: string;
  onClick?: () => void;
  disabledExplanation?: string;
}
interface ActionButtonItemProps {
  action: Action;
}
export default function ActionButtonItem({ action }: ActionButtonItemProps) {
  const navigate = useNavigate();

  const onClick = useCallback(() => {
    if (action.link) {
      navigate(action.link);
    }
    action.onClick?.();
  }, [action.link, action.onClick]);

  return (
    <li
      key={action.name}
      role="menuitem"
      className={styles.actionButtonItem}
    >
      <Tooltip
        text={action.disabledExplanation || ''}
        className={styles.tooltipWrapper}
        tooltipClassName={styles.tooltip}
      >
        {({ onMouseEnter, onMouseLeave }) => (
          <button
            type="button"
            onClick={action.disabled ? undefined : onClick}
            onMouseEnter={action.disabled && action.disabledExplanation ? onMouseEnter : undefined}
            onMouseLeave={action.disabled && action.disabledExplanation ? onMouseLeave : undefined}
            className={classnames(styles.button, {
              [styles.disabled]: action.disabled,
            })}
          >
            {action.iconName && (
              <Icon
                name={action.iconName}
                className={styles.icon}
              />
            )}
            {action.name}
          </button>
        )}
      </Tooltip>
    </li>
  );
}
