import { defineMessages } from 'react-intl';

export default defineMessages({
  programDateRange: {
    id: 'ProgramShow.programDateRange',
    defaultMessage: 'Du <boldThis>{startAt}</boldThis> au <boldThis>{endAt}</boldThis>',
  },
  more: {
    id: 'ProgramShow.more',
    defaultMessage: 'Voir plus',
  },
  edit: {
    id: 'ProgramShow.edit',
    defaultMessage: 'Modifier',
  },
  archive: {
    id: 'ProgramShow.archive',
    defaultMessage: 'Supprimer',
  },
  duplicate: {
    id: 'ProgramShow.duplicate',
    defaultMessage: 'Dupliquer',
  },
  members: {
    id: 'ProjectShow.members',
    defaultMessage: 'Membres',
  },
});
