import React from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { ProgressionStepsChildrenProps } from '../../../../../../components/progression-steps/ProgressionSteps';
import { Field, Formik, FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { handleGraphqlErrorsOnFormik } from '../../../../../../utils/formik';
import {
  Create_New_ProgramMutation,
  Create_New_ProgramMutationVariables,
  Get_Program_TemplatesQuery,
  Get_Program_TemplatesQueryVariables,
} from '../../../../@types/graphql';
import GlobalErrorDisplay from '../../../../commons/global-error-display/GlobalErrorDisplay';
import PageError from '../../../../commons/page-error/PageError';
import PageLoading from '../../../../commons/page-loading/PageLoading';
import commonButtonsTranslations from '../../../../commons/translations/buttons.translations';
import commonErrorsTranslations from '../../../../commons/translations/errors.translations';
import { CoachingType } from '../../@types/coaching-type';
import { StepsContext } from '../../@types/steps-context';
import useGroupId from '../../hooks/useGroupId';
import ProgramTemplateField from './components/program-template-field/ProgramTemplateField';
import { CREATE_NEW_PROGRAM, GET_PROGRAM_TEMPLATES } from './ProgramTemplateView.gql';
import translations from './ProgramTemplateView.translations';
import validationSchema from './ProgramTemplateView.validations';

import styles from './ProgramTemplateView.module.scss';
import { useParams } from 'react-router-dom';

interface FormikValues {
  programTemplateId: string;
}
export default function ProgramTemplateView({
  onNextStep,
  onPreviousStep,
  context,
}: ProgressionStepsChildrenProps<StepsContext>) {
  const intl = useIntl();
  const { projectid } = useParams();
  const groupId = useGroupId();

  const { data, loading, refetch } = useQuery<Get_Program_TemplatesQuery, Get_Program_TemplatesQueryVariables>(
    GET_PROGRAM_TEMPLATES,
  );

  const [createProgram] = useMutation<Create_New_ProgramMutation, Create_New_ProgramMutationVariables>(
    CREATE_NEW_PROGRAM,
  );

  function backToPreviousStep() {
    onPreviousStep();
  }

  function onSubmit(values: FormikValues, formikBag: FormikHelpers<FormikValues>) {
    return createProgram({
      variables: {
        projectId: projectid || '',
        groupId,
        programTemplateId: values.programTemplateId,
        individualSupport: context?.coachingType === CoachingType.Individual,
      },
    })
      .then((response) => {
        onNextStep({
          ...context,
          programTemplateId: values.programTemplateId,
          programId: response.data?.createProgram.id,
        });
      })
      .catch((error) =>
        handleGraphqlErrorsOnFormik(error, formikBag, {
          translations: {
            default: intl.formatMessage(commonErrorsTranslations.default),
            network: intl.formatMessage(commonErrorsTranslations.network),
            unknown: intl.formatMessage(commonErrorsTranslations.default),
          },
        }),
      );
  }

  if (loading) {
    return <PageLoading />;
  }

  const templates: React.ComponentPropsWithRef<typeof ProgramTemplateField>['templates'] | undefined =
    data?.programTemplates?.nodes;

  if (!templates?.length) {
    return <PageError refetch={refetch} />;
  }

  return (
    <section>
      <Formik
        initialValues={{
          programTemplateId: context?.programTemplateId || '',
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ handleSubmit, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
            <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
            <Field
              name="programTemplateId"
              component={ProgramTemplateField}
              templates={templates}
            />
            <GlobalErrorDisplay className={styles.errorDisplay} />
            <div className={styles.actions}>
              <button
                type="button"
                onClick={backToPreviousStep}
                className={styles.previousButton}
              >
                {intl.formatMessage(commonButtonsTranslations.previous)}
              </button>
              <button
                type="submit"
                className={styles.nextButton}
                disabled={!isValid || isSubmitting}
              >
                {intl.formatMessage(commonButtonsTranslations.next)}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </section>
  );
}
