import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import moment from 'moment-timezone';
import { ProgramInvitationImport, ProgramInvitationImportStatus } from '../../../../@types/graphql';
import translations, { statusMessages } from './ImportListTable.translations';
import './ImportListTable.scss';

type ImportParticipantsTableProps = {
  imports?: ProgramInvitationImport[];
};

export default function ImportListTable({ imports }: ImportParticipantsTableProps) {
  const intl = useIntl();
  const showInProgressMessage: boolean = useMemo(
    () => imports?.some((importItem) => importItem.status === ProgramInvitationImportStatus.Processing) || false,
    [imports],
  );

  const importResultMessage = (importItem: ProgramInvitationImport) => {
    if (importItem.resultFileUrl) {
      return <a href={importItem.resultFileUrl}>{intl.formatMessage(translations.downloadErrorFile)}</a>;
    }
    if (importItem.status === ProgramInvitationImportStatus.Finished) {
      return <p>{intl.formatMessage(translations.importSuccessful)}</p>;
    }

    return <>...</>;
  };

  return (
    <>
      <table className="import-list-table">
        <thead>
          <tr>
            <th className="import-list-table__head">{intl.formatMessage(translations.status)}</th>
            <th className="import-list-table__head">{intl.formatMessage(translations.date)}</th>
            <th className="import-list-table__head">{intl.formatMessage(translations.result)}</th>
          </tr>
        </thead>
        <tbody>
          {imports &&
            imports.length > 0 &&
            imports.map((importItem) => (
              <tr key={importItem.status}>
                <td className="import-list-table__cell">
                  <div className={`import-list-table-status ${`import-list-table-status-${importItem.status}`}`}>
                    <p>{intl.formatMessage(statusMessages[importItem.status])}</p>
                  </div>
                </td>
                <td className="import-list-table__cell">
                  <p>{moment(importItem.updatedAt).format('YYYY-MM-DD HH:mm:ss')}</p>
                </td>
                <td className="import-list-table__cell">{importResultMessage(importItem)}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {showInProgressMessage && (
        <div className="import-list-table__message">
          <i>{intl.formatMessage(translations.processingFreeze)}</i>
        </div>
      )}
    </>
  );
}
