import React from 'react';
import { IconProps } from '../../assets/component-icons/Icon';
import classNames from 'classnames';
import { ComponentType, DetailedHTMLProps, useState } from 'react';
import './NumberInput.scss';

// eslint-disable-next-line max-len
type DefaultInputProps = Pick<
  DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
  'onChange' | 'value' | 'onFocus' | 'onBlur' | 'placeholder' | 'type'
>;
type NumberInputProps = DefaultInputProps & {
  icon?: ComponentType<IconProps>;
  name?: string;
  label?: string;
  min?: string;
  max?: string;
  step?: string;
  error?: string;
  disabled?: boolean;
  dataCy?: string;
  initialValue?: string;
};

export default function NumberInput({
  icon: Icon,
  name,
  label,
  value,
  onChange,
  onFocus,
  onBlur,
  placeholder,
  error,
  min, 
  max,
  step,
  disabled,
  dataCy,
  initialValue,
}: NumberInputProps) {
  const [isFocused, setIsFocused] = useState(false);

  function handleFocus(event: any) {
    setIsFocused(true);
    onFocus?.(event);
  }

  function handleBlur(event: any) {
    setIsFocused(false);
    onBlur?.(event);
  }

  return (
    <div className="text-input">
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error })}
        >
          {label}
        </label>
      )}

      <div
        className={classNames(
          'text-input__main',
          { 'text-input__main--focused': isFocused },
          { 'text-input__main--error': error },
          { 'text-input__main--disabled': disabled },
        )}
      >
        {Icon && <Icon className="text-input__main__icon" />}
        <input
          data-cy={dataCy}
          id={name}
          type={'number'}
          min={min}
          max={max}
          step={step}
          {...{
            name,
            value,
            onChange,
            placeholder,
            initialValue,
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          disabled={disabled}
        />
      </div>

      {error && (
        <span
          data-cy={dataCy && `${dataCy}__error`}
          className="text-input__error"
        >
          {error}
        </span>
      )}
    </div>
  );
}
