import { useMemo } from 'react';
import Modal from '../../../../../../components/modal/Modal';
import ModalHeader from '../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../components/modal/components/modal-footer/ModalFooter';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../Routes';
import { ProgramShowParams } from '../../../../@types/route-params';
import RTFBold from '../../../../rich-text-formatting/bold/RTFBold';
import step1img from './images/step1.png';
import step2img from './images/step2.png';
import step3img from './images/step3.png';
import translations from './ProgramStudentAffiliationModal.translations';

import styles from './ProgramStudentAffiliationModal.module.scss';

export default function ProgramStudentAffiliationModal() {
  const intl = useIntl();
  const navigate = useNavigate();
  const params = useParams<ProgramShowParams>();

  const closeUrl = useMemo(() => generatePath(SchoolRoutes.programShow, { programid: params.programid }), [params]);

  function close() {
    navigate(closeUrl);
  }

  return (
    <Modal
      openPathname={SchoolRoutes.programStudentAffiliationHelp}
      closeUrl={closeUrl}
      title={intl.formatMessage(translations.title)}
      format="large"
    >
      <ModalHeader title={intl.formatMessage(translations.title)} />
      <ModalBody className={styles.content}>
        <div className={styles.step}>
          <img
            src={step1img}
            className={styles.stepImg}
            alt=""
          />
          <p className={styles.stepTitle}>{intl.formatMessage(translations.step1title)}</p>
          <p className={styles.stepDescription}>
            {intl.formatMessage(translations.step1description, {
              boldThis: RTFBold,
            })}
          </p>
        </div>
        <div className={styles.step}>
          <img
            src={step2img}
            className={styles.stepImg}
            alt=""
          />
          <p className={styles.stepTitle}>{intl.formatMessage(translations.step2title)}</p>
          <p className={styles.stepDescription}>
            {intl.formatMessage(translations.step2description, {
              boldThis: RTFBold,
            })}
          </p>
        </div>
        <div className={styles.step}>
          <img
            src={step3img}
            className={styles.stepImg}
            alt=""
          />
          <p className={styles.stepTitle}>{intl.formatMessage(translations.step3title)}</p>
          <p className={styles.stepDescription}>
            {intl.formatMessage(translations.step3description, {
              boldThis: RTFBold,
            })}
          </p>
        </div>
      </ModalBody>
      <ModalFooter
        cancelText=""
        submitText={intl.formatMessage(translations.confirmButton)}
        submitFunction={close}
        className={styles.modalFooter}
        hideCancelButton
      />
    </Modal>
  );
}
