import React from 'react';
import classnames from 'classnames';

import styles from './FullWidthContainer.module.scss';

interface FullWidthContainerProps {
  children: React.ReactNode;
  className?: string;
}
function FullWidthContainer({ children, className }: FullWidthContainerProps) {
  return <div className={classnames(styles.fullWidthContainer, className)}>{children}</div>;
}

export default FullWidthContainer;
