import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { parseURLSearch } from '../../../../../utils/routes';

export default function useFiltersParameter() {
  const location = useLocation();

  const filtersProgramIds = useMemo(() => {
    const urlProgramIds = parseURLSearch<{ programIds?: string }>(location.search)
      .programIds?.split(',')
      .filter((value) => !!value);
    return (urlProgramIds?.length || 0) > 0 ? urlProgramIds : null;
  }, [location.search]);

  const archivedParam = useMemo(() => {
    const archived = parseURLSearch<{ includeArchived?: string }>(location.search).includeArchived;
    return archived !== 'false';
  }, [location.search]);

  return {
    programIds: filtersProgramIds,
    includeArchived: archivedParam,
  };
}
