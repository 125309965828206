import { gql } from '@apollo/client';

export const GET_PROGRAM_PARTICIPATION = gql`
  query GET_PROGRAM_PARTICIPATION($id: ID!) {
    participation(id: $id) {
      id
      user {
        id
        firstName
        lastName
        email
        avatarUrl
        profileCompletionPourcentage
      }
      program {
        id
        name
        meetingsQuantity
        project {
          id
          name
        }
      }
      visitsCount
      bookmarkedProfessionalsCount
      conversationsCount
      unfollowedConversationCount
      remainingContactCount
      incomingMeetingCount
      waitingForConfirmationMeetingCount
      confirmedMeetingCount
      certificationStatus
      hermesContacts {
        appointmentContactMethod
        appointmentDate
        avatarUrl
        companyName
        companySector
        jobTitle
        professionalFirstName
        professionalUrl
        state
      }
    }
  }
`;
