import { gql } from '@apollo/client';
import { ProgramPublicationStatus } from '../../@types/graphql';

export const GET_SIDEBAR_PROJECT_TREE = gql`
  fragment TreeNavProgram on Program {
    id
    name
    canShow {
      value
    }
    canUpdate {
      value
    }
  }

  fragment TreeNavGroup on Group {
    id
    name
    canUpdate {
      value
    }
    canCreateGroup {
      value
    }
    canDelete {
      value
    }
    programs(filter: {
      query: {
        filterBy: programPublicationStatus,
        operator: eq,
        value: "${ProgramPublicationStatus.Published}"
      }
    }) {
      nodes {
        ...TreeNavProgram
      }
    }
    project {
      id
    }
  }

  query GET_SIDEBAR_PROJECT_TREE {
    projects {
      nodes {
        id
        name
        myRole
        directPrograms(filter: {
          query: {
            filterBy: programPublicationStatus,
            operator: eq,
            value: "${ProgramPublicationStatus.Published}"
          }
        }) {
          nodes {
            ...TreeNavProgram
          }
        }
        directGroups {
          nodes {
            ...TreeNavGroup
            groups {
              nodes {
                ...TreeNavGroup
                groups {
                  nodes {
                    ...TreeNavGroup
                    groups {
                      nodes {
                        ...TreeNavGroup
                        groups {
                          nodes {
                            ...TreeNavGroup
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
