import React from 'react';
import PageCenter from '../../layout/pages/page-center/PageCenter';

import styles from './PageError.module.scss';

interface PageErrorProps {
  refetch?: () => void;
}
function PageError({ refetch }: PageErrorProps) {
  return (
    <PageCenter>
      <div className={styles.pageError}>
        <p className={styles.description}>Erreur de chargement...</p>

        {refetch && (
          <button
            type="button"
            onClick={() => refetch()}
            className={styles.button}
          >
            Réessayer
          </button>
        )}
      </div>
    </PageCenter>
  );
}

export default PageError;
