import { ApolloError } from '@apollo/client';
import { GraphQLError } from 'graphql';

export enum MJGGraphQLErrorCode {
  'BAD_USER_INPUT' = 'BAD_USER_INPUT',
  'UNAUTHENTICATED' = 'UNAUTHENTICATED',
}

export interface MJGValidationError<GraphQLInputFields extends { [key: string]: any }, ERRORS extends string | number>
  extends GraphQLError {
  readonly extensions: {
    code?: MJGGraphQLErrorCode.BAD_USER_INPUT;
    validationErrors?: {
      [fieldName in keyof GraphQLInputFields]: ERRORS[];
    };
  };
}

export interface MJGClassicError<ERRORS extends string | number> extends GraphQLError {
  readonly extensions:
    | {
        code?: Exclude<ERRORS, MJGGraphQLErrorCode.BAD_USER_INPUT>;
      }
    | undefined;
}

export interface MJGApolloError<GraphQLInputFields extends { [key: string]: any }, ERRORS extends string | number>
  extends ApolloError {
  graphQLErrors: ReadonlyArray<MJGValidationError<GraphQLInputFields, ERRORS> | MJGClassicError<ERRORS>>;
}

export enum CREATE_PROJECT_ERRORS {
  PROJECT_NAME_TAKEN = 'PROJECT_NAME_TAKEN',
}

export enum READ_PROJECT_ERRORS {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum UPDATE_PROJECT_ERRORS {
  PROJECT_NAME_TAKEN = 'PROJECT_NAME_TAKEN',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum ARCHIVE_PROJECT_ERRORS {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum RECOVER_PROJECT_ERRORS {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum CREATE_GROUP_ERROR {
  UNAUTHORIZED = 'UNAUTHORIZED',
  GROUP_NAME_TAKEN = 'GROUP_NAME_TAKEN',
}

export enum UPDATE_GROUP_ERROR {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  GROUP_NAME_TAKEN = 'GROUP_NAME_TAKEN',
  GROUP_PARENT_CANNOT_PARENT_TO_SELF = 'GROUP_PARENT_CANNOT_PARENT_TO_SELF',
  GROUP_PARENT_CANNOT_PARENT_TO_CHILD = 'GROUP_PARENT_CANNOT_PARENT_TO_CHILD',
}

export enum DELETE_GROUP_ERROR {
  UNAUTHORIZED = 'UNAUTHORIZED',
  NOT_FOUND = 'NOT_FOUND',
}

export enum CREATE_INVITE_ERROR {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  INVITEEMAILTOPROJECTSERVICE_EMAIL_TAKEN = 'INVITEEMAILTOPROJECTSERVICE_EMAIL_TAKEN',
  INVITEEMAILTOPROJECTSERVICE_EMAIL_INVALID = 'INVITEEMAILTOPROJECTSERVICE_EMAIL_INVALID',
  INVITEEMAILTOPROJECTSERVICE_AUTHOR_BLANK = 'INVITEEMAILTOPROJECTSERVICE_AUTHOR_BLANK',
  INVITEEMAILTOPROJECTSERVICE_MEMBERSHIP_ALREADY_EXISTS = 'INVITEEMAILTOPROJECTSERVICE_MEMBERSHIP_ALREADY_EXISTS',
}

export enum UPDATE_INVITATION_ERROR {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum DELETE_INVITATION_ERROR {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
}

export enum UPDATE_MEMBERSHIP_ERROR {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  MEMBERSHIP_ROLE_PROJECT_WITHOUT_OWNER = 'MEMBERSHIP_ROLE_PROJECT_WITHOUT_OWNER',
}

export enum DELETE_MEMBERSHIP_ERROR {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  MEMBERSHIP_ROLE_PROJECT_OWNER_CANNOT_LEAVE = 'MEMBERSHIP_ROLE_PROJECT_OWNER_CANNOT_LEAVE',
}

export enum UPDATE_PROGRAM_ERROR {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  PROGRAM_NAME_TAKEN = 'PROGRAM_NAME_TAKEN',
  PROGRAM_GROUP_IS_FROM_ANOTHER_PROJECT = 'PROGRAM_GROUP_IS_FROM_ANOTHER_PROJECT',
  PROGRAM_NAME_BLANK = 'PROGRAM_NAME_BLANK',
  PROGRAM_DESCRIPTION_BLANK = 'PROGRAM_DESCRIPTION_BLANK',
  PROGRAM_PROGRAM_OPTIONS_UNKNOWN_PARAM = 'PROGRAM_PROGRAM_OPTIONS_UNKNOWN_PARAM',
  PROGRAM_MINIMAL_DAYS_DURATION_BELOW_MINIMAL_DAYS_DURATION = 'PROGRAM_MINIMAL_DAYS_DURATION_BELOW_MINIMAL_DAYS_DURATION',
  PROGRAM_START_AT_START_AT_AFTER_END_AT = 'PROGRAM_START_AT_START_AT_AFTER_END_AT',
  PROGRAM_END_AT_END_AT_BEFORE_START_AT = 'PROGRAM_END_AT_END_AT_BEFORE_START_AT',
}

export enum PUBLISH_PROGRAM_ERROR {
  NOT_FOUND = 'NOT_FOUND',
  UNAUTHORIZED = 'UNAUTHORIZED',
  PROGRAM_PROGRAM_PUBLICATION_STATUS_ALREADY_PUBLISHED = 'PROGRAM_PROGRAM_PUBLICATION_STATUS_ALREADY_PUBLISHED',
  PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_START_AT = 'PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_START_AT',
  PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_END_AT = 'PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_END_AT',
  PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_EXPECTED_PARTICIPATIONS = 'PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_EXPECTED_PARTICIPATIONS',
  PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MEETING_PROGRAM = 'PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MEETING_PROGRAM',
  PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MEETINGS_QUANTITY = 'PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MEETINGS_QUANTITY',
  PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MINIMAL_DAYS_DURATION = 'PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MINIMAL_DAYS_DURATION',
}
