export function download(uri: string, filename: string) {
  window
    .fetch(uri, {
      mode: 'cors',
      credentials: 'include',
    })
    .then((res) => res.blob())
    .then((response) => {
      const url = window.URL.createObjectURL(response);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
}
