import { Group, Maybe, Program, Project } from '../../../../../../../../@types/graphql';

type BaseProgram = Pick<Program, 'id' | '__typename'>;
type BaseGroup = Pick<Group, 'id' | '__typename'> & {
  groups?: Maybe<{
    nodes: Array<BaseGroup>;
  }>;
  programs?: Maybe<{
    nodes: Array<BaseProgram>;
  }>;
};
type BaseProject = Pick<Project, 'id' | '__typename'> & {
  directGroups?: Maybe<{
    nodes: Array<BaseGroup>;
  }>;
  directPrograms?: Maybe<{
    nodes: Array<BaseProgram>;
  }>;
};

export function removeTreeElement(object: BaseProject | BaseGroup, id: string): BaseProject | BaseGroup {
  if (object.__typename === 'Project') {
    let index = object.directGroups?.nodes.findIndex((node) => node.id === id) ?? -1;
    if (index > -1) {
      return {
        ...object,
        directGroups: {
          ...(object.directGroups || {}),
          nodes: [
            ...(object.directGroups?.nodes.slice(0, index) || []),

            ...(object.directGroups?.nodes.slice(index + 1, object.directGroups?.nodes.length) || []),
          ],
        },
      };
    }

    index = object.directPrograms?.nodes.findIndex((node) => node.id === id) ?? -1;
    if (index > -1) {
      return {
        ...object,
        directPrograms: {
          ...(object.directPrograms || {}),
          nodes: [
            ...(object.directPrograms?.nodes.slice(0, index) || []),

            ...(object.directPrograms?.nodes.slice(index + 1, object.directPrograms?.nodes.length) || []),
          ],
        },
      };
    }

    return {
      ...object,
      directGroups: {
        ...(object.directGroups || {}),
        nodes: object.directGroups?.nodes.map((node) => removeTreeElement(node, id)),
      },
    } as BaseProject;
  }

  if (object.__typename === 'Group') {
    let index = object.groups?.nodes.findIndex((node) => node.id === id) ?? -1;
    if (index > -1) {
      return {
        ...object,
        groups: {
          ...(object.groups || {}),
          nodes: [
            ...(object.groups?.nodes.slice(0, index) || []),
            ...(object.groups?.nodes.slice(index + 1, object.groups?.nodes.length) || []),
          ],
        },
      };
    }

    index = object.programs?.nodes.findIndex((node) => node.id === id) ?? -1;
    if (index > -1) {
      return {
        ...object,
        programs: {
          ...(object.programs || {}),
          nodes: [
            ...(object.programs?.nodes.slice(0, index) || []),
            ...(object.programs?.nodes.slice(index + 1, object.programs?.nodes.length) || []),
          ],
        },
      };
    }

    return {
      ...object,
      groups: {
        ...(object.groups || {}),
        nodes: object.groups?.nodes.map((node) => removeTreeElement(node, id)),
      },
    } as BaseGroup;
  }

  /* istanbul ignore next */
  return object;
}

export function addItemToGroup<T extends BaseProject | BaseGroup>(
  object: T,
  item: BaseGroup | BaseProgram,
  parentId: string | null,
): T {
  if (object.__typename === 'Group') {
    const group = object as BaseGroup;

    if (group.id === parentId) {
      if (item.__typename === 'Program') {
        return {
          ...group,
          programs: {
            ...(group.programs || {}),
            nodes: [...(group.programs?.nodes || []), item],
          },
        } as T;
      }

      if (item.__typename === 'Group') {
        return {
          ...group,
          groups: {
            ...(group.groups || {}),
            nodes: [...(group.groups?.nodes || []), item],
          },
        } as T;
      }
    } else {
      return {
        ...group,
        groups: {
          ...(group.groups || {}),
          nodes: group.groups?.nodes.map((node) => addItemToGroup(node, item, parentId)),
        },
      } as T;
    }
  }

  if (object.__typename === 'Project') {
    const project = object as BaseProject;

    if (parentId) {
      return {
        ...project,
        directGroups: {
          ...(project.directGroups || {}),
          nodes:
            project.directGroups?.nodes.map((groupNode) => addItemToGroup(groupNode, item, parentId) as BaseGroup) ||
            [],
        },
      } as T;
    }
    if (item.__typename === 'Group') {
      return {
        ...project,
        directGroups: {
          ...(project.directGroups || {}),
          nodes: [...(project.directGroups?.nodes || []), item],
        },
      } as T;
    }
    if (item.__typename === 'Program') {
      return {
        ...project,
        directPrograms: {
          ...(project.directPrograms || {}),
          nodes: [...(project.directPrograms?.nodes || []), item],
        },
      } as T;
    }
  }

  /* istanbul ignore next */
  return object;
}
