import classnames from 'classnames';
import Icon from '../../../../../../../../components/icon/Icon';

import styles from './ProjectStatisticTopFive.module.scss';

interface ProjectStatisticTopFiveProps {
  title: string;
  data: Array<{
    label: string;
  }>;
  noData?: boolean;
  noDataTitle: string;
  noDataDescription: string;
  className?: string;
}
export default function ProjectStatisticTopFive({
  title,
  data,
  noData,
  noDataTitle,
  noDataDescription,
  className,
}: ProjectStatisticTopFiveProps) {
  return (
    <div className={classnames(styles.bloc, className)}>
      <h4 className={styles.title}>{title}</h4>
      {noData ? (
        <div className={styles.noData}>
          <Icon
            name="format_list_numbered"
            className={styles.noDataIcon}
          />
          <h5 className={styles.noDataTitle}>{noDataTitle}</h5>
          <p className={styles.noDataDescription}>{noDataDescription}</p>
        </div>
      ) : (
        <ul className={styles.topFive}>
          {data.map((item, k) => (
            <li
              key={item.label}
              className={styles.item}
            >
              <span className={styles.number}>{k + 1}</span>
              <span className={styles.label}>{item.label}</span>
            </li>
          ))}
          {Array.from({ length: 5 - data.length }).map((_, k) => (
            <li
              key={k}
              className={styles.item}
            >
              <span className={styles.number}>{data.length + k + 1}</span>
              <span className={styles.label}>-</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}
