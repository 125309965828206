export function trimStart(str?: string, c: string = '\\s') {
  if (!str) {
    return '';
  }
  return str.replace(new RegExp(`^([${c}]*)(.*)$`), '$2');
}

export function getRandomArrayValue<T>(arr: T[]): T {
  return arr[Math.floor(Math.random() * arr.length)];
}
