import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../../../components/snackbar/hooks/useSnackbar';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import {
  Delegate_OwnershipMutation,
  Delegate_OwnershipMutationVariables,
  Edit_Invitation_RoleMutation,
  Edit_Invitation_RoleMutationVariables,
  Edit_Membership_RoleMutation,
  Edit_Membership_RoleMutationVariables,
  Invitation,
  InvitationRole,
  Membership,
  Role,
} from '../../../../../../@types/graphql';
import { SchoolRoutes } from '../../../../../../../../Routes';
import { handleGraphqlErrorsOnFormik } from '../../../../../../../../utils/formik';
import commonErrorsTranslations from '../../../../../../commons/translations/errors.translations';
import { DELEGATE_OWNERSHIP, EDIT_INVITATION_ROLE, EDIT_MEMBERSHIP_ROLE } from './MemberEditModal.gql';
import translations from './MemberEditModal.translations';

interface FormikMembershipFields {
  role: Role;
}
type OnSubmitMembership = (
  values: FormikMembershipFields,
  formikBag: FormikHelpers<FormikMembershipFields>,
) => Promise<void>;
function useMembershipEditMutation(membershipId?: string): OnSubmitMembership {
  const intl = useIntl();
  const navigate = useNavigate();
  const { projectid } = useParams();
  const { success } = useSnackbar();

  const [editRole] = useMutation<Edit_Membership_RoleMutation, Edit_Membership_RoleMutationVariables>(
    EDIT_MEMBERSHIP_ROLE,
  );

  const [delegateOwnership] = useMutation<Delegate_OwnershipMutation, Delegate_OwnershipMutationVariables>(
    DELEGATE_OWNERSHIP,
    {
      refetchQueries: ['GET_PROJECT_INFO'],
      awaitRefetchQueries: true,
    },
  );

  return function onSubmit(values: FormikMembershipFields, formikBag: FormikHelpers<FormikMembershipFields>) {
    return (
      values.role === Role.Owner
        ? delegateOwnership({
            variables: {
              id: membershipId || '',
            },
          })
        : editRole({
            variables: {
              id: membershipId || '',
              role: values.role,
            },
          })
    )
      .then(() => {
        success(intl.formatMessage(translations.success));
        navigate(generatePath(SchoolRoutes.projectMembers, { projectid: projectid || '' }));
      })
      .catch((err) =>
        handleGraphqlErrorsOnFormik(err, formikBag, {
          translations: {
            default: intl.formatMessage(commonErrorsTranslations.default),
            unknown: intl.formatMessage(commonErrorsTranslations.default),
            network: intl.formatMessage(commonErrorsTranslations.network),
          },
        }),
      );
  };
}

interface FormikInvitationFields {
  role: InvitationRole;
}
type OnSubmitInvitation = (
  values: FormikInvitationFields,
  formikBag: FormikHelpers<FormikInvitationFields>,
) => Promise<void>;
function useInvitationEditMutation(invitationId?: string): OnSubmitInvitation {
  const intl = useIntl();
  const navigate = useNavigate();
  const { projectid } = useParams();
  const { success } = useSnackbar();

  const [editRole] = useMutation<Edit_Invitation_RoleMutation, Edit_Invitation_RoleMutationVariables>(
    EDIT_INVITATION_ROLE,
  );

  return function onSubmit(values: FormikInvitationFields, formikBag: FormikHelpers<FormikInvitationFields>) {
    return editRole({
      variables: {
        id: invitationId || '',
        role: values.role,
      },
    })
      .then(() => {
        success(intl.formatMessage(translations.success));
        navigate(generatePath(SchoolRoutes.projectMembers, { projectid: projectid || '' }));
      })
      .catch((err) =>
        handleGraphqlErrorsOnFormik(err, formikBag, {
          translations: {
            default: intl.formatMessage(commonErrorsTranslations.default),
            unknown: intl.formatMessage(commonErrorsTranslations.default),
            network: intl.formatMessage(commonErrorsTranslations.network),
          },
        }),
      );
  };
}

export function useEditRoleMutation<R extends Membership | Invitation>(
  resourceType: R['__typename'],
  resourceId?: string,
): (
  values: R extends Membership ? FormikMembershipFields : FormikInvitationFields,
  formikBag: FormikHelpers<R extends Membership ? FormikMembershipFields : FormikInvitationFields>,
) => Promise<void> {
  const onSubmitMembershipUpdate = useMembershipEditMutation(resourceId);
  const onSubmitInvitationUpdate = useInvitationEditMutation(resourceId);

  if (resourceType === 'Membership') {
    return onSubmitMembershipUpdate as any;
  }

  if (resourceType === 'Invitation') {
    return onSubmitInvitationUpdate as any;
  }

  /* istanbul ignore next */
  return () => Promise.reject as any;
}
