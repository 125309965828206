import { ApolloClient, InMemoryCache } from '@apollo/client';
import { relayStylePagination } from '@apollo/client/utilities';
import { onError } from '@apollo/client/link/error';
import { ApolloLink } from '@apollo/client/link/core';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import { MJGGraphQLErrorCode } from '../@types/graphql-errors';
import * as Sentry from '@sentry/react';  

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors?.find((error) => error.extensions?.code === MJGGraphQLErrorCode.UNAUTHENTICATED)) {
    window.location.href = `${import.meta.env.REACT_APP_ACCOUNT_FRONTEND_URL}?redirect=${encodeURIComponent(window.location.href)}`;
  }
});

const uploadLink = createUploadLink({
  uri: `${import.meta.env.REACT_APP_BACKEND_API_URL}/graphql`,
  credentials: 'include',
}) as unknown as ApolloLink;

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          participationsAndInvitations: relayStylePagination(),
        },
      },
    },
  }),
  link: ApolloLink.from([errorLink, uploadLink]),
});

export default client;
