import { defineMessages } from 'react-intl';

export const errorTranslations = defineMessages({
  requiredSchoolName: {
    id: 'CreateGouvSchoolModal.requiredSchoolName',
    defaultMessage: 'Veuillez indiquer un nom d\'établissement.',
  },
  requiredLocation: {
    id: 'CreateGouvSchoolModal.requiredLocation',
    defaultMessage: 'Veuillez sélectionner une localisation.',
  },

});

export default defineMessages({
  title: {
    id: 'CreateGouvSchoolModal.title',
    defaultMessage: 'Ajouter un nouvel établissement',
  },
  schoolLabel: {
    id: 'CreateGouvSchoolModal.schoolLabel',
    defaultMessage: 'Nom de votre établissement',
  },
  locationLabel: {
    id: 'CreateGouvSchoolModal.locationLabel',
    defaultMessage: 'Emplacement',
  },
  success: {
    id: 'CreateGouvSchoolModal.success',
    defaultMessage: 'École ajoutée au projet',
  }
});
