import { defineMessages } from 'react-intl';

export default defineMessages({
  targetTitle: {
    id: 'ProgramShowTargetProgressionWithParticipants.targetTitle',
    defaultMessage: 'Objectif',
  },
  targetDisplay: {
    id: 'ProgramShowTargetProgressionWithParticipants.targetDisplay',
    defaultMessage: '{x} rendez-vous',
  },
  description: {
    id: 'ProgramShowTargetProgressionWithParticipants.description',
    defaultMessage:
      '<boldThis>Nombre de participants ayant atteint l’objectif,</boldThis> soit <value>{x}%</value> des participants prévus',
  },
});
