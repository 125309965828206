import React, { useCallback, useEffect, useState } from 'react';
import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import Icon from '../../../../../../components/icon/Icon';
import DatePickerField from '../../../../../../components/fields/date-picker-field/DatePickerField';
import { ProgressionStepsChildrenProps } from '../../../../../../components/progression-steps/ProgressionSteps';
import { Field, FormikHelpers, FormikProvider, useFormik } from 'formik';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { matchPath, useMatch } from 'react-router-dom';
import {
  Get_GradesQuery,
  Get_GradesQueryVariables,
  Get_Program_To_CustomizeQuery,
  Get_Program_To_CustomizeQueryVariables,
  TargetMeetingsCategories,
  TargetStudentsCategories,
} from '../../../../@types/graphql';
import { SchoolRoutes } from '../../../../../../Routes';
import GlobalErrorDisplay from '../../../../commons/global-error-display/GlobalErrorDisplay';
import PageLoading from '../../../../commons/page-loading/PageLoading';
import PageError from '../../../../commons/page-error/PageError';
import commonButtonsTranslations from '../../../../commons/translations/buttons.translations';
import commonTargetMeetingsCategories from '../../../../commons/translations/targetMeetings.translations';
import commonTargetStudentsCategories from '../../../../commons/translations/targetStudents.translations';
import { CoachingType } from '../../@types/coaching-type';
import { StepsContext } from '../../@types/steps-context';
import DateRangeWatcher from './components/date-range-watcher/DateRangeWatcher';
import useCancelProgramCreationAction from './hooks/useCancelProgramCreationAction';
import useProgramCustomizationAction, { CustomizeProgramFormikField } from './hooks/useProgramCustomizationAction';
import { GET_PROGRAM_TO_CUSTOMIZE, GET_GRADES } from './ProgramConfigurationView.gql';
import translations, { GradeTranslations } from './ProgramConfigurationView.translations';
import validationSchema from './ProgramConfigurationView.validations';

import styles from './ProgramConfigurationView.module.scss';
import TextInput from '../../../../../../components/text-input/TextInput';
import SelectInput from '../../../../../../components/select-input/SelectInput';
import NumberInput from '../../../../../../components/number-input/NumberInput';
import TextAreaInput from '../../../../../../components/text-area-input/TextAreaInput';
import RemovableTag from '../../../../../../components/removable-tag/RemovableTag';
import StaticAutocompleteMultiSelectInput from '../../../../../../components/static-autocomplete-multi-select-input/StaticAutocompleteMultiSelectInput';
import { GET_ME } from '../../../homepage/Homepage.gql';

export default function ProgramConfigurationView({
  onNextStep,
  onPreviousStep,
  context,
}: ProgressionStepsChildrenProps<StepsContext>) {
  const intl = useIntl();
  const [gradeOptions, setGradeOptions] = useState<{ value: string; translation: string }[]>([]);
  const [managers, setManagers] = useState<{ value: string; translation: string }[]>([]);
  const cancelProgramCreation = useCancelProgramCreationAction(context?.programId);
  const customizeProgram = useProgramCustomizationAction(context?.programId);
  const match = !!useMatch(SchoolRoutes.programUpdate);

  const { data, loading, error, refetch } = useQuery<
    Get_Program_To_CustomizeQuery,
    Get_Program_To_CustomizeQueryVariables
  >(GET_PROGRAM_TO_CUSTOMIZE, {
    skip: !context?.programId,
    variables: {
      id: context?.programId || '',
    },
  });

  const { data: user } = useQuery(GET_ME);

  function onSubmit(values: CustomizeProgramFormikField, formikBag: FormikHelpers<CustomizeProgramFormikField>) {
    return customizeProgram(values, formikBag, () => onNextStep({ ...context, ...values }));
  }

  function onCancel() {
    cancelProgramCreation();
    onPreviousStep({
      ...context,
      collaboratorsIds: undefined,
      programId: undefined,
    });
  }

  const schoolOptions = useMemo(
    () =>
      data?.program?.schools?.nodes?.map((node) => ({
        value: node?.id || '',
        translation: node?.name || '',
      })) || [],
    [data?.program?.schools],
  );

  const managerOptions = useMemo(
    () =>
      data?.program?.project?.memberships?.nodes?.map((node) => ({
        parent: '',
        value: node?.user.id,
        translation: `${node?.user.firstName} ${node?.user.lastName}`,
      })) || [],
    [data?.program?.project?.memberships],
  );

  const professionalOptions = useMemo(
    () =>
      [TargetMeetingsCategories.Professionals, TargetMeetingsCategories.Students, TargetMeetingsCategories.Any].map(
        (category) => ({
          value: category,
          translation: intl.formatMessage(
            commonTargetMeetingsCategories[category as keyof typeof commonTargetMeetingsCategories],
          ),
        }),
      ),
    [],
  );

  const studentOptions = useMemo(
    () =>
      [
        TargetStudentsCategories.AllStudents,
        TargetStudentsCategories.University,
        TargetStudentsCategories.HighSchool,
        TargetStudentsCategories.MiddleSchool,
      ].map((category) => ({
        value: category,
        translation: intl.formatMessage(
          commonTargetStudentsCategories[category as keyof typeof commonTargetStudentsCategories],
        ),
      })),
    [],
  );

  const minimalDaysDuration = data?.program?.programTemplate?.templateOptions?.minimalDaysDuration || 30;

  const dateFormat = useMemo(
    () => intl.formatDate('1970-11-22').replace('22', 'DD').replace('11', 'MM').replace('1970', 'YYYY'),
    [],
  );

  const formik = useFormik<CustomizeProgramFormikField>({
    initialValues: {
      name: context?.name || '',
      school: data?.program?.schools?.nodes?.[0]?.id || '',
      grade: data?.program?.grade || undefined,
      diploma: data?.program?.diploma || '',
      startAt: context?.startAt || data?.program?.startAt || null,
      endAt: context?.endAt || data?.program?.endAt || null,
      collaboratorsIds:
        context?.collaboratorsIds || [] || data?.program?.collaborations?.nodes?.map((node) => node?.user.id) || [],
      expectedParticipations:
        (context?.coachingType === CoachingType.Individual ? 1 : context?.expectedParticipations) ||
        data?.program?.expectedParticipations ||
        '',
      targetMeetingsCategories: data?.program?.targetMeetingsCategories || TargetMeetingsCategories.Professionals,
      targetStudentsCategories: data?.program?.targetStudentsCategories || TargetStudentsCategories.AllStudents,
      description: context?.description || data?.program?.description || '',
    },
    onSubmit: onSubmit,
    validationSchema: validationSchema(intl),
  });

  const handleManagers = useCallback(
    (manager: any) => {
      if (!formik.values.collaboratorsIds.includes(manager.value)) {
        formik.setFieldValue('collaboratorsIds', [...formik.values.collaboratorsIds, manager.value]);
        setManagers((prev) => [...prev, { value: manager.value, translation: manager.translation }]);
      } else {
        formik.setFieldValue(
          'collaboratorsIds',
          formik.values.collaboratorsIds.filter((id) => id !== manager.value),
        ),
          setManagers((prev) => prev.filter((prevManager) => prevManager.value !== manager.value));
      }
    },
    [formik.values.collaboratorsIds],
  );

  const { data: grades } = useQuery<Get_GradesQuery, Get_GradesQueryVariables>(GET_GRADES, {
    skip: !formik.values.school,
    variables: {
      id: formik.values.school || '',
    },
  });

  const showSchoolForm = user?.me?.primarySituation != 'JOB_SEEKER_SUPPORT' && data?.program?.schools?.nodes?.length;

  useMemo(() => {
    if (data?.program?.collaborations?.nodes) {
      const managers = data.program.collaborations.nodes.map((node) => ({
        value: node?.user.id || '',
        translation: `${node?.user.firstName} ${node?.user.lastName}`,
      }));
      formik.setFieldValue(
        'collaboratorsIds',
        data?.program?.collaborations?.nodes?.map((node) => node?.user.id),
      );
      setManagers(managers);
    }
  }, [data?.program]);

  useEffect(() => {
    if (Array.isArray(grades?.grades)) {
      const options = grades?.grades?.map((grade) => ({
        value: grade,
        translation: intl.formatMessage(GradeTranslations[grade]),
      }));
      setGradeOptions(options);
    } else {
      setGradeOptions([]);
    }
  }, [grades?.grades]);

  useEffect(() => {
    if (match) {
      formik.setFieldValue('school', data?.program?.school);
      formik.setFieldValue('grade', data?.program?.grade);
      formik.setFieldValue('diploma', data?.program?.diploma);
      formik.setFieldValue('startAt', data?.program?.startAt);
      formik.setFieldValue('expectedParticipations', data?.program?.expectedParticipations);
      formik.setFieldValue('description', data?.program?.description);
    }
  }, [data?.program])

  if (loading) {
    return <PageLoading />;
  }

  if (error) {
    return <PageError refetch={refetch} />;
  }

  return (
    <section>
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
          <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
          <p className={styles.information}>
            <Icon
              name="info"
              className={styles.informationIcon}
            />
            {intl.formatMessage(translations.information)}
          </p>
          <div className={styles.fieldset}>
            <label
              id="name_field"
              htmlFor="name"
              className={styles.label}
            >
              {intl.formatMessage(translations.nameLabel)}
            </label>
            <p className={styles.labelSubtitle}>{intl.formatMessage(translations.nameHelper)}</p>
            <TextInput
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
          </div>
          {showSchoolForm && (
            <>
              <div className={styles.fieldset}>
                <label
                  id="school_field"
                  htmlFor="school"
                  className={styles.label}
                >
                  {intl.formatMessage(translations.schoolLabel)}
                </label>
                <p className={styles.labelSubtitle}>{intl.formatMessage(translations.schoolHelper)}</p>
                <SelectInput
                  name="school"
                  values={schoolOptions}
                  value={formik.values.school}
                  onChange={(school) => formik.setFieldValue(`school`, school)}
                />
              </div>
              <div className={styles.fieldset}>
                <label
                  id="grade_field"
                  htmlFor="grade"
                  className={styles.label}
                >
                  {intl.formatMessage(translations.gradeLabel)}
                </label>
                <p className={styles.labelSubtitle}>{intl.formatMessage(translations.gradeHelper)}</p>
                <SelectInput
                  name="grade"
                  values={gradeOptions}
                  disabled={gradeOptions.length === 0}
                  value={formik.values.grade}
                  onChange={(grade) => formik.setFieldValue(`grade`, grade)}
                />
              </div>
              <div className={styles.fieldset}>
                <label
                  id="diploma_field"
                  htmlFor="diploma"
                  className={styles.label}
                >
                  {intl.formatMessage(translations.diplomaLabel)}
                </label>
                <p className={styles.labelSubtitle}>{intl.formatMessage(translations.diplomaHelper)}</p>
                <TextInput
                  name="diploma"
                  onChange={formik.handleChange}
                  value={formik.values.diploma}
                />
              </div>
            </>
          )}
          <div className={styles.fieldset}>
            <label
              id="date_field"
              htmlFor="startAt"
              className={styles.label}
            >
              {intl.formatMessage(translations.dateLabel)}
            </label>
            <p className={styles.labelSubtitle}>
              {intl.formatMessage(translations.programTemplateRestrictions, {
                programTemplateName: data?.program?.programTemplate.name,
                minimalMonthDuration: Math.round(minimalDaysDuration / 30),
                br: () => <br />,
              })}
            </p>
            <div className={styles.fieldsetRow}>
              <Field
                name="startAt"
                component={DatePickerField}
                minDate={moment()}
                displayFormat={dateFormat}
                label={intl.formatMessage(translations.startDatePlaceholder)}
                disabled={moment(formik.values.startAt).isBefore(moment())}
              />
              <DateRangeWatcher minRange={minimalDaysDuration} />
              <Field
                name="endAt"
                component={DatePickerField}
                label={intl.formatMessage(translations.endDatePlaceholder)}
                minDate={moment(formik.values.startAt)
                  .add(minimalDaysDuration, 'days')
                  .hour(0)
                  .minutes(0)
                  .second(0)
                  .millisecond(0)}
                displayFormat={dateFormat}
                disabled={!formik.values.startAt}
              />
            </div>
          </div>
          <div className={styles.fieldset}>
            <label
              id="collaborators_field"
              htmlFor="collaboratorsIds"
              className={styles.label}
            >
              {intl.formatMessage(translations.programManagerLabel)}
            </label>
            <p className={styles.labelSubtitle}>{intl.formatMessage(translations.programManagerHelper)}</p>

            <StaticAutocompleteMultiSelectInput
              name="collaboratorsIds"
              values={managerOptions}
              onChange={handleManagers}
              selectedValues={formik.values.collaboratorsIds || []}
              allowSelectAll={false}
            />
            {managers.length >= 1 && (
              <div className={styles.selectedManagers}>
                {managers?.map((manager, index) => (
                  <RemovableTag
                    key={index}
                    value={manager.value}
                    name={manager.translation}
                    onRemove={() => {
                      formik.setFieldValue(
                        'collaboratorsIds',
                        formik.values.collaboratorsIds.filter((id) => id !== manager.value),
                      );
                      setManagers((prev) => prev.filter((prevManager) => prevManager.value !== manager.value));
                    }}
                  />
                ))}
              </div>
            )}
          </div>
          <div className={styles.fieldset}>
            <label
              id="participations_field"
              htmlFor="expectedParticipations"
              className={styles.label}
            >
              {intl.formatMessage(translations.expectedParticipationsLabel)}
            </label>
            <NumberInput
              name="expectedParticipations"
              disabled={context?.coachingType === CoachingType.Individual}
              onChange={formik.handleChange}
              value={formik.values.expectedParticipations}
            />
          </div>
          <div className={styles.fieldset}>
            <label
              id="target_meetings_field"
              htmlFor="targetMeetingsCategories"
              className={styles.label}
            >
              {intl.formatMessage(translations.targetMeetingsLabel)}
            </label>
            <SelectInput
              name="targetMeetingsCategories"
              values={professionalOptions}
              value={formik.values.targetMeetingsCategories}
              onChange={(category) => {
                formik.setFieldValue('targetMeetingsCategories', category);
              }}
            />
          </div>
          {formik.values.targetMeetingsCategories == TargetMeetingsCategories.Students && (
            <div className={styles.fieldset}>
              <label
                id="target_students_field"
                htmlFor="targetStudentsCategories"
                className={styles.label}
              >
                {intl.formatMessage(translations.targetStudentsLabel)}
              </label>
              <SelectInput
                name="targetStudentsCategories"
                values={studentOptions}
                value={formik.values.targetStudentsCategories}
                onChange={(category) => {
                  formik.setFieldValue('targetStudentsCategories', category);
                }}
              />
            </div>
          )}
          <div className={styles.fieldset}>
            <label
              id="description_field"
              htmlFor="description"
              className={styles.label}
            >
              {intl.formatMessage(translations.descriptionLabel)}
            </label>
            <p className={styles.labelSubtitle}>{intl.formatMessage(translations.descriptionHelper)}</p>
            <TextAreaInput
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
            <p className={styles.programTemplateRestrictions}>
              {intl.formatMessage(translations.descriptionExample, {
                br: () => <br />,
                b: (text: string[]) => <b>{text}</b>,
              })}
            </p>
          </div>
          <GlobalErrorDisplay className={styles.errorDisplay} />
          <div className={styles.actions}>
            {!match && (
              <button
                type="button"
                onClick={onCancel}
                className={styles.previousButton}
              >
                {intl.formatMessage(commonButtonsTranslations.previous)}
              </button>
            )}
            <button
              type="submit"
              className={styles.nextButton}
              disabled={!formik.isValid || formik.isSubmitting}
              onClick={() => formik.handleSubmit}
            >
              {intl.formatMessage(commonButtonsTranslations.next)}
            </button>
          </div>
        </form>
      </FormikProvider>
    </section>
  );
}
