import { ApolloCache, gql, useApolloClient, useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../../../../../components/snackbar/hooks/useSnackbar';
import { useIntl } from 'react-intl';
import uniqid from 'uniqid';
import {
  Create_New_GroupMutation,
  Create_New_GroupMutationVariables,
  Group,
  GroupConnection,
  Maybe,
  Untitled_Groups_On_Groups_FragmentFragment,
  Untitled_Groups_On_Project_FragmentFragment,
} from '../../../../../../../../@types/graphql';
import { CREATE_GROUP_ERROR, MJGApolloError, MJGGraphQLErrorCode } from '../../../../../../../../@types/graphql-errors';
import commonErrorsTranslations from '../../../../../../../../commons/translations/errors.translations';
import {
  CREATE_NEW_GROUP,
  UNTITLED_GROUPS_ON_GROUPS_FRAGMENT,
  UNTITLED_GROUPS_ON_PROJECT_FRAGMENT,
} from './useAddGroupMutation.gql';
import translations, { errorTranslations } from './useAddGroupMutation.translations';

function getUntitledGroupName(
  cache: ApolloCache<object>,
  defaultUntitledGroupName: string,
  projectId?: string,
  parentId?: string,
) {
  let groups: Array<Pick<Group, 'id' | 'name'>> = [];
  if (parentId) {
    const result = cache.readFragment<Untitled_Groups_On_Groups_FragmentFragment>({
      id: `Group:${parentId}`,
      fragment: UNTITLED_GROUPS_ON_GROUPS_FRAGMENT,
    });
    groups = result?.groups?.nodes || [];
  } else {
    const result = cache.readFragment<Untitled_Groups_On_Project_FragmentFragment>({
      id: `Project:${projectId}`,
      fragment: UNTITLED_GROUPS_ON_PROJECT_FRAGMENT,
    });
    groups = result?.directGroups?.nodes || [];
  }

  const regex = new RegExp(`^${defaultUntitledGroupName} \\((\\d+)\\)`);
  const existingProjectUntitledValue = groups
    .map((group) => {
      if (group.name === defaultUntitledGroupName) {
        return '0';
      }
      return regex.exec(group.name)?.[1];
    })
    .filter(Boolean)
    .map((item) => +item!);
  const maxUntitledNumber = existingProjectUntitledValue.length ? Math.max(...existingProjectUntitledValue) : undefined;

  if (typeof maxUntitledNumber === 'number') {
    return `${defaultUntitledGroupName} (${maxUntitledNumber + 1})`;
  }

  return defaultUntitledGroupName;
}

export default function useAddGroupMutation(projectId?: string, parentId?: string) {
  const intl = useIntl();
  const { cache } = useApolloClient();
  const { error } = useSnackbar();

  const [addGroup] = useMutation<Create_New_GroupMutation, Create_New_GroupMutationVariables>(CREATE_NEW_GROUP);

  return () => {
    /* istanbul ignore next */
    if (!projectId) {
      return undefined;
    }

    const newGroupName = getUntitledGroupName(
      cache,
      intl.formatMessage(translations.untitledGroup),
      projectId,
      parentId,
    );

    function handleAddGroup(subs: Maybe<GroupConnection>, newGroup: Create_New_GroupMutation['createGroup']) {
      const newGroupInCache = cache.writeFragment({
        data: newGroup,
        fragment: gql`
          fragment NEW_GROUP_IN_CACHE on Group {
            id
            name
            canUpdate {
              value
            }
            canCreateGroup {
              value
            }
            canDelete {
              value
            }
            programs {
              nodes {
                id
              }
            }
            groups {
              nodes {
                id
              }
            }
          }
        `,
      });

      return {
        ...(subs || {}),
        __typename: 'GroupConnection',
        nodes: [...(subs?.nodes || []), newGroupInCache],
      };
    }
    return addGroup({
      variables: {
        projectId,
        parentId,
        name: newGroupName,
      },
      optimisticResponse: {
        createGroup: {
          id: uniqid(),
          __typename: 'Group',
          name: newGroupName,
          canUpdate: {
            __typename: 'AuthorizationResult',
            value: false,
          },
          canCreateGroup: {
            __typename: 'AuthorizationResult',
            value: false,
          },
          canDelete: {
            __typename: 'AuthorizationResult',
            value: false,
          },
          programs: {
            __typename: 'ProgramConnection',
            nodes: [],
          },
          groups: {
            __typename: 'GroupConnection',
            nodes: [],
          },
        },
      },
      update(_, { data }) {
        const newGroup = data?.createGroup;
        if (newGroup) {
          cache.modify({
            id: cache.identify({
              id: parentId || projectId,
              __typename: parentId ? 'Group' : 'Project',
            }),
            fields: {
              [parentId ? 'groups' : 'directGroups']: (subs: Maybe<GroupConnection>) => handleAddGroup(subs, newGroup),
            },
          });
        }
      },
    }).catch((err: MJGApolloError<{ name: string }, CREATE_GROUP_ERROR>) => {
      if (err.graphQLErrors?.[0]?.extensions?.code === CREATE_GROUP_ERROR.UNAUTHORIZED) {
        error(intl.formatMessage(errorTranslations[CREATE_GROUP_ERROR.UNAUTHORIZED]));
      } else if (
        err.graphQLErrors?.[0]?.extensions?.code === MJGGraphQLErrorCode.BAD_USER_INPUT &&
        err.graphQLErrors?.[0]?.extensions?.validationErrors?.name?.[0].includes(CREATE_GROUP_ERROR.GROUP_NAME_TAKEN)
      ) {
        error(intl.formatMessage(commonErrorsTranslations.technical));
      } else {
        error(intl.formatMessage(commonErrorsTranslations.default));
      }
    });
  };
}
