export function throttle(callback: (...args: any[]) => void, interval: number) {
  let enableCall = true;

  return function (...args: any[]) {
    if (!enableCall) return;

    enableCall = false;
    // @ts-ignore
    callback.apply(this, args);

    setTimeout(() => (enableCall = true), interval);
  };
}
