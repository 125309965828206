import React from 'react';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import translations from './ParticipationBloc.translations';

import styles from './ParticipationBloc.module.scss';

interface ParticipationBlocProps {
  title: string;
  className?: string;
  component: React.FunctionComponent<{ participationid: string }>;
  participationid: string;
  faqLink?: string;
}
export default function ParticipationBloc({
  title,
  className,
  component: Component,
  participationid,
  faqLink,
}: ParticipationBlocProps) {
  const intl = useIntl();

  return (
    <section className={classnames(styles.bloc, className)}>
      <header className={styles.header}>
        <h3 className={styles.title}>{title}</h3>

        {faqLink && (
          <a
            href={faqLink}
            target="_blank"
            rel="noopener noreferrer"
            className={styles.faqLink}
          >
            {intl.formatMessage(translations.faq)}
          </a>
        )}
      </header>
      <Component participationid={participationid} />
    </section>
  );
}
