import React from 'react';
import { useEffect } from 'react';
import Icon from '../../../../../../../../components/icon/Icon';
import Tooltip from '../../../../../../../../components/tooltip/Tooltip';
import { useIntl } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';
import useDropdown from '../../../../../../hooks/use-drop-down/useDropDown';
import { SchoolRoutes } from '../../../../../../../../Routes';
import { TreeNavGroupItem } from '../../../../@types/TreeNavComponent';
import TreeNavProgram from '../tree-nav-program/TreeNavProgram';
import translations from './TreeNavGroup.translations';

import styles from './TreeNavGroup.module.scss';

interface TreeNavGroupProps {
  myRole?: string;
  group: TreeNavGroupItem;
  level: number;
  openParent: () => void;
}
export default function TreeNavGroup({ myRole, group, level, openParent }: TreeNavGroupProps) {
  const intl = useIntl();
  const [isVisibleChildren, toggle, open] = useDropdown();

  useEffect(() => {
    if (isVisibleChildren) {
      openParent();
    }
  }, [isVisibleChildren]);

  const hasChildren = (group.groups?.nodes.length || 0) > 0 || (group.programs?.nodes.length || 0) > 0;

  return (
    <li
      data-testid={group.id}
      role="treeitem"
      aria-haspopup={hasChildren}
      aria-expanded={isVisibleChildren}
    >
      <div
        className={styles.treeNavGroup}
        style={{
          paddingLeft: 16 + (18 + 8) * level,
        }}
      >
        <button
          type="button"
          onClick={toggle}
          disabled={!hasChildren}
          className={styles.toggleChildrenButton}
        >
          <Icon
            name={isVisibleChildren ? 'arrow_drop_down' : 'arrow_right'}
            className={styles.arrow}
          />
        </button>
        <span className={styles.label}>
          <Icon
            name="folder"
            className={styles.icon}
          />
          {group.name}
        </span>
        {myRole !== 'guest' && (
          <Tooltip
            text={intl.formatMessage(translations.addProgram)}
            className={styles.addProgramTooltip}
            position="bottom-start"
          >
            {({ onMouseEnter, onMouseLeave, tooltipId }) => (
              <Link
                to={generatePath(SchoolRoutes.groupProgramCreate, {
                  projectid: group.project.id,
                  groupid: group.id,
                })}
                className={styles.addProgramButton}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                aria-describedby={tooltipId}
              >
                <Icon
                  name="add_box"
                  className={styles.icon}
                />
              </Link>
            )}
          </Tooltip>
        )}
      </div>
      <ul
        role="tree"
        hidden={!isVisibleChildren}
        className={styles.tree}
      >
        {group.groups?.nodes.map((subGroup) => (
          <TreeNavGroup
            key={subGroup.id}
            group={subGroup}
            myRole={myRole}
            level={level + 1}
            openParent={open}
          />
        ))}
        {group.programs?.nodes.map(
          (subProgram) =>
            subProgram?.canShow?.value && (
              <TreeNavProgram
                key={subProgram.id}
                program={subProgram}
                level={level + 1}
                openParent={open}
              />
            ),
        )}
      </ul>
    </li>
  );
}
