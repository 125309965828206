import { useEffect, useRef, useState } from 'react';

/**
@param value - The value to debounce
@param isNetworkLoading - A boolean indicating if the value is waiting for a network response
@param delay - The delay in milliseconds (default value is 500ms)
@return [debouncedValue, isLoading] - The debounced value and a boolean indicating if the value is loading
*/
export default function useDebounce<T>(value: T, delay = 500): [T, (isNetworkLoading: boolean) => boolean] {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [isDebouncing, setDebounceState] = useState(false);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    setDebounceState(debouncedValue !== value);

    timeoutRef.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);
  }, [value, timeoutRef?.current, delay, debouncedValue]);

  return [debouncedValue, (isNetworkLoading: boolean) => isDebouncing || isNetworkLoading];
}
