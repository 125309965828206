import classnames from 'classnames';
import Icon from '../../../icon/Icon';
import { AvatarSize } from '../../@types/avatar';

import styles from './AvatarIcon.module.scss';

interface AvatarIconProps {
  avatarIcon: string;
  size: AvatarSize;
  className?: string;
}
function AvatarIcon({ avatarIcon, size, className }: AvatarIconProps) {
  return (
    <span className={classnames(styles.avatarContainer, [styles[size]], className)}>
      <Icon
        name={avatarIcon}
        className={styles.icon}
      />
    </span>
  );
}

export default AvatarIcon;
