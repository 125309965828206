import { defineMessages } from 'react-intl';
import { ReviewNotation } from '../../@types/graphql';

const commonReviewNotation = defineMessages({
  [ReviewNotation.ReviewNotation_1]: {
    id: 'ReviewNotation.ReviewNotation_1',
    defaultMessage: '1/5',
  },
  [ReviewNotation.ReviewNotation_2]: {
    id: 'ReviewNotation.ReviewNotation_2',
    defaultMessage: '2/5',
  },
  [ReviewNotation.ReviewNotation_3]: {
    id: 'ReviewNotation.ReviewNotation_3',
    defaultMessage: '3/5',
  },
  [ReviewNotation.ReviewNotation_4]: {
    id: 'ReviewNotation.ReviewNotation_4',
    defaultMessage: '4/5',
  },
  [ReviewNotation.ReviewNotation_5]: {
    id: 'ReviewNotation.ReviewNotation_5',
    defaultMessage: '5/5',
  },
});

export default commonReviewNotation;
