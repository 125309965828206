import { useMutation } from '@apollo/client';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { handleGraphqlErrorsOnFormik } from '../../../../../../../utils/formik';
import {
  Customize_ProgramMutation,
  Customize_ProgramMutationVariables,
  GradeKeyEnum,
  TargetMeetingsCategories,
  TargetStudentsCategories,
} from '../../../../../@types/graphql';
import { UPDATE_PROGRAM_ERROR } from '../../../../../@types/graphql-errors';
import commonErrorsTranslations from '../../../../../commons/translations/errors.translations';
import { CUSTOMIZE_PROGRAM } from './useProgramCustomizationAction.gql';
import { errorTranslations } from '../ProgramConfigurationView.translations';

export interface CustomizeProgramFormikField {
  name: string;
  school?: string;
  grade?: GradeKeyEnum;
  diploma?: string;
  startAt: string | null;
  endAt: string | null;
  collaboratorsIds: Array<string>;
  expectedParticipations: number | string;
  description: string;
  targetMeetingsCategories: TargetMeetingsCategories;
  targetStudentsCategories: TargetStudentsCategories;
}
export default function useProgramCustomizationAction(programId?: string) {
  const intl = useIntl();

  const [customizeProgram] = useMutation<Customize_ProgramMutation, Customize_ProgramMutationVariables>(
    CUSTOMIZE_PROGRAM,
  );

  return function customize(
    values: CustomizeProgramFormikField,
    formikBag: FormikHelpers<CustomizeProgramFormikField>,
    onSuccess: () => void,
  ) {
    if (programId) {
      return customizeProgram({
        variables: {
          id: programId,
          name: values.name,
          school: values.school,
          grade: values.grade,
          diploma: values.diploma,
          startAt: values.startAt,
          endAt: values.endAt,
          collaboratorsIds: values.collaboratorsIds,
          expectedParticipations: +values.expectedParticipations,
          description: values.description,
          targetMeetingsCategories: values.targetMeetingsCategories,
          targetStudentsCategories: values.targetStudentsCategories,
        },
      })
        .then(onSuccess)
        .catch((error) =>
          handleGraphqlErrorsOnFormik(error, formikBag, {
            translations: {
              default: intl.formatMessage(commonErrorsTranslations.default),
              network: intl.formatMessage(commonErrorsTranslations.network),
              unknown: intl.formatMessage(commonErrorsTranslations.default),
              name: {
                [UPDATE_PROGRAM_ERROR.PROGRAM_NAME_BLANK]: intl.formatMessage(errorTranslations.programNameRequired),
                [UPDATE_PROGRAM_ERROR.PROGRAM_NAME_TAKEN]: intl.formatMessage(
                  errorTranslations.programNameAlreadyExist,
                ),
              },
              description: {
                [UPDATE_PROGRAM_ERROR.PROGRAM_DESCRIPTION_BLANK]: intl.formatMessage(
                  errorTranslations.descriptionRequired,
                ),
              },
            },
          }),
        );
    }
    /* istanbul ignore next */
    return Promise.reject();
  };
}
