import React from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Get_MeQuery, Get_MeQueryVariables } from '../../@types/graphql';
import { PageHeader } from '../../layout/context/HeaderContext';
import ProjectList from './components/project-list/ProjectList';
import { GET_ME } from './Homepage.gql';
import translations from './Homepage.translations';

import styles from './Homepage.module.scss';

function Homepage() {
  const intl = useIntl();
  const { data } = useQuery<Get_MeQuery, Get_MeQueryVariables>(GET_ME);

  return (
    <main className={styles.homepage}>
      <PageHeader hasBreadcrumb />

      <h1 className={styles.title}>{intl.formatMessage(translations.title, { firstName: data?.me?.firstName })}</h1>

      <ProjectList />
    </main>
  );
}

export default Homepage;
