import React, { createRef, useEffect, useMemo, useState } from 'react';
import { useQuery } from '@apollo/client';
import Icon from '../../../../../../../../components/icon/Icon';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
  Get_Project_Diagram_StructureQuery,
  Get_Project_Diagram_StructureQueryVariables,
} from '../../../../../../@types/graphql';
import DP01 from '../../../../../../commons/svg/shadow/DP01';
import DiagramBloc from './components/diagram-bloc/DiagramBloc';
import { GET_PROJECT_DIAGRAM_STRUCTURE } from './ProjectDiagramStructure.gql';
import translations from './ProjectDiagramStructure.translations';
import { countTreeLeaf, makeGroupHierarchyTreeDate } from './ProjectDiagramStructure.utils';
import styles from './ProjectDiagramStructure.module.scss';
import { ProjectStructureDiagramDimensions } from '../../enums/Dimensions';

function ProjectDiagramStructure() {
  const intl = useIntl();

  const { projectid } = useParams();

  const { data } = useQuery<Get_Project_Diagram_StructureQuery, Get_Project_Diagram_StructureQueryVariables>(
    GET_PROJECT_DIAGRAM_STRUCTURE,
    {
      skip: !projectid,
      variables: {
        id: projectid || '',
      },
      fetchPolicy: 'cache-only',
    },
  );

  const [isMoving, setIsMoving] = useState(false);
  const [viewbox, setViewbox] = useState({
    x: 0,
    y: 0,
    width: 600,
    height: 1000,
    zoom: 1,
  });

  function startMoving() {
    setIsMoving(true);
  }
  function endMoving() {
    setIsMoving(false);
  }
  function handleMove(e: React.MouseEvent<SVGSVGElement>) {
    if (isMoving) {
      setViewbox({
        ...viewbox,
        x: viewbox.x - e.movementX * viewbox.zoom,
        y: viewbox.y - e.movementY * viewbox.zoom,
      });
    }
  }

  const project = data?.project;
  const dataTree = useMemo(() => project && makeGroupHierarchyTreeDate(project), [project]);
  const treeLeaf = useMemo(() => (dataTree ? countTreeLeaf(dataTree) : 1), [dataTree]);

  const SVGref = createRef<SVGSVGElement>();
  useEffect(() => {
    const rect = SVGref.current?.getBoundingClientRect();
    const leafWidth = treeLeaf * (ProjectStructureDiagramDimensions.ITEM_WIDTH + 20);
    setViewbox({
      ...viewbox,
      x: (leafWidth - (rect?.width || 0)) / 2,
      width: rect?.width || viewbox.width,
      height: rect?.height || viewbox.height,
    });
  }, [SVGref.current, treeLeaf]);

  useEffect(() => {
    function resize() {
      const rect = SVGref.current?.getBoundingClientRect();
      setViewbox({
        ...viewbox,
        width: rect?.width || viewbox.width,
      });
    }
    window.addEventListener('resize', resize, false);
    return () => window.removeEventListener('resize', resize, false);
  }, [SVGref.current, viewbox]);

  function zoomIn() {
    setViewbox({
      ...viewbox,
      zoom: viewbox.zoom / 1.5,
    });
  }

  function zoomOut() {
    setViewbox({
      ...viewbox,
      zoom: viewbox.zoom * 1.5,
    });
  }

  if (!dataTree) {
    return null;
  }

  return (
    <section className={styles.projectDiagramStructure}>
      <header className={styles.header}>
        <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
      </header>
      <div className={styles.diagramWrapper}>
        <div className={styles.zoomies}>
          <button
            type="button"
            className={styles.zoomButton}
            onClick={zoomOut}
            disabled={viewbox.zoom > 3}
          >
            <Icon
              name="remove"
              className={styles.icon}
            />
          </button>
          <button
            type="button"
            className={styles.zoomButton}
            onClick={zoomIn}
            disabled={viewbox.zoom < 0.5}
          >
            <Icon
              name="add"
              className={styles.icon}
            />
          </button>
        </div>

        <svg
          data-testid="project-diagram-structure"
          ref={SVGref}
          viewBox={`${viewbox.x} ${viewbox.y} ${Math.round(viewbox.width * viewbox.zoom)} ${Math.round(viewbox.height * viewbox.zoom)}`}
          width="100%"
          height="100%"
          onMouseDown={startMoving}
          onMouseUp={endMoving}
          onMouseLeave={endMoving}
          onMouseMove={handleMove}
        >
          <defs>
            <marker
              id="line-arrow-marker"
              markerWidth="5"
              markerHeight="7"
              refX="0"
              refY="2"
              orient="auto"
            >
              <polygon
                points="0 0, 5 2, 0 4"
                fill="grey"
              />
            </marker>
          </defs>
          <DP01 />
          <DiagramBloc element={dataTree} />
        </svg>
      </div>
    </section>
  );
}

export default ProjectDiagramStructure;
