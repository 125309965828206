import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import ProgressBar from '../../../../../../components/progress-bar/ProgressBar';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import {
  Get_Program_Participation_ProgressQuery,
  Get_Program_Participation_ProgressQueryVariables,
} from '../../../../@types/graphql';
import useParticipationId from '../../hooks/useParticipationId';
import AppointmentList from './components/appointment-list/AppointmentList';
import { GET_PROGRAM_PARTICIPATION_PROGRESS } from './ProgressBloc.gql';
import translations from './ProgressBloc.translations';

import styles from './ProgressBloc.module.scss';

export default function ProgressBloc() {
  const intl = useIntl();
  const participationid = useParticipationId();

  const { data } = useQuery<Get_Program_Participation_ProgressQuery, Get_Program_Participation_ProgressQueryVariables>(
    GET_PROGRAM_PARTICIPATION_PROGRESS,
    {
      variables: {
        id: participationid,
      },
      fetchPolicy: 'cache-only',
    },
  );

  const completeCount = useMemo(
    () =>
      Math.min(
        Math.round(
          ((data?.participation?.confirmedMeetingCount || 0) / (data?.participation?.program.meetingsQuantity || 1)) *
            100,
        ),
        100,
      ),
    [data?.participation],
  );

  const pendingCount = useMemo(
    () =>
      Math.min(
        Math.round(
          ((data?.participation?.waitingForConfirmationMeetingCount || 0) /
            (data?.participation?.program.meetingsQuantity || 1)) *
            100,
        ),
        100 - completeCount,
      ),
    [data?.participation, completeCount],
  );

  const isAlert = (data?.participation?.unfollowedConversationCount || 0) > 0;

  return (
    <>
      <p className={styles.target}>{intl.formatMessage(translations.target)}</p>
      <p className={styles.targetValue}>
        {intl.formatMessage(translations.targetValue, {
          x: data?.participation?.program.meetingsQuantity,
        })}
      </p>
      <ProgressBar
        count={completeCount}
        tooltip={
          completeCount >= 100
            ? intl.formatMessage(translations.successTooltip, {
                confirmed: data?.participation?.confirmedMeetingCount || 0,
                waiting: data?.participation?.waitingForConfirmationMeetingCount || 0,
              })
            : intl.formatMessage(translations.confirmedTooltip, {
                x: data?.participation?.confirmedMeetingCount || 0,
              })
        }
        pendingCount={pendingCount}
        pendingTooltip={intl.formatMessage(translations.waitingTooltip, {
          x: data?.participation?.waitingForConfirmationMeetingCount || 0,
        })}
        className={classnames(styles.progressBar, {
          [styles.success]: !isAlert && completeCount >= 100,
          [styles.progressBarNotice]: isAlert,
        })}
        valueClassName={styles.progressBarValue}
        pendingBarClassName={styles.pendingBar}
        barClassName={styles.progress}
      />
      <AppointmentList items={data?.participation?.hermesContacts || []} />
    </>
  );
}
