import React from 'react';
import Icon from '../../../../../../../../components/icon/Icon';
import Tooltip from '../../../../../../../../components/tooltip/Tooltip';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { generatePath, Link } from 'react-router-dom';
import useProjectRouteMatch from '../../../../../../hooks/use-project-route-match/useProjectRouteMatch';
import { SchoolRoutes } from '../../../../../../../../Routes';
import { TreeNavProjectItem } from '../../../../@types/TreeNavComponent';
import useDropdown from '../../../../../../hooks/use-drop-down/useDropDown';
import TreeNavGroup from '../tree-nav-group/TreeNavGroup';
import TreeNavProgram from '../tree-nav-program/TreeNavProgram';
import translations from './TreeNavProject.translations';

import styles from './TreeNavProject.module.scss';

interface TreeNavProjectProps {
  project: TreeNavProjectItem;
}
export default function TreeNavProject({ project }: TreeNavProjectProps) {
  const intl = useIntl();
  const [isCurrentLocation] = useProjectRouteMatch(project.id);
  const [isVisibleChildren, toggle, open] = useDropdown();

  const hasChildren = (project.directGroups?.nodes.length || 0) > 0 || (project.directPrograms?.nodes.length || 0) > 0;

  return (
    <li
      data-testid={project.id}
      role="treeitem"
      className={styles.project}
      aria-haspopup={hasChildren}
      aria-expanded={isVisibleChildren}
    >
      <div
        className={classnames(styles.treeNavProject, {
          [styles.currentLocation]: isCurrentLocation,
        })}
      >
        <button
          type="button"
          onClick={toggle}
          disabled={!hasChildren}
          className={styles.toggleChildrenButton}
        >
          <Icon
            name={isVisibleChildren ? 'arrow_drop_down' : 'arrow_right'}
            className={styles.arrow}
          />
        </button>
        <Link
          to={generatePath(SchoolRoutes.projectStats, { projectid: project.id })}
          className={styles.link}
          aria-current={isCurrentLocation ? 'location' : undefined}
          onClick={open}
        >
          <Icon
            name="assessment"
            className={styles.icon}
          />
          {project.name}
        </Link>
        {project?.myRole !== 'guest' && (
          <Tooltip
            text={intl.formatMessage(translations.addProgram)}
            className={styles.addProgramTooltip}
            position="bottom-start"
          >
            {({ onMouseEnter, onMouseLeave, tooltipId }) => (
              <Link
                to={generatePath(SchoolRoutes.projectProgramCreate, { projectid: project.id })}
                className={styles.addProgramButton}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                aria-describedby={tooltipId}
              >
                <Icon
                  name="add_box"
                  className={styles.icon}
                />
              </Link>
            )}
          </Tooltip>
        )}
      </div>
      <ul
        role="tree"
        hidden={!isVisibleChildren}
        className={styles.tree}
      >
        {project.directGroups?.nodes.map((group) => (
          <TreeNavGroup
            key={group.id}
            myRole={project.myRole as string}
            group={group}
            level={1}
            openParent={open}
          />
        ))}
        {project.directPrograms?.nodes.map(
          (program) =>
            program?.canShow?.value && (
              <TreeNavProgram
                key={program.id}
                program={program}
                level={1}
                openParent={open}
              />
            ),
        )}
      </ul>
    </li>
  );
}
