import React from 'react';
import { useIntl } from 'react-intl';
import classNames from 'classnames';
import translations from './TabSwitch.translations';
import './TabSwitch.scss';
import { ImportParticipantsTabs } from '../../../@types';

type TabSwitchProps = {
  setTab: (tab: ImportParticipantsTabs) => void;
  activeTab: ImportParticipantsTabs;
};

export default function TabSwitch({ activeTab, setTab }: TabSwitchProps) {
  const intl = useIntl();

  return (
    <div
      className={'tab-switch'}
    >
      <button
        className={classNames('tab-switch__option', {
          'tab-switch__option--toggled': activeTab === ImportParticipantsTabs.singleImport,
        })}
        onClick={() => setTab(ImportParticipantsTabs.singleImport)}
      >
        {intl.formatMessage(translations.singleImport)}
      </button>
      <button
        className={classNames('tab-switch__option', {
          'tab-switch__option--toggled': activeTab === ImportParticipantsTabs.Imports,
        })}
        onClick={() => setTab(ImportParticipantsTabs.Imports)}
      >
        {intl.formatMessage(translations.imports)}
      </button>
    </div>
  );
}
