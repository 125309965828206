import { useEffect } from 'react';
import { useFormikContext } from 'formik';
import moment from 'moment-timezone';
import { CustomizeProgramFormikField } from '../../hooks/useProgramCustomizationAction';

interface DateRangeWatcherProps {
  minRange: number; // in days
}
export default function DateRangeWatcher({ minRange }: DateRangeWatcherProps) {
  const { values, setFieldValue } = useFormikContext<CustomizeProgramFormikField>();

  useEffect(() => {
    if (values.startAt) {
      if (!values.endAt || moment(values.endAt).diff(moment(values.startAt), 'days') < minRange) {
        setFieldValue('endAt', moment(values.startAt).add(minRange, 'days'));
      }
    }
  }, [values.startAt]);

  return null;
}
