import { gql } from '@apollo/client';

export const UPDATE_GROUP_NAME = gql`
  mutation UPDATE_GROUP_NAME($id: ID!, $name: String!) {
    updateGroup(id: $id, input: { name: $name }) {
      id
      name
    }
  }
`;
