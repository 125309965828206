import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CannotArchiveModalContent.title',
    defaultMessage: 'Vous ne pouvez pas archiver ce projet',
  },
  description: {
    id: 'CannotArchiveModalContent.description',
    defaultMessage: 'Pour archiver un projet, tous les programmes contenus à l’intérieur doivent être terminés.',
  },
});
