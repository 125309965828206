import React from 'react';
import Icon from '../../../../../../components/icon/Icon';
import './ActionButton.scss';

interface ActionButtonProps {
  onClick: () => void;
  iconName: string;
  name: string;
  show: boolean;
}
export default function ActionButton({ onClick, iconName, name, show }: ActionButtonProps) {
  if (show) {
    return <></>;
  }

  return (
    <button
      type="button"
      onClick={onClick}
      className="action-button"
    >
      <Icon
        name={iconName}
        className="action-button__icon"
      />
      {name}
    </button>
  );
}
