import { useContext } from 'react';
import Icon from '../../../../components/icon/Icon';
import Tooltip from '../../../../components/tooltip/Tooltip';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import Breadcrumb from '../breadcrumb/Breadcrumb';
import HeaderContext from '../context/HeaderContext';
import LayoutContext from '../context/LayoutContext';
import ProfileActionItem from './components/action-item/components/profile-action-item/ProfileActionItem';
import translations from './Header.translations';
import styles from './Header.module.scss';

function Header() {
  const intl = useIntl();

  const { isSidebarOpen, openSidebar } = useContext(LayoutContext);
  const { title, isHiddenTitle, breadcrumb, hasBreadcrumb } = useContext(HeaderContext);

  return (
    <header
      data-testid="header"
      className={classnames(styles.header, {
        [styles.withSidebar]: isSidebarOpen,
        [styles.withTitle]: !!title && !isHiddenTitle,
        [styles.withBreadcrumb]: hasBreadcrumb || breadcrumb.length,
      })}
    >
      {!isSidebarOpen && (
        <Tooltip
          text={intl.formatMessage(translations.openSidebarTooltip)}
          position="bottom-end"
          className={styles.openSidebarTooltip}
        >
          {({ onMouseEnter, onMouseLeave, tooltipId }) => (
            <button
              type="button"
              onClick={openSidebar}
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              className={styles.openSidebarButton}
              aria-controls="main-sidebar"
              aria-describedby={tooltipId}
            >
              <Icon
                name="menu"
                className={styles.icon}
              />
            </button>
          )}
        </Tooltip>
      )}

      <Breadcrumb
        items={breadcrumb}
        show={hasBreadcrumb}
        className={styles.breadcrumb}
      />
      {title && !isHiddenTitle && <h1 className={styles.title}>{title}</h1>}

      <div className={styles.profile}>
        <ProfileActionItem />
      </div>
    </header>
  );
}

export default Header;
