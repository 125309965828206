import React from 'react';
import { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import {
  Get_Project_StatisticsQuery,
  Get_Project_StatisticsQueryVariables,
  Get_MeQuery,
  Get_MeQueryVariables,
} from '../../../../@types/graphql';
import commonReviewNotation from '../../../../commons/translations/reviewNotation.translations';
import RTFBold from '../../../../rich-text-formatting/bold/RTFBold';
import ProjectStatisticFilter from './components/project-statistic-filter/ProjectStatisticFilter';
import ProjectStatisticWithColumnChart from './components/project-statistic-with-column-chart/ProjectStatisticWithColumnChart';
import ProjectStatisticWithDonutChart from './components/project-statistic-with-donut-chart/ProjectStatisticWithDonutChart';
import ProjectStatisticWithIcon from './components/project-statistic-with-icon/ProjectStatisticWithIcon';
import ProjectStatisticTopFive from './components/project-statistic-top-five/ProjectStatisticTopFive';
import useFiltersParameter from '../../hooks/useFiltersParameter';
import { ProjectShowParams } from '../../../../@types/route-params';
import { GET_PROJECT_STATISTICS } from './ProjectStatisticsDisplay.gql';
import { GET_ME } from '../../../homepage/Homepage.gql';
import translations from './ProjectStatisticsDisplay.translations';
import styles from './ProjectStatisticsDisplay.module.scss';

export default function ProjectStatisticsDisplay() {
  const intl = useIntl();
  const params = useParams<ProjectShowParams>();
  const projectId = params.projectid || '';
  const { programIds, includeArchived } = useFiltersParameter();
  const [showCollectiveMeetingsData, setShowCollectiveMeetingsData] = useState(true);

  const user = useQuery<Get_MeQuery, Get_MeQueryVariables>(GET_ME);

  const { data } = useQuery<Get_Project_StatisticsQuery, Get_Project_StatisticsQueryVariables>(GET_PROJECT_STATISTICS, {
    variables: {
      id: projectId,
      programIds,
      includeArchived,
    },
    fetchPolicy: 'cache-only',
  });

  const studentEvaluationData =
    data?.project?.programsStatistics.studentEvaluations.map((evaluation) => [
      intl.formatMessage(commonReviewNotation[evaluation.key]),
      evaluation.count,
      evaluation.count,
    ]) || [];

  const studentReviewsCount =
    data?.project?.programsStatistics.studentEvaluations
      .map((evaluation) => evaluation.count)
      .reduce((partialSum, a) => partialSum + a, 0) || 0;

  const studentInitialData: any[] = [['Évaluations', `Total (${studentReviewsCount})`, { role: 'annotation' }]];

  const professionalEvaluationData =
    data?.project?.programsStatistics.professionalEvaluations.map((evaluation) => [
      intl.formatMessage(commonReviewNotation[evaluation.key]),
      evaluation.count,
      evaluation.count,
    ]) || [];

  const professionalReviewsCount =
    data?.project?.programsStatistics.professionalEvaluations
      .map((evaluation) => evaluation.count)
      .reduce((partialSum, a) => partialSum + a, 0) || 0;

  const professionalInitialData: any[] = [
    ['Évaluations', `Total (${professionalReviewsCount})`, { role: 'annotation' }],
  ];

  const studentEvaluations = studentInitialData.concat(studentEvaluationData);
  const professionalEvaluations = professionalInitialData.concat(professionalEvaluationData);

  useEffect(() => {
    if (user?.data?.me?.primarySituation === 'POST_BAC_STUDENT_SUPPORT') {
      setShowCollectiveMeetingsData(false);
    }
  }, [user?.data?.me?.primarySituation]);

  return (
    <section>
      <ProjectStatisticFilter
        projectId={projectId}
        programsCount={data?.project?.programsStatistics.programsCount || 0}
        totalProgramsCount={data?.project?.programsStatistics.programsCount || 0}
      />
      <article className={styles.contentRowOne}>
        <ProjectStatisticWithIcon
          title={intl.formatMessage(translations.participantsAffiliated, {
            boldThis: RTFBold,
          })}
          iconName="people"
          value={data?.project?.programsStatistics.participationsCount}
          description={intl.formatMessage(translations.participantsAffiliatedDescription, {
            x: data?.project?.programsStatistics.programsCompletionsPourcentage || 0,
            boldThis: RTFBold,
          })}
          className={styles.participantsAffiliated}
          highlight
        />
        <ProjectStatisticWithIcon
          title={intl.formatMessage(translations.professionalsContactedByParticipants, {
            boldThis: RTFBold,
          })}
          iconName="message"
          value={data?.project?.programsStatistics.conversationsCount}
          description={intl.formatMessage(translations.professionalsContactedByParticipantsDescription, {
            x: data?.project?.programsStatistics.participantsWithConversationsPourcentage || 0,
            boldThis: RTFBold,
          })}
          withoutParticipant={!data?.project?.programsStatistics.participationsCount}
          className={styles.professionalsContactedByParticipants}
        />
        <ProjectStatisticWithIcon
          title={intl.formatMessage(translations.accomplishedAppointments, {
            boldThis: RTFBold,
          })}
          iconName="event"
          value={data?.project?.programsStatistics.confirmedAppointmentsCount}
          description={intl.formatMessage(translations.accomplishedAppointmentsDescription, {
            x: data?.project?.programsStatistics.participantsWithAppointmentsPourcentage || 0,
            boldThis: RTFBold,
          })}
          withoutParticipant={!data?.project?.programsStatistics.participationsCount}
          className={styles.accomplishedAppointments}
        />
        {showCollectiveMeetingsData && (
          <ProjectStatisticWithIcon
            title={intl.formatMessage(translations.accomplishedCollectiveAppointments, {
              boldThis: RTFBold,
            })}
            iconName="event"
            value={data?.project?.collectiveMeetingsCount || 0}
            description={intl.formatMessage(translations.accomplishedCollectiveAppointmentsDescription, {
              x: data?.project?.collectiveMeetingsParticipants || 0,
              boldThis: RTFBold,
            })}
            withoutParticipant={!data?.project?.programsStatistics.participationsCount}
            className={styles.accomplishedAppointments}
          />
        )}
        <ProjectStatisticWithIcon
          title={intl.formatMessage(translations.differentProfessionalsMet, {
            boldThis: RTFBold,
          })}
          iconName="person"
          value={data?.project?.programsStatistics.metProfessionalsCount}
          description={intl.formatMessage(translations.differentProfessionalsMetDescription, {
            x: data?.project?.programsStatistics.confirmedAppointmentsCount || 0,
            boldThis: RTFBold,
          })}
          withoutParticipant={!data?.project?.programsStatistics.participationsCount}
          className={styles.differentProfessionalsMet}
        />
      </article>
      <article className={styles.contentRowTwo}>
        <ProjectStatisticWithDonutChart
          title={intl.formatMessage(translations.progression)}
          description={intl.formatMessage(translations.progressionDescription, {
            boldThis: RTFBold,
          })}
          count={data?.project?.programsStatistics.delivredCertificates || 0}
          total={data?.project?.programsStatistics.expectedCertificates || 0}
          countDescription={
            (data?.project?.programsStatistics.deliveredCertificatesPourcentage || 0) > 0
              ? intl.formatMessage(translations.progressionPercentage, {
                  x: data?.project?.programsStatistics.deliveredCertificatesPourcentage || 0,
                  boldThis: RTFBold,
                })
              : undefined
          }
          noDataTitle={intl.formatMessage(translations.noData)}
          noDataDescription={intl.formatMessage(translations.progressionNoData)}
          withoutParticipant={!data?.project?.programsStatistics.participationsCount}
          className={styles.progression}
        />
        <ProjectStatisticWithColumnChart
          title={intl.formatMessage(translations.utilityOfAppointments)}
          description={intl.formatMessage(translations.utilityOfAppointmentsDescription, {
            boldThis: RTFBold,
          })}
          data={studentEvaluations}
          reviewCount={studentReviewsCount}
          noData={!data?.project?.programsStatistics.confirmedAppointmentsCount}
          noDataTitle={intl.formatMessage(translations.noData)}
          noDataDescription={intl.formatMessage(translations.utilityOfAppointmentsNoData)}
          className={styles.utilityOfAppointments}
          color="#e0ca4b"
        />
        <ProjectStatisticWithColumnChart
          title={intl.formatMessage(translations.preparationOfAppointments)}
          description={intl.formatMessage(translations.preparationOfAppointmentsDescription, {
            boldThis: RTFBold,
          })}
          data={professionalEvaluations}
          reviewCount={professionalReviewsCount}
          noData={!data?.project?.programsStatistics.confirmedAppointmentsCount}
          noDataTitle={intl.formatMessage(translations.noData)}
          noDataDescription={intl.formatMessage(translations.preparationOfAppointmentsNoData)}
          className={styles.preparationOfAppointments}
          color="#0E0E11"
        />
      </article>
      <article className={styles.contentRowThree}>
        <ProjectStatisticTopFive
          title={intl.formatMessage(translations.top5Companies)}
          data={
            data?.project?.programsStatistics.companyTopFive.map((item) => ({
              label: item.identifier,
            })) || []
          }
          noData={!data?.project?.programsStatistics.companyTopFive.length}
          noDataTitle={intl.formatMessage(translations.noData)}
          noDataDescription={intl.formatMessage(translations.top5CompaniesNoData)}
          className={styles.top5Companies}
        />
        <ProjectStatisticTopFive
          title={intl.formatMessage(translations.top5SecteursOfActivity)}
          data={
            data?.project?.programsStatistics.sectorTopFive.map((item) => ({
              label: item.identifier,
            })) || []
          }
          noData={!data?.project?.programsStatistics.sectorTopFive.length}
          noDataTitle={intl.formatMessage(translations.noData)}
          noDataDescription={intl.formatMessage(translations.top5SecteursOfActivityNoData)}
          className={styles.top5SecteursOfActivity}
        />
        <ProjectStatisticTopFive
          title={intl.formatMessage(translations.top5Professions)}
          data={
            data?.project?.programsStatistics.jobTopFive.map((item) => ({
              label: item.identifier,
            })) || []
          }
          noData={!data?.project?.programsStatistics.jobTopFive.length}
          noDataTitle={intl.formatMessage(translations.noData)}
          noDataDescription={intl.formatMessage(translations.top5ProfessionsNoData)}
          className={styles.top5Professions}
        />
      </article>
    </section>
  );
}
