import { gql } from '@apollo/client';

export const GET_PROGRAM_TO_UPDATE = gql`
  query GET_PROGRAM_TO_UPDATE($id: ID!) {
    program(id: $id) {
      id
      name
      startAt
      endAt
      expectedParticipations
      collaborations {
        nodes {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
      description
      programTemplate {
        id
        name
        templateOptions {
          minimalDaysDuration
          meetingsQuantity
        }
      }
      programNotificationFrequency
      project {
        id
      }
    }
  }
`;
