import { gql } from '@apollo/client';
import { ProgramProgressionStatus, ProgramPublicationStatus } from '../../../../@types/graphql';

export const COUNT_NOT_FINISHED_PROJECT_PROGRAMS = gql`
  query COUNT_NOT_FINISHED_PROJECT_PROGRAMS($projectId: ID!) {
    project(id: $projectId) {
      id
      programs(filter: {
        query: [
          {
            filterBy: programProgressionStatus,
            operator: neq,
            value: "${ProgramProgressionStatus.Finished}"
          },
          {
            filterBy: programPublicationStatus,
            operator: neq,
            value: "${ProgramPublicationStatus.Draft}"
          }
        ]
      }) {
        total
      }
    }
  }
`;
