import React from 'react';
import { TabNavigationItem } from './@types/TabNavigationItem';
import TabItem from './components/tab-item/TabItem';

import styles from './TabNavigation.module.scss';

interface TabNavigationProps {
  items: TabNavigationItem[];
}
function TabNavigation({ items }: TabNavigationProps) {
  return (
    <React.Fragment>
      <ul
        role="tablist"
        className={styles.tabNavigation}
        data-testid="tab-navigation"
      >
        {items.map((item) => (
          <li
            key={item.link}
            data-testid={item.id}
            className={styles.tabNavigationItem}
            role="presentation"
            data-userpilot-kind={item.userpilotKind}
          >
            <TabItem item={item} />
          </li>
        ))}
      </ul>
    </React.Fragment>
  );
}

export default TabNavigation;
