import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import { useIntercom, IntercomProvider as ReactIntercomProvider } from 'react-use-intercom';
import { Get_Intercom_Current_UserQuery, Get_Intercom_Current_UserQueryVariables } from '../../@types/graphql';
import { GET_INTERCOM_CURRENT_USER } from './IntercomProvider.gql';

interface useIntercomWrapperProps {
  children: React.ReactNode;
}

interface IntercomProviderWrapperProps {
  children: React.ReactNode;
}

const INTERCOM_APP_ID = import.meta.env.REACT_APP_INTERCOM_APP_ID;
const INTERCOM_API_BASE = 'https://api-iam.eu.intercom.io';

function UseIntercomWrapper({ children }: useIntercomWrapperProps): JSX.Element {
  const { boot, update } = useIntercom();
  const location = useLocation();
  const [intercomBooted, setIntercomBooted] = useState(false);
  const { data } = useQuery<Get_Intercom_Current_UserQuery, Get_Intercom_Current_UserQueryVariables>(
    GET_INTERCOM_CURRENT_USER,
  );

  useEffect(() => {
    if (data?.me && !intercomBooted) {
      boot({
        email: data.me.email,
        userId: data.me.hermesUserId,
        createdAt: data.me.createdAt,
        avatar: data.me.avatarUrl
          ? {
              type: 'avatar',
              imageUrl: data.me.avatarUrl,
            }
          : undefined,
        userHash: data.me.intercomUserHash ? data.me.intercomUserHash : undefined,
      });
      setIntercomBooted(true);
    }
  }, [data]);

  useEffect(() => {
    if (!intercomBooted) {
      return;
    }

    update();
  }, [location]);

  if (!data) {
    return <div />;
  }

  return children as JSX.Element;
}

export default function IntercomProvider({ children }: IntercomProviderWrapperProps): JSX.Element {
  if (!INTERCOM_APP_ID) {
    return <>children</>;
  }

  return (
    <ReactIntercomProvider
      appId={INTERCOM_APP_ID}
      apiBase={INTERCOM_API_BASE}
      initializeDelay={1000}
    >
      <UseIntercomWrapper>{children}</UseIntercomWrapper>
    </ReactIntercomProvider>
  );
}
