import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ProgramShowNoParticipants.title',
    defaultMessage: 'Aucun participant identifié',
  },
  description: {
    id: 'ProgramShowNoParticipants.description',
    defaultMessage: 'Les participants rejoignent votre programme en renseignant son code dans leur interface  ',
  },
});
