import { gql } from '@apollo/client';

export const IMPORT_SINGLE_INVITATION = gql`
  mutation IMPORT_SINGLE_INVITATION(
    $programId: ID!
    $firstName: String!
    $lastName: String!
    $email: String!
    $message: String
  ) {
    importSingleInvitation(
      programId: $programId
      firstName: $firstName
      lastName: $lastName
      email: $email
      message: $message
    ) {
      id
    }
  }
`;
