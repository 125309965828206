import React from 'react';
import { useIntl } from 'react-intl';
import classnames from 'classnames';
import './LoadMoreButton.scss';
import translations from './LoadMoreButton.translations';

interface LoadMoreButtonProps {
  onClick: () => void;
  hasNextPage?: boolean;
}

export default function LoadMoreButton({ onClick, hasNextPage }: LoadMoreButtonProps) {
  const intl = useIntl();

  return (
    <button
      type="button"
      className={classnames('load-more-button', {
        ['load-more-button__disabled']: !hasNextPage,
      })}
      onClick={onClick}
      disabled={!hasNextPage}
    >
      {intl.formatMessage(translations.text)}
    </button>
  );
}
