import React from 'react';
import { useMemo } from 'react';
import Icon from '../../../../../../../../components/icon/Icon';
import classnames from 'classnames';
import moment from 'moment-timezone';
import { useIntl } from 'react-intl';
import { InvitationStatus } from '../../../../../../@types/graphql';
import cellWrapper from '../../../../../../commons/cell-wrapper/cellWrapper';
import { RowRenderer } from '../../@types/project-members-table';
import translations from './StatusCell.translations';

import styles from './StatusCell.module.scss';

enum PossibleStatuses {
  member = 'member',
  invited = 'invited',
  declined = 'declined',
}
const STATUS = {
  [PossibleStatuses.member]: {
    label: translations.member,
    from: translations.memberFrom,
  },
  [PossibleStatuses.invited]: {
    label: translations.invited,
    from: translations.invitedFrom,
  },
  [PossibleStatuses.declined]: {
    label: translations.declined,
    from: translations.declinedFrom,
  },
};

function StatusCell({ rowData }: RowRenderer) {
  const intl = useIntl();
  const memberStatus = useMemo(() => {
    if (rowData.__typename === 'Membership') {
      return PossibleStatuses.member;
    }
    if (rowData.__typename === 'Invitation' && rowData.invitationStatus === InvitationStatus.Declined) {
      return PossibleStatuses.declined;
    }
    return PossibleStatuses.invited;
  }, [rowData]);

  return (
    <p className={classnames(styles.membershipStatus, styles[memberStatus])}>
      <span className={styles.status}>
        <Icon
          name="check_circle"
          className={styles.icon}
        />
        {intl.formatMessage(STATUS[memberStatus].label)}
      </span>
      <br />
      <span className={styles.from}>
        {intl.formatMessage(STATUS[memberStatus].from, {
          date: intl.formatDate(
            memberStatus === PossibleStatuses.declined
              ? moment(rowData.updatedAt).toDate()
              : moment(rowData.createdAt).toDate(),
            {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            },
          ),
        })}
      </span>
    </p>
  );
}

export default cellWrapper(StatusCell);
