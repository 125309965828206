import { gql } from '@apollo/client';

export const GET_PROGRAM_IMPORTS = gql`
  query GET_PROGRAM_INVITATIONS_IMPORTS($filter: ProgramInvitationImportsFilterInput!) {
    programInvitationImports(filter: $filter) {
      nodes {
        resultFileUrl
        status
        updatedAt
      }
    }
  }
`;
