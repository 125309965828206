import classnames from 'classnames';
import { useIntl } from 'react-intl';
import translations from './ProfileCompletionStatus.translations';

import styles from './ProfileCompletionStatus.module.scss';

interface ProfileCompletionStatusProps {
  complete: boolean;
}
export default function ProfileCompletionStatus({ complete }: ProfileCompletionStatusProps) {
  const intl = useIntl();

  if (complete) {
    return (
      <span className={classnames(styles.profileCompletionStatus, styles.complete)}>
        {intl.formatMessage(translations.complete)}
      </span>
    );
  }

  return (
    <span className={classnames(styles.profileCompletionStatus, styles.incomplete)}>
      {intl.formatMessage(translations.incomplete)}
    </span>
  );
}
