import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { InvitationRole, Role } from '../../@types/graphql';
import commonRoleTranslations from '../translations/roles.translations';

import styles from './RoleBadge.module.scss';

interface RoleBadgeProps {
  role?: Role | InvitationRole;
  className?: string;
}
export default function RoleBadge({ role, className }: RoleBadgeProps) {
  const intl = useIntl();

  switch (role) {
    case Role.Owner:
      return (
        <span
          data-testid="role-badge"
          className={classnames(className, styles.badge, styles.owner)}
        >
          {intl.formatMessage(commonRoleTranslations[Role.Owner])}
        </span>
      );
    case InvitationRole.Admin:
    case Role.Admin:
      return (
        <span
          data-testid="role-badge"
          className={classnames(className, styles.badge, styles.admin)}
        >
          {intl.formatMessage(commonRoleTranslations[Role.Admin])}
        </span>
      );
    case InvitationRole.Companion:
    case Role.Companion:
      return (
        <span
          data-testid="role-badge"
          className={classnames(className, styles.badge, styles.companion)}
        >
          {intl.formatMessage(commonRoleTranslations[Role.Companion])}
        </span>
      );
    case InvitationRole.Guest:
    case Role.Guest:
      return (
        <span
          data-testid="role-badge"
          className={classnames(className, styles.badge, styles.guest)}
        >
          {intl.formatMessage(commonRoleTranslations[Role.Guest])}
        </span>
      );
    default:
      return null;
  }
}
