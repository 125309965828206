import { useMemo } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import {
  Membership,
  Project_Members_MembershipFragment,
  Query_Membership_For_DeletionQuery,
  Query_Membership_For_DeletionQueryVariables,
} from '../../../../../@types/graphql';
import { PROJECT_MEMBERS_MEMBERSHIP } from '../../../ProjectShow.gql';
import { QUERY_MEMBERSHIP_FOR_DELETION } from './useMembership.gql';

export default function useMembership(
  membershipId?: string,
): Pick<Membership, '__typename' | 'userFirstName' | 'userLastName' | 'role' | 'isMe'> | undefined {
  const { cache } = useApolloClient();

  // try to get membership from cache
  const membership = useMemo(
    () =>
      cache.readFragment<Project_Members_MembershipFragment>({
        id: cache.identify({
          id: membershipId,
          __typename: 'Membership',
        }),
        fragment: PROJECT_MEMBERS_MEMBERSHIP,
      }),
    [membershipId],
  );

  // query membership on the server
  const { data } = useQuery<Query_Membership_For_DeletionQuery, Query_Membership_For_DeletionQueryVariables>(
    QUERY_MEMBERSHIP_FOR_DELETION,
    {
      skip: !!membership || !membershipId,
      variables: {
        id: membershipId || '',
      },
    },
  );

  return membership || data?.membership || undefined;
}
