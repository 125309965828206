import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams, generatePath } from 'react-router-dom';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { SchoolRoutes } from '../../../../Routes';
import GroupActions from './components/group-actions/GroupActions';
import PageClassic from '../../layout/pages/page-classic/PageClassic';
import './ImportParticipantsView.scss';
import { Get_Program_To_ShowQuery, Get_Program_To_ShowQueryVariables } from '../../@types/graphql';
import { ProgramUpdateParams } from '../../@types/route-params';
import { ImportParticipantsTabs } from './@types';
import translations from './ImportParticipants.translations';
import ProgramDot from '../../commons/program-dot/ProgramDot';
import ImportParticipantsTab from '../import-participants-modal/ImportParticipantsModal';
import ImportSingleParticipantTab from '../import-single-participant-modal/ImportSingleParticipantModal';
import { GET_PROGRAM_TO_SHOW } from '../program-show/ProgramShow.gql';

export default function ImportParticipants() {
  const intl = useIntl();
  const { programid = '' } = useParams();
  const [activeTab, setActiveTab] = useState<ImportParticipantsTabs>(ImportParticipantsTabs.Imports);

  const { data } = useQuery<Get_Program_To_ShowQuery, Get_Program_To_ShowQueryVariables>(GET_PROGRAM_TO_SHOW, {
    variables: {
      id: programid || '',
    },
  });

  return (
    <PageClassic
      title={intl.formatMessage(translations.title)}
      breadcrumbs={
        data?.program
          ? [
              {
                iconName: 'assessment',
                link: generatePath(SchoolRoutes.projectStats, {
                  projectid: data.program.project.id,
                }),
                name: data.program.project.name,
              },
              {
                iconComponent: ProgramDot,
                link: generatePath(SchoolRoutes.programShow, {
                  programid: data.program.id,
                }),
                name: data.program.name,
              },
            ]
          : undefined
      }
    >
      <GroupActions
        setTab={setActiveTab}
        activeTab={activeTab}
        programId={programid}
      />
      <div className="import-participants-view">
        {activeTab === ImportParticipantsTabs.singleImport && <ImportSingleParticipantTab programId={programid} />}
        {activeTab === ImportParticipantsTabs.Imports && <ImportParticipantsTab programId={programid} />}
      </div>
    </PageClassic>
  );
}
