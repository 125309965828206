import React from 'react';
import Icon from '../../../../../../../../components/icon/Icon';
import ProgressBar from '../../../../../../../../components/progress-bar/ProgressBar';
import { useIntl } from 'react-intl';
import translations from './ProgramShowCurrentParticipation.translations';

import styles from './ProgramShowCurrentParticipation.module.scss';

interface ProgramShowCurrentParticipationProps {
  totalParticipations: number;
  expectedParticipations: number;
}
export default function ProgramShowCurrentParticipation({
  totalParticipations,
  expectedParticipations,
}: ProgramShowCurrentParticipationProps) {
  const intl = useIntl();

  return (
    <>
      <div className={styles.informations}>
        <aside className={styles.aside}>
          <Icon
            name="people"
            className={styles.icon}
          />
        </aside>
        <div>
          <p className={styles.totalParticipations}>{totalParticipations}</p>
          <p className={styles.expectedParticipations}>
            {intl.formatMessage(translations.expectedParticipations, {
              x: expectedParticipations,
            })}
          </p>
        </div>
      </div>
      <ProgressBar count={Math.min(Math.round((totalParticipations / expectedParticipations) * 100), 100)} />
    </>
  );
}
