import { defineMessages } from 'react-intl';

export default defineMessages({
  interestTitle: {
    id: 'StatisticsBloc.interestTitle',
    defaultMessage: 'Intérêt',
  },
  viewedProfiles: {
    id: 'StatisticsBloc.viewedProfiles',
    defaultMessage: 'Profils visités',
  },
  favoritesPros: {
    id: 'StatisticsBloc.favoritesPros',
    defaultMessage: 'Ambassadeurs en favoris',
    description: '',
  },

  messagingTitle: {
    id: 'StatisticsBloc.messagingTitle',
    defaultMessage: 'Messagerie',
  },
  contactedProfessionals: {
    id: 'StatisticsBloc.contactedProfessionals',
    defaultMessage: 'Ambassadeurs contactés',
  },
  unfollowedConversations: {
    id: 'StatisticsBloc.unfollowedConversations',
    defaultMessage: 'Conversations non suivies',
  },
  unfollowedConversationsTooltip: {
    id: 'StatisticsBloc.unfollowedConversationsTooltip',
    defaultMessage: 'Une conversation où le participant n’a pas répondu à la première réponse de l’ambassadeur',
  },
  remainingContactRequest: {
    id: 'StatisticsBloc.remainingContactRequest',
    defaultMessage: 'Prises de contact restantes',
  },
  remainingContactRequestTooltip: {
    id: 'StatisticsBloc.remainingContactRequestTooltip',
    defaultMessage: "Un participant peut contacter jusqu'à 3 ambassadeurs par semaine",
  },

  appointmentsTitle: {
    id: 'StatisticsBloc.appointmentsTitle',
    defaultMessage: 'Rendez-vous',
  },
  upcomingAppt: {
    id: 'StatisticsBloc.upcomingAppt',
    defaultMessage: 'RDV à venir',
  },
  pastAppointmentWaitingConfirmation: {
    id: 'StatisticsBloc.pastAppointmentWaitingConfirmation',
    defaultMessage: 'RDV passés en attente de confirmation',
  },
  confirmedPastAppointments: {
    id: 'StatisticsBloc.confirmedPastAppointments',
    defaultMessage: 'RDV réalisé',
  },
});
