import classnames from 'classnames';
import { ProgramProgressionStatus } from '../../@types/graphql';

import styles from './ProgramDot.module.scss';

interface ProgramDotProps {
  status?: ProgramProgressionStatus;
  className?: string;
}
export default function ProgramDot({ status, className }: ProgramDotProps) {
  return <span className={classnames(styles.programDot, className, styles[status || ''])} />;
}
