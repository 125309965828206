import { gql } from '@apollo/client';
import { ProgramPublicationStatus } from '../../@types/graphql';

export const GET_PROJECT_TREE = gql`
  fragment ProjectTreeProgram on Program {
    id
    name
    programProgressionStatus
    canShow {
      value
    }
    canUpdate {
      value
    }
  }

  fragment ProjectTreeGroup on Group {
    id
    name
    canUpdate {
      value
    }
    canCreateGroup {
      value
    }
    canDelete {
      value
    }
    programs(filter: {
      query: {
        filterBy: programPublicationStatus,
        operator: eq,
        value: "${ProgramPublicationStatus.Published}"
      }
    }) {
      nodes {
        ...ProjectTreeProgram
      }
    }
    project {
      id
    }
  }

  query GET_PROJECT_TREE($projectId: ID!) {
    project(id: $projectId) {
      id
      name
      directPrograms(filter: {
        query: {
          filterBy: programPublicationStatus,
          operator: eq,
          value: "${ProgramPublicationStatus.Published}"
        }
      }) {
        nodes {
          ...ProjectTreeProgram
        }
      }
      directGroups {
        nodes {
          ...ProjectTreeGroup
          groups {
            nodes {
              ...ProjectTreeGroup
              groups {
                nodes {
                  ...ProjectTreeGroup
                  groups {
                    nodes {
                      ...ProjectTreeGroup
                      groups {
                        nodes {
                          ...ProjectTreeGroup
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
