import React from 'react';
import { useQuery } from '@apollo/client';
import Icon from '../../../../../../components/icon/Icon';
import Tooltip from '../../../../../../components/tooltip/Tooltip';
import classnames from 'classnames';
import { useCallback, useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { generatePath, Link, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../Routes';
import {
  Get_Program_Participation_CodeQuery,
  Get_Program_Participation_CodeQueryVariables,
} from '../../../../@types/graphql';
import RTFBold from '../../../../rich-text-formatting/bold/RTFBold';
import { GET_PROGRAM_PARTICIPATION_CODE } from './ProgramParticipationCode.gql';
import translations from './ProgramParticipationCode.translations';
import { toCanvas, toDataURL } from 'qrcode';

import styles from './ProgramParticipationCode.module.scss';

const ACCOUNT_FRONTEND_URL = `${import.meta.env.REACT_APP_ACCOUNT_FRONTEND_URL}/signup`;

export default function ProgramParticipationCode() {
  const qrCodeCanvas = useRef<HTMLCanvasElement>(null);

  const intl = useIntl();

  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [copied, setCopied] = useState(false);
  const [affiliationLink, setAffiliationLink] = useState('');

  const { programid } = useParams();

  const { data } = useQuery<Get_Program_Participation_CodeQuery, Get_Program_Participation_CodeQueryVariables>(
    GET_PROGRAM_PARTICIPATION_CODE,
    {
      skip: !programid,
      variables: {
        id: programid || '',
      },
      fetchPolicy: 'cache-only',
    },
  );

  useEffect(() => {
    if (!data?.program?.participationCode) return;
    const { participationCode } = data.program;
    setAffiliationLink(`${ACCOUNT_FRONTEND_URL}?program_code=${participationCode}`);

    if (!qrCodeCanvas.current) return;
    toCanvas(qrCodeCanvas.current, `${ACCOUNT_FRONTEND_URL}?program_code=${participationCode}`, {
      width: 96,
      errorCorrectionLevel: 'H',
      color: {
        dark: '#09193b',
        light: '#fff',
      },
    });
  }, [data?.program?.participationCode, qrCodeCanvas.current]);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const onOptionClick = useCallback(() => {
    setCopied(true);
    setIsPopupOpen(false);
  }, [copied]);

  const downloadQRCode = useCallback(async () => {
    if (!data?.program?.participationCode) return;
    const { participationCode } = data.program;
    const dataUrl = await toDataURL(`${ACCOUNT_FRONTEND_URL}?program_code=${participationCode}`, {
      width: 512,
      errorCorrectionLevel: 'H',
      color: {
        dark: '#09193b',
        light: '#fff',
      },
    });

    const link = document.createElement('a');
    link.href = dataUrl;
    link.download = `qrcode-myjobglasses-affiliation-${participationCode}.png`;
    link.click();
    link.remove();
  }, [data?.program?.participationCode]);

  return (
    <div className={styles.programParticipationCode}>
      <section>
        <p className={styles.programTemplateName}>
          {intl.formatMessage(translations.programTemplateName, {
            programTemplateName: data?.program?.programTemplate.name,
            boldThis: RTFBold,
          })}
        </p>
        <h3 className={styles.participationCodeTitle}>
          {intl.formatMessage(translations.participationCode)}
          <Tooltip
            text={intl.formatMessage(translations.learnMore)}
            tooltipClassName={styles.tooltip}
          >
            {({ onMouseEnter, onMouseLeave, tooltipId }) => (
              <Link
                to={generatePath(SchoolRoutes.programStudentAffiliationHelp, {
                  programid: programid || '',
                })}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                aria-describedby={tooltipId}
                className={styles.helpLink}
              >
                <Icon
                  name="help_outline"
                  className={styles.helpIcon}
                />
              </Link>
            )}
          </Tooltip>
        </h3>
        <p className={styles.participationCode}>
          {data?.program?.participationCode}
          <Tooltip text={intl.formatMessage(translations.shareOptions)}>
            {({ onMouseEnter, onMouseLeave }) => (
              <button
                type="button"
                className={styles.shareButton}
                onClick={() => setIsPopupOpen((prev) => !prev)}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
              >
                <Icon
                  name={copied ? 'check' : 'share'}
                  className={classnames(styles.icon, {
                    [styles.copied]: copied,
                  })}
                />
              </button>
            )}
          </Tooltip>

          {isPopupOpen && (
            <ul className={styles.popup}>
              <li>
                <CopyToClipboard
                  text={data?.program?.participationCode || ''}
                  onCopy={onOptionClick}
                >
                  <button
                    type="button"
                    className={styles.copyToClipboard}
                  >
                    <Icon
                      name="content_copy"
                      className={styles.icon}
                    />
                    {intl.formatMessage(translations.copyCode)}
                  </button>
                </CopyToClipboard>
              </li>
              <li>
                <CopyToClipboard
                  text={affiliationLink}
                  onCopy={onOptionClick}
                >
                  <button
                    type="button"
                    className={styles.copyToClipboard}
                  >
                    <Icon
                      name="link"
                      className={styles.icon}
                    />
                    {intl.formatMessage(translations.copyLink)}
                  </button>
                </CopyToClipboard>
              </li>
            </ul>
          )}
        </p>
      </section>
      <section className={styles.qrCodeSection}>
        <canvas ref={qrCodeCanvas} />
        <button
          type="button"
          className={styles.downloadButton}
          onClick={downloadQRCode}
        >
          <Icon
            name="download"
            className={styles.icon}
          />
          {intl.formatMessage(translations.downloadQRCode)}
        </button>
      </section>
    </div>
  );
}
