import { defineMessages } from 'react-intl';

export default defineMessages({
  addAGroup: {
    id: 'ProjectStructureListGroupItem.addAGroup',
    defaultMessage: 'Ajouter un sous-groupe',
  },
  deleteGroupTooltip: {
    id: 'ProjectStructureListGroupItem.deleteGroupTooltip',
    defaultMessage: 'Supprimer ce groupe',
  },
});
