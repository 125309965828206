import React from 'react';
import { GridPagination } from '@mui/x-data-grid';
import LoadMoreButton from './components/LoadMoreButton';

interface PaginationProps {
  onClick: () => void;
  hasNextPage?: boolean;
}

export default function Pagination({onClick, hasNextPage}:PaginationProps) {
  return (
    <GridPagination
      ActionsComponent={() => (
        <LoadMoreButton
          onClick={onClick}
          hasNextPage={hasNextPage}
        />
      )}
    />
  );
}
