import './ImportCard.scss';

interface ImportCardProps {
  number: string;
  title: string;
  description: string;
  htmlElement: React.ReactNode;
}

export default function ImportCard({ number, title, description, htmlElement }: ImportCardProps) {
  return (
    <div className="import-card">
      <div className="import-card__title">
        <div className="import-card__title__number">{number}</div>
        <h1 className="import-card__title__text">{title}</h1>
      </div>
      <p className="import-card__description">{description}</p>
      {htmlElement}
    </div>
  );
}
