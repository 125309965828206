import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../../../components/snackbar/hooks/useSnackbar';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../Routes';
import { handleGraphqlErrorsOnFormik } from '../../../../../../../../utils/formik';
import { Remove_MembershipMutation, Remove_MembershipMutationVariables } from '../../../../../../@types/graphql';
import { DELETE_MEMBERSHIP_ERROR } from '../../../../../../@types/graphql-errors';
import commonErrorsTranslations from '../../../../../../commons/translations/errors.translations';
import useMembership from '../../hooks/useMembership';
import { REMOVE_MEMBERSHIP } from './MemberDeleteModal.gql';
import translations, { errorTranslations } from './MemberDeleteModal.translations';

interface FormikFields {
  leave: string;
}
export function useMembershipDeleteMutation(membershipId?: string) {
  const intl = useIntl();
  const { projectid } = useParams();
  const membership = useMembership(membershipId);
  const navigate = useNavigate();
  const { success } = useSnackbar();

  const [deleteMembership] = useMutation<Remove_MembershipMutation, Remove_MembershipMutationVariables>(
    REMOVE_MEMBERSHIP,
    {
      variables: {
        id: membershipId || '',
      },
      refetchQueries: ['GET_PROJECT_INFO'],
    },
  );

  return function onSubmit(values: FormikFields, formikBag: FormikHelpers<FormikFields>) {
    return deleteMembership()
      .then(() => {
        success(
          intl.formatMessage(translations.success, {
            firstName: membership?.userFirstName,
            lastName: membership?.userLastName,
          }),
        );

        if (membership?.isMe) {
          navigate(SchoolRoutes.home);
        } else {
          navigate(generatePath(SchoolRoutes.projectMembers, { projectid: projectid || '' }));
        }
      })
      .catch((err) =>
        handleGraphqlErrorsOnFormik<FormikFields, Remove_MembershipMutationVariables, DELETE_MEMBERSHIP_ERROR>(
          err,
          formikBag,
          {
            translations: {
              default: intl.formatMessage(commonErrorsTranslations.default),
              network: intl.formatMessage(commonErrorsTranslations.network),
              unknown: intl.formatMessage(commonErrorsTranslations.technical),
              global: {
                [DELETE_MEMBERSHIP_ERROR.MEMBERSHIP_ROLE_PROJECT_OWNER_CANNOT_LEAVE]: intl.formatMessage(
                  errorTranslations.MEMBERSHIP_ROLE_PROJECT_OWNER_CANNOT_LEAVE,
                ),
              },
            },
          },
        ),
      );
  };
}
