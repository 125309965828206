import React from 'react';
import { useIntl } from 'react-intl';
import { useNavigate, generatePath } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../Routes';
import { ImportParticipantsTabs } from '../../@types';
import './GroupActions.scss';
import TabSwitch from './components/TabSwitch';
import translations from './GroupActions.translations';

export type GroupActionsProps = {
  setTab: (tab: ImportParticipantsTabs) => void;
  activeTab: ImportParticipantsTabs;
  programId: string;
};

export default function GroupActions({ setTab, activeTab, programId }: GroupActionsProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <div className="group-actions">
      <div className="group-actions__tabs">
        <button
          type="button"
          className="resend-button"
          onClick={() => navigate(generatePath(SchoolRoutes.programShow, { programid: programId }))}
        >
          Retour
        </button>
        <TabSwitch
          activeTab={activeTab}
          setTab={setTab}
        />
      </div>
      <div className="actions-buttons">
        <button
          type="button"
          className="resend-button"
        >
          {intl.formatMessage(translations.resend)}
        </button>
      </div>
    </div>
  );
}
