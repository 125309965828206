import { defineMessages } from 'react-intl';

export default defineMessages({
  invited: {
    id: 'StatusCell.invited',
    defaultMessage: 'En attente…',
  },
  invitedFrom: {
    id: 'StatusCell.invitedFrom',
    defaultMessage: 'Invité(e) le {date}',
  },
  declined: {
    id: 'StatusCell.declined',
    defaultMessage: 'Invitation déclinée',
  },
  declinedFrom: {
    id: 'StatusCell.declinedFrom',
    defaultMessage: 'le {date}',
  },
  member: {
    id: 'StatusCell.member',
    defaultMessage: 'Membre',
  },
  memberFrom: {
    id: 'StatusCell.memberFrom',
    defaultMessage: 'Depuis le {date}',
  },
});
