import React from 'react';
import { Link, matchPath, useLocation } from 'react-router-dom';
import { TabNavigationItem } from '../../@types/TabNavigationItem';

import styles from './TabItem.module.scss';
import classNames from 'classnames';

interface TabItemProps {
  item: TabNavigationItem;
}
function TabItem({ item }: TabItemProps) {
  const location = useLocation();
  const match = item.routes.find((route) => matchPath(route, location.pathname));

  return (
    <Link
      to={item.link}
      role="tab"
      className={classNames(styles.item, {
        [styles.current]: match,
      })}
      aria-current={match ? 'location' : undefined}
    >
      {item.label}
    </Link>
  );
}

export default TabItem;