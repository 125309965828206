import { gql } from '@apollo/client';

export const GET_PARTICIPATION_CERTIFICATION_STATUS = gql`
  query GET_PARTICIPATION_CERTIFICATION_STATUS($participationId: ID!) {
    participation(id: $participationId) {
      id
      certificationStatus
      unfollowedConversationCount
    }
  }
`;
