import { gql } from '@apollo/client';

export const CREATE_GOUV_SCHOOL = gql`
  mutation CREATE_GOUV_SCHOOL($name: String!, $communeName: String, $establishmentType: String, $departmentLabel: String) {
    createGouvSchool(
      name: $name
      communeName: $communeName
      establishmentType: $establishmentType
      departmentLabel: $departmentLabel
    ) {
      id
      name
    }
  }
`;
