import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ParticipantsActivityBoard.title',
    defaultMessage: 'Activité des participants',
  },
  description: {
    id: 'ParticipantsActivityBoard.description',
    defaultMessage: 'Retrouvez plus de données dans <link>votre page projet ></link>',
  },
});
