import { gql } from '@apollo/client';
import { ProgramPublicationStatus } from '../../../../../../../../@types/graphql';

export const CREATE_NEW_GROUP = gql`
  mutation CREATE_NEW_GROUP(
    $projectId: ID!,
    $parentId: ID,
    $name: String!
  ) {
    createGroup(input: {
      name: $name,
      projectId: $projectId,
      parentId: $parentId,
    }) {
      id
      name
      canUpdate {
        value
      }
      canCreateGroup {
        value
      }
      canDelete {
        value
      }
      programs(filter: {
        query: {
          filterBy: programPublicationStatus,
          operator: eq,
          value: "${ProgramPublicationStatus.Published}"
        }
      }) {
        nodes {
          id
          name
        }
      }
      groups {
        nodes {
          id
          name
        }
      }
    }
  }
`;

export const UNTITLED_GROUPS_ON_GROUPS_FRAGMENT = gql`
  fragment UNTITLED_GROUPS_ON_GROUPS_FRAGMENT on Group {
    groups {
      nodes {
        id
        name
        canUpdate {
          value
        }
        canCreateGroup {
          value
        }
        canDelete {
          value
        }
      }
    }
  }
`;

export const UNTITLED_GROUPS_ON_PROJECT_FRAGMENT = gql`
  fragment UNTITLED_GROUPS_ON_PROJECT_FRAGMENT on Project {
    directGroups {
      nodes {
        id
        name
        canUpdate {
          value
        }
        canCreateGroup {
          value
        }
        canDelete {
          value
        }
      }
    }
  }
`;
