import React, { Suspense } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import client from './scenes/schools/config/graphql';
import * as Sentry from '@sentry/react';

import 'react-virtualized/styles.css';
import { ApolloProvider } from '@apollo/client';
import SnackbarProvider from './components/snackbar/provider/SnackbarProvider';
import { IntlProvider } from 'react-intl';
import IntercomProvider from './scenes/schools/commons/intercom-provider/IntercomProvider';
import Layout from './scenes/schools/layout/layout/Layout';
import PlateformDisplay from './scenes/schools/commons/plateform-display/PlateformDisplay';
import { SchoolRoutes } from './Routes';
import ProjectCreateUpdate from './scenes/schools/pages/projects-create-update/ProjectCreateUpdate';
import Crash from './scenes/schools/pages/crash/Crash';
import Page404 from './scenes/schools/commons/page-404/Page404';
import ImportsHistory from './scenes/schools/pages/imports-history/ImportsHistory';
import ImportParticipants from './scenes/schools/pages/import-participants/ImportParticipants';
import Homepage from './scenes/schools/pages/homepage/Homepage';
import ParticipationShow from './scenes/schools/pages/participation-show/ParticipationShow';
import ProgramUpdate from './scenes/schools/pages/program-update/ProgramUpdate';
import ProgramShow from './scenes/schools/pages/program-show/ProgramShow';
import ProgramCreate from './scenes/schools/pages/program-create/ProgramCreate';
import InvitationJoin from './scenes/schools/pages/invitation-join/InvitationJoin';
import ProjectArchivedList from './scenes/schools/pages/project-archived-list/ProjectArchivedList';
import ProjectShow from './scenes/schools/pages/project-show/ProjectShow';

import './App.scss';
import { HelmetProvider } from 'react-helmet-async';
import ProjectStatisticsDisplay from './scenes/schools/pages/project-show/components/project-show-stats/ProjectStatisticsDisplay';
import ProjectShowStructure from './scenes/schools/pages/project-show/components/project-show-structure/ProjectShowStructure';
import ProjectShowMembers from './scenes/schools/pages/project-show/components/project-show-members/ProjectShowMembers';
import ArchiveModal from './scenes/schools/pages/project-show/components/archive-modal/ArchiveModal';
import ProgramStudentAffiliationModal from './scenes/schools/pages/program-show/components/program-student-affiliation-modal/ProgramStudentAffiliationModal';
import ProgramDuplicateModal from './scenes/schools/pages/program-show/components/duplicate-modal/ProgramDuplicateModal';
import ProgramArchiveModal from './scenes/schools/pages/program-show/components/archive-modal/ProgramArchiveModal';
import ArchiveParticipationModal from './scenes/schools/pages/program-show/components/participants-activity-board/components/participants-table/components/archive-participation-modal/ArchiveParticipationModal';
import RecoverParticipationModal from './scenes/schools/pages/program-show/components/participants-activity-board/components/participants-table/components/recover-participation-modal/RecoverParticipationModal';
import RecoverProjectModal from './scenes/schools/pages/project-archived-list/components/recover-project-modal/RecoverProjectModal';

const App = () => {

  const SentryRoutes = Sentry.withSentryReactRouterV7Routing(Routes);

  return (
    <Suspense fallback={<div>Loading...</div>}>
      <HelmetProvider>
        <ApolloProvider client={client}>
          <Router>
            <SnackbarProvider>
              <IntlProvider
                defaultLocale="fr"
                locale="fr"
              >
                <IntercomProvider>
                  <Layout>
                    {import.meta.env.REACT_APP_ENVIRONMENT === 'staging' && <PlateformDisplay />}
                    <SentryRoutes>
                      <Route
                        path={SchoolRoutes.projectCreate}
                        element={<ProjectCreateUpdate />}
                      />
                      <Route
                        path={SchoolRoutes.projectUpdate}
                        element={<ProjectCreateUpdate />}
                      />
                      <Route
                        path={SchoolRoutes.createGouvSchool}
                        element={<ProjectCreateUpdate />}
                      />
                      <Route
                        path={SchoolRoutes.projectShow}
                        element={<ProjectShow />}
                      >
                        <Route
                          path={SchoolRoutes.projectStats}
                          element={<ProjectStatisticsDisplay />}
                        />
                        <Route
                          path={SchoolRoutes.projectCustomize}
                          element={<ProjectShowStructure />}
                        />
                        <Route
                          path={SchoolRoutes.projectGroupDelete}
                          element={<ProjectShowStructure />}
                        />
                        <Route
                          path={SchoolRoutes.projectArchive}
                          element={<ArchiveModal />}
                        />
                        {[
                          SchoolRoutes.projectMembers,
                          SchoolRoutes.projectMembersCreateInvitation,
                          SchoolRoutes.projectMemberInvitationAddToProgram,
                          SchoolRoutes.projectMemberAddToProgram,
                          SchoolRoutes.projectMembersEditInvitation,
                          SchoolRoutes.projectMemberEdit,
                          SchoolRoutes.projectMemberDelete,
                        ].map((path) => (
                          <Route
                            key={path}
                            path={path}
                            element={<ProjectShowMembers />}
                          />
                        ))}
                      </Route>
                      <Route
                        path={SchoolRoutes.projectArchivedList}
                        element={<ProjectArchivedList />}
                      >
                        <Route
                          path={SchoolRoutes.projectArchivedListRecover}
                          element={<RecoverProjectModal />}
                        />
                      </Route>

                      <Route
                        path={SchoolRoutes.projectArchivedList}
                        element={<ProjectArchivedList />}
                      />
                      <Route
                        path={SchoolRoutes.projectJoin}
                        element={<InvitationJoin />}
                      />
                      <Route
                        path={SchoolRoutes.projectProgramCreate}
                        element={<ProgramCreate />}
                      />
                      <Route
                        path={SchoolRoutes.groupProgramCreate}
                        element={<ProgramCreate />}
                      />
                      <Route
                        path={SchoolRoutes.programShow}
                        element={<ProgramShow />}
                      >
                        <Route
                          path={SchoolRoutes.programShowRemoveParticipation}
                          element={<ArchiveParticipationModal />}
                        />

                        <Route
                          path={SchoolRoutes.programShowRecoverParticipation}
                          element={<RecoverParticipationModal />}
                        />
                        <Route
                          path={SchoolRoutes.programStudentAffiliationHelp}
                          element={<ProgramStudentAffiliationModal />}
                        />
                        <Route
                          path={SchoolRoutes.programArchive}
                          element={<ProgramArchiveModal />}
                        />
                        <Route
                          path={SchoolRoutes.programDuplicate}
                          element={<ProgramDuplicateModal />}
                        />
                      </Route>
                      <Route
                        path={SchoolRoutes.programUpdate}
                        element={<ProgramUpdate />}
                      />
                      <Route
                        path={SchoolRoutes.participationShow}
                        element={<ParticipationShow />}
                      />
                      <Route
                        path={SchoolRoutes.home}
                        element={<Homepage />}
                      />
                      <Route
                        path={SchoolRoutes.projectStats}
                        element={<ProjectStatisticsDisplay />}
                      />
                      <Route
                        path={SchoolRoutes.importParticipants}
                        element={<ImportParticipants />}
                      />
                      <Route
                        path={SchoolRoutes.importsHistory}
                        element={<ImportsHistory />}
                      />
                      <Route
                        path={SchoolRoutes.crash}
                        element={<Crash />}
                      />
                      <Route element={<Page404 />} />
                      {/* <Navigate to={Routes.schoolAdmin} /> */}
                    </SentryRoutes>
                  </Layout>
                </IntercomProvider>
              </IntlProvider>
            </SnackbarProvider>
          </Router>
        </ApolloProvider>
      </HelmetProvider>
    </Suspense>
  );
};

export default App;
