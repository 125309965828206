import { gql } from '@apollo/client';
import { ProgramPublicationStatus } from '../../../../../../@types/graphql';

export const INVIT_USER_TO_PROJECT = gql`
  mutation INVIT_USER_TO_PROJECT($projectId: ID!, $invitations: [CreateInvitationInput!]!) {
    createInvitation(projectId: $projectId, invitations: $invitations) {
      invitations {
        id
      }
    }
  }
`;

export const GET_PROJECT_MEMBERS_INVITATION_AUTORIZATION = gql`
  query GET_PROJECT_MEMBERS_INVITATION_AUTORIZATION($id: ID!) {
    project(id: $id) {
      id
      myRole
      canCreateInvitation {
        value
      }
      programs(filter: {
        query: {
          filterBy: programPublicationStatus,
          operator: eq,
          value: "${ProgramPublicationStatus.Published}"
        }
      }) {
        nodes {
          id
          name
          group {
            name
          }
        }
      }
    }
  }
`;

export const NEW_INVITATION_FRAGMENT = gql`
  fragment NEW_INVITATION_FRAGMENT on Invitation {
    id
    email
    role
    createdAt
    updatedAt
    invitationStatus
    canUpdate {
      value
    }
    canDelete {
      value
    }
  }
`;
