import { useEffect } from "react";
import * as Sentry from "@sentry/react";
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from "react-router";

type BreadcrumbCategory = 'Network' | 'UI';
type ErrorNamespace = 'GraphQL' | 'Frontend';
type GraphQLQueryType = 'query' | 'mutation';

const ANONYMIZED_FIELDS = [
    'password',
    'email',
    'name',
    'phone',
    'gender',
    'presentation',
    'address',
    'birthdate',
    'meeting_place',
    'meetingplace',
];

class ErrorTracker {
    private _userId?: string;

    constructor() {

        Sentry.init({
            dsn: "https://ab41607bb0cd442c598ed3588590dbc0@sentry.mjg-staging.com/7", 
            integrations: [
                Sentry.reactRouterV7BrowserTracingIntegration({
                    useEffect: useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
            ],
            // Learn more at
            // https://docs.sentry.io/platforms/javascript/session-replay/configuration/#general-integration-configuration
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
            tracesSampleRate: 1.0,
            tracePropagationTargets: [/^https:\/\/api-admin\.myjobglasses\.com/],
        });
    }

    public set userId(userId: string) {
        this._userId = userId;
        Sentry.setUser({ id: userId });
    }

    //   public getBoundaryErrorHandler() {
    //     return (error: Error) => {
    //       setTimeout(() => {
    //         const name = `[${error.name}] ${error.message}`;
    //         const desc = error.stack || '';

    //         const tags: { [key: string]: string } = {};
    //         if (error.cause) tags.cause = JSON.stringify(error.cause);

    //         this.sendError(name, desc, {
    //           namespace: 'Frontend',
    //           tags,
    //         });
    //       }, 500);
    //     };
    //   }

    public addNetworkBreadcrumb(message: string, type: GraphQLQueryType) {
        this.addBreadcrumb('Network', message, { queryType: type });
    }

    public addUIBreadcrumb(message: string) {
        this.addBreadcrumb('UI', message);
    }

    private addBreadcrumb(category: BreadcrumbCategory, message: string, data?: { [key: string]: string }) {
        Sentry.addBreadcrumb({
            category,
            message,
            data,
            level: 'info',
        });
    }

    public sendError(
        name: string,
        description: string,
        opts: {
            namespace: ErrorNamespace;
            payload?: object;
            tags?: { [key: string]: string };
        },
    ) {
        const error = new Error(description);
        error.name = name;

        const tags = opts.tags || {};
        if (opts.payload) {
            this.anonymizePayload(opts.payload);
            tags.payload = JSON.stringify(opts.payload);
        }

        if (this._userId) tags.userId = this._userId;

        try {
            Sentry.withScope((scope) => {
                scope.setTags({ namespace: opts.namespace, ...tags });
                Sentry.captureException(error);
            });
        } catch (e) {
            console.error(e);
        }
    }

    private anonymizePayload(object: any) {
        Object.keys(object).forEach((key) => {
            if (ANONYMIZED_FIELDS.some((field) => key.toLowerCase().includes(field.toLowerCase()))) {
                object[key] = '[ANONYMIZED]';
            }
        });
    }

}

export const errorTracker = new ErrorTracker();