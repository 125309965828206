import { defineMessages } from 'react-intl';

export default defineMessages({
  appointment: {
    id: 'AppointmentItem.appointment',
    defaultMessage: 'Rendez-vous',
  },
  seeProfile: {
    id: 'AppointmentItem.seeProfile',
    defaultMessage: 'Voir profil',
  },
});
