import Icon from '../../../../../../components/icon/Icon';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { useOutsideClickEffect } from '../../../../../../utils/useOutsideClickEffect';
import { BreadcrumbsItem } from '../../../context/HeaderContext';

import styles from './BreadcrumbOverflowItem.module.scss';

interface BreadcrumbOverflowItemProps {
  items: BreadcrumbsItem[];
}
function BreadcrumbOverflowItem({ items }: BreadcrumbOverflowItemProps) {
  const [isVisibleDropdown, setDropdownVisibility] = useState(false);
  function toggle() {
    setDropdownVisibility(!isVisibleDropdown);
  }
  function close() {
    setDropdownVisibility(false);
  }
  const ref = useOutsideClickEffect<HTMLDivElement>(close);

  return (
    <>
      <div
        ref={ref}
        className={styles.dropdownWrapper}
        aria-haspopup
        aria-expanded={isVisibleDropdown}
      >
        <button
          type="button"
          onClick={toggle}
          className={styles.breadcrumbOverflowItem}
        >
          ...
        </button>
        <ul
          role="menu"
          hidden={!isVisibleDropdown}
          className={styles.dropdown}
        >
          {items.map((item) => (
            <li
              key={`breadcrumb_overflow_${item.name}`}
              className={styles.item}
              role="menuitem"
            >
              <Link
                to={item.link}
                onClick={close}
              >
                {item.iconName && (
                  <Icon
                    name={item.iconName}
                    className={styles.icon}
                  />
                )}
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      <span role="separator">
        <Icon
          name="navigate_next"
          className={styles.divider}
        />
      </span>
    </>
  );
}

export default BreadcrumbOverflowItem;
