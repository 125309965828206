import { useState } from 'react';
import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../components/snackbar/hooks/useSnackbar';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { Formik } from 'formik';
import { SchoolRoutes } from '../../../../Routes';
import ImportCard from './components/ImportCard';
import translations, { toastrMessages } from './ImportParticipantsModal.translations';
import './ImportParticipantsModal.scss';
import { IMPORT_PARTICIPANTS } from './gql/UploadParticipantsTable.gql';

type ImportParticipantsModalProps = {
  programId: string;
};

function ImportParticipantsTab({ programId }: ImportParticipantsModalProps) {
  const intl = useIntl();
  const { error, success } = useSnackbar();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState('');

  const [importParticipants] = useMutation(IMPORT_PARTICIPANTS, {
    onCompleted: () => {
      success(intl.formatMessage(translations.success));
      navigate(
        generatePath(SchoolRoutes.importsHistory, {
          programid: programId,
        }),
      );
    },
    onError: (err) => {
      const errorCode = err.graphQLErrors?.[0]?.extensions?.code;
      if (errorCode && (errorCode as keyof typeof toastrMessages)) {
        const toastrMessage = intl.formatMessage(toastrMessages[errorCode as keyof typeof toastrMessages]);
        if (toastrMessage) {
          error(toastrMessage);
        } else {
          error(intl.formatMessage(toastrMessages.error));
        }
      } else {
        error(err.message);
        error(intl.formatMessage(toastrMessages.error));
      }
    },
  });

  return (
    <Formik
      initialValues={{
        file: {} as File,
      }}
      onSubmit={(values) => {
        if (values.file) {
          importParticipants({
            variables: {
              programId,
              file: values.file,
            },
          });
        }
      }}
    >
      {({ values, handleSubmit, setFieldValue }) => (
        <>
          <h2 className="import-participants-modal--section-title">{intl.formatMessage(translations.sectionTitle)}</h2>
          <div className="import-participants-modal--upload-container">
            <ImportCard
              number={'1'}
              title={intl.formatMessage(translations.downloadTemplateCardTitle)}
              description={intl.formatMessage(translations.downloadTemplateCardDescription)}
              htmlElement={
                <a
                  href="https://assets.myjobglasses.com/document/invitation_upload_template.xlsx"
                  download
                  className="import-participants-modal--file-example-button"
                >
                  {intl.formatMessage(translations.downloadTemplateCardButton)}
                </a>
              }
            />
            <ImportCard
              number={'2'}
              title={intl.formatMessage(translations.uploadFileCardTitle)}
              description={intl.formatMessage(translations.uploadFileCardDescription)}
              htmlElement={
                <label
                  htmlFor="file"
                  className="fileButton"
                >
                  {fileName || intl.formatMessage(translations.uploadFileCardButton)}
                  <input
                    id="file"
                    name="file"
                    type="file"
                    className="fileInput form-control import-participants-modal--file-uploader-input-label"
                    onChange={(event) => {
                      if (event?.currentTarget?.files?.[0]) {
                        setFileName(event?.currentTarget?.files?.[0].name);
                        setFieldValue('file', event?.currentTarget?.files?.[0]);
                      }
                    }}
                  />
                </label>
              }
            />
            <ImportCard
              number={'3'}
              title={intl.formatMessage(translations.submitFormCardTitle)}
              description={intl.formatMessage(translations.submitFormCardDescription)}
              htmlElement={
                <button
                  type="button"
                  className="fileButton"
                  onClick={() => handleSubmit()}
                  disabled={!values.file}
                >
                  {intl.formatMessage(translations.submitFormCardButton)}
                </button>
              }
            />
          </div>
          <h2 className="import-participants-modal--section-title">{intl.formatMessage(translations.instructions)}</h2>
          <ul className="import-participants-modal--list">
            <li className="import-participants-modal--list-item">{intl.formatMessage(translations.firstItem)}</li>
            <li className="import-participants-modal--list-item">{intl.formatMessage(translations.secondItem)}</li>
            <li className="import-participants-modal--list-item">{intl.formatMessage(translations.thirdItem)}</li>

            <li className="import-participants-modal--list-item">{intl.formatMessage(translations.fourthItem)}</li>
            <li className="import-participants-modal--list-item">{intl.formatMessage(translations.fifthItem)}</li>

            <li className="import-participants-modal--list-item">{intl.formatMessage(translations.sixthItem)}</li>
            <li className="import-participants-modal--list-item">{intl.formatMessage(translations.seventhItem)}</li>

            <li className="import-participants-modal--list-item">{intl.formatMessage(translations.eighthItem)}</li>
            <li className="import-participants-modal--list-item">{intl.formatMessage(translations.ninthItem)}</li>
          </ul>
        </>
      )}
    </Formik>
  );
}

export default ImportParticipantsTab;
