import React from 'react';
import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, Link, useParams, Outlet } from 'react-router-dom';
import Icon from '../../../../components/icon/Icon';
import TabNavigation from '../../../../components/tab-navigation/TabNavigation';
import { SchoolRoutes } from '../../../../Routes';
import { READ_PROJECT_ERRORS } from '../../@types/graphql-errors';
import RoleBadge from '../../commons/role-badge/RoleBadge';
import PageError from '../../commons/page-error/PageError';
import PageLoading from '../../commons/page-loading/PageLoading';
import PageClassic from '../../layout/pages/page-classic/PageClassic';
import ArchiveModal from './components/archive-modal/ArchiveModal';
import ProjectStatisticsDisplay from './components/project-show-stats/ProjectStatisticsDisplay';
import ProjectStatisticsNoPrograms from './components/project-show-stats/components/project-statistics-no-programs/ProjectStatisticsNoPrograms';
import PromptProgramCreateBanner from './components/prompt-program-create-banner/PromptProgramCreateBanner';
import useFiltersParameter from './hooks/useFiltersParameter';
import { Get_Project_InfoQuery, Get_Project_InfoQueryVariables } from '../../@types/graphql';
import ActionButton from './components/action-button/ActionButton';
import { GET_PROJECT_INFO } from './ProjectShow.gql';
import translations from './ProjectShow.translations';

import styles from './ProjectShow.module.scss';

function ProjectShow() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { projectid } = useParams();
  const { programIds, includeArchived } = useFiltersParameter();

  const { data, loading, error, refetch } = useQuery<Get_Project_InfoQuery, Get_Project_InfoQueryVariables>(
    GET_PROJECT_INFO,
    {
      variables: {
        id: projectid || '',
        programIds,
        includeArchived,
      },
      fetchPolicy: 'cache-and-network',
    },
  );

  if (error?.graphQLErrors?.[0]?.extensions?.code === READ_PROJECT_ERRORS.UNAUTHORIZED) {
    navigate(SchoolRoutes.home);
    return null;
  }

  if (error || !projectid) {
    return <PageError refetch={refetch} />;
  }

  if (loading && !data) {
    return <PageLoading />;
  }

  const programsCount = data?.project?.programs?.total;

  const statTabComponent = programsCount === 0 ? ProjectStatisticsNoPrograms : ProjectStatisticsDisplay;

  const tabItems = [
    {
      label: intl.formatMessage(translations.tabStats),
      link: generatePath(SchoolRoutes.projectStats, { projectid: projectid }),
      routes: [SchoolRoutes.projectStats],
    },
    {
      label: intl.formatMessage(translations.tabStructure),
      link: generatePath(SchoolRoutes.projectCustomize, { projectid: projectid }),
      routes: [SchoolRoutes.projectCustomize, SchoolRoutes.projectGroupDelete],
    },
    {
      label: intl.formatMessage(translations.tabMembers),
      link: generatePath(SchoolRoutes.projectMembers, { projectid: projectid }),
      routes: [
        SchoolRoutes.projectMembers,
        SchoolRoutes.projectMembersCreateInvitation,
        SchoolRoutes.projectMemberInvitationAddToProgram,
        SchoolRoutes.projectMemberAddToProgram,
        SchoolRoutes.projectMembersEditInvitation,
        SchoolRoutes.projectMemberEdit,
        SchoolRoutes.projectMemberDelete,
      ],
    },
  ];

  const actions = [
    <ActionButton
      onClick={() =>
        navigate(
          generatePath(SchoolRoutes.projectUpdate, {
            projectid: projectid || '',
          }),
        )
      }
      iconName="edit"
      name={intl.formatMessage(translations.editAction)}
      show={!data?.project?.canUpdate.value}
    />,
    <ActionButton
      onClick={() =>
        navigate(
          generatePath(SchoolRoutes.projectArchive, {
            projectid: projectid || '',
          }),
        )
      }
      iconName="archive"
      name={intl.formatMessage(translations.archive)}
      show={!data?.project?.canArchive.value}
    />,
  ];

  return (
    <PageClassic
      title={data?.project?.name}
      iconName="assessment"
      actions={actions}
      loading={loading && !data}
      badge={<RoleBadge role={data?.project?.myRole || undefined} />}
    >
      <div className={styles.projectHeader}>
        <p
          data-testid="project-show-organization-name"
          className={classnames(styles.projectHeader__organization, {
            [styles.projectHeader__loading]: loading,
          })}
        >
          {data?.project?.schools?.nodes?.length ? data?.project?.schools?.nodes[0]?.name : data?.project?.organization?.name}
        </p>

        <PromptProgramCreateBanner />
        <div className={styles.projectHeader__footer}>
          <p className={styles.projectHeader__footer__description}>
            {intl.formatMessage(translations.description)}
            <br />
            <a
              href="https://help.myjobglasses.com/fr/collections/194721-creer-un-programme-d-accompagnement"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.projectHeader__footer__description__link}
              data-testid="see-more-link"
            >
              {intl.formatMessage(translations.seeMore)}
              <Icon
                name="chevron_right"
                className={styles.projectHeader__footer__description__icon}
              />
            </a>
          </p>
          {data?.project?.myRole !== 'guest' && (
            <Link
              to={generatePath(SchoolRoutes.projectProgramCreate, {
                projectid: projectid,
              })}
              className={styles.projectHeader__footer__button}
            >
              <Icon
                name="add"
                className={styles.projectHeader__footer__button__icon}
              />
              {intl.formatMessage(translations.addProgramButton)}
            </Link>
          )}
        </div>
      </div>
      <TabNavigation items={tabItems} />
      <ArchiveModal />
      <Outlet />
    </PageClassic>
  );
}

export default ProjectShow;
