import { ProjectStructureDiagramDimensions } from '../../../../enums/Dimensions';

interface DiagramProgramItemProps {
  x: number;
  y: number;
  label: string;
}
const GROUP_ITEM_HEIGHT = 35;
export default function DiagramProgramItem({ x, y, label }: DiagramProgramItemProps) {
  return (
    <g
      width={ProjectStructureDiagramDimensions.ITEM_WIDTH}
      height={GROUP_ITEM_HEIGHT}
      x={x}
      y={y}
      transform={`translate(${x}, ${y})`}
    >
      <rect
        x={-(ProjectStructureDiagramDimensions.ITEM_WIDTH / 2)}
        y={-(GROUP_ITEM_HEIGHT / 2)}
        width={ProjectStructureDiagramDimensions.ITEM_WIDTH}
        height={GROUP_ITEM_HEIGHT}
        fill="#b7b7b7"
        rx={4}
        style={{ filter: 'url(#DP01)' }}
      />
      <text
        x={0}
        y={4}
        textAnchor="middle"
        fontSize={12}
        fill="rgba(14,14,17,0.68)"
      >
        {label}
      </text>
    </g>
  );
}
