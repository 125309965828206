import ProgressBar from '../../../../../../../../../../components/progress-bar/ProgressBar';
import classnames from 'classnames';
import styles from './ProgressCell.module.scss';

/* istanbul ignore next */
export default function ProgressCell(props: any) {
  const meetingsCount = Math.round((props.cellData.row?.confirmedMeetingCount / props.meetingsQuantity) * 100);
  const unfollowedConversationCount = props.cellData.row?.unfollowedConversationCount || 0;
  const reportCount = props.cellData.row?.reportCount || 0;
  return (
    <ProgressBar
      count={Math.min(meetingsCount, 100)}
      className={styles.progressbar}
      valueClassName={classnames(styles.value, {
        [styles.full]: meetingsCount >= 100,
        [styles.reported]: unfollowedConversationCount > 0 || reportCount > 0,
      })}
      barClassName={classnames(styles.bar, {
        [styles.full]: meetingsCount >= 100,
        [styles.reported]: unfollowedConversationCount > 0 || reportCount > 0,
      })}
    />
  );
}
