import React from 'react';
import Modal from '../../../../../../components/modal/Modal';
import ModalHeader from '../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../components/modal/components/modal-footer/ModalFooter';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams, matchPath } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../Routes';
import commonButtonsTranslations from '../../../../commons/translations/buttons.translations';
import translations from './PreventLeaveProgramCreation.translations';

import styles from './PreventLeaveProgramCreation.module.scss';

export default function PreventLeaveProgramCreation() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { projectid } = useParams();

  const match =
    matchPath(SchoolRoutes.projectProgramCreate, location.pathname) ||
    matchPath(SchoolRoutes.groupProgramCreate, location.pathname);

  function leave() {
    navigate(
      generatePath(SchoolRoutes.projectStats, {
        projectid: projectid!,
      }),
    );
  }

  function cancel() {
    if (match) {
      navigate(generatePath(match?.pathname, match.params));
    }
  }

  if (!match) {
    return null;
  }

  return (
    <Modal
      title={intl.formatMessage(translations.title)}
      openPathname={[`${SchoolRoutes.projectProgramCreate}/leave`, `${SchoolRoutes.groupProgramCreate}/leave`]}
      closeUrl={generatePath(match?.pathname, match?.params)}
    >
      <ModalHeader title={intl.formatMessage(translations.title)} />
      <ModalBody className={styles.modalBody}>
        <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
      </ModalBody>
      <ModalFooter
        cancelFunction={cancel}
        cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
        submitFunction={leave}
        submitText={intl.formatMessage(commonButtonsTranslations.confirm)}
        className={styles.modalFooter}
      />
    </Modal>
  );
}
