import React from 'react';
import { useEffect } from 'react';
import classnames from 'classnames';
import { generatePath, Link } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../Routes';
import useProgramRouteMatch from '../../../../../../hooks/use-program-route-match/useProgramRouteMatch';
import { TreeNavProgramItem } from '../../../../@types/TreeNavComponent';
import ProgramDot from '../../../../../../commons/program-dot/ProgramDot';

import styles from './TreeNavProgram.module.scss';

interface TreeNavProgramProps {
  program: TreeNavProgramItem;
  level: number;
  openParent: () => void;
}
export default function TreeNavProgram({ program, level, openParent }: TreeNavProgramProps) {
  const [isCurrentLocation] = useProgramRouteMatch(program.id);

  useEffect(() => {
    if (isCurrentLocation) {
      openParent();
    }
  }, [isCurrentLocation]);

  return (
    <li
      data-testid={program.id}
      role="treeitem"
    >
      <div
        className={classnames(styles.treeNavProgram, {
          [styles.currentLocation]: isCurrentLocation,
        })}
        style={{
          paddingLeft: 16 + (18 + 8) * level,
        }}
      >
        <Link
          to={generatePath(SchoolRoutes.programShow, { programid: program.id })}
          className={styles.link}
          aria-current={isCurrentLocation ? 'location' : undefined}
        >
          <ProgramDot />
          {program.name}
        </Link>
      </div>
    </li>
  );
}
