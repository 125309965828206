import { defineMessages } from 'react-intl';
import { CREATE_GROUP_ERROR } from '../../../../../../../../@types/graphql-errors';

export const errorTranslations = defineMessages({
  [CREATE_GROUP_ERROR.UNAUTHORIZED]: {
    id: 'useAddGroupMutation.UNAUTHORIZED',
    defaultMessage: 'Cette action est réservée aux membres administrateurs du projet.',
  },
});

export default defineMessages({
  untitledGroup: {
    id: 'useAddGroupMutation.untitledGroup',
    defaultMessage: 'Sans titre',
  },
});
