import { gql } from '@apollo/client';
import { InvitationStatus } from '../../../../@types/graphql';

export const GET_MY_PROJECT_LIST = gql`
  query GET_MY_PROJECT_LIST {
    projects {
      nodes {
        id
        name
        myRole
        organization {
          name
        }
        schools {
          nodes {
            name
          }
    }
        memberships {
          nodes {
            id
            userFirstName
            userLastName
          }
        }
      }
    }
    me {
      id
      invitations(filter: {
        query: [
          {filterBy: invitationStatus, operator: eq, value: "${InvitationStatus.Sent}"}
        ]
      }) {
        nodes {
          id
          project {
            id
            name
            organization {
              name
            }
          }
        }
      }
      archivedProjects {
        total
      }
    }
  }
`;
