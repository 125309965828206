import { defineMessages } from 'react-intl';

export default defineMessages({
  searchTerm: {
    id: 'ParticipantsTable.searchTerm',
    defaultMessage: 'Rechercher par prénom et/ou nom…',
  },
  statusFilter: {
    id: 'ParticipantsTable.statusFilter',
    defaultMessage: 'Statut de l\'invitation',
  },
  firstNameHeaderLabel: {
    id: 'ParticipantsTable.firstNameHeaderLabel',
    defaultMessage: 'Prénom',
  },
  lastNameHeaderLabel: {
    id: 'ParticipantsTable.lastNameHeaderLabel',
    defaultMessage: 'Nom',
  },
  accepted: {
    id: 'ParticipantsTable.accepted',
    defaultMessage: 'Acceptée',
  },
  processing: {
    id: 'ParticipantsTable.processing',
    defaultMessage: 'En cours',
  },
  sent: {
    id: 'ImportParticipantsTable.sent',
    defaultMessage: 'Envoyée',
  },
  rejected: {
    id: 'ImportParticipantsTable.rejected',
    defaultMessage: 'Refusée',
  },
  invitationStatus: {
    id: 'ParticipantsTable.invitationStatus',
    defaultMessage: "Statut de l'invitation",
  },
  createdAt: {
    id: 'ParticipantsTable.createdAt',
    defaultMessage: "Date d'affiliation",
  },
  profilesViewed: {
    id: 'ParticipantsTable.profilesViewed',
    defaultMessage: 'Profils consultés',
  },
  prosContacted: {
    id: 'ParticipantsTable.prosContacted',
    defaultMessage: 'Ambassadeurs contactés',
  },
  upcomingAppt: {
    id: 'ParticipantsTable.upcomingAppt',
    defaultMessage: 'RDV à venir',
  },
  activeParticipations: {
    id: 'ParticipantsTable.activeParticipations',
    defaultMessage: 'Actifs ({count})',
  },
  archivedParticipations: {
    id: 'ParticipantsTable.archivedParticipations',
    defaultMessage: 'Archivés ({count})',
  },
  confirmedPastAppt: {
    id: 'ParticipantsTable.confirmedPastAppt',
    defaultMessage: 'RDV réalisé',
  },
  waitingForConfirmationMeetingCount: {
    id: 'ParticipantsTable.waitingForConfirmationMeetingCount',
    defaultMessage: 'RDV passés en attente de confirmation',
  },
  target: {
    id: 'ParticipantsTable.target',
    defaultMessage: 'Objectif',
  },
  participantsSelected: {
    id: 'ParticipantsTable.participantsSelected',
    defaultMessage:
      '{x, plural, zero {# participant sélectionné} =1 {# participant sélectionné} other {# participants sélectionnés}}',
  },
  removeButton: {
    id: 'ParticipantsTable.removeButton',
    defaultMessage: 'Archiver',
  },
  recoverButton: {
    id: 'ParticipantsTable.recoverButton',
    defaultMessage: 'Réactiver',
  },
});
