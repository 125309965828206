import { useMutation } from '@apollo/client';
import {
  Cancel_Program_CreationMutation,
  Cancel_Program_CreationMutationVariables,
} from '../../../../../@types/graphql';
import { CANCEL_PROGRAM_CREATION } from './useCancelProgramCreationAction.gql';

export default function useCancelProgramCreationAction(programId?: string) {
  const [cancelProgramCreation] = useMutation<
    Cancel_Program_CreationMutation,
    Cancel_Program_CreationMutationVariables
  >(CANCEL_PROGRAM_CREATION);

  return () =>
    programId &&
    cancelProgramCreation({
      variables: {
        id: programId,
      },
    });
}
