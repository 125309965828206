import React from 'react';
import { useIntl } from 'react-intl';
import translations from '../../ParticipantsTable.translations';
import TextInput from '../../../../../../../../../../components/text-input/TextInput';
import SearchIcon from '../../../../../../../../../../assets/component-icons/SearchIcon';
import SelectInput from '../../../../../../../../../../components/select-input/SelectInput';
import { Program, ProgramInvitationStatus } from '../../../../../../../../@types/graphql';
import RefreshIcon from '../../../../../../../../../../assets/component-icons/RefreshIcon';
import Tooltip from '../../../../../../../../../../components/tooltip/Tooltip';

type SearchBarProps = {
  term: string;
  setTerm: (value: string) => void;
  statusFilter: ProgramInvitationStatus | undefined;
  setStatusFilter: (value: ProgramInvitationStatus | undefined) => void;
};

export default function Searchbar({ term, setTerm, statusFilter, setStatusFilter }: SearchBarProps) {
  const intl = useIntl();

  const statusFilterOptions = [
    { value: ProgramInvitationStatus.Accepted, translation: intl.formatMessage(translations.accepted) },
    { value: ProgramInvitationStatus.Processing, translation: intl.formatMessage(translations.processing) },
    { value: ProgramInvitationStatus.Rejected, translation: intl.formatMessage(translations.rejected) },
    { value: ProgramInvitationStatus.Sent, translation: intl.formatMessage(translations.sent) },
  ];

  return (
    <div className='searchbar'>
      <TextInput
        type="text"
        value={term}
        placeholder={intl.formatMessage(translations.searchTerm)}
        onChange={(e) => setTerm(e.target.value)}
        icon={SearchIcon}
      />
      <SelectInput
        name="statusFilter"
        values={statusFilterOptions}
        onChange={(status) => setStatusFilter(status)}
        placeholder={intl.formatMessage(translations.statusFilter)}
        value={statusFilter}
      />
      <button
        className="searchbar__reset-button"
        onClick={() => {
          setTerm('');
          setStatusFilter(undefined);
        }}
      >
        <RefreshIcon />
        <p>Réinitialiser la recherche</p>
      </button>
    </div>
  );
}
