import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ProgramShowTargetProgressionNoParticipants.title',
    defaultMessage: 'Aucune donnée disponible',
  },
  description: {
    id: 'ProgramShowTargetProgressionNoParticipants.description',
    defaultMessage: 'Le nombre de participants ayant atteint l’objectif sera visible ici',
  },
  targetTitle: {
    id: 'ProgramShowTargetProgressionNoParticipants.targetTitle',
    defaultMessage: 'Objectif',
  },
  targetDisplay: {
    id: 'ProgramShowTargetProgressionNoParticipants.targetDisplay',
    defaultMessage: '{x} rendez-vous',
  },
});
