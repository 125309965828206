import { createRef, useEffect } from 'react';

/**
 * Check if the user click outside component
 * (to close select dropdown for example)
 * @url https://codesandbox.io/s/outside-alerter-hooks-lmr2y?module=%2Fsrc%2FOutsideAlerter.js
 */
export function useOutsideClickEffect<E extends HTMLElement>(callback: () => void) {
  const ref = createRef<E>();
  function handleClickOutside(event: MouseEvent) {
    if (!ref.current?.contains(event.target as Node)) {
      callback();
    }
  }

  useEffect(() => {
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return ref;
}
