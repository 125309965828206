import React, { useCallback, useState } from 'react';
import Modal from '../../../../../../components/modal/Modal';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../Routes';
import { useIntl } from 'react-intl';
import { useSnackbar } from '../../../../../../components/snackbar/hooks/useSnackbar';
import { useMutation } from '@apollo/client';
import { Archive_ProgramMutation, Archive_ProgramMutationVariables } from '../../../../@types/graphql';
import { ARCHIVE_PROGRAM } from './ProgramArchiveModal.gql';
import commonErrorsTranslations from '../../../../commons/translations/errors.translations';
import translations from './ProgramArchiveModal.translations';
import ModalHeader from '../../../../../../components/modal/components/modal-header/ModalHeader';
import commonButtonsTranslations from '../../../../commons/translations/buttons.translations';
import ModalFooter from '../../../../../../components/modal/components/modal-footer/ModalFooter';
import './ProgramArchiveModal.scss';

export default function ProgramArchiveModal() {
  const { programid = '' } = useParams();

  const intl = useIntl();
  const navigate = useNavigate();
  const { error } = useSnackbar();
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const [archiveProgram] = useMutation<Archive_ProgramMutation, Archive_ProgramMutationVariables>(ARCHIVE_PROGRAM, {
    variables: {
      id: programid,
    },
    refetchQueries: ['GET_SIDEBAR_PROJECT_TREE', 'GET_MY_PROJECT_LIST', 'GET_ARCHIVED_PROJECTS_LIST'],
    onCompleted: (data) => {
      if (data.archiveProgram) {
        navigate(
          generatePath(SchoolRoutes.projectStats, {
            projectid: data.archiveProgram.project.id,
          }),
        );
      }
    },
    onError: () => {
      error(intl.formatMessage(commonErrorsTranslations.default));
    },
  });

  const onConfirm = useCallback(() => archiveProgram(), []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue === 'SUPPRIMER') {
      setDisableSubmitButton(false);
    } else {
      setDisableSubmitButton(true);
    }
  };

  return (
    <Modal
      title={intl.formatMessage(translations.title)}
      openPathname={generatePath(SchoolRoutes.programArchive, { programid })}
      closeUrl={generatePath(SchoolRoutes.programShow, { programid })}
    >
      <ModalHeader title={intl.formatMessage(translations.title)} />
      <div className="modal-body">
        <p className="modal-body__description">{intl.formatMessage(translations.description)}</p>
        <input
          className="modal-body__input"
          name="programArchiveInput"
          onChange={onChange}
        />
      </div>
      <ModalFooter
        cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
        disableSubmit={disableSubmitButton}
        submitText={intl.formatMessage(commonButtonsTranslations.confirm)}
        submitFunction={onConfirm}
        className="modal-footer"
      />
    </Modal>
  );
}
