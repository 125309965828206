import { generatePath, useNavigate } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { SchoolRoutes } from '../../../../../../../../Routes';
import './ImportsHistoryButton.scss';
import translations from './ImportsHistoryButton.translations';

type ImportParticipantsButtonProps = {
  programId: string;
};

export default function ImportsHistoryButton({ programId }: ImportParticipantsButtonProps) {
  const intl = useIntl();
  const navigate = useNavigate();
  return (
    <button
      className="import-history-button"
      type="button"
      onClick={() => {
        navigate(
          generatePath(SchoolRoutes.importsHistory, {
            programid: programId,
          }),
        );
      }}
    >
      {intl.formatMessage(translations.text)}
    </button>
  );
}
