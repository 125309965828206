import classnames from 'classnames';
import useMoveGroupProgram from '../../hooks/use-move-group-program/useMoveGroupProgram';

import styles from './DropZoneProgramGroup.module.scss';

interface DropZoneProgramGroupProps {
  projectId: string;
}
export default function DropZoneProgramGroup({ projectId }: DropZoneProgramGroupProps) {
  const [{ isOver }, dropRef] = useMoveGroupProgram(null, projectId);

  return (
    <div
      ref={dropRef}
      className={classnames(styles.dropZone, {
        [styles.isOver]: isOver,
      })}
    />
  );
}
