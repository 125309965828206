import classnames from 'classnames';
import { useIntl } from 'react-intl';
import { ProgramProgressionStatus } from '../../@types/graphql';
import translations from './ProgramStatusBadge.translations';

import styles from './ProgramStatusBadge.module.scss';

interface ProgramStatusBadgeProps {
  status?: ProgramProgressionStatus;
  className?: string;
}
export default function ProgramStatusBadge({ status, className }: ProgramStatusBadgeProps) {
  const intl = useIntl();

  if (!status) {
    return null;
  }

  return (
    <span
      className={classnames(
        styles.programStatusBadge,
        {
          [styles[status]]: !!status,
        },
        className,
      )}
    >
      {intl.formatMessage(translations[status])}
    </span>
  );
}
