import React, { useCallback } from 'react';
import Modal from '../../../../../../../../../../components/modal/Modal';
import ModalHeader from '../../../../../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../../../../../components/modal/components/modal-footer/ModalFooter';
import { useIntl } from 'react-intl';
import commonButtonsTranslations from '../../../../../../../../commons/translations/buttons.translations';
import translations from './RecoverParticipationModal.translations';

import styles from './RecoverParticipationModal.module.scss';
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../../../Routes';
import client from '../../../../../../../../config/graphql';
import {
  Recover_ParticipationMutation,
  Recover_ParticipationMutationVariables
} from '../../../../../../../../@types/graphql';
import { useMutation } from '@apollo/client';
import { RECOVER_PARTICIPATION } from '../../ParticipantsTable.gql';

export default function RecoverParticipationModal() {
  const intl = useIntl();
  const location = useLocation();
  const navigate = useNavigate();
  const values = location.state?.values || [];
  const { programid = '' } = useParams();

  const [recoverParticipations] = useMutation<
    Recover_ParticipationMutation,
    Recover_ParticipationMutationVariables
  >(RECOVER_PARTICIPATION, {
    onError: (err) => {
      console.log(err.graphQLErrors?.[0]?.extensions?.code);
    },
  });


  const recover = useCallback(
    (ids: string[]) => {
      return Promise.all(
        ids.map((id) =>
          recoverParticipations({
            refetchQueries: ['GET_PARTICIPANTS_AND_INVITATIONS'],
            variables: {
              id,
            },
            awaitRefetchQueries: true,
          }).then(() => {
            client.cache.evict({
              id: client.cache.identify({
                id,
                __typename: 'Participation',
              }),
            });
            navigate(generatePath(SchoolRoutes.programShow, { programid }))
          })
        )
      );
    },
    [recoverParticipations]
  );

  return (
    <Modal
      title={intl.formatMessage(translations.confirmTitle)}
      openPathname={generatePath(SchoolRoutes.programShowRecoverParticipation, { programid })}
      closeUrl={generatePath(SchoolRoutes.programShow, { programid })}
    >
      <ModalHeader title={intl.formatMessage(translations.confirmTitle)} />

      <ModalBody>
        <p className={styles.description}>{intl.formatMessage(translations.confirmDescription)}</p>
      </ModalBody>

      <ModalFooter
        className={styles.modalFooter}
        cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
        submitFunction={() => recover(values)}
        submitText={intl.formatMessage(commonButtonsTranslations.confirm)}
      />
    </Modal>
  );
}
