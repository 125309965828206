import { defineMessages } from 'react-intl';

export default defineMessages({
  submitButton: {
    id: 'ImportSingleParticipantModal.submitButton',
    defaultMessage: 'Ajouter',
  },
  firstNameLabel: {
    id: 'ImportSingleParticipantModal.firstNameLabel',
    defaultMessage: 'Prénom',
  },
  lastNameLabel: {
    id: 'ImportSingleParticipantModal.lastNameLabel',
    defaultMessage: 'Nom',
  },
  emailLabel: {
    id: 'ImportSingleParticipantModal.emailLabel',
    defaultMessage: 'Email',
  },
  success: {
    id: 'ImportSingleParticipantModal.success',
    defaultMessage: 'Le participant a bien été ajouté au programme.',
  },
});

export const errorTranslations = defineMessages({
  required: {
    id: 'ImportSingleParticipantModal.required',
    defaultMessage: 'Ce champ ne peut être vide',
  },
  wrongEmail: {
    id: 'ImportSingleParticipantModal.wrongEmail',
    defaultMessage: "Rentrez un format d'adresse email valide",
  },
  error: {
    id: 'ImportSingleParticipantModal.error',
    defaultMessage: "Une erreur lors de l'ajout du participant. Veuillez réessayer.",
  },
});

export const toastrMessages = defineMessages({
  success: {
    id: 'ImportSingleParticipantModal.success',
    defaultMessage: 'Le participant a bien été ajouté au programme.',
  },
  error: {
    id: 'ImportSingleParticipantModal.error',
    defaultMessage: "Une erreur a eu lieu lors de l'ajout du participant. Veuillez réessayer.",
  },
  EMAIL_ALREADY_INVITED: {
    id: 'ImportSingleParticipantModal.EMAIL_ALREADY_INVITED',
    defaultMessage: 'Cet email a déjà été invité sur ce programme',
  },
  WRONG_EMAIL_FORMAT: {
    id: 'ImportSingleParticipantModal.WRONG_EMAIL_FORMAT',
    defaultMessage: 'Le format de cette adresse email est erroné',
  },
});
