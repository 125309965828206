import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { errorTranslations } from './ImportSingleParticipantModal.translations';

export default (intl: IntlShape) =>
  Yup.object().shape({
    firstName: Yup.string().required(intl.formatMessage(errorTranslations.required)),
    lastName: Yup.string().required(intl.formatMessage(errorTranslations.required)),
    email: Yup.string()
      .email(intl.formatMessage(errorTranslations.wrongEmail))
      .required(intl.formatMessage(errorTranslations.required)),
  });
