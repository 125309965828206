import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import { SchoolRoutes } from '../../../../Routes';
import PageCenter from '../../layout/pages/page-center/PageCenter';
import astronaute from '../../images/404-astronaute.png';
import translations from './Page404.translations';

import styles from './Page404.module.scss';

export default function Page404() {
  const intl = useIntl();

  return (
    <PageCenter className={styles.page404}>
      <img
        src={astronaute}
        className={styles.astronaute}
        alt=""
      />
      <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
      <Link
        to={SchoolRoutes.home}
        className={styles.button}
      >
        {intl.formatMessage(translations.link)}
      </Link>
    </PageCenter>
  );
}
