import { gql } from '@apollo/client';

export const GET_PROGRAM_TO_CUSTOMIZE = gql`
  query GET_PROGRAM_TO_CUSTOMIZE($id: ID!) {
    program(id: $id) {
      id
      name
      startAt
      endAt
      expectedParticipations
      targetMeetingsCategories
      targetStudentsCategories
      diploma
      grade
      school
      collaborations {
        nodes {
          id
          user {
            id
            firstName
            lastName
          }
        }
      }
      description
      schools {
        nodes {
          id
          name
        }
      }
      programTemplate {
        id
        name
        templateOptions {
          minimalDaysDuration
          meetingsQuantity
        }
      }
      project {
        id
        memberships {
          nodes {
            id
              user {
                id
                firstName
                lastName
              }
          }
        }
      }
    }
  }
`;


export const GET_GRADES = gql`
  query GET_GRADES($id: ID!) {
    grades(id: $id)
  }
`;
