import { defineMessages } from 'react-intl';

export default defineMessages({
  confirmTitle: {
    id: 'ConfirmationModal.confirmTitle',
    defaultMessage: 'Retirer du programme',
  },
  confirmDescription: {
    id: 'ConfirmationModal.confirmDescription',
    defaultMessage:
      'En cliquant sur “Confirmer”, tous les participants sélectionnés seront retirés du programme. Vous pouvez les réintégrer à tout moment.',
  },
});
