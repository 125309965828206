import { gql } from '@apollo/client';
import { PROJECT_MEMBERS_INVITATION } from '../../../ProjectShow.gql';

export const QUERY_INVITATION_FOR_DELETION = gql`
  ${PROJECT_MEMBERS_INVITATION}

  query QUERY_INVITATION_FOR_DELETION($id: ID!) {
    invitation(id: $id) {
      ...PROJECT_MEMBERS_INVITATION
    }
  }
`;
