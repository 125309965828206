import React, { useMemo } from 'react';
import Icon from '../../../../../../../../../../components/icon/Icon';
import classnames from 'classnames';
import { useFormikContext } from 'formik';
import CustomCheckbox from '../../../../../../../../commons/custom-checkbox/CustomCheckbox';
import { ProjectTreeGroup } from '../../../../../../../../hooks/use-project-tree/useProjectTree';
import { ProjectStatisticFiltersValues } from '../../ProjectStatisticFilter';
import FilterProgramItem from '../filter-program-item/FilterProgramItem';

import styles from './FilterGroupItem.module.scss';

function searchProgramIds(group: ProjectTreeGroup): string[] {
  return group.programs
    .filter((program) => program.canShow)
    .map(({ id }) => id)
    .concat(...group.groups.map(searchProgramIds));
}

interface FilterGroupItemProps {
  group: ProjectTreeGroup;
}
export default function FilterGroupItem({ group }: FilterGroupItemProps) {
  const { setFieldValue, values } = useFormikContext<ProjectStatisticFiltersValues>();
  const subProgramsIds = useMemo(() => searchProgramIds(group), [group]);

  const checked = useMemo(
    () =>
      subProgramsIds.length > 0 && subProgramsIds.filter((value) => !values.programIds.includes(value)).length === 0,
    [subProgramsIds, values],
  );

  const indeterminate = useMemo(
    () => subProgramsIds.length > 0 && values.programIds.filter((value) => subProgramsIds.includes(value)).length > 0,
    [subProgramsIds, values],
  );

  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (e.target.checked) {
      setFieldValue('programIds', [...values.programIds, ...subProgramsIds]);
    } else {
      setFieldValue(
        'programIds',
        values.programIds.filter((value) => !subProgramsIds.includes(value)),
      );
    }
  }

  return (
    <>
      <li
        className={classnames(styles.groupItem, {
          [styles.disabled]: !subProgramsIds.length,
        })}
        role="treeitem"
      >
        <CustomCheckbox
          id={group.id}
          onChange={onChange}
          indeterminate={indeterminate}
          checked={checked}
          disabled={!subProgramsIds.length}
        />
        <Icon
          name="folder"
          className={styles.filterGroupIcon}
        />
        <label
          htmlFor={group.id}
          className={styles.groupItemLabel}
        >
          {group.name}
        </label>
      </li>
      <ul
        className={styles.subTree}
        role="group"
      >
        {group.groups.map((subGroup) => (
          <FilterGroupItem
            key={`statistic_filter_group_${subGroup.id}`}
            group={subGroup}
          />
        ))}
        {group.programs.map(
          (program) =>
            program.canShow && (
              <FilterProgramItem
                key={`statistic_filter_program_${program.id}`}
                program={program}
              />
            ),
        )}
      </ul>
    </>
  );
}
