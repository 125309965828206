import { defineMessages } from 'react-intl';

export default defineMessages({
  progressTitle: {
    id: 'ParticipationShow.progressTitle',
    defaultMessage: 'Progression',
  },
  certificateTitle: {
    id: 'ParticipationShow.certificateTitle',
    defaultMessage: 'Certification',
  },
  statisticsTitle: {
    id: 'ParticipationShow.statisticsTitle',
    defaultMessage: 'Statistiques',
  },
});
