import { defineMessages } from 'react-intl';
import { DELETE_GROUP_ERROR } from '../../../../../../../../@types/graphql-errors';

export const errorTranslations = defineMessages({
  [DELETE_GROUP_ERROR.UNAUTHORIZED]: {
    id: 'DeleteGroupModal.UNAUTHORIZED',
    defaultMessage: 'Cette action est réservée aux membres administrateurs du projet.',
  },
  [DELETE_GROUP_ERROR.NOT_FOUND]: {
    id: 'DeleteGroupModal.NOT_FOUND',
    defaultMessage: 'Groupe introuvable',
  },
});
