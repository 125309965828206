import { defineMessages } from 'react-intl';
import { CertificationStatus } from '../../../../@types/graphql';

export default defineMessages({
  [CertificationStatus.CertificateUnavailable]: {
    id: 'CertificationBloc.CertificateUnavailable',
    defaultMessage: 'Indisponible',
  },
  [CertificationStatus.CertificateFailed]: {
    id: 'CertificationBloc.CertificateFailed',
    defaultMessage: 'Non obtenue',
  },
  [CertificationStatus.CertificateDelivered]: {
    id: 'CertificationBloc.CertificateDelivered',
    defaultMessage: 'Délivrée',
  },
  unfollowedConversation: {
    id: 'CertificationBloc.unfollowedConversation',
    defaultMessage:
      '{x, plural, =0 {# conversation non suivie} =1 {# conversation non suivie} other {# conversations non suivies}}',
  },
});
