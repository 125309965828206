import { gql } from '@apollo/client';

export const GET_PROGRAM_TO_SHOW = gql`
  query GET_PROGRAM_TO_SHOW($id: ID!) {
    program(id: $id) {
      id
      name
      startAt
      endAt
      participationCode
      expectedParticipations
      completedParticipationsCount
      meetingsQuantity
      programProgressionStatus
      canDuplicate {
        value
      }
      canUpdate {
        value
      }
      canArchive {
        value
      }
      participations {
        total
      }
      programTemplate {
        id
        name
      }
      project {
        id
        name
        memberships {
          nodes {
            role
            user {
              id
              lastName
              firstName
            }
          }
        }
      }
      collaborations {
        nodes {
          user {
            id
            lastName
            firstName
          }
        }
      }
    }
  }
`;