import React from 'react';
import { useIntl } from 'react-intl';
import Icon from '../../../../../../../../components/icon/Icon';
import Tooltip from '../../../../../../../../components/tooltip/Tooltip';
import ProgressDonut from '../../../../../../../../components/progress-donut/ProgressDonut';
import classnames from 'classnames';
import styles from './ProjectStatisticWithDonutChart.module.scss';
import translations from './ProjectStatisticWithDonut.translations';

interface ProjectStatisticWithDonutChartProps {
  title: string;
  description?: string | React.ReactNode;
  className?: string;
  count: number | null;
  total: number | null;
  countDescription?: string | React.ReactNode;
  noDataTitle: string;
  noDataDescription: string;
  withoutParticipant?: boolean;
}
export default function ProjectStatisticWithDonutChart({
  title,
  description,
  className,
  count,
  total,
  countDescription,
  noDataTitle,
  noDataDescription,
  withoutParticipant,
}: ProjectStatisticWithDonutChartProps) {
  const intl = useIntl();
  const openInNewTab = (url: string) => {
    window.open(url, '_blank', 'noreferrer');
  };

  return (
    <div className={classnames(styles.bloc, className)}>
      <div className={styles.titleBloc}>
        <h4 className={styles.title}>{title}</h4>
        <Tooltip
          text={intl.formatMessage(translations.tooltipText)}
          position="bottom-start"
        >
          {({ onMouseEnter, onMouseLeave }) => (
            <button
              type="button"
              aria-label="tooltip"
              onMouseEnter={onMouseEnter}
              onMouseLeave={onMouseLeave}
              onClick={() =>
                openInNewTab(
                  'https://help.myjobglasses.com/fr/articles/109067-qu-est-ce-que-le-certificat-d-orientation-my-job-glasses',
                )
              }
              className={styles.certificateTooltip}
            >
              <Icon name="info" />
            </button>
          )}
        </Tooltip>
      </div>
      {withoutParticipant ? (
        <div className={styles.noParticipants}>
          <Icon
            name="data_usage"
            className={styles.noParticipantIcon}
          />
          <h5 className={styles.noParticipantTitle}>{noDataTitle}</h5>
          <p className={styles.noParticipantDescription}>{noDataDescription}</p>
        </div>
      ) : (
        <div className={styles.withParticipants}>
          {description && <p className={styles.description}>{description}</p>}
          <ProgressDonut
            count={count || 0}
            total={total || 0}
            className={styles.progressDonut}
          />
          {!!countDescription && <p className={styles.countDescription}>{countDescription}</p>}
        </div>
      )}
    </div>
  );
}
