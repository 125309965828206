import { defineMessages } from 'react-intl';
import { PUBLISH_PROGRAM_ERROR } from '../../../../../@types/graphql-errors';

export const errorTranslations = defineMessages({
  [PUBLISH_PROGRAM_ERROR.UNAUTHORIZED]: {
    id: 'useProgramPublicationAction.UNAUTHORIZED',
    defaultMessage: 'Cette action est réservée aux membres du projet.',
  },
  [PUBLISH_PROGRAM_ERROR.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_START_AT]: {
    id: 'useProgramPublicationAction.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_START_AT',
    defaultMessage: 'Vous devez sélectionner une date de début.',
  },
  [PUBLISH_PROGRAM_ERROR.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_END_AT]: {
    id: 'useProgramPublicationAction.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_END_AT',
    defaultMessage: 'Vous devez sélectionner une date de fin.',
  },
  [PUBLISH_PROGRAM_ERROR.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_EXPECTED_PARTICIPATIONS]: {
    id: 'useProgramPublicationAction.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_EXPECTED_PARTICIPATIONS',
    defaultMessage: 'Vous devez saisir un nombre de participants.',
  },
});
