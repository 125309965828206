import { Get_Project_Diagram_StructureQuery, Group, Maybe, Program } from '../../../../../../@types/graphql';

export interface DiagramElement {
  id: string;
  name: string;
  type: 'Project' | 'Group' | 'Program';
  children?: DiagramElement[];
  leaf: number;
}

type MinimalProgram = Pick<Program, 'id' | 'name' | '__typename'>;

interface MinimalImbricatedGroup {
  id: Group['id'];
  name: Group['name'];
  __typename?: Group['__typename'];
  groups?: Maybe<{
    nodes: MinimalImbricatedGroup[];
  }>;
  programs?: Maybe<{
    nodes: MinimalProgram[];
  }>;
}

export function countTreeLeaf(element: DiagramElement): number {
  return (
    element.children?.reduce((prev, cur) => {
      let count = prev;
      if (cur.children?.length) {
        count += cur.children.reduce((subPrev, subCur) => subPrev + countTreeLeaf(subCur), 0);
      } else {
        count += 1;
      }
      return count;
    }, 0) || 1
  );
}

function makeChildren(element: MinimalImbricatedGroup | MinimalProgram): DiagramElement {
  const result = {
    id: element.id,
    name: element.name,
    type: element.__typename || 'Group',
    children:
      element.__typename === 'Group'
        ? [...(element.groups?.nodes.map(makeChildren) || []), ...(element.programs?.nodes.map(makeChildren) || [])]
        : undefined,
    leaf: 0,
  };
  result.leaf = countTreeLeaf(result);

  return result;
}

export function makeGroupHierarchyTreeDate(
  project: NonNullable<Get_Project_Diagram_StructureQuery['project']>,
): DiagramElement {
  const master = {
    id: project.id,
    name: project.name,
    type: project.__typename || 'Project',
    children: [
      ...(project?.directGroups?.nodes.map<DiagramElement>(makeChildren) || []),
      ...(project?.directPrograms?.nodes.map<DiagramElement>(makeChildren) || []),
    ],
    leaf: 0,
  };
  master.leaf = countTreeLeaf(master);
  return master;
}
