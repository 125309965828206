import React, { useCallback, useContext, useState } from 'react';
import Icon from '../../../../components/icon/Icon';
import Tooltip from '../../../../components/tooltip/Tooltip';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import Storage from '../../../../enums/storage';
import { getStorageItem, setStorageItem } from '../../../../utils/storage';
import { throttle } from '../../../../utils/throttle';
import LayoutContext from '../context/LayoutContext';
import TreeNav from '../tree-nav/TreeNav';
import translations from './Sidebar.translations';

import styles from './Sidebar.module.scss';

const noop = () => undefined;
function Sidebar() {
  const intl = useIntl();

  const { isSidebarOpen, closeSidebar, showSidebar, hideSidebar } = useContext(LayoutContext);

  const clampWidth = (w: number) => Math.min(Math.max(w, 256), 400);
  const widthFromStorage = getStorageItem(Storage.SIDEBAR_WIDTH, '256');
  const [width, setWidth] = useState(clampWidth(+widthFromStorage));
  const [isDragging, setIsDragging] = useState(false);
  const handleDrag = useCallback(
    throttle((event: React.DragEvent<HTMLDivElement>) => {
      if (event.pageX) {
        setWidth(clampWidth(event.pageX));
      }
    }, 1),
    [],
  );
  function handleDragStart(event: React.DragEvent<HTMLDivElement>) {
    setIsDragging(true);
    event.dataTransfer.setData('text/plain', event.currentTarget.id);
    event.dataTransfer.setDragImage(document.querySelector('#resize-ghost')!, 0, 0);
  }
  function handleDragEnd() {
    setIsDragging(false);
    setStorageItem(Storage.SIDEBAR_WIDTH, width.toString());
  }

  return (
    <>
      <div
        id="main-sidebar"
        data-testid="main-sidebar"
        className={classnames(styles.sidebar, {
          [styles.dragging]: isDragging,
        })}
        aria-expanded={isSidebarOpen}
        style={{ width }}
        onMouseLeave={hideSidebar}
        onMouseEnter={isSidebarOpen ? noop : showSidebar}
      >
        <div className={styles.sidebarHeader}>
          <img
            src="https://assets.myjobglasses.com//logo/mjg-logo-v3-rectangle-blanc-vocation.png"
            alt=""
            className={styles.logo}
          />
          <Tooltip text={intl.formatMessage(translations.closeTooltip)}>
            {({ onMouseEnter, onMouseLeave, tooltipId }) => (
              <button
                type="button"
                onClick={closeSidebar}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                className={styles.toggleSidebarVisibility}
                aria-describedby={tooltipId}
              >
                <Icon name="menu_open" />
              </button>
            )}
          </Tooltip>
        </div>
        <div className={styles.content}>
          <TreeNav />
        </div>
        <div
          className={styles.resizer}
          onDragStart={isSidebarOpen ? handleDragStart : noop}
          onDragEnd={isSidebarOpen ? handleDragEnd : noop}
          onDrag={isSidebarOpen ? handleDrag : noop}
          draggable={isSidebarOpen}
        />
        <img
          id="resize-ghost"
          src="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
          className={styles.resizeGhost}
          alt=""
        />
      </div>
      <div
        style={isSidebarOpen ? { width } : undefined}
        aria-hidden="true"
        className={styles.spacer}
      />
    </>
  );
}

export default Sidebar;
