import ModalBody from '../../../../components/modal/components/modal-body/ModalBody';
import ContentLoading from '../content-loading/ContentLoading';

import styles from './ModalLoading.module.scss';

export default function ModalLoading() {
  return (
    <ModalBody className={styles.body}>
      <ContentLoading />
    </ModalBody>
  );
}
