import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { Get_Participant_StatisticsQuery, Get_Participant_StatisticsQueryVariables } from '../../../../@types/graphql';
import ParticipantStatisticValue from './components/participant-statistic-value/ParticipantStatisticValue';
import { GET_PARTICIPANT_STATISTICS } from './StatisticsBloc.gql';
import translations from './StatisticsBloc.translations';

import styles from './StatisticsBloc.module.scss';

interface StatisticsBlocProps {
  participationid: string;
}
export default function StatisticsBloc({ participationid }: StatisticsBlocProps) {
  const intl = useIntl();

  const { data } = useQuery<Get_Participant_StatisticsQuery, Get_Participant_StatisticsQueryVariables>(
    GET_PARTICIPANT_STATISTICS,
    {
      variables: {
        id: participationid,
      },
      fetchPolicy: 'cache-only',
    },
  );

  return (
    <section>
      <h4 className={styles.title}>{intl.formatMessage(translations.interestTitle)}</h4>
      <ParticipantStatisticValue
        label={intl.formatMessage(translations.viewedProfiles)}
        value={data?.participation?.visitsCount ?? '-'}
      />
      <ParticipantStatisticValue
        label={intl.formatMessage(translations.favoritesPros)}
        value={data?.participation?.bookmarkedProfessionalsCount ?? '-'}
      />

      <h4 className={styles.title}>{intl.formatMessage(translations.messagingTitle)}</h4>
      <ParticipantStatisticValue
        label={intl.formatMessage(translations.contactedProfessionals)}
        value={data?.participation?.conversationsCount ?? '-'}
      />
      <ParticipantStatisticValue
        label={intl.formatMessage(translations.unfollowedConversations)}
        tooltip={intl.formatMessage(translations.unfollowedConversationsTooltip)}
        value={data?.participation?.unfollowedConversationCount ?? '-'}
        badValue
      />
      <ParticipantStatisticValue
        label={intl.formatMessage(translations.remainingContactRequest)}
        tooltip={intl.formatMessage(translations.remainingContactRequestTooltip)}
        value={data?.participation?.remainingContactCount ?? '-'}
      />

      <h4 className={styles.title}>{intl.formatMessage(translations.appointmentsTitle)}</h4>
      <ParticipantStatisticValue
        label={intl.formatMessage(translations.upcomingAppt)}
        value={data?.participation?.incomingMeetingCount ?? '-'}
      />
      <ParticipantStatisticValue
        label={intl.formatMessage(translations.pastAppointmentWaitingConfirmation)}
        value={data?.participation?.waitingForConfirmationMeetingCount ?? '-'}
      />
      <ParticipantStatisticValue
        label={intl.formatMessage(translations.confirmedPastAppointments)}
        value={data?.participation?.confirmedMeetingCount ?? '-'}
        goodValue
      />
    </section>
  );
}
