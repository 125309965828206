import React from 'react';
import { useMemo } from 'react';
import Icon from '../../../../../../../../../../components/icon/Icon';
import Tooltip from '../../../../../../../../../../components/tooltip/Tooltip';
import { useIntl } from 'react-intl';
import cellWrapper from '../../../../../../../../commons/cell-wrapper/cellWrapper';
import translations from './CertificationCell.translations';

import styles from './CertificationCell.module.scss';

function CertificationCell(props: any) {
  const intl = useIntl();
  const unfollowedConversationCount = props.cellData.row?.unfollowedConversationCount || 0;
  const reportCount = props.cellData.row?.reportCount || 0;

  const tooltip = useMemo(() => {
    if ((unfollowedConversationCount || 0) > 0) {
      return intl.formatMessage(translations.unfollowedConversationTooltip);
    }
    if ((reportCount || 0) > 0) {
      return intl.formatMessage(translations.reportedTooltip);
    }
    return null;
  }, [props.cellData.row]);

  if (!tooltip) {
    return null;
  }

  return (
    <Tooltip
      text={tooltip}
      position="bottom-start"
      tooltipClassName={styles.tooltipBox}
    >
      {({ onMouseEnter, onMouseLeave, tooltipId }) => (
        <button
          type="button"
          onMouseEnter={onMouseEnter}
          onFocus={onMouseEnter}
          onMouseLeave={onMouseLeave}
          onBlur={onMouseLeave}
          aria-describedby={tooltipId}
          className={styles.tooltip}
        >
          <Icon
            name="error_outline"
            className={styles.icon}
          />
        </button>
      )}
    </Tooltip>
  );
}

export default cellWrapper(CertificationCell);
