import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CanDuplicateProgramModalContent.title',
    defaultMessage: 'Dupliquer le programme',
  },
  description: {
    id: 'CanDuplicateProgramModalContent.description',
    defaultMessage:
      'Veuilez indiquer le nombre de copies du programme que vous souhaitez.',
  },
});


export const toastrMessages = defineMessages({
  success: {
    id: 'CanDuplicateProgramModalContent.success',
    defaultMessage: 'Le programme a été dupliqué avec succès.',
  },
  error: {
    id: 'CanDuplicateProgramModalContent.error',
    defaultMessage: "Une erreur a eu lieu.",
  },
  UNAUTHORIZED: {
    id: 'CanDuplicateProgramModalContent.UNAUTHORIZED',
    defaultMessage: 'Vous n\'avez pas les droits pour effectuer cette action',
  },
  BAD_USER_INPUT: {
    id: 'CanDuplicateProgramModalContent.BAD_USER_INPUT',
    defaultMessage: 'Une erreur a eu lieu',
  },
  NOT_FOUND: {
    id: 'CanDuplicateProgramModalContent.NOT_FOUND',
    defaultMessage: 'Le programme que vous souhaitez dupliquer n\'existe pas',
  },
  PROGRAM_NAME_TAKEN: {
    id: 'CanDuplicateProgramModalContent.PROGRAM_NAME_TAKEN',
    defaultMessage: 'Ce nom de programme est déjà utilisé',
  },
});

