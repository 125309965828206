import { defineMessages } from 'react-intl';

export default defineMessages({
  programTemplateName: {
    id: 'ProgramParticipationCode.programTemplateName',
    defaultMessage: 'Programme <boldThis>{programTemplateName}</boldThis>',
  },
  participationCode: {
    id: 'ProgramParticipationCode.participationCode',
    defaultMessage: 'Code de participation',
  },
  shareOptions: {
    id: 'ProgramParticipationCode.partager',
    defaultMessage: 'Partager',
  },
  copyCode: {
    id: 'ProgramParticipationCode.copyCode',
    defaultMessage: 'Copier le code',
  },
  copyLink: {
    id: 'ProgramParticipationCode.copyLink',
    defaultMessage: 'Copier le lien',
  },
  downloadQRCode: {
    id: 'ProgramParticipationCode.downloadQRCode',
    defaultMessage: 'Télécharger',
  },
  learnMore: {
    id: 'ProgramParticipationCode.learnMore',
    defaultMessage: 'En savoir plus',
  },
});
