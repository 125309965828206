import { TreeNavComponent } from '../../@types/TreeNavComponent';

import styles from './TreeNavTitle.module.scss';

function TreeNavTitle({ label }: TreeNavComponent) {
  return (
    <p
      data-testid="tree-nav-title"
      className={styles.treenavTitle}
    >
      {label}
    </p>
  );
}

export default TreeNavTitle;
