import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ProjectArchivedList.title',
    defaultMessage: 'Projets archivés',
  },
  description: {
    id: 'ProjectArchivedList.description',
    defaultMessage:
      'Pour consulter les statistiques détaillées d’un projet archivé, contactez le propriétaire ou désarchivez-le.',
  },
});
