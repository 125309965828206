import { useEffect, useState } from 'react';
import { generatePath, useLocation } from 'react-router-dom';
import { SchoolRoutes } from '../../../../Routes';

export default function useProjectRouteMatch(projectId: string) {
  const [isCurrentLocation, setIsCurrentLocation] = useState(false);
  const currentLocation = useLocation();

  useEffect(() => {
    if (
      [
        generatePath(SchoolRoutes.projectStats, { projectid: projectId }),
        generatePath(SchoolRoutes.projectUpdate, { projectid: projectId }),
        generatePath(SchoolRoutes.projectCustomize, { projectid: projectId }),
        generatePath(SchoolRoutes.projectMembers, { projectid: projectId }),
        generatePath(SchoolRoutes.projectProgramCreate, { projectid: projectId }),
      ].find((path) => path === currentLocation.pathname)
    ) {
      setIsCurrentLocation(true);
    } else {
      setIsCurrentLocation(false);
    }
  }, [currentLocation]);

  return [isCurrentLocation];
}
