import { defineMessages } from 'react-intl';

export default defineMessages({
  programNameTitle: {
    id: 'ProgramCustomizeDetails.programNameTitle',
    defaultMessage: 'Nom du programme :',
  },
  programDates: {
    id: 'ProgramCustomizeDetails.programDate',
    defaultMessage: 'Période :',
  },
  programDatesDisplay: {
    id: 'ProgramCustomizeDetails.programDatesDisplay',
    defaultMessage: 'du {startAt} au {endAt}',
  },
  programManagers: {
    id: 'ProgramCustomizeDetails.programManagers',
    defaultMessage: 'Responsable(s) du programme :',
  },
  expectedParticipations: {
    id: 'ProgramCustomizeDetails.expectedParticipations',
    defaultMessage: 'Nombre de participants prévu :',
  },
  targetMeetings: {
    id: 'ProgramConfigurationView.targetMeetingsLabel',
    defaultMessage: 'Les participants devront rencontrer',
  },
  targetStudents: {
    id: 'ProgramConfigurationView.targetStudentsLabel',
    defaultMessage: 'Catégories d’étudiants à rencontrer',
  },
  descriptionTitle: {
    id: 'ProgramCustomizeDetails.descriptionTitle',
    defaultMessage: 'Description :',
  },
});
