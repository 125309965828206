import { gql } from '@apollo/client';

export const GET_PROGRAM_PARTICIPATION_CODE = gql`
  query GET_PROGRAM_PARTICIPATION_CODE($id: ID!) {
    program(id: $id) {
      id
      participationCode
      programTemplate {
        id
        name
      }
    }
  }
`;
