import React from 'react';
import ModalBody from '../../../../../../../../components/modal/components/modal-body/ModalBody';
import ModalHeader from '../../../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalFooter from '../../../../../../../../components/modal/components/modal-footer/ModalFooter';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../Routes';
import commonButtonsTranslations from '../../../../../../commons/translations/buttons.translations';
import translations from './CannotArchiveModalContent.translations';

import styles from './CannotArchiveModalContent.module.scss';

export default function CannotArchiveModalContent() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { projectid } = useParams();

  return (
    <>
      <ModalHeader title={intl.formatMessage(translations.title)} />
      <ModalBody className={styles.body}>
        <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
      </ModalBody>
      <ModalFooter
        cancelText=""
        submitFunction={() =>
          navigate(
            generatePath(SchoolRoutes.projectStats, {
              projectid: projectid || '',
            }),
          )
        }
        submitText={intl.formatMessage(commonButtonsTranslations.gotIt)}
        className={styles.footer}
        hideCancelButton
      />
    </>
  );
}
