import React from 'react';
import classnames from 'classnames';

interface IconProps {
  name: string;
  className?: string;
}

export default function Icon({ name, className }: IconProps) {
  return <span className={classnames(className, 'material-icons-outlined notranslate')}>{name}</span>;
}
