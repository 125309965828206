import React from 'react';
import { useMemo } from 'react';
import Icon from '../../../../../../components/icon/Icon';
import SelectField from '../../../../../../components/fields/select-field/SelectField';
import { ProgressionStepsChildrenProps } from '../../../../../../components/progression-steps/ProgressionSteps';
import { Field, Formik } from 'formik';
import { useIntl } from 'react-intl';
import GlobalErrorDisplay from '../../../../commons/global-error-display/GlobalErrorDisplay';
import commonButtonsTranslations from '../../../../commons/translations/buttons.translations';
import commonProgramNotificationFrequency from '../../../../commons/translations/programNotificationFrequencies.translations';
import { StepsContext } from '../../@types/steps-context';
import useProgramNotificationConfiguration, {
  FormikProgramNotificationField,
} from './hooks/useProgramNotificationConfiguration';
import validationSchema from './ProgramNotificationConfigurationView.validations';
import translations from './ProgramNotificationConfigurationView.translations';

import styles from './ProgramNotificationConfigurationView.module.scss';
import SelectInput from '../../../../../../components/select-input/SelectInput';

export default function ProgramNotificationConfigurationView({
  onNextStep,
  onPreviousStep,
  context,
}: ProgressionStepsChildrenProps<StepsContext>) {
  const intl = useIntl();

  // Can't use 0 as it is a falsy value and its value wont show up in the select
  const options = useMemo(
    () =>
      [1000, 1, 2, 3, 4].map((frequency) => ({
        value: frequency,
        translation: intl.formatMessage(
          commonProgramNotificationFrequency[frequency as keyof typeof commonProgramNotificationFrequency],
        ),
      })),
    [],
  );

  const onSubmit = useProgramNotificationConfiguration(context?.programId, (values: FormikProgramNotificationField) =>
    onNextStep({
      ...context,
      ...values,
    }),
  );

  return (
    <section>
      <Formik
        initialValues={{
          programNotificationFrequency: context?.programNotificationFrequency,
        }}
        onSubmit={onSubmit}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ handleSubmit, values, setFieldValue, isValid, isSubmitting }) => (
          <form onSubmit={handleSubmit}>
            <h2 className={styles.title}>{intl.formatMessage(translations.title)}</h2>
            <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
            <p className={styles.information}>
              <Icon
                name="info"
                className={styles.informationIcon}
              />
              {intl.formatMessage(translations.information)}
            </p>
            <p className={styles.information2}>
              {intl.formatMessage(translations.information2, {
                br: () => <br />,
              })}
            </p>

            <div className={styles.fieldset}>
              <label
                id="programNotificationFrequency_field"
                htmlFor="programNotificationFrequency"
                className={styles.label}
              >
                {intl.formatMessage(translations.emailFrequencyLabel)}
              </label>
              <SelectInput
                name="programNotificationFrequency"
                values={options}
                value={values.programNotificationFrequency}
                onChange={(frequency) => setFieldValue('programNotificationFrequency', frequency)}
              />
            </div>

            <GlobalErrorDisplay className={styles.error} />

            <div className={styles.actions}>
              <button
                type="button"
                onClick={() => onPreviousStep()}
                className={styles.previousButton}
              >
                {intl.formatMessage(commonButtonsTranslations.previous)}
              </button>
              <button
                type="submit"
                className={styles.nextButton}
                disabled={!isValid || isSubmitting}
              >
                {intl.formatMessage(commonButtonsTranslations.next)}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </section>
  );
}
