import classnames from 'classnames';
import React, { createRef, useContext, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { SchoolRoutes } from '../../../../Routes';
import { BreadcrumbsItem } from '../context/HeaderContext';
import LayoutContext from '../context/LayoutContext';
import BreadcrumbItem from './components/breadcrumb-item/BreadcrumbItem';
import BreadcrumbOverflowItem from './components/breadcrumb-overflow-item/BreadcrumbOverflowItem';
import translations from './Breadcrumb.translations';

import styles from './Breadcrumb.module.scss';

interface BreadcrumbProps {
  items: BreadcrumbsItem[];
  className?: string;
  show?: boolean;
}
function Breadcrumb({ items, show, className }: BreadcrumbProps) {
  const intl = useIntl();

  const lastItem = useMemo(() => items[items.length - 1], [items]);
  const leftItems = useMemo(() => items.slice(0, -1), [items]);

  const [needCalculating, setNeedCalculating] = useState(true);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const ref = createRef<HTMLUListElement>();
  const [previous, setPrevious] = useState(items);
  useEffect(() => {
    setPrevious(items);
    if (JSON.stringify(previous) !== JSON.stringify(items)) {
      setIsOverflowing(false);
      setNeedCalculating(true);
    }
  }, [items]);

  const { isSidebarOpen } = useContext(LayoutContext);
  const [previousSidebarOpenValue, setPreviousSidebarOpenValue] = useState(isSidebarOpen);
  useEffect(() => {
    if (previousSidebarOpenValue !== isSidebarOpen) {
      setTimeout(() => {
        setIsOverflowing(false);
        setNeedCalculating(true);
        setPreviousSidebarOpenValue(isSidebarOpen);
      }, 300);
    }
  }, [isSidebarOpen]);

  useEffect(() => {
    if (needCalculating) {
      const element = ref.current;
      if (element) {
        element.style.overflow = 'hidden';
        setIsOverflowing(element.scrollWidth > element.clientWidth);
        element.style.overflow = 'visible';
      }
      setNeedCalculating(false);
    }
  }, [needCalculating]);

  if (!show && items.length === 0) {
    return null;
  }

  return (
    <ul
      data-testid="breadcrumb"
      className={classnames(className, styles.breadcrumb)}
      ref={ref}
    >
      <li
        key="home"
        className={styles.breadcrumbItem}
        data-testid="home"
      >
        <BreadcrumbItem
          item={{
            iconName: 'home',
            name: intl.formatMessage(translations.home),
            link: SchoolRoutes.home.toString(),
          }}
          className={styles.content}
          isLastItem={items.length === 0}
        />
      </li>

      <li
        key="overflow"
        className={styles.breadcrumbItem}
        hidden={!isOverflowing || !leftItems.length}
        data-testid="overflow"
      >
        <BreadcrumbOverflowItem items={leftItems} />
      </li>

      {leftItems.map((item) => (
        <li
          key={`breadcrumb_${item.name}`}
          className={styles.breadcrumbItem}
          hidden={isOverflowing}
          data-testid="middle"
        >
          <BreadcrumbItem
            item={item}
            className={styles.content}
          />
        </li>
      ))}

      {lastItem && (
        <li className={styles.breadcrumbItem}>
          <BreadcrumbItem
            item={lastItem}
            className={styles.content}
            isLastItem
          />
        </li>
      )}
    </ul>
  );
}

export default Breadcrumb;
