import { IntlShape } from 'react-intl';
import * as Yup from 'yup';
import { errorTranslations } from './CreateGouvSchoolModal.translations';

export default (intl: IntlShape) =>
  Yup.object().shape({
    name: Yup.string()
      .ensure()
      .min(1, intl.formatMessage(errorTranslations.requiredSchoolName))
      .required(intl.formatMessage(errorTranslations.requiredSchoolName)),
    communeName: Yup.string()
      .ensure()
      .min(1, intl.formatMessage(errorTranslations.requiredLocation))
      .required(intl.formatMessage(errorTranslations.requiredLocation)),
  });
