import { defineMessages } from 'react-intl';

export default defineMessages({
  home: {
    id: 'TreeNav.home',
    defaultMessage: 'Accueil',
  },
  projects: {
    id: 'TreeNav.projects',
    defaultMessage: 'Projets',
  },
});
