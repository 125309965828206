import { useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/client';
import { Option } from '../../../../components/fields/select-field/@types/option';
import { FormikHelpers, FormikValues } from 'formik';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { SchoolRoutes } from '../../../../Routes';
import { handleGraphqlErrorsOnFormik } from '../../../../utils/formik';
import {
  Create_New_ProjectMutation,
  Create_New_ProjectMutationVariables,
  Search_Organisation_By_NameQuery,
  Search_Organisation_By_NameQueryVariables,
  Update_ProjectMutation,
  Update_ProjectMutationVariables,
} from '../../@types/graphql';
import { CREATE_PROJECT_ERRORS, UPDATE_PROJECT_ERRORS } from '../../@types/graphql-errors';
import { CREATE_NEW_PROJECT, SEARCH_ORGANISATION_BY_NAME, UPDATE_PROJECT } from './ProjectCreateUpdate.gql';
import { errorTranslations } from './ProjectCreateUpdate.translations';

export function useOrganizationAutocompletion(): [
  (term: string) => Promise<Array<Option>>,
  { options: Array<Option> },
] {
  const client = useApolloClient();
  const [organizations, setOrganizations] = useState<Array<Option>>([]);
  function autocomplete(term: string) {
    return client
      .query<Search_Organisation_By_NameQuery, Search_Organisation_By_NameQueryVariables>({
        query: SEARCH_ORGANISATION_BY_NAME,
        variables: {
          term,
        },
      })
      .then((data) => {
        const options =
          data?.data.organizations?.nodes?.map((organization) => ({
            icon: 'search',
            value: organization.name,
            label: organization.name,
          })) || [];
        setOrganizations(options);
        return options;
      });
  }
  return [autocomplete, { options: organizations }];
}

interface FormikFields {
  name: string;
  schools: [];
}
export function useCreateNewProject(projectId?: string) {
  const intl = useIntl();
  const navigate = useNavigate();

  const [createProject] = useMutation<Create_New_ProjectMutation, Create_New_ProjectMutationVariables>(
    CREATE_NEW_PROJECT,
    {
      refetchQueries: ['GET_SIDEBAR_PROJECT_TREE', 'GET_MY_PROJECT_LIST'],
    },
  );

  const [updateProject] = useMutation<Update_ProjectMutation, Update_ProjectMutationVariables>(UPDATE_PROJECT);

  function onSubmit(values: any, formikBag: any) {
    let action;
    if (projectId) {
      action = updateProject({
        variables: {
          id: projectId,
          name: values.name,
          schools: values.schools
        },
      });
    } else {
      action = createProject({
        variables: {
          name: values.name,
          schools: values.schools
        },
      });
    }
    return action
      .then(({ data }) => {
        if (data?.project.id) {
          navigate(
            generatePath(SchoolRoutes.projectStats, {
              projectid: data.project.id,
            }),
          );
        }
      })
      .catch((error) =>
        handleGraphqlErrorsOnFormik<
          FormikFields,
          Create_New_ProjectMutationVariables,
          CREATE_PROJECT_ERRORS | UPDATE_PROJECT_ERRORS
        >(error, formikBag, {
          translations: {
            default: intl.formatMessage(errorTranslations.default),
            unknown: intl.formatMessage(errorTranslations.default),
            network: intl.formatMessage(errorTranslations.default),
            global: {
              [UPDATE_PROJECT_ERRORS.UNAUTHORIZED]: intl.formatMessage(
                errorTranslations[UPDATE_PROJECT_ERRORS.UNAUTHORIZED],
              ),
            },
            name: {
              [UPDATE_PROJECT_ERRORS.PROJECT_NAME_TAKEN]: intl.formatMessage(
                errorTranslations[CREATE_PROJECT_ERRORS.PROJECT_NAME_TAKEN],
              ),
            },
          },
        }),
      );
  }
  return onSubmit;
}
