import { defineMessages } from 'react-intl';

export default defineMessages({
  filterButton: {
    id: 'ProjectStatisticFilter.filterButton',
    defaultMessage: 'Filtres',
  },
  nPrograms: {
    id: 'ProjectStatisticFilter.nPrograms',
    defaultMessage:
      '<boldThis>{x}</boldThis> sur {total, plural, =0 {# programme} =1 {# programme} other {# programmes}}',
  },
  filterBarTitle: {
    id: 'ProjectStatisticFilter.filterBarTitle',
    defaultMessage: 'Filtres',
  },
  filterBarSubtitle: {
    id: 'ProjectStatisticFilter.filterBarSubtitle',
    defaultMessage: 'Programmes',
  },
  filterBarDescription: {
    id: 'ProjectStatisticFilter.filterBarDescription',
    defaultMessage: 'Sélectionnez les programmes dont vous souhaitez afficher les données et validez votre sélection.',
  },
  reset: {
    id: 'ProjectStatisticFilter.reset',
    defaultMessage: 'Réinitialiser les filtres',
  },
  hideArchived: {
    id: 'ProjectStatisticFilter.hideArchived',
    defaultMessage: 'Masquer les statistiques des utilisateurs archivés',
  },
});
