import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'CanArchiveProgramModalContent.title',
    defaultMessage: 'Supprimer le programme',
  },
  description: {
    id: 'CanArchiveProgramModalContent.description',
    defaultMessage:
      'Si vous supprimez ce programme, vous n’aurez plus accès à son contenu. Afin de confirmer la suppression du programme, veuillez saisir "SUPPRIMER" ci-dessous.',
  },
});
