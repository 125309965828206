import React from 'react';
import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useIntl } from 'react-intl';
import { useMatch, useParams } from 'react-router-dom';
import {
  Get_Participation_Code_For_Empty_ParticipationQuery,
  Get_Participation_Code_For_Empty_ParticipationQueryVariables,
} from '../../../../../../../../@types/graphql';
import { ProgramShowParams } from '../../../../../../../../@types/route-params';
import { SchoolRoutes } from '../../../../../../../../../../Routes';
import { GET_PARTICIPATION_CODE_FOR_EMPTY_PARTICIPATION } from './ParticipantsTableEmpty.gql';
import translations from './ParticipantsTableEmpty.translations';

import styles from './ParticipantsTableEmpty.module.scss';

export default function ParticipantsTableEmpty() {
  const intl = useIntl();

  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const { programid } = useParams();

  const { data } = useQuery<
    Get_Participation_Code_For_Empty_ParticipationQuery,
    Get_Participation_Code_For_Empty_ParticipationQueryVariables
  >(GET_PARTICIPATION_CODE_FOR_EMPTY_PARTICIPATION, {
    skip: !programid,
    variables: {
      id: programid || '',
    },
    fetchPolicy: 'cache-only',
  });

  return (
    <div className={styles.wrapper}>
      <p className={styles.title}>{intl.formatMessage(translations.title)}</p>
      <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
      <CopyToClipboard
        text={data?.program?.participationCode || ''}
        onCopy={() => setCopied(true)}
      >
        <button
          type="button"
          className={styles.copyToClipboard}
        >
          {copied ? intl.formatMessage(translations.codeCopied) : intl.formatMessage(translations.copyCode)}
        </button>
      </CopyToClipboard>
    </div>
  );
}
