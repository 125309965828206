import Icon from '../../../../../../../../components/icon/Icon';
import Tooltip from '../../../../../../../../components/tooltip/Tooltip';
import classnames from 'classnames';
import styles from './ParticipantStatisticValue.module.scss';

interface ParticipantStatisticValueProps {
  label: string;
  tooltip?: string;
  value?: number | string;
  badValue?: boolean;
  goodValue?: boolean;
}
export default function ParticipantStatisticValue({
  label,
  tooltip,
  value,
  badValue,
  goodValue,
}: ParticipantStatisticValueProps) {
  return (
    <dl className={styles.participantStatisticValue}>
      <dt className={styles.title}>
        {label}
        {tooltip && (
          <Tooltip
            text={tooltip}
            position="bottom-start"
            className={styles.tooltip}
            tooltipClassName={styles.tooltipItem}
          >
            {({ onMouseEnter, onMouseLeave, tooltipId }) => (
              <button
                type="button"
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
                aria-describedby={tooltipId}
                className={styles.tooltipButton}
              >
                <Icon
                  name="help_outline"
                  className={styles.tooltipIcon}
                />
              </button>
            )}
          </Tooltip>
        )}
      </dt>
      <dd
        className={classnames(styles.value, {
          [styles.badValue]: badValue && typeof value === 'number' && value > 0,
          [styles.goodValue]: goodValue && typeof value === 'number' && value > 0,
        })}
      >
        {value}
      </dd>
    </dl>
  );
}
