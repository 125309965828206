import { defineMessages } from 'react-intl';

export default defineMessages({
  removeButton: {
    id: 'BatchActionBar.removeButton',
    defaultMessage: 'Archiver',
  },
  recoverButton: {
    id: 'BatchActionBar.recoverButton',
    defaultMessage: 'Réactiver',
  },
});
