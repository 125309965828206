import { HermesContactAppointmentContactMethod, Maybe } from '../../../../../../../../@types/graphql';

export function getAppointmentTypeIcon(type?: Maybe<HermesContactAppointmentContactMethod>) {
  switch (type) {
    case HermesContactAppointmentContactMethod.Physical:
      return 'local_cafe';
    case HermesContactAppointmentContactMethod.Video:
      return 'videocam';
    case HermesContactAppointmentContactMethod.Phone:
    default:
      return 'phone';
  }
}
