import { useIntl } from 'react-intl';
import { Unpacked } from '../../../../../../../../@types/helper';
import { Maybe, Program, TargetMeetingsCategories } from '../../../../../../@types/graphql';
import commonTargetMeetingsCategories from '../../../../../../commons/translations/targetMeetings.translations';
import commonTargetStudentsCategories from '../../../../../../commons/translations/targetStudents.translations';
import translations from './ProgramCustomizeDetails.translations';

import styles from './ProgramCustomizeDetails.module.scss';

interface ProgramCustomizeDetailsProps {
  program: Pick<
    Program,
    | 'name'
    | 'description'
    | 'startAt'
    | 'endAt'
    | 'expectedParticipations'
    | 'description'
    | 'targetMeetingsCategories'
    | 'targetStudentsCategories'
  > & {
    collaborations?: Maybe<{
      nodes: Array<{
        user: Pick<Unpacked<NonNullable<Program['collaborations']>['nodes']>['user'], 'firstName' | 'lastName'>;
      }>;
    }>;
  };
}
export default function ProgramCustomizeDetails({ program }: ProgramCustomizeDetailsProps) {
  const intl = useIntl();

  return (
    <>
      <div className={styles.bloc}>
        <h4 className={styles.title}>{intl.formatMessage(translations.programNameTitle)}</h4>
        <p className={styles.description}>{program.name}</p>
      </div>

      <div className={styles.bloc}>
        <h4 className={styles.title}>{intl.formatMessage(translations.programDates)}</h4>
        <p className={styles.description}>
          {intl.formatMessage(translations.programDatesDisplay, {
            startAt: intl.formatDate(program.startAt, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
            endAt: intl.formatDate(program.endAt, {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
            }),
          })}
        </p>
      </div>

      <div className={styles.bloc}>
        <h4 className={styles.title}>{intl.formatMessage(translations.programManagers)}</h4>
        <ul className={styles.list}>
          {program.collaborations?.nodes.map((node) => (
            <li key={`${node.user.firstName} ${node.user.lastName}`}>
              {`${node.user.firstName} ${node.user.lastName},`}
            </li>
          ))}
        </ul>
      </div>

      <div className={styles.bloc}>
        <h4 className={styles.title}>{intl.formatMessage(translations.expectedParticipations)}</h4>
        <p className={styles.description}>{program.expectedParticipations}</p>
      </div>

      <div className={styles.bloc}>
        <h4 className={styles.title}>{intl.formatMessage(translations.targetMeetings)}</h4>
        <p className={styles.description}>
          {intl.formatMessage(commonTargetMeetingsCategories[program.targetMeetingsCategories!])}
        </p>
      </div>

      {program.targetMeetingsCategories == TargetMeetingsCategories.Students && (
        <div className={styles.bloc}>
          <h4 className={styles.title}>{intl.formatMessage(translations.targetStudents)}</h4>
          <p className={styles.description}>
            {intl.formatMessage(commonTargetStudentsCategories[program.targetStudentsCategories!])}
          </p>
        </div>
      )}

      <div className={styles.bloc}>
        <h4 className={styles.title}>{intl.formatMessage(translations.descriptionTitle)}</h4>
        <p className={styles.description}>{program.description}</p>
      </div>
    </>
  );
}
