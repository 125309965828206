import { useIntl } from 'react-intl';
import { Program } from '../../../../../../@types/graphql';
import commonProgramNotificationFrequency from '../../../../../../commons/translations/programNotificationFrequencies.translations';
import translations from './ProgramNotificationsConfiguration.translations';

import styles from './ProgramNotificationsConfiguration.module.scss';

interface ProgramNotificationsConfigurationProps {
  program: Pick<Program, 'programNotificationFrequency'>;
}
export default function ProgramNotificationsConfiguration({ program }: ProgramNotificationsConfigurationProps) {
  const intl = useIntl();

  if (!program.programNotificationFrequency) {
    return null;
  }

  return (
    <div className={styles.bloc}>
      <h4 className={styles.title}>{intl.formatMessage(translations.notificationFrequency)}</h4>
      <p className={styles.description}>
        {intl.formatMessage(
          commonProgramNotificationFrequency[
            program.programNotificationFrequency as keyof typeof commonProgramNotificationFrequency
          ],
        )}
      </p>
    </div>
  );
}
