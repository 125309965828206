import { ComponentType } from 'react';
import { IconProps } from '../../assets/component-icons/Icon';

export type InputValue<T> = {
  icon?: ComponentType<IconProps>;
  parent?: string;
  value: T;
  translation: string;
};

export type CategorizedInputValue<T> = {
  icon?: ComponentType<IconProps>;
  value: T;
  categoryKey: string;
  translation: string;
};

export function sortByTranslation<T>(values: InputValue<T>[]): InputValue<T>[] {
  return values.sort((a, b) => a.translation.localeCompare(b.translation));
}
