import React, { ChangeEvent, useEffect, useRef } from 'react';
import CheckIcon from '../../assets/component-icons/CheckIcon';
import ChevronDownIcon from '../../assets/component-icons/ChevronDownIcon';
import { IconProps } from '../../assets/component-icons/Icon';
import classNames from 'classnames';
import { ComponentType, useMemo, useState } from 'react';
import { InputValue, sortByTranslation } from '../types/BaseInputValue';
import './SelectInput.scss';

type SelectInputProps<T> = {
  icon?: ComponentType<IconProps>;
  name: string;
  label?: string;
  value?: T;
  values: InputValue<T>[];
  placeholder?: string;
  onChange: (value: T) => void;
  error?: string;
  sort?: boolean;
  disabled?: boolean;
};

export default function SelectInput<T extends string | number>({
  icon: Icon,
  name,
  label,
  placeholder,
  error,
  value,
  values: paramValues,
  sort,
  disabled,
  onChange,
}: SelectInputProps<T>) {
  const componentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [inputText, setInputText] = useState('');
  const [parsedValue, setParsedValue] = useState<InputValue<T> | undefined>();

  const values: InputValue<T>[] = useMemo(
    () => (sort ? sortByTranslation(paramValues) : paramValues),
    [sort, paramValues],
  );

  const filteredValues = useMemo(() => {
    if (!inputText) return values;
    return values.filter((value) => value.translation.toLowerCase().includes(inputText.toLowerCase()));
  }, [values, inputText]);

  function handleComponentClick() {
    if (disabled) {
      return;
    }

    setDropdownOpen((prev) => {
      const mirrorValue = !prev;

      return mirrorValue;
    });
  }

  function handleSelection(value: T) {
    onChange(value);
    setInputText('');
    setDropdownOpen(false);
  }

  function handleTyping(e: ChangeEvent<HTMLInputElement>) {
    const value = e.target.value;
    setInputText(value);

    if (value === '' && parsedValue) {
      onChange('' as T);
    }
    setDropdownOpen(true);
  }

  useEffect(() => {
    document.addEventListener('mousedown', (ev) => {
      if (componentRef.current && !componentRef.current.contains(ev.target as Node)) {
        setDropdownOpen(false);
      }
    });
  }, [isDropdownOpen, values, value]);

  useEffect(() => {
    if (value === '') {
      setParsedValue(undefined);
    } else {
      setParsedValue(paramValues.find(({ value: v }) => v === value));
    }
  }, [value, paramValues]);

  return (
    <div
      ref={componentRef}
      className="select-input"
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      <div
        className={classNames(
          'select-input__main',
          { 'select-input__main--focused': isDropdownOpen },
          { 'select-input__main--disabled': disabled },
          { 'select-input__main--error': error },
        )}
        onClick={handleComponentClick}
      >
        {Icon && <Icon className="select-input__main__icon" />}

        <input
          ref={inputRef}
          value={inputText ? inputText : parsedValue?.translation || ''}
          onChange={handleTyping}
          placeholder={placeholder}
        />

        <label htmlFor={name}>
          <ChevronDownIcon
            className={classNames('select-input__main__icon', {
              'select-input__main__icon--turn': isDropdownOpen,
            })}
          />
        </label>
      </div>

      {isDropdownOpen && filteredValues.length > 0 && (
        <ul className="select-input__dropdown">
          {filteredValues.map((value) => (
            <li
              key={value.value}
              className={classNames('select-input__dropdown__option', {
                'select-input__dropdown__option--selected': value.value === parsedValue?.value,
              })}
            >
              <button
                type="button"
                onClick={() => handleSelection(value.value)}
              >
                <CheckIcon />
                {value.translation}
              </button>
            </li>
          ))}
        </ul>
      )}

      {error && <span className="select-input__error">{error}</span>}
    </div>
  );
}
