import { RowRenderer } from '../../@types/project-members-table';

import styles from './InformationsCell.module.scss';

export default function InformationsCell({ rowData }: RowRenderer) {
  if (rowData.__typename === 'Membership') {
    return (
      <p className={styles.userInformations}>
        <span className={styles.fullName}>{`${rowData.userFirstName} ${rowData.userLastName}`}</span>
        <br />
        <span className={styles.email}>{rowData.userEmail}</span>
      </p>
    );
  }
  if (rowData.__typename === 'Invitation') {
    return (
      <p className={styles.userInformations}>
        <span className={styles.email}>{rowData.email}</span>
      </p>
    );
  }
  /* istanbul ignore next */
  return null;
}
