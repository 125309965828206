import { ProjectStructureDiagramDimensions } from '../../../../enums/Dimensions';

interface DiagramGroupItemProps {
  x: number;
  y: number;
  label: string;
}
const GROUP_ITEM_HEIGHT = 35;
export default function DiagramGroupItem({ x, y, label }: DiagramGroupItemProps) {
  return (
    <g
      width={ProjectStructureDiagramDimensions.ITEM_WIDTH}
      height={GROUP_ITEM_HEIGHT}
      x={x}
      y={y}
      transform={`translate(${x}, ${y})`}
    >
      <rect
        x={-(ProjectStructureDiagramDimensions.ITEM_WIDTH / 2)}
        y={-(GROUP_ITEM_HEIGHT / 2)}
        width={ProjectStructureDiagramDimensions.ITEM_WIDTH}
        height={GROUP_ITEM_HEIGHT}
        fill="#09193b"
        rx={4}
        style={{ filter: 'url(#DP01)' }}
      />
      <text
        x={0}
        y={4}
        textAnchor="middle"
        fontSize={12}
        fill="#FFFFFF"
      >
        {label}
      </text>
    </g>
  );
}
