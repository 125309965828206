import React from 'react';
import Icon from '../../../../../../../../components/icon/Icon';
import { useIntl } from 'react-intl';
import translations from './ProgramShowTargetProgressionNoParticipants.translations';

import styles from './ProgramShowTargetProgressionNoParticipants.module.scss';

interface ProgramShowTargetProgressionNoParticipantsProps {
  target: number;
}
export default function ProgramShowTargetProgressionNoParticipants({
  target,
}: ProgramShowTargetProgressionNoParticipantsProps) {
  const intl = useIntl();

  return (
    <div className={styles.noParticipants}>
      <div className={styles.targetWrapper}>
        <Icon
          name="track_changes"
          className={styles.illustration}
        />
        <div className={styles.target}>
          <p className={styles.targetTitle}>{intl.formatMessage(translations.targetTitle)}</p>
          <p className={styles.targetDisplay}>
            {intl.formatMessage(translations.targetDisplay, {
              x: target,
            })}
          </p>
        </div>
      </div>
      <h4 className={styles.title}>{intl.formatMessage(translations.title)}</h4>
      <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
    </div>
  );
}
