import React from 'react';
import classnames from 'classnames';
import { HeaderAction, PageHeader } from '../../context/HeaderContext';
import styles from './PageCenter.module.scss';

interface PageCenterProps {
  title?: string;
  actions?: HeaderAction[];
  children: React.ReactNode;
  className?: string;
}
function PageCenter({ title, actions, children, className }: PageCenterProps) {
  return (
    <main className={classnames(styles.pageCenter, className)}>
      {(typeof title !== 'undefined' || actions?.length) && (
        <PageHeader
          title={title}
          actions={actions}
        />
      )}
      <section className={styles.content}>{children}</section>
    </main>
  );
}

export default PageCenter;
