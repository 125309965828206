import React from 'react';
import Icon from '../../../../../../../../components/icon/Icon';

import styles from './ProgramPublicationGroup.module.scss';

type ProgramPublicationGroupProps<P> = {
  title: string | React.ReactNode;
  component?: (props: P) => React.ReactElement | null;
} & P;
export default function ProgramPublicationGroup<P>({
  title,
  component: Component,
  ...props
}: ProgramPublicationGroupProps<P>) {
  return (
    <article className={styles.programPublicationGroup}>
      <div className={styles.sideIconBar}>
        <Icon
          name="done"
          className={styles.icon}
        />
      </div>
      <div>
        <h3 className={styles.title}>{title}</h3>
        {Component && (
          /* @ts-expect-error */
          <Component {...props} />
        )}
      </div>
    </article>
  );
}
