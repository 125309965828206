import React from 'react';
import { useQuery } from '@apollo/client';
import Avatar from '../../../../components/avatar/Avatar';
import { AvatarSize } from '../../../../components/avatar/Avatar';
import Icon from '../../../../components/icon/Icon';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { SchoolRoutes } from '../../../../Routes';
import { getUserInitials } from '../../../../utils/user';
import { Get_Program_ParticipationQuery, Get_Program_ParticipationQueryVariables } from '../../@types/graphql';
import PageError from '../../commons/page-error/PageError';
import ProgramDot from '../../commons/program-dot/ProgramDot';
import PageClassic from '../../layout/pages/page-classic/PageClassic';
import ParticipationBloc from './components/participation-bloc/ParticipationBloc';
import ProfileCompletionStatus from './components/profile-completion-status/ProfileCompletionStatus';
import useParticipationId from './hooks/useParticipationId';
import CertificationBloc from './view/certification-bloc/CertificationBloc';
import ProgressBloc from './view/progress-bloc/ProgressBloc';
import StatisticsBloc from './view/statistics-bloc/StatisticsBloc';
import { GET_PROGRAM_PARTICIPATION } from './ParticipationShow.gql';
import translations from './ParticipationShow.translations';

import styles from './ParticipationShow.module.scss';

export default function ParticipationShow() {
  const intl = useIntl();
  const participationid = useParticipationId();

  const { data, loading, error, refetch } = useQuery<
    Get_Program_ParticipationQuery,
    Get_Program_ParticipationQueryVariables
  >(GET_PROGRAM_PARTICIPATION, {
    variables: {
      id: participationid,
    },
  });

  if (error) {
    return <PageError refetch={refetch} />;
  }

  return (
    <PageClassic
      title={data?.participation ? `${data.participation.user.firstName} ${data.participation.user.lastName}` : ''}
      subTitle={
        data?.participation?.user.email && (
          <>
            <Icon
              name="email"
              className={styles.emailIcon}
            />
            {data?.participation?.user.email}
          </>
        )
      }
      breadcrumbs={
        data?.participation
          ? [
              {
                iconName: 'assessment',
                link: generatePath(SchoolRoutes.projectStats, {
                  projectid: data.participation.program.project.id,
                }),
                name: data.participation.program.project.name,
              },
              {
                iconComponent: ProgramDot,
                link: generatePath(SchoolRoutes.programShow, {
                  programid: data.participation.program.id,
                }),
                name: data.participation.program.name,
              },
            ]
          : undefined
      }
      leftAside={() => (
        <Avatar
          avatarInitials={data?.participation?.user ? getUserInitials(data?.participation?.user) : undefined}
          avatarLink={data?.participation?.user.avatarUrl || undefined}
          size={AvatarSize.medium}
        />
      )}
      actions={[
        <ProfileCompletionStatus complete={(data?.participation?.user.profileCompletionPourcentage || 0) >= 100} />,
      ]}
      loading={loading}
    >
      <div className={styles.wrapper}>
        <ParticipationBloc
          title={intl.formatMessage(translations.certificateTitle)}
          component={CertificationBloc}
          participationid={participationid}
          className={styles.certificateBloc}
          faqLink="https://help.myjobglasses.com/fr/articles/109067-qu-est-ce-que-le-certificat-d-orientation-my-job-glasses"
        />
        <ParticipationBloc
          title={intl.formatMessage(translations.progressTitle)}
          component={ProgressBloc}
          participationid={participationid}
          className={styles.progressBloc}
        />
        <ParticipationBloc
          title={intl.formatMessage(translations.statisticsTitle)}
          component={StatisticsBloc}
          participationid={participationid}
          className={styles.statisticsBloc}
        />
      </div>
    </PageClassic>
  );
}
