import { gql } from '@apollo/client';

export const CONFIGURE_PROGRAM_NOTIFICATIONS = gql`
  mutation CONFIGURE_PROGRAM_NOTIFICATIONS($id: ID!, $programNotificationFrequency: Int) {
    updateProgram(id: $id, input: { programNotificationFrequency: $programNotificationFrequency }) {
      id
      programNotificationFrequency
    }
  }
`;
