import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'MemberEditModal.title',
    defaultMessage: 'Modifier le role',
  },
  membershipDescription: {
    id: 'MemberEditModal.membershipDescription',
    defaultMessage: ' {firstName} {lastName} sera averti(e) par email de son nouveau rôle.',
  },
  invitationDescription: {
    id: 'MemberEditModal.invitationDescription',
    defaultMessage: '{email} occupera ce nouveau rôle dès qu’il/elle rejoint le projet.',
  },
  seeMore: {
    id: 'MemberEditModal.seeMore',
    defaultMessage: 'En savoir plus sur les rôles.',
  },
  success: {
    id: 'MemberEditModal.success',
    defaultMessage: 'Le rôle a été modifié avec succès.',
  },
});
