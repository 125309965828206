import React from 'react';
import { useQuery } from '@apollo/client';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { AutoSizer, Column, Table } from 'react-virtualized';
import { SchoolRoutes } from '../../../../../../Routes';
import { Get_Project_MembershipsQuery, Get_Project_MembershipsQueryVariables } from '../../../../@types/graphql';
import PageError from '../../../../commons/page-error/PageError';
import FullWidthContainer from '../../../../layout/full-width-container/FullWidthContainer';
import ActionsCell from './components/actions-cell/ActionsCell';
import AvatarCell from './components/avatar-cell/AvatarCell';
import InformationsCell from './components/informations-cell/InformationsCell';
import MemberDeleteModal from './components/member-delete-modal/MemberDeleteModal';
import MemberEditModal from './components/member-edit-modal/MemberEditModal';
import MemberInvitationModal from './components/member-invitation-modal/MemberInvitationModal';
import MemberInvitationProgramModal from './components/member-invitation-program-modal /MemberInvitationProgramModal';
import MemberProgramModal from './components/member-program-modal/MemberProgramModal';
import RoleCell from './components/role-cell/RoleCell';
import StatusCell from './components/status-cell/StatusCell';
import { GET_PROJECT_MEMBERSHIPS } from './ProjectShowMembers.gql';
import translations from './ProjectShowMembers.translations';
import styles from './ProjectShowMembers.module.scss';

function ProjectShowMembers() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { projectid } = useParams();

  const { data } = useQuery<Get_Project_MembershipsQuery, Get_Project_MembershipsQueryVariables>(
    GET_PROJECT_MEMBERSHIPS,
    {
      skip: !projectid,
      variables: {
        id: projectid || '',
      },
      fetchPolicy: 'cache-only',
    },
  );

  const list = useMemo(
    () => [...(data?.project?.invitations?.nodes || []), ...(data?.project?.memberships?.nodes || [])],
    [data?.project],
  );

  function openInvitationModal() {
    navigate(
      generatePath(SchoolRoutes.projectMembersCreateInvitation, {
        projectid: projectid || '',
      }),
    );
  }

  if (!projectid) {
    return <PageError />;
  }

  return (
    <FullWidthContainer className={styles.projectCustomizeMembers}>
      <button
        type="button"
        onClick={openInvitationModal}
        className={styles.invitationButton}
        disabled={!data?.project?.canCreateInvitation.value}
      >
        {intl.formatMessage(translations.invitationButton)}
      </button>
      <AutoSizer disableHeight>
        {({ width }) => (
          <Table
            width={width}
            height={list.length * 88 + 48}
            rowHeight={88}
            rowGetter={({ index }) => list[index]}
            rowCount={list.length}
            rowClassName={styles.row}
            headerHeight={48}
            headerClassName={styles.headerRow}
            gridClassName={styles.grid}
          >
            <Column
              dataKey="avatar"
              label={intl.formatMessage(translations.informationsColumnName)}
              width={48}
              cellRenderer={AvatarCell}
              headerStyle={{
                overflow: 'visible',
                flexBasis: ((width - 48) / 12) * 4 + 48 + 10,
              }}
              flexShrink={0}
            />
            <Column
              dataKey="email"
              width={((width - 48) / 12) * 4}
              cellRenderer={InformationsCell}
              headerStyle={{
                display: 'none',
              }}
              flexShrink={0}
            />
            <Column
              dataKey="createdAt"
              label={intl.formatMessage(translations.statusColumnName)}
              width={((width - 48) / 12) * 3}
              cellRenderer={StatusCell}
              flexShrink={0}
            />
            <Column
              dataKey="role"
              label={intl.formatMessage(translations.roleColumnName)}
              width={((width - 48) / 12) * 3}
              cellRenderer={RoleCell}
              flexShrink={0}
            />
            <Column
              dataKey="actions"
              width={((width - 48) / 12) * 2}
              cellRenderer={ActionsCell}
              flexShrink={0}
              className={styles.actionCell}
            />
          </Table>
        )}
      </AutoSizer>
      <MemberInvitationProgramModal projectId={projectid} />
      <MemberInvitationModal projectId={projectid} />
      <MemberProgramModal projectId={projectid} />
      <MemberDeleteModal />
      <MemberEditModal />
    </FullWidthContainer>
  );
}

export default ProjectShowMembers;
