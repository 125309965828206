import React from 'react';
import { useMutation } from '@apollo/client';
import ModalHeader from '../../../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../../../components/modal/components/modal-footer/ModalFooter';
import { useSnackbar } from '../../../../../../../../components/snackbar/hooks/useSnackbar';
import { useIntl } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../Routes';
import { Archive_ProjectMutation, Archive_ProjectMutationVariables } from '../../../../../../@types/graphql';
import { ARCHIVE_PROJECT_ERRORS, MJGApolloError } from '../../../../../../@types/graphql-errors';
import commonButtonsTranslations from '../../../../../../commons/translations/buttons.translations';
import { ARCHIVE_PROJECT } from './CanArchiveModalContent.gql';
import translations, { errorTranslations } from './CanArchiveModalContent.translations';

import styles from './CanArchiveModalContent.module.scss';
import commonErrorsTranslations from '../../../../../../commons/translations/errors.translations';

export default function CanArchiveModalContent() {
  const intl = useIntl();
  const { error } = useSnackbar();
  const navigate = useNavigate();
  const { projectid } = useParams();
  const [archiveProject, { loading }] = useMutation<Archive_ProjectMutation, Archive_ProjectMutationVariables>(
    ARCHIVE_PROJECT,
    {
      refetchQueries: ['GET_SIDEBAR_PROJECT_TREE', 'GET_MY_PROJECT_LIST', 'GET_ARCHIVED_PROJECTS_LIST'],
      variables: {
        projectId: projectid || '',
      },
      awaitRefetchQueries: false,
    },
  );

  function submit() {
    archiveProject()
      .then(() => {
        navigate(SchoolRoutes.home);
      })
      .catch((err: MJGApolloError<{}, ARCHIVE_PROJECT_ERRORS>) => {
        if (err.graphQLErrors.find((subError) => subError.extensions?.code === ARCHIVE_PROJECT_ERRORS.NOT_FOUND)) {
          error(intl.formatMessage(errorTranslations.NOT_FOUND));
        } else if (
          err.graphQLErrors.find((subError) => subError.extensions?.code === ARCHIVE_PROJECT_ERRORS.UNAUTHORIZED)
        ) {
          error(intl.formatMessage(errorTranslations.UNAUTHORIZED));
        } else if (err.networkError) {
          error(intl.formatMessage(commonErrorsTranslations.network));
        } else {
          error(intl.formatMessage(commonErrorsTranslations.technical));
        }
      });
  }

  return (
    <>
      <ModalHeader title={intl.formatMessage(translations.title)} />
      <ModalBody className={styles.body}>
        <p className={styles.description}>{intl.formatMessage(translations.description)}</p>
      </ModalBody>
      <ModalFooter
        cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
        submitFunction={submit}
        submitText={intl.formatMessage(commonButtonsTranslations.confirm)}
        disableSubmit={loading}
        className={styles.footer}
      />
    </>
  );
}
