import { defineMessages } from 'react-intl';
import { TargetMeetingsCategories } from '../../@types/graphql';

const commonTargetMeetingsCategories = defineMessages({
  [TargetMeetingsCategories.Professionals]: {
    id: 'targetMeetingsCategories.Professionals',
    defaultMessage: 'Des professionnels',
  },
  [TargetMeetingsCategories.Students]: {
    id: 'targetMeetingsCategories.Students',
    defaultMessage: 'Des étudiants',
  },
  [TargetMeetingsCategories.Any]: {
    id: 'targetMeetingsCategories.Any',
    defaultMessage: 'Toutes catégories',
  },
});

export default commonTargetMeetingsCategories;
