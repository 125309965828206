import React from 'react';
import CheckIcon from '../../assets/component-icons/CheckIcon';
import ChevronDownIcon from '../../assets/component-icons/ChevronDownIcon';
import { IconProps } from '../../assets/component-icons/Icon';
import LoadingIcon from '../../assets/component-icons/LoadingIcon';
import classNames from 'classnames';
import { ChangeEvent, ComponentType, Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { CategorizedInputValue, InputValue } from '../types/BaseInputValue';
import './DynamicAutocompleteCategorizedSelectInput.scss';

type DynamicAutocompleteCategorizedSelectInputProps<T> = {
  icon?: ComponentType<IconProps>;
  name: string;
  label?: string;
  value?: string;
  values: CategorizedInputValue<T>[];
  placeholder?: string;
  initialValue?: string;
  onSelection: (value: InputValue<T>) => void;
  onTyping: (value: string) => void;
  error?: string;
  loading?: boolean;
  dataCy?: string;
  emptyStateMessage?: string;
  onEmptyStateClick?: () => void;
  disabled?: boolean;
};

export default function DynamicAutocompleteCategorizedSelectInput<T>({
  icon: Icon,
  name,
  label,
  placeholder,
  error,
  values: paramValues,
  onSelection,
  onTyping,
  initialValue = '',
  value = '',
  loading,
  dataCy,
  emptyStateMessage,
  onEmptyStateClick,
  disabled,
}: DynamicAutocompleteCategorizedSelectInputProps<T>) {
  const componentRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const values = useMemo(() => {
    return paramValues.reduce(
      (acc, current) => {
        if (!acc[current.categoryKey]) acc[current.categoryKey] = [];
        acc[current.categoryKey].push(current);
        return acc;
      },
      {} as { [key: string]: CategorizedInputValue<T>[] },
    );
  }, [paramValues]);

  const [selectedIndex, setSelectedIndex] = useState<number>();
  const [autocompleteTextValue, setAutocompleteTextValue] = useState(initialValue);
  const [userInteracted, setUserInteracted] = useState(false);

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => {
    setAutocompleteTextValue(value);
  }, [value]);

  function handleComponentClick() {
    setDropdownOpen((prev) => {
      const mirrorValue = !prev;

      if (mirrorValue === true) {
        inputRef.current?.focus();
        if (!userInteracted) {
          setAutocompleteTextValue('');
          setUserInteracted(true);
        }
      } else {
        inputRef.current?.blur();
      }

      return mirrorValue;
    });
  }

  function handleTyping(e: ChangeEvent<HTMLInputElement>) {
    setAutocompleteTextValue(e.target.value);
    onTyping(e.target.value);
    if (!userInteracted) {
      setUserInteracted(true);
    }
  }

  function handleSelection(value: InputValue<T>) {
    onSelection(value);
    setAutocompleteTextValue('');
    handleComponentClick();
    setUserInteracted(true);
  }

  useEffect(() => {
    document.addEventListener('mousedown', (ev) => {
      if (componentRef.current && !componentRef.current.contains(ev.target as Node)) {
        setDropdownOpen(false);
        setUserInteracted(false);
      }
    });

    document.addEventListener('mousemove', () => {
      if (selectedIndex) {
        setSelectedIndex(undefined);
      }
    });

    document.addEventListener('keydown', (ev) => {
      if (isDropdownOpen) {
        switch (ev.key) {
          case 'Escape':
            ev.preventDefault();
            setDropdownOpen(false);
            break;
        }
      }
    });
  }, [isDropdownOpen, values]);

  return (
    <div
      ref={componentRef}
      className="select-input"
    >
      {label && (
        <label
          htmlFor={name}
          className={classNames({ error: error })}
        >
          {label}
        </label>
      )}

      <div
        data-cy={dataCy}
        className={classNames(
          'select-input__main',
          { 'select-input__main--focused': isDropdownOpen },
          { 'select-input__main--error': error },
          { 'select-input__main--disabled': disabled },
        )}
        onClick={handleComponentClick}
      >
        {Icon && <Icon className="select-input__main__icon" />}

        <input
          data-cy={dataCy && `${dataCy}__input`}
          ref={inputRef}
          value={userInteracted || autocompleteTextValue ? autocompleteTextValue : initialValue}
          onChange={handleTyping}
          placeholder={placeholder}
          className={classNames({
            'input--initial-value': !userInteracted && initialValue && !autocompleteTextValue,
          })}
        />

        <label htmlFor={name}>
          {loading ? (
            <LoadingIcon className="select-input__main__icon" />
          ) : (
            <ChevronDownIcon
              className={classNames('select-input__main__icon', {
                'select-input__main__icon--turn': isDropdownOpen,
              })}
            />
          )}
        </label>
      </div>

      {isDropdownOpen && (
        (Object.keys(values).length > 0 || emptyStateMessage) ? (
          <ul className="select-input__dropdown">
        {Object.keys(values).length > 0 ? (
          Object.keys(values).map((key) => (
            <Fragment key={key}>
          <li
            className={classNames(
              'select-input__dropdown__option',
              'dynamic-autocomplete-categorized-select-input__dropdown__category',
            )}
          >
            {key}
          </li>
          {values[key].map((value) => (
            <li
              data-cy={dataCy && `${dataCy}__item`}
              key={value.translation}
              className={classNames('select-input__dropdown__option')}
            >
              <button
            type="button"
            onClick={() => handleSelection(value)}
              >
            <CheckIcon />
            {value.translation}
              </button>
            </li>
          ))}
            </Fragment>
          ))
        ) : emptyStateMessage && (
          <li
            className="select-input__dropdown__option"
            onClick={onEmptyStateClick}
          >
            {emptyStateMessage}
          </li>
        )}
          </ul>
        ) : null
      )}

      {error && <span className="select-input__error">{error}</span>}
    </div>
  );
}
