import React from 'react';
import { useEffect, useState } from 'react';
import Icon from '../../../../../../../../components/icon/Icon';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { TreeNavComponent } from '../../../../@types/TreeNavComponent';

import styles from './TreeNavItemLink.module.scss';

type TreeNavItemLinkProps = Pick<TreeNavComponent, 'id' | 'label' | 'link' | 'icon' | 'locked' | 'level'> & {
  openParent?: () => void;
};
function TreeNavItemLink({ id, label, link, icon, locked, level, openParent }: TreeNavItemLinkProps) {
  const [isCurrentLocation, setIsCurrentLocation] = useState(false);
  const currentLocation = useLocation();
  useEffect(() => {
    if (link && currentLocation?.pathname === link.toString()) {
      openParent?.();
      setIsCurrentLocation(true);
    } else {
      setIsCurrentLocation(false);
    }
  }, [currentLocation]);

  return (
    <li
      data-testid={id}
      role="treeitem"
      className={styles.item}
    >
      <Link
        to={link || '#'}
        className={classnames(styles.treeNavItemLink, styles.withLink, {
          [styles.locked]: locked,
          [styles.currentLocation]: isCurrentLocation,
        })}
        style={{
          // ([level] * [iconSize] + [icon margin right]) + [item padding]
          paddingLeft: (level || 0) * (18 + 8) + 16,
        }}
        aria-current={isCurrentLocation ? 'location' : undefined}
      >
        {locked && (
          <Icon
            name="locked"
            className={styles.icon}
          />
        )}
        {icon && !locked && (
          <Icon
            name={icon}
            className={styles.icon}
          />
        )}
        <span className={styles.label}>{label}</span>
      </Link>
    </li>
  );
}

export default TreeNavItemLink;
