import React from 'react';
import Card from '../../../../../../../../components/card/Card';
import { FieldProps } from 'formik';
import { useIntl } from 'react-intl';
import RTFBold from '../../../../../../rich-text-formatting/bold/RTFBold';
import { CoachingType } from '../../../../@types/coaching-type';
import translations from './CoachingTypeField.translations';

import styles from './CoachingTypeField.module.scss';

interface CoachingTypeFieldProps extends FieldProps {}
export default function CoachingTypeField({ field }: CoachingTypeFieldProps) {
  const intl = useIntl();

  return (
    <div className={styles.coachingTypeField}>
      <label
        htmlFor={`${field.name}_${CoachingType.Individual}`}
        className={styles.option}
      >
        <input
          id={`${field.name}_${CoachingType.Individual}`}
          type="radio"
          name={field.name}
          value={CoachingType.Individual}
          onChange={field.onChange}
          checked={field.value === CoachingType.Individual}
        />
        <Card
          iconName="person"
          name={intl.formatMessage(translations.individualCoaching, {
            boldThis: RTFBold,
          })}
          active={field.value === CoachingType.Individual}
          options={[{ name: intl.formatMessage(translations.optionIndividualSupport) }]}
          className={styles.card}
        />
      </label>
      <label
        htmlFor={`${field.name}_${CoachingType.Group}`}
        className={styles.option}
      >
        <input
          id={`${field.name}_${CoachingType.Group}`}
          type="radio"
          name={field.name}
          value={CoachingType.Group}
          onChange={field.onChange}
          checked={field.value === CoachingType.Group}
        />
        <Card
          iconName="group"
          name={intl.formatMessage(translations.groupCoaching, {
            boldThis: RTFBold,
          })}
          active={field.value === CoachingType.Group}
          options={[
            { name: intl.formatMessage(translations.optionIndividualSupport) },
            { name: intl.formatMessage(translations.optionCollectiveSupport) },
            { name: intl.formatMessage(translations.optionConsolidatedData) },
          ]}
          className={styles.card}
        />
      </label>
    </div>
  );
}
