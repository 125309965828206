import { useMutation } from '@apollo/client';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { handleGraphqlErrorsOnFormik } from '../../../../../../../utils/formik';
import {
  Configure_Program_NotificationsMutation,
  Configure_Program_NotificationsMutationVariables,
} from '../../../../../@types/graphql';
import commonErrorsTranslations from '../../../../../commons/translations/errors.translations';
import { CONFIGURE_PROGRAM_NOTIFICATIONS } from './useProgramNotificationConfiguration.gql';

export interface FormikProgramNotificationField {
  programNotificationFrequency: number | undefined;
}
export default function useProgramNotificationConfiguration(
  programID: string | undefined,
  onSuccess: (values: FormikProgramNotificationField) => void,
) {
  const intl = useIntl();

  const [updateProgramNotificationFrequency] = useMutation<
    Configure_Program_NotificationsMutation,
    Configure_Program_NotificationsMutationVariables
  >(CONFIGURE_PROGRAM_NOTIFICATIONS);

  return (values: FormikProgramNotificationField, formikBag: FormikHelpers<FormikProgramNotificationField>) =>
    values.programNotificationFrequency
      ? updateProgramNotificationFrequency({
          variables: {
            id: programID || '',
            programNotificationFrequency: values.programNotificationFrequency,
          },
        })
          .then(() => onSuccess(values))
          .catch((err) =>
            handleGraphqlErrorsOnFormik(err, formikBag, {
              translations: {
                default: intl.formatMessage(commonErrorsTranslations.default),
                network: intl.formatMessage(commonErrorsTranslations.network),
                unknown: intl.formatMessage(commonErrorsTranslations.default),
              },
            }),
          )
      : Promise.reject();
}
