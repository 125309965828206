import { gql } from '@apollo/client';
import { PROJECT_MEMBERS_MEMBERSHIP } from '../../../ProjectShow.gql';

export const QUERY_MEMBERSHIP_FOR_DELETION = gql`
  ${PROJECT_MEMBERS_MEMBERSHIP}

  query QUERY_MEMBERSHIP_FOR_DELETION($id: ID!) {
    membership(id: $id) {
      ...PROJECT_MEMBERS_MEMBERSHIP
    }
  }
`;
