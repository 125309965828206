import React from 'react';
import { useMemo, useCallback, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import Modal from '../../../../../../../../components/modal/Modal';
import ModalHeader from '../../../../../../../../components/modal/components/modal-header/ModalHeader';
import ModalBody from '../../../../../../../../components/modal/components/modal-body/ModalBody';
import ModalFooter from '../../../../../../../../components/modal/components/modal-footer/ModalFooter';
import { useFormik, FieldArray, FormikProvider } from 'formik';
import { useIntl } from 'react-intl';
import { generatePath } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../../Routes';
import {
  Get_Project_Members_Invitation_AutorizationQuery,
  Get_Project_Members_Invitation_AutorizationQueryVariables,
  Role,
  InvitationRole,
  CreateInvitationInput,
} from '../../../../../../@types/graphql';
import TextInput from '../../../../../../../../components/text-input/TextInput';
import SelectInput from '../../../../../../../../components/select-input/SelectInput';
import StaticAutocompleteMultiSelectInput from '../../../../../../../../components/static-autocomplete-multi-select-input/StaticAutocompleteMultiSelectInput';
import PlusIcon from '../../../../../../../../assets/component-icons/PlusIcon';
import XIcon from '../../../../../../../../assets/component-icons/XIcon';
import GlobalErrorDisplay from '../../../../../../commons/global-error-display/GlobalErrorDisplay';
import commonButtonsTranslations from '../../../../../../commons/translations/buttons.translations';
import commonRoleTranslations from '../../../../../../commons/translations/roles.translations';
import { useInvitationMutation } from './MemberInvitationModal.hooks';
import { GET_PROJECT_MEMBERS_INVITATION_AUTORIZATION } from './MemberInvitationModal.gql';
import translations from './MemberInvitationModal.translations';

import styles from './MemberInvitationModal.module.scss';

type FormikValues = {
  invitations: [CreateInvitationInput];
};
interface MemberInvitationModalProps {
  projectId: string;
}
export default function MemberInvitationModal({ projectId }: MemberInvitationModalProps) {
  const intl = useIntl();

  const [enableAddButton, setEnableAddButton] = useState<boolean>(true);

  const invitUser = useInvitationMutation(projectId);

  const { data } = useQuery<
    Get_Project_Members_Invitation_AutorizationQuery,
    Get_Project_Members_Invitation_AutorizationQueryVariables
  >(GET_PROJECT_MEMBERS_INVITATION_AUTORIZATION, {
    variables: {
      id: projectId,
    },
  });

  const formik = useFormik<FormikValues>({
    initialValues: {
      invitations: [
        {
          email: '',
          role: '' as InvitationRole,
          programIds: [],
        },
      ],
    },
    onSubmit: invitUser,
  });

  const roleOptions = [Role.Guest, Role.Companion, Role.Admin].map((role) => ({
    value: role,
    translation: intl.formatMessage(commonRoleTranslations[role]),
    parent: '',
  }));

  const programOptions = useMemo(
    () =>
      data?.project?.programs?.nodes?.map((node) => ({
        parent: node?.group?.name || '',
        key: node?.id || '',
        name: node?.name || '',
      })) || [],
    [data],
  );

  const handlePrograms = useCallback(
    (program: any, index: number) => {
      const invitation = formik.values.invitations[index];
      if (!invitation?.programIds?.includes(program.value)) {
        formik.setFieldValue(`invitations[${index}].programIds`, [...(invitation?.programIds || []), program.value]);
      } else {
        formik.setFieldValue(
          `invitations[${index}].programIds`,
          invitation?.programIds?.filter((v) => v !== program.value),
        );
      }
    },
    [formik.values.invitations],
  );

  const selectAll = (index: number) => {
    if (formik.values.invitations[index].programIds?.length === data?.project?.programs?.nodes?.length) {
      formik.setFieldValue(`invitations[${index}].programIds`, []);
    } else {
      formik.setFieldValue(
        `invitations[${index}].programIds`,
        data?.project?.programs?.nodes?.map((node) => node?.id) || [],
      );
    }
  };

  const getPlaceholderMessage = (count: number) => {
    if (count === 0) {
      return '';
    } else {
      return intl.formatMessage(translations.placeholder, { count });
    }
  };

  useEffect(() => {
    if (formik.values.invitations.length < 5) {
      setEnableAddButton(true);
    } else {
      setEnableAddButton(false);
    }
  }, [formik.values.invitations]);

  return (
    <Modal
      title={intl.formatMessage(translations.title)}
      openPathname={SchoolRoutes.projectMembersCreateInvitation}
      closeUrl={generatePath(SchoolRoutes.projectMembers, { projectid: projectId })}
      format="xlarge"
    >
      <FormikProvider value={formik}>
        <form onSubmit={formik.handleSubmit}>
          <ModalHeader title={intl.formatMessage(translations.title)} />
          <ModalBody className={styles.modalBody}>
            <p className={styles.description}>
              {`${intl.formatMessage(translations.description)} `}
              <br></br>
              <a
                href="https://help.myjobglasses.com/fr/articles/109079-quels-sont-les-differents-roles-au-sein-d-un-projet"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.descriptionLink}
              >
                {intl.formatMessage(translations.seeMoreAboutRoles)}
              </a>
            </p>
            <FieldArray name="invitations">
              {({ remove, push }) => (
                <div>
                  {formik.values.invitations.length > 0 &&
                    formik.values.invitations.map((invitation, index) => (
                      <div
                        key={index}
                        className={styles.formWrapper}
                      >
                        <div className={styles.fieldWrapper}>
                          <TextInput
                            name={`invitations[${index}].email`}
                            type="email"
                            label={intl.formatMessage(translations.emailLabel)}
                            onChange={formik.handleChange}
                          />
                        </div>
                        <div className={styles.fieldWrapper}>
                          <SelectInput
                            name={`invitations[${index}].role`}
                            label={intl.formatMessage(translations.roleLabel)}
                            values={roleOptions}
                            value={formik.values.invitations[index].role}
                            onChange={(role) => formik.setFieldValue(`invitations[${index}].role`, role)}
                          />
                        </div>
                        <div className={styles.fieldWrapper}>
                          <StaticAutocompleteMultiSelectInput
                            name={`invitations[${index}].programIds`}
                            values={programOptions.map((value) => ({
                              parent: value.parent,
                              value: value.key,
                              translation: value.name,
                            }))}
                            label={intl.formatMessage(translations.programLabel)}
                            placeholder={getPlaceholderMessage(
                              formik.values.invitations[index].programIds?.length || 0,
                            )}
                            disabled={programOptions.length === 0}
                            selectedValues={formik.values.invitations[index].programIds || []}
                            onChange={(program) => handlePrograms(program.value, index)}
                            selectAll={() => selectAll(index)}
                            allowSelectAll={true}
                          />
                        </div>
                        {index > 0 ? (
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className={styles.addInvitationButton}
                          >
                            <XIcon className={styles.closeIcon} />
                          </button>
                        ) : (
                          <button
                            type="button"
                            onClick={() => remove(index)}
                            className={styles.addInvitationButton}
                            style={{ pointerEvents: 'none' }}
                          >
                            <XIcon className={styles.closeIconWhite} />
                          </button>
                        )}
                      </div>
                    ))}
                  {enableAddButton && (
                    <button
                      type="button"
                      onClick={() => push({ email: '', role: '', programIds: [] })}
                      className={styles.addInvitationButton}
                    >
                      <PlusIcon className={styles.icon} />
                      {intl.formatMessage(translations.addInvitation)}
                    </button>
                  )}
                </div>
              )}
            </FieldArray>
            <GlobalErrorDisplay className={styles.errorDisplay} />
          </ModalBody>
          <ModalFooter
            cancelText={intl.formatMessage(commonButtonsTranslations.cancel)}
            submitText={intl.formatMessage(translations.invitButton)}
            disableSubmit={!formik.dirty || !data?.project?.canCreateInvitation.value}
            className={styles.modalFooter}
          />
        </form>
      </FormikProvider>
    </Modal>
  );
}
