import { defineMessages } from 'react-intl';

export default defineMessages({
  informationsColumnName: {
    id: 'ProjectCustomizeMembers.informationsColumnName',
    defaultMessage: 'Informations',
  },
  statusColumnName: {
    id: 'ProjectCustomizeMembers.statusColumnName',
    defaultMessage: 'Statut',
  },
  roleColumnName: {
    id: 'ProjectCustomizeMembers.roleColumnName',
    defaultMessage: 'Rôle',
  },
  invitationButton: {
    id: 'ProjectCustomizeMembers.invitationButton',
    defaultMessage: 'Inviter',
  },
});
