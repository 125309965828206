import { defineMessages } from 'react-intl';

export default defineMessages({
  target: {
    id: 'ProgressBloc.target',
    defaultMessage: 'Objectif',
  },
  targetValue: {
    id: 'ProgressBloc.targetValue',
    defaultMessage: '{x} rendez-vous',
  },
  confirmedTooltip: {
    id: 'ProgressBloc.confirmedTooltip',
    defaultMessage:
      '{x, plural, =0 {# rendez-vous passé confirmé} =1 {# rendez-vous passé confirmé} other {# rendez-vous passés confirmés}}',
  },
  waitingTooltip: {
    id: 'ProgressBloc.waitingTooltip',
    defaultMessage:
      '{x, plural, =0 {# rendez-vous passé en attente de confirmation} =1 {# rendez-vous passé en attente de confirmation} other {# rendez-vous passés en attente de confirmation}}',
  },
  successTooltip: {
    id: 'ProgressBloc.successTooltip',
    defaultMessage:
      '{confirmed, plural, =0 {# RDV réalisé} =1 {# RDV réalisé} other {# RDV RDV réalisés}} et {waiting, plural, =0 {# RDV passé} =1 {# RDV passé} other {# RDV passés}} en attente de confirmation',
  },
});
