import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'ProgramStudentAffiliationModal.title',
    defaultMessage: 'Comment les participants rejoignent-ils votre programme ?',
  },
  confirmButton: {
    id: 'ProgramStudentAffiliationModal.confirmButton',
    defaultMessage: "J'ai compris",
  },
  step1title: {
    id: 'ProgramStudentAffiliationModal.step1title',
    defaultMessage: 'Étape 1',
  },
  step1description: {
    id: 'ProgramStudentAffiliationModal.step1description',
    defaultMessage: 'Les participants créent un compte <boldThis>Membre</boldThis> sur My Job Glasses',
  },
  step2title: {
    id: 'ProgramStudentAffiliationModal.step2title',
    defaultMessage: 'Étape 2',
  },
  step2description: {
    id: 'ProgramStudentAffiliationModal.step2description',
    defaultMessage:
      'Les participants renseignent le <boldThis>code de participation</boldThis> dans leur page “Dashboard”',
  },
  step3title: {
    id: 'ProgramStudentAffiliationModal.step3title',
    defaultMessage: 'Étape 3',
  },
  step3description: {
    id: 'ProgramStudentAffiliationModal.step3description',
    defaultMessage: 'Les participants apparaissent dans votre <boldThis>tableau de suivi d’activité</boldThis>',
  },
});
