import { useMutation } from '@apollo/client';
import { useSnackbar } from '../../../../../../../../components/snackbar/hooks/useSnackbar';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import {
  Invit_User_To_ProjectMutation,
  Invit_User_To_ProjectMutationVariables,
  CreateInvitationInput,
} from '../../../../../../@types/graphql';
import { SchoolRoutes } from '../../../../../../../../Routes';
import { INVIT_USER_TO_PROJECT } from './MemberInvitationModal.gql';
import translations, { errorTranslations } from './MemberInvitationModal.translations';

interface FormikValues {
  invitations: [CreateInvitationInput];
}
export function useInvitationMutation(projectId: string) {
  const intl = useIntl();
  const navigate = useNavigate();
  const { success } = useSnackbar();

  const [invitUser] = useMutation<Invit_User_To_ProjectMutation, Invit_User_To_ProjectMutationVariables>(
    INVIT_USER_TO_PROJECT,
    {
      refetchQueries: ['GET_PROJECT_MEMBERSHIPS'],
    },
  );

  return (values: FormikValues, formikBag: FormikHelpers<FormikValues>) =>
    values.invitations
      ? invitUser({
          variables: {
            projectId,
            invitations: values.invitations,
          },
        })
          .then((response) => {
            if (response) {
              success(intl.formatMessage(translations.success));
              navigate(generatePath(SchoolRoutes.projectMembers, { projectid: projectId }));
            }
          })
          .catch((err) => {
            const errors = err.graphQLErrors[0].extensions;
            if (errors.validationErrors) {
              const firstError = errors.validationErrors.email?.[0] || errors.validationErrors.membership?.[0];
              formikBag.setStatus({
                __globalError: intl.formatMessage(errorTranslations[firstError as keyof typeof errorTranslations]),
              });
            } else if (errors.code === 'BAD_USER_INPUT') {
              formikBag.setStatus({
                __globalError: intl.formatMessage(errorTranslations.default),
              });
            }
          })
      : Promise.reject();
}
