import Icon from '../../../../../../../../../../components/icon/Icon';
import { useDrag } from 'react-dnd';
import { Program } from '../../../../../../../../@types/graphql';
import { DNDItem } from '../../../../enums/DND';

import styles from './ProjectStructureListProgramItem.module.scss';

interface ProjectStructureListProgramItemProps {
  program: Pick<Program, 'id' | 'name'> & {
    canUpdate: Pick<Program['canUpdate'], 'value'>;
  };
  level: number;
}
export default function ProjectStructureListProgramItem({ program, level = 0 }: ProjectStructureListProgramItemProps) {
  const [, dragRef, dragPreview] = useDrag(
    () => ({
      type: DNDItem.program,
      item: program,
      collect: (monitor) => ({
        opacity: monitor.isDragging() ? 0.5 : 1,
        isDragging: !!monitor.isDragging(),
      }),
      canDrag: program.canUpdate?.value,
    }),
    [],
  );

  return (
    <>
      <li
        ref={dragPreview}
        className={styles.projectStudentListProgramItem}
        data-testid={`program-${program.id}`}
      >
        <button
          type="button"
          ref={dragRef}
          className={styles.dragButton}
          data-testid={`program-${program.id}-drag`}
          disabled={!program.canUpdate?.value}
        >
          <Icon
            name="more_vert"
            className={styles.icon}
          />
        </button>
        <div
          className={styles.content}
          style={{
            marginLeft: level * 8,
          }}
        >
          <div className={styles.programName}>{program.name}</div>
        </div>
      </li>
    </>
  );
}
