import React, { useState } from 'react';
import Icon from '../../../../components/icon/Icon';
import classnames from 'classnames';
import ActionButtonItem from './components/action-button-item/ActionButtonItem';
import { useOutsideClickEffect } from '../../../../utils/useOutsideClickEffect';

import styles from './ActionButton.module.scss';

interface ActionButtonProps {
  iconName: string;
  actions?: React.ComponentPropsWithRef<typeof ActionButtonItem>['action'][];
  className?: string;
  buttonClassName?: string;
  popupClassName?: string;
}
function ActionButton({
  iconName,
  actions,
  className = undefined,
  buttonClassName = undefined,
  popupClassName,
}: ActionButtonProps) {
  const [isShowingPopup, setPopupVisibility] = useState(false);
  function togglePopupVisibility(e: React.SyntheticEvent<HTMLButtonElement>) {
    e.stopPropagation();
    setPopupVisibility(!isShowingPopup);
  }
  const ref = useOutsideClickEffect(() => {
    setPopupVisibility(false);
  });

  if (!actions) {
    return null;
  }

  return (
    <span
      className={classnames(styles.wrapper, className)}
      aria-haspopup
      aria-expanded={isShowingPopup}
      ref={ref}
    >
      <button
        type="button"
        onClick={togglePopupVisibility}
        className={classnames(styles.action, buttonClassName)}
        aria-haspopup
        aria-expanded="false"
      >
        <Icon
          name={iconName}
          className={styles.actionIcon}
        />
      </button>
      <ul
        role="menu"
        className={classnames(styles.popup, popupClassName)}
        hidden={!isShowingPopup}
      >
        {actions.map((action) => (
          <ActionButtonItem
            key={action.name}
            action={action}
          />
        ))}
      </ul>
    </span>
  );
}

export default ActionButton;
