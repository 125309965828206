import Icon from '../../../../../../../../../../components/icon/Icon';
import { useIntl } from 'react-intl';
import useAddGroupMutation from '../../hooks/add-group-mutation/useAddGroupMutation';
import translations from './AddGroupButton.translations';

import styles from './AddGroupButton.module.scss';

interface AddGroupButtonProps {
  projectId?: string;
  disabled?: boolean;
}
export default function AddGroupButton({ projectId, disabled }: AddGroupButtonProps) {
  const intl = useIntl();

  const addGroup = useAddGroupMutation(projectId);

  if (!projectId) {
    return null;
  }

  return (
    <button
      type="button"
      className={styles.addGroupButton}
      onClick={addGroup}
      disabled={disabled}
    >
      <Icon
        name="add"
        className={styles.icon}
      />
      {intl.formatMessage(translations.label)}
    </button>
  );
}
