import { useMutation } from '@apollo/client';
import { FormikHelpers } from 'formik';
import { useIntl } from 'react-intl';
import { generatePath, useNavigate } from 'react-router-dom';
import { SchoolRoutes } from '../../../../../../../Routes';
import { handleGraphqlErrorsOnFormik } from '../../../../../../../utils/formik';
import { Publish_ProgramMutation, Publish_ProgramMutationVariables } from '../../../../../@types/graphql';
import { PUBLISH_PROGRAM_ERROR } from '../../../../../@types/graphql-errors';
import commonErrorsTranslations from '../../../../../commons/translations/errors.translations';
import { errorTranslations } from './useProgramPublicationAction.translations';
import { PUBLISH_PROGRAM } from './useProgramPublicationAction.gql';

export default function useProgramPublicationAction(programId?: string) {
  const navigate = useNavigate();
  const intl = useIntl();

  const [publishProgram] = useMutation<Publish_ProgramMutation, Publish_ProgramMutationVariables>(PUBLISH_PROGRAM, {
    refetchQueries: ['GET_SIDEBAR_PROJECT_TREE', 'GET_MY_PROJECT_LIST'],
    variables: {
      id: programId || '',
    },
  });

  return (_: {}, formikBag: FormikHelpers<{}>) =>
    publishProgram()
      .then(() => {
        navigate(
          generatePath(SchoolRoutes.programShow, {
            programid: programId || '',
          }),
        );
      })
      .catch((err) =>
        handleGraphqlErrorsOnFormik<{}, Publish_ProgramMutationVariables, PUBLISH_PROGRAM_ERROR>(err, formikBag, {
          translations: {
            default: intl.formatMessage(commonErrorsTranslations.default),
            network: intl.formatMessage(commonErrorsTranslations.network),
            unknown: intl.formatMessage(commonErrorsTranslations.default),
            global: {
              [PUBLISH_PROGRAM_ERROR.NOT_FOUND]: intl.formatMessage(commonErrorsTranslations.technical),
              [PUBLISH_PROGRAM_ERROR.UNAUTHORIZED]: intl.formatMessage(errorTranslations.UNAUTHORIZED),
              [PUBLISH_PROGRAM_ERROR.PROGRAM_PROGRAM_PUBLICATION_STATUS_ALREADY_PUBLISHED]: intl.formatMessage(
                commonErrorsTranslations.technical,
              ),
              [PUBLISH_PROGRAM_ERROR.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_START_AT]: intl.formatMessage(
                errorTranslations.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_START_AT,
              ),
              [PUBLISH_PROGRAM_ERROR.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_END_AT]: intl.formatMessage(
                errorTranslations.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_END_AT,
              ),
              [PUBLISH_PROGRAM_ERROR.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_EXPECTED_PARTICIPATIONS]: intl.formatMessage(
                errorTranslations.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_EXPECTED_PARTICIPATIONS,
              ),
              [PUBLISH_PROGRAM_ERROR.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MEETING_PROGRAM]: intl.formatMessage(
                commonErrorsTranslations.technical,
              ),
              [PUBLISH_PROGRAM_ERROR.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MEETINGS_QUANTITY]: intl.formatMessage(
                commonErrorsTranslations.technical,
              ),
              [PUBLISH_PROGRAM_ERROR.PROGRAM_PROGRAM_OPTIONS_MISSING_VALUE_MINIMAL_DAYS_DURATION]: intl.formatMessage(
                commonErrorsTranslations.technical,
              ),
            },
          },
        }),
      );
}
