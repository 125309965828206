import { useQuery } from '@apollo/client';
import { useIntl } from 'react-intl';
import { useParams, generatePath } from 'react-router-dom';
import { SchoolRoutes } from '../../../../Routes';
import { ProgramUpdateParams } from '../../@types/route-params';
import {
  ProgramInvitationFields,
  QueryFilterOperator,
  Get_Program_To_ShowQuery,
  Get_Program_To_ShowQueryVariables,
} from '../../@types/graphql';
import PageClassic from '../../layout/pages/page-classic/PageClassic';
import ProgramDot from '../../commons/program-dot/ProgramDot';
import { GET_PROGRAM_TO_SHOW } from '../program-show/ProgramShow.gql';
import { GET_PROGRAM_IMPORTS } from '../import-participants/gql/getimports.gql';
import ImportListTable from './components/import-list-table/ImportListTable';
import translations from './ImportsHistory.translations';

export default function ImportsHistory() {
  const intl = useIntl();
  const { programid } = useParams<ProgramUpdateParams>();

  const { data } = useQuery<Get_Program_To_ShowQuery, Get_Program_To_ShowQueryVariables>(GET_PROGRAM_TO_SHOW, {
    variables: {
      id: programid || '',
    },
  });

  const { data: imports } = useQuery(GET_PROGRAM_IMPORTS, {
    variables: {
      filter: {
        query: [
          {
            filterBy: ProgramInvitationFields.ProgramId,
            operator: QueryFilterOperator.Eq,
            value: programid,
          },
        ],
      },
    },
  });

  return (
    <PageClassic
      title={intl.formatMessage(translations.title)}
      breadcrumbs={
        data?.program
          ? [
              {
                iconName: 'assessment',
                link: generatePath(SchoolRoutes.projectStats, {
                  projectid: data.program.project.id,
                }),
                name: data.program.project.name,
              },
              {
                iconComponent: ProgramDot,
                link: generatePath(SchoolRoutes.programShow, {
                  programid: data.program.id,
                }),
                name: data.program.name,
              },
            ]
          : undefined
      }
    >
      <ImportListTable imports={imports?.programInvitationImports?.nodes} />
    </PageClassic>
  );
}
