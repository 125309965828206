import { defineMessages } from 'react-intl';

export default defineMessages({
  status: {
    id: 'ImportListTable.Status',
    defaultMessage: 'Statut',
  },
  date: {
    id: 'ImportListTable.Date',
    defaultMessage: 'Date',
  },
  result: {
    id: 'ImportListTable.result',
    defaultMessage: 'Résultat',
  },
  downloadErrorFile: {
    id: 'ImportListTable.downloadErrorFile',
    defaultMessage: "Télécharger le fichier d'erreurs",
  },
  importSuccessful: {
    id: 'ImportListTable.importSuccessful',
    defaultMessage: 'Aucune erreur',
  },
  processingFreeze: {
    id: 'ImportListTable.processingFreeze',
    defaultMessage:
      'Après chaque import, merci d\'attendre une dizaine de secondes et de rafraîchir la page avec F5. Si votre import reste bloqué sur le statut "en cours", merci de nous contacter.',
  },
});

export const statusMessages = defineMessages({
  failed: {
    id: 'ImportListTableStatus.failed',
    defaultMessage: 'Terminé - voir erreurs',
  },
  finished: {
    id: 'ImportListTableStatus.finished',
    defaultMessage: 'Fini',
  },
  processing: {
    id: 'ImportListTableStatus.processing',
    defaultMessage: 'En cours',
  },
});
