import { useQuery } from '@apollo/client';
import classnames from 'classnames';
import { useIntl } from 'react-intl';
import {
  CertificationStatus,
  Get_Participation_Certification_StatusQuery,
  Get_Participation_Certification_StatusQueryVariables,
} from '../../../../@types/graphql';
import { GET_PARTICIPATION_CERTIFICATION_STATUS } from './CertificationBloc.gql';
import translations from './CertificationBloc.translations';

import styles from './CertificationBloc.module.scss';

interface CertificationBlocProps {
  participationid: string;
}
export default function CertificationBloc({ participationid }: CertificationBlocProps) {
  const intl = useIntl();

  const { data } = useQuery<
    Get_Participation_Certification_StatusQuery,
    Get_Participation_Certification_StatusQueryVariables
  >(GET_PARTICIPATION_CERTIFICATION_STATUS, {
    variables: {
      participationId: participationid,
    },
    fetchPolicy: 'cache-only',
  });

  switch (data?.participation?.certificationStatus) {
    case CertificationStatus.CertificateUnavailable:
      return (
        <>
          <p className={classnames(styles.certificationStatus, styles.unavailable)}>
            {intl.formatMessage(translations[CertificationStatus.CertificateUnavailable])}
          </p>
          {(data.participation.unfollowedConversationCount || 0) > 0 && (
            <p className={styles.notice}>
              {intl.formatMessage(translations.unfollowedConversation, {
                x: data.participation.unfollowedConversationCount || 0,
              })}
            </p>
          )}
        </>
      );
    case CertificationStatus.CertificateFailed:
      return (
        <p className={classnames(styles.certificationStatus, styles.failed)}>
          {intl.formatMessage(translations[CertificationStatus.CertificateFailed])}
        </p>
      );
    case CertificationStatus.CertificateDelivered:
      return (
        <p className={classnames(styles.certificationStatus, styles.delivered)}>
          {intl.formatMessage(translations[CertificationStatus.CertificateDelivered])}
        </p>
      );
    default:
      return null;
  }
}
