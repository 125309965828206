import { defineMessages } from 'react-intl';
import { CREATE_INVITE_ERROR } from '../../../../../../@types/graphql-errors';

export const errorTranslations = defineMessages({
  default: {
    id: 'MemberInvitationModal.default',
    defaultMessage: 'Erreur rencontrée lors de l’invitation. Veuillez réessayer.',
  },
  bad_email: {
    id: 'MemberInvitationModal.bad_email',
    defaultMessage: 'Veuillez saisir une adresse email valide.',
  },
  [CREATE_INVITE_ERROR.INVITEEMAILTOPROJECTSERVICE_EMAIL_TAKEN]: {
    id: 'MemberInvitationModal.INVITEEMAILTOPROJECTSERVICE_EMAIL_TAKEN',
    defaultMessage: 'Un membre a déjà été invité à rejoindre le projet.',
  },
  [CREATE_INVITE_ERROR.INVITEEMAILTOPROJECTSERVICE_MEMBERSHIP_ALREADY_EXISTS]: {
    id: 'MemberInvitationModal.INVITEEMAILTOPROJECTSERVICE_MEMBERSHIP_ALREADY_EXISTS',
    defaultMessage: 'Un membre a déjà rejoint le projet.',
  },
  [CREATE_INVITE_ERROR.UNAUTHORIZED]: {
    id: 'MemberInvitationModal.UNAUTHORIZED',
    defaultMessage: 'Cette action est réservée aux membres administrateurs du projet.',
  },
});

export default defineMessages({
  title: {
    id: 'MemberInvitationModal.title',
    defaultMessage: 'Inviter des membres au projet',
  },
  description: {
    id: 'MemberInvitationModal.description',
    defaultMessage: 'Ajouter un ou des membres au projet, leur donner un rôle et leur affecter un ou des programmes.',
  },
  seeMoreAboutRoles: {
    id: 'MemberInvitationModal.seeMoreAboutRoles',
    defaultMessage: 'En savoir plus sur les rôles >',
  },
  emailLabel: {
    id: 'MemberInvitationModal.emailLabel',
    defaultMessage: 'Adresse e-mail',
  },
  roleLabel: {
    id: 'MemberInvitationModal.roleLabel',
    defaultMessage: 'Rôle',
  },
  programLabel: {
    id: 'MemberInvitationModal.programLabel',
    defaultMessage: 'Sélectionnez un ou plusieurs programmes',
  },
  invitButton: {
    id: 'MemberInvitationModal.invitButton',
    defaultMessage: 'Inviter',
  },
  success: {
    id: 'MemberInvitationModal.success',
    defaultMessage: 'Invitation envoyée !',
  },
  addInvitation: {
    id: 'MemberInvitationModal.addInvitation',
    defaultMessage: 'Ajouter un autre membre',
  },
  placeholder: {
    id: 'MemberInvitationModal.selectedPrograms',
    defaultMessage: '{count} programme(s) sélectionné(s)',
  },
});
