import Avatar from '../../../../../../../../components/avatar/Avatar';
import { AvatarSize } from '../../../../../../../../components/avatar/Avatar';
import { getUserInitials } from '../../../../../../../../utils/user';
import { RowRenderer } from '../../@types/project-members-table';

import styles from './AvatarCell.module.scss';

export default function AvatarCell({ rowData }: RowRenderer) {
  if (rowData.__typename === 'Membership') {
    return (
      <Avatar
        avatarInitials={getUserInitials({
          firstName: rowData.userFirstName,
          lastName: rowData.userLastName,
        })}
        size={AvatarSize.small}
        className={styles.memberAvatar}
      />
    );
  }
  return (
    <Avatar
      avatarIcon="mail"
      size={AvatarSize.small}
    />
  );
}
