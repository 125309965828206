import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import { useDebounce } from 'use-debounce';
import { useOutsideClickEffect } from '../../../utils/useOutsideClickEffect';
import Icon from '../../icon/Icon';
import RingLoader from '../../ring-loader/RingLoader';
import { BaseFieldProps, FieldWithOptionnalLabel } from './@types/field';
import { Option } from './@types/option';
import SelectFieldOption from './components/SelectFieldOption';

import styles from './SelectField.module.scss';

interface SelectFieldAutocomplete {
  isAutocomplete: true;
  acquireOptionsFn: (term: string) => Promise<Array<Option>>;
  options: undefined;
  loadingText?: string;
  canAddNewValue?: boolean;
  addNewValueLabel?: (term: string) => string;
}
interface SelectFieldWithOptions {
  isAutocomplete?: false;
  acquireOptionsFn: undefined;
  options: Array<Option>;
  loadingText: undefined;
  canAddNewValue: undefined;
  addNewValueLabel: undefined;
}

type SelectFieldProps = BaseFieldProps &
  FieldWithOptionnalLabel &
  (SelectFieldWithOptions | SelectFieldAutocomplete) & { leadingIcon?: string };

function SelectField({
  field,
  form,
  label,
  placeholder,
  labelId,
  className,
  disabled,
  leadingIcon,
  helper,
  options,
  isAutocomplete,
  acquireOptionsFn,
  loadingText,
  canAddNewValue,
  addNewValueLabel,
}: SelectFieldProps) {
  const error = form.errors?.[field.name];
  const touched = form.touched?.[field.name];

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  function toggleDropdown() {
    setIsDropdownVisible(!isDropdownVisible);
  }
  function close() {
    setIsDropdownVisible(false);
  }
  const ref = useOutsideClickEffect<HTMLDivElement>(close);

  const [isSearchOption, setIsSearchOption] = useState(false);
  const [displayedOptions, setDisplayedOptions] = useState<Array<Option>>([]);
  const [currentValue, setCurrentValue] = useState('');
  const [currentValueLabel, setCurrentValueLabel] = useState<string>('');
  useEffect(() => {
    if (!isSearchOption) {
      if (field.value && displayedOptions.length) {
        const result = displayedOptions?.find(({ value: optionValue }) => optionValue === field.value)?.label;
        if (result) {
          setCurrentValueLabel(result);
        } else {
          setCurrentValueLabel(field.value);
        }
        setCurrentValue(field.value);
      } else {
        setCurrentValueLabel(field.value);
      }
    }
  }, [field.value, displayedOptions]);
  function onChange(e: React.ChangeEvent<HTMLInputElement>) {
    setIsSearchOption(true);
    setCurrentValueLabel(e.target.value);
    setCurrentValue(e.target.value);
  }

  const [isLoadingOptions, setIsLoadingOptions] = useState(false);
  const [debouncedCurrentValue] = useDebounce(currentValueLabel, 300);
  useEffect(() => {
    if (!isAutocomplete) {
      if (options?.length) {
        setDisplayedOptions(options);
      } else {
        setDisplayedOptions([]);
      }
    }
  }, [options]);
  useEffect(() => {
    if (isAutocomplete) {
      if (field.value !== currentValue) {
        if (
          typeof acquireOptionsFn === 'function' &&
          (debouncedCurrentValue || (!displayedOptions.length && field.value))
        ) {
          if (isSearchOption) {
            setIsDropdownVisible(true);
          }
          setIsLoadingOptions(true);
          acquireOptionsFn(debouncedCurrentValue).then((newOptions) => {
            setDisplayedOptions(newOptions);
            setIsLoadingOptions(false);
          });
        }
      }
    }
  }, [debouncedCurrentValue]);

  const iconSpacer = useMemo(() => !!displayedOptions.find(({ icon }) => !!icon), [displayedOptions]);

  function handleValueChoice(value: string) {
    form.setFieldTouched(field.name);
    form.setFieldValue(field.name, value);
    setIsDropdownVisible(false);
    setIsSearchOption(false);
  }

  return (
    <div
      data-testid="select-field"
      data-test-name={field.name}
      className={classnames(styles.fieldWrapper)}
    >
      <div
        className={styles.dropdownWrapper}
        ref={ref}
      >
        <div
          className={classnames(styles.field, className, {
            [styles.focused]: isDropdownVisible,
            [styles.withValue]: currentValueLabel,
            [styles.disabled]: disabled,
          })}
          aria-invalid={(!!error && !!touched) || undefined}
        >
          {leadingIcon && (
            <Icon
              name={leadingIcon}
              className={styles.leadingIcon}
            />
          )}

          <input
            id={field.name}
            name={field.name}
            onClick={toggleDropdown}
            value={currentValueLabel}
            disabled={disabled}
            onChange={isAutocomplete ? onChange : undefined}
            onBlur={isAutocomplete ? field.onBlur : undefined}
            readOnly={!isAutocomplete}
            placeholder={placeholder}
            aria-labelledby={`${label ? `${field.name}_label` : labelId} ${helper && `${field.name}_helper`}`}
          />

          {isAutocomplete && (
            <RingLoader
              className={styles.loading}
              loading={isLoadingOptions}
            />
          )}

          <button
            type="button"
            className={styles.caretButton}
            onClick={toggleDropdown}
            disabled={disabled}
          >
            <Icon
              name={isDropdownVisible ? 'arrow_drop_up' : 'arrow_drop_down'}
              className={styles.caret}
            />
          </button>

          {label && (
            <label
              id={`${field.name}_label`}
              htmlFor={field.name}
              className={styles.label}
            >
              {label}
            </label>
          )}
        </div>
        <div
          data-testid="select-field-dropdown"
          className={styles.dropdown}
          hidden={!isDropdownVisible || (displayedOptions.length === 0 && !isLoadingOptions && !canAddNewValue)}
        >
          {isLoadingOptions ? (
            <div className={styles.loadingText}>{loadingText ?? 'Loading...'}</div>
          ) : (
            <ul className={styles.options}>
              {displayedOptions.map((option) => (
                <li
                  key={option.value}
                  className={styles.option}
                  data-testid="select-field-option"
                >
                  <SelectFieldOption
                    option={option}
                    onChange={handleValueChoice}
                    iconSpacer={iconSpacer}
                  />
                </li>
              ))}
              {canAddNewValue && (
                <li
                  data-testid="add-new-item"
                  className={styles.option}
                >
                  <SelectFieldOption
                    option={{
                      icon: 'add',
                      value: currentValueLabel,
                      label:
                        typeof addNewValueLabel === 'function'
                          ? addNewValueLabel(currentValueLabel)
                          : currentValueLabel,
                    }}
                    onChange={handleValueChoice}
                  />
                </li>
              )}
            </ul>
          )}
        </div>
      </div>
      <div className={styles.footer}>
        {helper && (!error || !touched) && (
          <p
            id={`${field.name}_helper`}
            className={styles.helper}
          >
            {helper}
          </p>
        )}
        {error && touched && (
          <p
            id={`${field.name}_error_message`}
            className={styles.error}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  );
}

export default SelectField;
