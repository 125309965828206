import React, { useEffect, useState } from 'react';
import Storage from '../../../../enums/storage';
import { getStorageItem, setStorageItem } from '../../../../utils/storage';

const LayoutContext = React.createContext({
  isSidebarOpen: true,
  /* istanbul ignore next */
  openSidebar() {},
  /* istanbul ignore next */
  closeSidebar() {},
  /* istanbul ignore next */
  showSidebar() {},
  /* istanbul ignore next */
  hideSidebar() {},
});

export interface LayoutWithDynamicSidebarProps {
  defaultVisibility?: boolean;
  children: React.ReactNode;
}
export function LayoutWithDynamicSidebar({ defaultVisibility = true, children }: LayoutWithDynamicSidebarProps) {
  const [isTemporaryVisible, setTemporaryVisibility] = useState(false);
  function showSidebar() {
    setTemporaryVisibility(true);
  }
  function hideSidebar() {
    setTemporaryVisibility(false);
  }

  const [isVisible, setVisibility] = useState<boolean>(defaultVisibility);
  function openSidebar() {
    setVisibility(true);
  }
  function closeSidebar() {
    setVisibility(false);
  }
  useEffect(() => {
    const storedVisibility = getStorageItem(Storage.SIDEBAR_OPEN_STATUS, defaultVisibility.toString());
    if (storedVisibility === defaultVisibility.toString()) {
      setVisibility(defaultVisibility);
    } else {
      setVisibility(storedVisibility !== 'false');
    }
  }, []);
  useEffect(() => {
    setStorageItem(Storage.SIDEBAR_OPEN_STATUS, isVisible.toString());
  }, [isVisible]);

  return (
    <LayoutContext.Provider
      value={{
        isSidebarOpen: isVisible || isTemporaryVisible,
        openSidebar,
        closeSidebar,
        showSidebar,
        hideSidebar,
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
}

export default LayoutContext;
