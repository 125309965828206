import { gql } from '@apollo/client';

export const GET_PROGRAM_PARTICIPATIONS_MEETING_QUANTITY = gql`
  query GET_PROGRAM_PARTICIPATIONS_MEETING_QUANTITY($programId: ID!) {
    program(id: $programId) {
      id
      meetingsQuantity
    }
  }
`;

export const GET_PARTICIPANTS_AND_INVITATIONS = gql`
  query GET_PARTICIPANTS_AND_INVITATIONS(
    $programId: ID!
    $orderBy: ParticipationAndInvitationFields!
    $order: SortDirection!
    $term: String
    $cursor: String
    $query: [ParticipationsAndInvitationsQueryFilterInput!]
  ) {
    activeParticipants: participationsAndInvitations(
      first: 75
      after: $cursor
      programId: $programId
      archived: false
      filter: { query: $query, search: $term }
      sort: { orderBy: $orderBy, order: $order }
    ) {
      total
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          archivedAt
          bookmarkedProfessionalsCount
          certificateUrl
          certificationStatus
          confirmedMeetingCount
          conversationsCount
          createdAt
          id
          incomingMeetingCount
          invitationStatus
          programId
          updatedAt
          user {
            id
          }
          userEmail
          userFirstName
          userId
          userLastName
          visitsCount
          reportCount
          unfollowedConversationCount
          waitingForConfirmationMeetingCount
        }
      }
    }
    archivedParticipants: participationsAndInvitations(
      first: 75
      after: $cursor
      programId: $programId
      archived: true
      filter: { query: [], search: $term }
      sort: { orderBy: $orderBy, order: $order }
    ) {
      total
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        cursor
        node {
          archivedAt
          bookmarkedProfessionalsCount
          certificateUrl
          certificationStatus
          confirmedMeetingCount
          conversationsCount
          createdAt
          id
          incomingMeetingCount
          invitationStatus
          programId
          updatedAt
          user {
            id
          }
          userEmail
          userFirstName
          userId
          userLastName
          visitsCount
          reportCount
          unfollowedConversationCount
          waitingForConfirmationMeetingCount
        }
      }
    } 
  }
`;

export const REMOVE_PARTICIPANT_OR_INVITATION_FROM_PROGRAM = gql`
  mutation REMOVE_PARTICIPANT_OR_INVITATION_FROM_PROGRAM($id: ID!) {
    deleteParticipationOrInvitation(id: $id) {
      id
    }
  }
`;


export const RECOVER_PARTICIPATION = gql`
  mutation RECOVER_PARTICIPATION($id: ID!) {
    recoverParticipation(id: $id) {
      id
    }
  }
`;
