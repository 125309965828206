import { useQuery } from '@apollo/client';
import {
  Get_Project_TreeQuery,
  Get_Project_TreeQueryVariables,
  Group,
  Maybe,
  Program,
  ProgramProgressionStatus,
} from '../../@types/graphql';
import { GET_PROJECT_TREE } from './useProjectTree.gql';

type RawGraphQLPrograms = Pick<Program, 'id' | 'name' | 'programProgressionStatus'> & {
  canShow: Pick<Program['canShow'], 'value'>;
};
type RawGraphQLGroup = Pick<Group, 'id' | 'name'> & {
  groups?: Maybe<{
    nodes: RawGraphQLGroup[];
  }>;
  programs?: Maybe<{
    nodes: RawGraphQLPrograms[];
  }>;
};

export type ProjectTreeProgram = {
  id: string;
  name: string;
  canShow: boolean;
  status: ProgramProgressionStatus;
};
export type ProjectTreeGroup = {
  id: string;
  name: string;
  groups: ProjectTreeGroup[];
  programs: ProjectTreeProgram[];
};
type ResultProjectTree = {
  id: string;
  name: string;
  groups: ProjectTreeGroup[];
  programs: ProjectTreeProgram[];
};

function transformProgram(program: RawGraphQLPrograms): ProjectTreeProgram {
  return {
    id: program.id,
    name: program.name,
    canShow: program.canShow.value,
    status: program.programProgressionStatus,
  };
}

function transformGroup(group: RawGraphQLGroup): ProjectTreeGroup {
  return {
    id: group.id,
    name: group.name,
    groups: group.groups?.nodes.map(transformGroup) || [],
    programs: group.programs?.nodes.map(transformProgram) || [],
  };
}

export default function useProjectTree(projectId: string): ResultProjectTree | undefined {
  const { data } = useQuery<Get_Project_TreeQuery, Get_Project_TreeQueryVariables>(GET_PROJECT_TREE, {
    variables: {
      projectId,
    },
  });

  if (!data?.project) {
    return undefined;
  }

  return {
    id: data.project.id,
    name: data.project.name,
    groups: data.project.directGroups?.nodes.map(transformGroup) || [],
    programs: data.project.directPrograms?.nodes.map(transformProgram) || [],
  };
}
